<div fxLayout="column" fxFill>
  <div>
    <pt-feature-info />
    <pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
    <div class="border-b border-solid border-slate-300"></div>
  </div>

  <div fxFlex class="content">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div fxLayout="column" fxLayoutGap="16px" class="wrapper">
        <h1 class="mat-headline-4">General Settings</h1>

        <div class="mb-4">
          <mat-card appearance="outlined">
            <mat-card-header class="mb-4">
              <mat-card-title>Brand</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="column">
                <div fxFlex="row">
                  <mat-form-field fxFlex>
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required />
                  </mat-form-field>
                </div>

                <div fxFlex="row" formGroupName="settings">
                  <mat-form-field fxFlex>
                    <mat-label>Timezone</mat-label>
                    <mat-select formControlName="timezone" required>
                      <mat-option
                        *ngFor="
                          let timezone of timezones;
                          trackBy: trackByTimezone
                        "
                        [value]="timezone"
                      >
                        {{ timezone }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxLayout="column" fxLayoutGap="16px" formGroupName="settings">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>Appointment Scheduling</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <div formGroupName="scheduling">
                <pt-settings-menu-slide-toggle-item
                  label="Enable Waitlist By Default"
                  tooltip="Select whether the waitlist is enabled by default"
                  formControlName="defaultWaitlistSettingsOn"
                  ptElementTarget="enable-waitlist-by-default-button"
                />
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>Treatment Plan Management</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <div formGroupName="treatmentPlanning">
                <pt-settings-menu-slide-toggle-item
                  label="Auto-complete treatment plan after last step"
                  tooltip="Enabling this will automatically transition Treatment Plans to 'Completed' when the final step of the plan is completed"
                  formControlName="autoCompletePlanAfterLastStep"
                  ptElementTarget="auto-complete-treatment-plan-after-last-step-button"
                />

                <pt-settings-menu-slide-toggle-item
                  label="Include Completed Treatment Plans in the Timeline scheduling workflow"
                  tooltip="Enabling this will give controls to add new steps to completed treatment plans in the timeline scheduling workflow"
                  formControlName="includeCompletedTreatmentPlansInTimeline"
                  ptElementTarget="include-completed-treatment-plans-in-timeline-button"
                />
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>Online Bookings</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <div formGroupName="onlineBookings">
                <pt-settings-menu-slide-toggle-item
                  label="Restrict Availability to Pre Blocks"
                  tooltip="Only show availability for pre-blocks with 'Can be Booked Online' is selected'"
                  formControlName="restrictToPreBlocks"
                />
                <pt-settings-menu-text-field
                  label="Booking Request Success Message"
                  tooltip="Enter the message that will be displayed to the patient after they have submitted a booking request."
                  [placeholder]="onlineBookingSuccessMessagePlaceholder"
                  formControlName="requestSuccessMessage"
                />
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>Online Forms</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <div class="flex flex-col" formGroupName="patientDetailsForm">
                <div class="inner-container flex flex-col">
                  <h4 class="sub-header">Patient Details</h4>

                  <pt-settings-menu-multi-select
                    label="Required Patient Fields"
                    formControlName="requiredFields"
                    tooltip="Select whether any optional patient fields should be required to be filled out"
                    [options]="patientFields"
                  />
                  <span class="warn self-end">
                    {{
                      'patientDetailsForm'
                        | map: integrationWarningMessage$ : this
                        | async
                    }}
                  </span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>Patient</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <div class="flex flex-col" formGroupName="patient">
                <pt-settings-menu-multi-select
                  label="Required Patient Fields"
                  formControlName="requiredFields"
                  tooltip="Select whether any optional patient fields should be required to be filled out"
                  [options]="patientFields"
                  ptElementTarget="required-patient-fields-button"
                />
                <span class="warn self-end">
                  {{
                    'patient' | map: integrationWarningMessage$ : this | async
                  }}
                </span>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>Optional Display Information</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <div formGroupName="optionalDisplayInformation">
                <pt-settings-menu-multi-select
                  label="Appointment Confirmation Page"
                  formControlName="appointmentConfirmationInfo"
                  tooltip="Select additional information to display on the appointment confirmation page"
                  [options]="optionalAppointmentConfirmationInfo"
                  ptElementTarget="optional-appointment-confirmation-info-button"
                />
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="flex flex-col gap-4" *prIsManagement>
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title>Management Only Settings</mat-card-title>
            </mat-card-header>

            <mat-card-content>
              <div class="flex flex-col pt-4" formGroupName="settings">
                <mat-form-field fxFlex>
                  <mat-label>Enabled Managed Reports</mat-label>
                  <mat-select multiple formControlName="availableSystemReports">
                    @for (
                      managedReport of managedReportOptions;
                      track managedReport.type
                    ) {
                      <mat-option
                        [value]="managedReport.type"
                        [matTooltip]="managedReport.description"
                        matTooltipPosition="right"
                        matTooltipShowDelay="300"
                      >
                        {{ managedReport.name }}
                      </mat-option>
                    }
                  </mat-select>
                  <mat-hint>{{ defaultReportsSummary }}</mat-hint>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </form>
  </div>

  <pt-page-footer>
    <pt-buttons-container padding="horizontal">
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="isDisabled$ | async"
        (click)="save()"
      >
        Save Changes
      </button>
    </pt-buttons-container>
  </pt-page-footer>
</div>
