import { toChartedRef } from '@principle-theorem/principle-core';
import {
  Arch,
  ChartableSurface,
  Quadrant,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IDentalChartView,
  type IDentalChartViewArch,
  type IDentalChartViewMouth,
  type IDentalChartViewQuadrant,
  type IDentalChartViewSurface,
  type IDentalChartViewTooth,
  type ITooth,
  type IChartedSurface,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  isSameRef,
  sortTimestamp,
  toTimestamp,
  type DocumentReference,
  type WithRef,
  Timestamp,
} from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import { ChartedItemsCollection } from '../models/charted-items-collection';

interface IChartedItemWithConfig {
  item: IChartedItem<object>;
  config: WithRef<IChartedItemConfiguration>;
}

export class ChartViewRenderer {
  render: IDentalChartView;

  constructor(
    private _teeth: ITooth[],
    private _chartedItems: IChartedItem[],
    private _configurations: WithRef<IChartedItemConfiguration>[]
  ) {
    this.render = this._buildAll(this._teeth, this._chartedItems);
  }

  private _buildAll(
    teeth: ITooth[],
    chartedItems: IChartedItem[]
  ): IDentalChartView {
    return {
      mouth: this._buildMouth(teeth, chartedItems),
    };
  }

  private _buildMouth(
    teeth: ITooth[],
    chartedItems: IChartedItem[]
  ): IDentalChartViewMouth {
    const chartedRef = toChartedRef({
      surface: ChartableSurface.WholeMouth,
    });

    return {
      ...this._buildBaseView(chartedItems, chartedRef),
      ...this._buildArches(teeth, chartedItems),
    };
  }

  private _buildArches(
    teeth: ITooth[],
    chartedItems: IChartedItem[]
  ): DentalChartViewArches {
    return {
      upper: this._buildArch(teeth, chartedItems, Arch.Upper),
      lower: this._buildArch(teeth, chartedItems, Arch.Lower),
    };
  }

  private _buildArch(
    teeth: ITooth[],
    chartedItems: IChartedItem[],
    area: Arch
  ): IDentalChartViewArch {
    const chartedRef = toChartedRef({
      surface: ChartableSurface.Arch,
      area,
    });

    return {
      ...this._buildBaseView(chartedItems, chartedRef),
      ...this._buildQuadrants(teeth, chartedItems, area),
    };
  }

  private _buildQuadrants(
    teeth: ITooth[],
    chartedItems: IChartedItem[],
    arch: Arch
  ): DentalChartViewQuadrants {
    let leftAdultQuadrant: IDentalChartViewQuadrant;
    let leftDeciduousQuadrant: IDentalChartViewQuadrant;
    let rightAdultQuadrant: IDentalChartViewQuadrant;
    let rightDeciduousQuadrant: IDentalChartViewQuadrant;

    if (arch === Arch.Upper) {
      leftAdultQuadrant = this._buildQuadrant(
        teeth,
        chartedItems,
        Quadrant.AdultUpperLeft
      );
      leftDeciduousQuadrant = this._buildQuadrant(
        teeth,
        chartedItems,
        Quadrant.DeciduousUpperLeft
      );
      rightAdultQuadrant = this._buildQuadrant(
        teeth,
        chartedItems,
        Quadrant.AdultUpperRight
      );
      rightDeciduousQuadrant = this._buildQuadrant(
        teeth,
        chartedItems,
        Quadrant.DeciduousUpperRight
      );
    } else {
      leftAdultQuadrant = this._buildQuadrant(
        teeth,
        chartedItems,
        Quadrant.AdultLowerLeft
      );
      leftDeciduousQuadrant = this._buildQuadrant(
        teeth,
        chartedItems,
        Quadrant.DeciduousLowerLeft
      );
      rightAdultQuadrant = this._buildQuadrant(
        teeth,
        chartedItems,
        Quadrant.AdultLowerRight
      );
      rightDeciduousQuadrant = this._buildQuadrant(
        teeth,
        chartedItems,
        Quadrant.DeciduousLowerRight
      );
    }

    return {
      left: {
        adult: leftAdultQuadrant,
        deciduous: leftDeciduousQuadrant,
      },
      right: {
        adult: rightAdultQuadrant,
        deciduous: rightDeciduousQuadrant,
      },
    };
  }

  private _buildQuadrant(
    teeth: ITooth[],
    chartedItems: IChartedItem[],
    area: Quadrant
  ): IDentalChartViewQuadrant {
    const chartedRef = toChartedRef({
      surface: ChartableSurface.Quadrant,
      area,
    });

    return {
      ...this._buildBaseView(chartedItems, chartedRef),
      teeth: this._buildTeeth(teeth, chartedItems, area),
    };
  }

  private _buildTeeth(
    teeth: ITooth[],
    chartedItems: IChartedItem[],
    quadrant: Quadrant
  ): IDentalChartViewTooth[] {
    const filteredTeeth = teeth.filter(
      (tooth) => tooth.toothRef.quadrant === quadrant
    );

    return !filteredTeeth.length
      ? []
      : filteredTeeth.map((tooth) => this._buildTooth(chartedItems, tooth));
  }

  private _buildTooth(
    chartedItems: IChartedItem[],
    tooth: ITooth
  ): IDentalChartViewTooth {
    const chartedRef = toChartedRef({
      surface: ChartableSurface.WholeTooth,
      tooth,
    });
    return {
      ...this._buildBaseView(chartedItems, chartedRef),
      ...this._buildToothSurfaces(chartedItems, tooth),
      tooth,
    };
  }

  private _buildToothSurfaces(
    chartedItems: IChartedItem[],
    tooth: ITooth
  ): DentalChartViewToothSurfaces {
    const mesial = this._buildToothSurface(
      chartedItems,
      tooth,
      ChartableSurface.Mesial
    );
    const distal = this._buildToothSurface(
      chartedItems,
      tooth,
      ChartableSurface.Distal
    );
    const occlusal = this._buildToothSurface(
      chartedItems,
      tooth,
      ChartableSurface.Occlusal
    );
    const lingual = this._buildToothSurface(
      chartedItems,
      tooth,
      ChartableSurface.Lingual
    );
    const facial = this._buildToothSurface(
      chartedItems,
      tooth,
      ChartableSurface.Facial
    );

    return {
      mesial,
      distal,
      occlusal,
      lingual,
      facial,
    };
  }

  private _buildToothSurface(
    chartedItems: IChartedItem[],
    tooth: ITooth,
    surface: ChartableSurface
  ): IDentalChartViewSurface {
    const chartedRef: Partial<IChartedRef> = toChartedRef({
      surface,
      tooth,
    });

    return this._buildBaseView(chartedItems, chartedRef);
  }

  private _buildBaseView(
    chartedItems: IChartedItem[],
    chartedRef: Partial<IChartedRef>
  ): IDentalChartViewSurface {
    const filteredChartedItems: IChartedItem[] = new ChartedItemsCollection(
      chartedItems
    )
      .filterByChartedRef(chartedRef)
      .toArray();
    const badge: number = filteredChartedItems.length;

    const surface: IDentalChartViewSurface = {
      id: chartedRef,
      badge,
      chartedItems: [],
    };

    if (!filteredChartedItems.length) {
      return surface;
    }

    const chartedItemConfigs = compact(
      filteredChartedItems.map((chartedItem) => {
        const chartedItemConfig = this._resolveChartedItemConfiguration(
          asDocRef<IChartedItemConfiguration>(chartedItem.config.ref)
        );

        if (!chartedItemConfig) {
          return;
        }

        return {
          item: chartedItem,
          config: chartedItemConfig,
        };
      })
    );

    const sorted = this._sortedChartedItemConfigs(chartedItemConfigs);
    const texture = first(sorted)?.config.display;
    return {
      ...surface,
      texture,
      chartedItems: sorted,
    };
  }

  private _getChartedSurfaceDate(chartedSurface: IChartedSurface): Timestamp {
    return chartedSurface.resolvedAt ?? chartedSurface.chartedAt;
  }

  private _sortedChartedItemConfigs(
    chartedItemConfigs: IChartedItemWithConfig[]
  ): IChartedItemWithConfig[] {
    return chartedItemConfigs
      .map((chartedItemConfig) => {
        const sortedSurfaces = chartedItemConfig.item.chartedSurfaces.sort(
          (surfaceA, surfaceB) =>
            sortTimestamp(
              this._getChartedSurfaceDate(surfaceA),
              this._getChartedSurfaceDate(surfaceB)
            )
        );
        const firstSurface = first(sortedSurfaces);
        const latestTimestamp = firstSurface
          ? this._getChartedSurfaceDate(firstSurface)
          : toTimestamp();
        return {
          chartedItemConfig,
          latestTimestamp,
        };
      })
      .sort((chartedItemA, chartedItemB) =>
        sortTimestamp(
          chartedItemA.latestTimestamp,
          chartedItemB.latestTimestamp
        )
      )
      .map(({ chartedItemConfig }) => chartedItemConfig);
  }

  private _resolveChartedItemConfiguration(
    configRef: DocumentReference<IChartedItemConfiguration>
  ): WithRef<IChartedItemConfiguration> | undefined {
    return this._configurations.find((configuration) =>
      isSameRef(configuration, configRef)
    );
  }
}

export type DentalChartViewToothSurfaces = Pick<
  IDentalChartViewTooth,
  'distal' | 'facial' | 'lingual' | 'mesial' | 'occlusal'
>;
export type DentalChartViewQuadrants = Pick<
  IDentalChartViewArch,
  'left' | 'right'
>;
export type DentalChartViewArches = Pick<
  IDentalChartViewMouth,
  'upper' | 'lower'
>;
