import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ITransactionActionsData } from '@principle-theorem/ng-payments';
import {
  CSVExporterService,
  ObservableDataSource,
  extendSortingDataAccessor,
  timestampSortingAccessor,
} from '@principle-theorem/ng-shared';
import {
  Patient,
  isManualTransaction,
} from '@principle-theorem/principle-core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  multiMap,
  snapshot,
  toMoment,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { startCase, sum } from 'lodash';
import { ReplaySubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { type ILatestTransactionReportRecord } from '../../../core/reporting-functions';
import { PendingPaymentToCSV } from './pending-payment-to-csv';

@Component({
  selector: 'pr-pending-payments-table',
  templateUrl: './pending-payments-table.component.html',
  styleUrls: ['./pending-payments-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingPaymentsReportTableComponent implements OnDestroy {
  records$ = new ReplaySubject<ILatestTransactionReportRecord[]>(1);
  dateRange$ = new ReplaySubject<ITimePeriod>(1);
  totalAmount$: Observable<number>;
  dataSource: ObservableDataSource<ILatestTransactionReportRecord>;
  dateFormat = DAY_MONTH_YEAR_FORMAT;
  displayedColumns = [
    'patientName',
    'transactionDate',
    'invoice',
    'transactionType',
    'transactionProvider',
    'transactionProviderType',
    'transactionDescription',
    'amount',
    'actions',
  ];

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input()
  set records(records: ILatestTransactionReportRecord[]) {
    if (records) {
      this.records$.next(records);
    }
  }

  constructor(private _csvExporter: CSVExporterService) {
    this.dataSource = new ObservableDataSource(this.records$);
    this.dataSource.filterPredicate = this._filterPredicate;
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );

    this.totalAmount$ = this.dataSource.items$.pipe(
      multiMap((item) => item.latest.amount),
      map(sum)
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  getMobileNumber(patient: WithRef<IPatient>): string | undefined {
    return Patient.getMobileNumber(patient);
  }

  async downloadCSV(): Promise<void> {
    const range = await snapshot(this.dateRange$);
    const startDate = toMoment(range.from).format(DAY_MONTH_YEAR_FORMAT);
    const endDate = toMoment(range.to).format(DAY_MONTH_YEAR_FORMAT);
    const fileName = `pending-payments-${startDate}-${endDate}`;
    const records = await snapshot(this.records$);
    await this._csvExporter.download(
      fileName,
      records,
      new PendingPaymentToCSV()
    );
  }

  getActionsData(
    record: ILatestTransactionReportRecord
  ): ITransactionActionsData<unknown> {
    return {
      invoice: record.invoice,
      transaction: record.latest,
      latestTransaction: record.latest,
    };
  }

  filterResults(filterValue: string): void {
    this.dataSource.filter = filterValue;
  }

  private _sortingDataAccessor(
    data: ILatestTransactionReportRecord,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'transactionDate':
        return timestampSortingAccessor(data.latest.createdAt);
      default:
        return;
    }
  }

  private _filterPredicate(
    record: ILatestTransactionReportRecord,
    filter: string
  ): boolean {
    const filterValues: string[] = [
      record.patient.name,
      record.invoice.reference,
      record.latest.reference,
      record.latest.type,
      startCase(record.latest.provider),
      toMoment(record.latest.createdAt).format(DAY_MONTH_YEAR_FORMAT),
      isManualTransaction(record.latest)
        ? record.latest.extendedData?.transactionType?.name ?? ''
        : '',
      record.latest.description ?? '',
    ];

    const taskFilterString: string = filterValues.join(' ').toLowerCase();
    const transformedFilter: string = filter.trim().toLowerCase();

    return taskFilterString.indexOf(transformedFilter) !== -1;
  }
}
