import {
  TypeGuard,
  isDocRef,
  isEnumValue,
  isINamedDocument,
  isObject,
  isTimestamp,
  type ArchivedDocument,
  type DocumentReference,
  type IFirestoreModel,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { type IAccountDetails } from '../account/account';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import {
  TransactionAction,
  isTransaction,
  type ITransaction,
} from '../transaction/transaction';
import {
  isCustomLineItem,
  type ICustomLineItem,
  type IDepositLineItem,
  type ITreatmentLineItem,
} from './custom-line-items';
import { IHealthcareClaim, isHealthcareClaim } from './healthcare-claim';
import {
  type IHasLineItems,
  type IInvoiceItemGroupWithProvider,
} from './line-item';
import { IInvoiceSummary } from '../patient/account-summary';

export enum InvoiceCollection {
  Transactions = 'transactions',
  InvoiceHistory = 'invoiceHistory',
  InvoiceInteractions = 'invoiceInteractions',
}

export enum InvoiceStatus {
  Draft = 'draft',
  Issued = 'issued',
  Cancelled = 'cancelled',
  WrittenOff = 'writtenOff',
  Paid = 'paid',
}

export interface IInvoiceStatusLog {
  status: InvoiceStatus;
  updatedAt: Timestamp;
}

export interface IInvoiceCancellation {
  reason: string;
  replacement?: DocumentReference<IInvoice>;
}

export interface IInvoiceWithProviderDetails {
  invoice: WithRef<IInvoice>;
  balance: IBalance;
  transactions?: WithRef<ITransaction<unknown>>[];
  groups?: IInvoiceItemGroupWithProvider[];
}

export type CancelledInvoice = IBaseInvoice & {
  status: InvoiceStatus.Cancelled;
  cancelledAt: Timestamp;
  cancellation: IInvoiceCancellation;
};

export type CancelledAndReplacedInvoice = CancelledInvoice & {
  cancellation: Required<IInvoiceCancellation>;
};

export type IssuedInvoice = IBaseInvoice & {
  status: InvoiceStatus.Issued;
  issuedAt: Timestamp;
};

export type PaidInvoice = IBaseInvoice & {
  status: InvoiceStatus.Paid;
  issuedAt: Timestamp;
  paidAt: Timestamp;
};

export function isPaidInvoice(data: unknown): data is PaidInvoice {
  return (
    isObject(data) &&
    isBaseInvoice(data) &&
    isTimestamp(data.paidAt) &&
    data.status === InvoiceStatus.Paid
  );
}

export const STATUS_COLOUR_MAP: { [key in InvoiceStatus]: string } = {
  [InvoiceStatus.Draft]: 'default',
  [InvoiceStatus.Cancelled]: 'accent',
  [InvoiceStatus.Issued]: 'primary',
  [InvoiceStatus.Paid]: 'success',
  [InvoiceStatus.WrittenOff]: 'warn',
};

export type ActiveInvoice = IBaseInvoice & {
  status: InvoiceStatus.Draft | InvoiceStatus.Issued | InvoiceStatus.Paid;
};

export enum InvoiceType {
  Invoice = 'invoice',
  CreditNote = 'creditNote',
}

export interface IBaseInvoice extends IFirestoreModel, IHasLineItems {
  reference: string;
  from: IAccountDetails;
  to: IAccountDetails;
  statusHistory: IInvoiceStatusLog[];
  type?: InvoiceType;
  summary: IInvoiceSummary;
  due?: Timestamp;
  issuedAt?: Timestamp;
  paidAt?: Timestamp;
  items: ICustomLineItem[];
  practice: INamedDocument<IPractice>;
  amendmentOf?: DocumentReference<ArchivedDocument<IInvoice>>;
  claims?: IHealthcareClaim[];
}

export function isBaseInvoice(data: unknown): data is IBaseInvoice {
  return (
    isObject(data) &&
    TypeGuard.arrayOf(isCustomLineItem)(data.items) &&
    isINamedDocument(data.practice)
  );
}

export type IInvoice =
  | (IBaseInvoice & {
      status: InvoiceStatus.WrittenOff;
    })
  | ActiveInvoice
  | IssuedInvoice
  | PaidInvoice
  | CancelledInvoice
  | CancelledAndReplacedInvoice;

export interface IInvoicePracitionerSummary {
  practitioner: INamedAllocationTarget;
  treatments: ITreatmentLineItem[];
  treatmentsTotal: number;
  deposits: IDepositLineItem[];
  depositsTotal: number;
  practitionerTotal: number;
  allocatedPercent: number;
}

export interface IPaymentPracitionerSummary {
  practitioner: INamedAllocationTarget;
  allocatedPercent: number;
  allocatedAmount: number;
}

export interface IAddTransactionRequest {
  invoiceRef: DocumentReference<IInvoice>;
  transaction: ITransaction;
  action: TransactionAction;
  claim?: IHealthcareClaim;
}

export function isAddTransactionRequest(
  data: unknown
): data is IAddTransactionRequest {
  return (
    isObject(data) &&
    isDocRef(data.invoiceRef) &&
    isTransaction(data.transaction) &&
    TypeGuard.undefinedOr(isHealthcareClaim)(data.claim) &&
    isEnumValue(TransactionAction, data.action)
  );
}

export interface IBalance {
  subtotal: number;
  tax: number;
  treatments: number;
  deposits: number;
  other: number;
  total: number;
  paidToDate: number;
  balance: number;
}

export enum SpecialTransactionAllocationTarget {
  Unallocated = 'unallocated',
}

export type TransactionAllocationTarget =
  | DocumentReference<IStaffer>
  | SpecialTransactionAllocationTarget;

export interface ITransactionAllocation {
  allocatedTo: TransactionAllocationTarget;
  allocatedProportion: number;
  allocatedAmount: number;
}

export interface IInvoiceTransactionAllocations {
  transactionRef: DocumentReference<ITransaction<unknown>>;
  allocations: ITransactionAllocation[];
}

export interface IAllocationSummary {
  allocatedTo: TransactionAllocationTarget;
  allocatedAmount: number;
}

export interface INamedAllocationTarget {
  name: string;
  ref: TransactionAllocationTarget;
}
