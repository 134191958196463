<ng-container *ngIf="clinicalNotes$ | async as clinicalNotes">
  <div
    class="notes box-border flex h-full min-h-full w-full flex-col"
  >
    <ng-container *ngIf="clinicalNotes.length; else noNotes">
      <ng-container
        *ngFor="
          let note of clinicalNotes;
          trackBy: trackByNote;
          let isLast = last
        "
      >
        <div class="layout-margin note">
          <div class="header flex justify-between gap-4">
            <div>
              <h3>{{ note.owner.name }}</h3>
              <div class="flex gap-4">
                <p class="mat-caption">
                  Record Date
                  <span class="time">
                    {{ note.recordDate | moment | amDateFormat: dateFormat }}
                  </span>
                </p>
                <p class="mat-caption">
                  Last Modified
                  <span class="time">
                    {{ note.updatedAt | moment | amTimeAgo }}
                  </span>
                </p>
                <div
                  class="lock flex gap-1.5"
                  [ngClass]="{ 'unlocked-warning': note.immutable === false }"
                >
                  <mat-icon>
                    {{ note.immutable ? 'lock' : 'lock_open' }}
                  </mat-icon>
                  <span class="mat-caption">
                    {{ note.immutable ? 'Completed' : 'Incomplete' }}
                  </span>
                </div>
              </div>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="moreMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu="matMenu">
              <span [matTooltip]="note | map: noteLockedMessage">
                <button
                  [disabled]="note.immutable"
                  mat-menu-item
                  (click)="editClinicalNote(note)"
                >
                  <mat-icon>edit</mat-icon>
                  Edit Clinical Note
                </button>
              </span>
              <button
                *ngIf="note | map: isNoteOwner$:this | async"
                (click)="toggleLock(note)"
                mat-menu-item
              >
                <mat-icon>{{ note.immutable ? 'lock' : 'lock_open' }}</mat-icon>
                {{ note.immutable ? 'Unlock' : 'Lock' }} Clinical Note
              </button>
            </mat-menu>
          </div>
          <pr-content-view
            class="content"
            [content]="note.content"
           />
        </div>
        <mat-divider *ngIf="!isLast" />
      </ng-container>
    </ng-container>

    <ng-template #noNotes>
      <div class="layout-margin mat-caption">No clinical notes</div>
    </ng-template>
  </div>
</ng-container>
