import { type ICSVExport } from '@principle-theorem/ng-shared';
import {
  getTransactionProviderName,
  Invoice,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import { type ITransaction } from '@principle-theorem/principle-core/interfaces';
import { type IInvoiceReportRecord } from '@principle-theorem/reporting';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';
import { groupBy, toPairs } from 'lodash';

export interface IOutstandingInvoicesCSV {
  createdAt: string;
  status: string;
  invoice: string;
  patient: string;
  issuedAt: string;
  due: string;
  amount: string;
  outstanding: string;
  pending: string;
}

export class OutstandingInvoicesToCSV
  implements ICSVExport<IInvoiceReportRecord, IOutstandingInvoicesCSV>
{
  headers = [
    'Created At',
    'Status',
    'Invoice No.',
    'Patient',
    'Issued At',
    'Due',
    'Amount',
    'Outstanding',
    'Pending',
  ];

  translate(records: IInvoiceReportRecord[]): IOutstandingInvoicesCSV[] {
    return records.map((record) => {
      const pendingProviders = toPendingProviders(record);
      const pending = pendingProviders
        .map((row) => `${row.provider}: ${row.total}`)
        .join(', ');

      return {
        createdAt: toMoment(record.invoice.createdAt).format(
          DAY_MONTH_YEAR_FORMAT
        ),
        status: record.invoice.status,
        invoice: record.invoice.reference,
        patient: record.patient.name,
        issuedAt: record.invoice.issuedAt
          ? toMoment(record.invoice.issuedAt).format(DAY_MONTH_YEAR_FORMAT)
          : '',
        due: record.invoice.due
          ? toMoment(record.invoice.due).format(DAY_MONTH_YEAR_FORMAT)
          : '',
        amount: Invoice.total(record.invoice).toString(),
        outstanding: Invoice.balance(
          record.invoice,
          record.transactions
        ).toString(),
        pending,
      };
    });
  }
}

export interface IPendingProviderRow {
  provider: string;
  transactions: ITransaction[];
  total: number;
}

export function toPendingProviders(
  record: IInvoiceReportRecord
): IPendingProviderRow[] {
  const allTransactions = new TransactionOperators(record.transactions);
  const incompleteTransactions = allTransactions
    .pending()
    .filter(
      (pendingTransaction) =>
        allTransactions
          .byReference(pendingTransaction.reference)
          .completed()
          .result().length < 1
    )
    .result();

  const grouped = groupBy(incompleteTransactions, (transaction) =>
    getTransactionProviderName(transaction)
  );

  return toPairs(grouped).map(([provider, transactions]) => ({
    provider,
    transactions,
    total: new TransactionOperators(transactions).sum(),
  }));
}
