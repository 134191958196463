import { createAction, props } from '@ngrx/store';
import {
  type ILabJob,
  type IPatient,
  type LabJobStatus,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type LabJobPresetFilter } from '../lab-job-preset-filters';
import { type LabJobSortId } from '../lab-job-sort-options';

export enum LabJobsActions {
  LoadPracticeLabJobs = '[Lab Jobs] Load Practice Lab Jobs',
  LoadPatientLabJobs = '[Lab Job] Load Lab Jobs For Patient',
  LoadLabJobsSuccess = '[Lab Jobs] Load Lab Jobs Success',
  LoadLabJobsFailure = '[Lab Jobs] Load Lab Jobs Failure',
  SelectLabJob = '[Lab Jobs] Select Lab Job',
  ViewLabJob = '[Lab Jobs] View Lab Job',
  AddLabJob = '[Lab Jobs] Add Lab Job',
  SelectFromRoute = '[Lab Jobs] Select Lab Job From Route',
  LoadLabJobFromRoute = '[Lab Jobs] Load Lab Job From Route',
  LoadLabJobFromRouteSuccess = '[Lab Jobs] Load Lab Job From Route Success',
  LoadLabJobFromRouteFailure = '[Lab Jobs] Load Lab Job From Route Failure',
  ClearSelectedLabJob = '[Lab Jobs] Clear Selected Lab Job',
  LoadLabJobsForLab = '[Lab - Lab Jobs] Load Lab Jobs',
  LoadLabJobsForLabSuccess = '[Lab - Lab Jobs] Load Lab Jobs Success',
}

export enum LabJobsFilteringActions {
  SetStatusFilter = '[Lab Jobs Filter] Set Status Filter',
  SetPresetFilter = '[Lab Jobs Filter] Set Preset Filter',
  SetSortOption = '[Lab Jobs Filter] Set Sort Option',
  SetDateRange = '[Lab Jobs Filter] Set Date Range',
  Cancel = '[Lab Jobs Filter] Cancel Filter Action',
}

export interface ILoadPatientLabJobs {
  patientRef: DocumentReference<IPatient>;
}

export interface ILoadLabJobsSuccess {
  labJobs: WithRef<ILabJob>[];
}

export interface ILoadLabJobsFailure {
  error: string;
}

export interface ISelectLabJob {
  id: string;
}

export interface ISetLabJobStatusFilter {
  status: LabJobStatus;
}

export interface ISetSortOption {
  id: LabJobSortId;
}

export interface ISetLabJobPresetFilter {
  preset: LabJobPresetFilter;
}

export interface ILoadLabJobSuccess {
  labJob: WithRef<ILabJob>;
}

export interface ILoadLabJobsForLab {
  id: string;
}

export const loadPracticeLabJobs = createAction(
  LabJobsActions.LoadPracticeLabJobs
);
export const loadPatientLabJobs = createAction(
  LabJobsActions.LoadPatientLabJobs,
  props<SerialisedData<ILoadPatientLabJobs>>()
);

export const loadLabJobsSuccess = createAction(
  LabJobsActions.LoadLabJobsSuccess,
  props<SerialisedData<ILoadLabJobsSuccess>>()
);

export const loadLabJobsFailure = createAction(
  LabJobsActions.LoadLabJobsFailure,
  props<ILoadLabJobsFailure>()
);

export const selectLabJob = createAction(
  LabJobsActions.SelectLabJob,
  props<ISelectLabJob>()
);

export const viewLabJob = createAction(LabJobsActions.ViewLabJob);
export const addLabJob = createAction(LabJobsActions.AddLabJob);

export const setStatusFilter = createAction(
  LabJobsFilteringActions.SetStatusFilter,
  props<ISetLabJobStatusFilter>()
);

export const setPresetFilter = createAction(
  LabJobsFilteringActions.SetPresetFilter,
  props<ISetLabJobPresetFilter>()
);

export const setSortOption = createAction(
  LabJobsFilteringActions.SetSortOption,
  props<ISetSortOption>()
);

export const cancelFilterAction = createAction(LabJobsFilteringActions.Cancel);

export const selectJobFromRoute = createAction(
  LabJobsActions.SelectFromRoute,
  props<ISelectLabJob>()
);

export const clearSelectedLabJob = createAction(
  LabJobsActions.ClearSelectedLabJob
);

export const loadLabJobsForLab = createAction(
  LabJobsActions.LoadLabJobsForLab,
  props<ILoadLabJobsForLab>()
);

export const loadLabJobFromRouteSuccess = createAction(
  LabJobsActions.LoadLabJobFromRouteSuccess,
  props<SerialisedData<ILoadLabJobSuccess>>()
);

export const loadLabJobFromRouteFailure = createAction(
  LabJobsActions.LoadLabJobFromRouteFailure
);

export const loadLabJobsForLabSuccess = createAction(
  LabJobsActions.LoadLabJobsForLabSuccess,
  props<SerialisedData<ILoadLabJobsSuccess>>()
);
