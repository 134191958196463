<pr-chat-header
  [compact]="true"
  [summary]="summary$ | async"
  [actions]="actions"
  (closeChat)="dialogRef.close()"
/>

<mat-dialog-content #scrollMe class="!block !pl-[14px] !pr-4">
  <div class="chat-body flex grow">
    <ng-container *ngIf="actions.content$ | async as messages">
      <ng-container *ngIf="messages.length; else noMessages">
        <pr-chat-body class="grow" [messages]="messages" />
      </ng-container>
    </ng-container>

    <ng-template #noMessages>
      <div class="flex h-full w-full items-center justify-center">
        <pr-empty-state image="message" title="messages available" />
      </div>
    </ng-template>
  </div>
</mat-dialog-content>

<pr-chat-footer [compact]="true" [summary]="summary$ | async" />
