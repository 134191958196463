import { type ICSVExport } from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';
import { type IReferralSourcesReportRecord } from '../referral-sources-report.store';

export interface IReferralSourcesDrilldownCSV {
  appointmentDate: string;
  patientName: string;
  mobileNumber: string;
  referredBy: string;
  treatmentAmount: string;
}

export class ReferralSourcesDrilldownToCSV
  implements
    ICSVExport<IReferralSourcesReportRecord, IReferralSourcesDrilldownCSV>
{
  headers = [
    'Appointment Date',
    'Patient',
    'Mobile Number',
    'Referred By',
    'Treatment Amount',
  ];

  translate(
    records: IReferralSourcesReportRecord[]
  ): IReferralSourcesDrilldownCSV[] {
    return records.map((record) => ({
      appointmentDate: toMoment(record.appointment.event.from).format(
        DAY_MONTH_YEAR_FORMAT
      ),
      patientName: record.patient.name,
      mobileNumber: Patient.getMobileNumber(record.patient) ?? '',
      referredBy: record.patient.referrer?.name ?? '',
      treatmentAmount: record.treatmentAmount.toString(),
    }));
  }
}
