import { type ICSVExport } from '@principle-theorem/ng-shared';
import {
  isManualExtendedData,
  isPurchaseTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';
import { isTransactionCompleteCallbackData } from '@principle-theorem/tyro';
import { startCase } from 'lodash';
import { type IPractitionerTransactionReportRecord } from '@principle-theorem/reporting';
import { getTransactionProviderName } from '@principle-theorem/principle-core';
import { roundTo2Decimals } from '@principle-theorem/accounting';

export interface IPractitionerTransactionDrilldownCSV {
  transactionDate: string;
  invoiceIssuedAt: string;
  patient: string;
  invoice: string;
  transactionProvider: string;
  transactionProviderType: string;
  accountCreditPaymentType: string;
  accountCreditAmount: string;
  discountAmount: string;
  paymentAmount: string;
  practitionerProportionAmount: string;
}

export class PractitionerTransactionDrilldownToCSV
  implements
    ICSVExport<
      IPractitionerTransactionReportRecord,
      IPractitionerTransactionDrilldownCSV
    >
{
  headers: string[];

  constructor(isAllocated: boolean) {
    const normalHeaders = [
      'Transaction Date',
      'Invoice Issued At',
      'Patient',
      'Invoice/Transaction Reference',
      'Type',
      'Card / Manual Type',
      'Account Credit Payment Type',
      'Account Credits',
      'Discounts',
      'Payments',
    ];
    this.headers = isAllocated
      ? [...normalHeaders, 'Practitioner Proportion']
      : [...normalHeaders, 'Unallocated Proportion'];
  }

  translate(
    records: IPractitionerTransactionReportRecord[]
  ): IPractitionerTransactionDrilldownCSV[] {
    return records.map((record) => {
      let transactionProviderType = '';
      if (isManualExtendedData(record.transaction.extendedData)) {
        transactionProviderType =
          record.transaction.extendedData.transactionType?.name ?? '';
      }
      if (
        isPurchaseTransactionExtendedData(record.transaction.extendedData) &&
        isTransactionCompleteCallbackData(
          record.transaction.extendedData.response
        )
      ) {
        transactionProviderType =
          record.transaction.extendedData.response.cardType ?? '';
      }

      return {
        transactionDate: toMoment(record.transaction.createdAt).format(
          DAY_MONTH_YEAR_FORMAT
        ),
        invoiceIssuedAt: record.invoice.issuedAt
          ? toMoment(record.invoice.issuedAt).format(DAY_MONTH_YEAR_FORMAT)
          : '',
        patient: record.patient.name,
        invoice: record.invoice.reference,
        transactionProvider: startCase(record.transaction.provider),
        transactionProviderType,
        accountCreditPaymentType: record.accountCreditPaymentTransactions
          .map(
            (creditPayment) =>
              `${getTransactionProviderName(
                creditPayment.payment.transaction
              )} - $${roundTo2Decimals(creditPayment.practitionerAmount)}`
          )
          .join(', ')
          .toString(),
        accountCreditAmount: roundTo2Decimals(
          record.summary.accountCreditAmount
        ).toString(),
        discountAmount: roundTo2Decimals(
          record.summary.discountAmount
        ).toString(),
        paymentAmount: roundTo2Decimals(
          record.summary.paymentAmount
        ).toString(),
        practitionerProportionAmount: roundTo2Decimals(
          record.summary.practitionerProportionAmount
        ).toString(),
      };
    });
  }
}
