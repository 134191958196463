<div class="flex h-full w-full flex-col">
  <pr-chat-header [summary]="summary$ | async" [actions]="actions" />
  <div
    #scrollMe
    class="chat-body flex flex-1 basis-px flex-col-reverse overflow-y-auto"
  >
    <ng-container *ngIf="actions.content$ | async as messages">
      <ng-container *ngIf="messages.length; else noMessages">
        <pr-chat-body [messages]="messages" />
      </ng-container>
    </ng-container>

    <ng-template #noMessages>
      <div class="flex h-full w-full items-center justify-center">
        <pr-empty-state image="message" title="messages available" />
      </div>
    </ng-template>
  </div>
  <pr-chat-footer [summary]="summary$ | async" />
</div>
