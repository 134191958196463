import { type ICSVExport } from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';
import { type IPatientVisitRecord } from '../patient-visits-report.store';

export interface IPatientVisitCSV {
  patientName: string;
  mobileNumber: string;
  email: string;
  appointmentDate: string;
  isFirstAppointment: string;
  referredBy: string;
}

export class PatientVisitToCSV
  implements ICSVExport<IPatientVisitRecord, IPatientVisitCSV>
{
  headers = [
    'Patient',
    'Mobile Number',
    'Email',
    'Appointment Date',
    'First Appointment',
    'Referred By',
  ];

  translate(records: IPatientVisitRecord[]): IPatientVisitCSV[] {
    return records.map((record) => ({
      patientName: record.patient.name,
      mobileNumber: Patient.getMobileNumber(record.patient) ?? '',
      email: record.patient.email ?? '',
      appointmentDate: toMoment(record.appointment.event.from).format(
        DAY_MONTH_YEAR_FORMAT
      ),
      isFirstAppointment: record.isFirstAppointment ? 'Yes' : 'No',
      referredBy: record.patient.referrer?.name ?? '',
    }));
  }
}
