import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type ITreatmentTemplate } from '@principle-theorem/principle-core/interfaces';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  type IBreadcrumb,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type IBrand } from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import {
  addDoc,
  filterUndefined,
  multiFilter,
  multiSortBy$,
  nameSorter,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type ITreatmentTemplateData,
  UpsertTreatmentTemplateComponent,
} from './upsert-treatment-template/upsert-treatment-template.component';

@Component({
  selector: 'pr-brand-treatment-templates',
  templateUrl: './brand-treatment-templates.component.html',
  styleUrls: ['./brand-treatment-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandTreatmentTemplatesComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  brand$: Observable<WithRef<IBrand>>;
  trackByTemplate = TrackByFunctions.ref<WithRef<ITreatmentTemplate>>();
  publicTreatments$: Observable<WithRef<ITreatmentTemplate>[]>;
  privateTreatments$: Observable<WithRef<ITreatmentTemplate>[]>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    currentBrand: CurrentBrandScope,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.brand$ = currentBrand.doc$.pipe(filterUndefined());
    this.breadcrumbs$ = this.brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Treatment Templates' },
      ])
    );

    const templates$ = this.brand$.pipe(
      switchMap((brand) =>
        Brand.treatmentTemplates$(brand).pipe(
          multiFilter((template) => !template.deleted)
        )
      ),
      multiSortBy$(nameSorter())
    );
    this.publicTreatments$ = templates$.pipe(
      multiFilter((template) => template.isPublic)
    );
    this.privateTreatments$ = templates$.pipe(
      multiFilter((template) => !template.isPublic)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async add(): Promise<void> {
    const template = await this._dialog
      .open<
        UpsertTreatmentTemplateComponent,
        ITreatmentTemplateData,
        ITreatmentTemplate
      >(UpsertTreatmentTemplateComponent, DialogPresets.medium({}))
      .afterClosed()
      .toPromise();

    if (!template) {
      return;
    }

    await addDoc(
      Brand.treatmentTemplateCol(await snapshot(this.brand$)),
      template
    );
    this._snackBar.open('Treatment Template Added');
  }
}
