import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  AppointmentSummary,
  EVENT_TYPES_ICONS,
  EventType,
  UNCATEGORISED_CATEGORY,
  type IPatient,
  type ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import { TIME_FORMAT, getDoc, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-timeline-appointment-tooltip',
  templateUrl: './timeline-appointment-tooltip.component.html',
  styleUrls: ['./timeline-appointment-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineAppointmentTooltipComponent {
  readonly timeFormat = TIME_FORMAT;
  appointment$ = new ReplaySubject<AppointmentSummary>(1);
  eventIcon$: Observable<string>;
  patient$: Observable<WithRef<IPatient>>;
  category$: Observable<ITreatmentCategory>;
  status$: Observable<string>;

  @Input()
  set appointment(appointment: AppointmentSummary) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(private _global: GlobalStoreService) {
    this.eventIcon$ = of(EVENT_TYPES_ICONS[EventType.Appointment]);
    this.patient$ = this.appointment$.pipe(
      switchMap((eventable) => getDoc(eventable.metadata.patientRef))
    );
    this.status$ = this.appointment$.pipe(
      map((appointment) =>
        appointment.metadata.status.replace(/([A-Z])/g, ' $1')
      )
    );
    this.category$ = this.appointment$.pipe(
      switchMap((appointment) => {
        const displayRef = appointment.metadata.categoryRef;

        if (!displayRef) {
          return of(UNCATEGORISED_CATEGORY);
        }

        return this._global
          .getTreatmentCategory$(displayRef)
          .pipe(map((category) => category ?? UNCATEGORISED_CATEGORY));
      })
    );
  }
}
