import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type ITreatmentTemplate } from '@principle-theorem/principle-core/interfaces';
import { patchDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type IBrand } from '@principle-theorem/principle-core/interfaces';
import {
  type ITreatmentTemplateData,
  UpsertTreatmentTemplateComponent,
} from '../upsert-treatment-template/upsert-treatment-template.component';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-treatment-template',
  templateUrl: './treatment-template.component.html',
  styleUrls: ['./treatment-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentTemplateComponent {
  brand$ = new ReplaySubject<WithRef<IBrand>>(1);
  treatmentTemplate$ = new ReplaySubject<WithRef<ITreatmentTemplate>>(1);

  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar) {}

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this.brand$.next(brand);
    }
  }

  @Input()
  set treatmentTemplate(treatmentTemplate: WithRef<ITreatmentTemplate>) {
    if (treatmentTemplate) {
      this.treatmentTemplate$.next(treatmentTemplate);
    }
  }

  async edit(): Promise<void> {
    const treatmentTemplate = await snapshot(this.treatmentTemplate$);
    const updatedTreatmentTemplate = await this._dialog
      .open<
        UpsertTreatmentTemplateComponent,
        ITreatmentTemplateData,
        ITreatmentTemplate
      >(
        UpsertTreatmentTemplateComponent,
        DialogPresets.medium({
          data: {
            treatmentTemplate,
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!updatedTreatmentTemplate) {
      return;
    }
    await patchDoc(treatmentTemplate.ref, updatedTreatmentTemplate);
    this._snackBar.open('Treatment Template Updated');
  }

  async delete(): Promise<void> {
    const treatmentTemplate = await snapshot(this.treatmentTemplate$);
    await patchDoc(treatmentTemplate.ref, {
      deleted: true,
    });
    this._snackBar.open('Treatment Template Deleted');
  }
}
