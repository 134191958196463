import {
  IBrand,
  ICalendarEventSchedule,
  IPractice,
  IRole,
  IStaffer,
  IUser,
} from '@principle-theorem/principle-core/interfaces';
import { FirestoreCache, FirestoreQueryCache } from '@principle-theorem/shared';

export class OrganisationCache {
  static practices = new FirestoreCache<IPractice>();
  static brands = new FirestoreCache<IBrand>();
  static roles = new FirestoreCache<IRole>();
  static staff = {
    get: new FirestoreCache<IStaffer>(),
    all: new FirestoreQueryCache<IStaffer>(),
  };
  static rosterSchedules = new FirestoreQueryCache<ICalendarEventSchedule>();
  static users = {
    get: new FirestoreCache<IUser>(),
    byOrganisation: new FirestoreQueryCache<IUser>(),
    byBrand: new FirestoreQueryCache<IUser>(),
    byPractice: new FirestoreQueryCache<IUser>(),
  };
}
