import {
  AppointmentStatus,
  Gender,
  IAccountCreditExtendedData,
  IAppointment,
  IAutomation,
  IBasePatient,
  IBrand,
  IClinicalChart,
  IContactNumber,
  IFeeSchedule,
  IInteractionV2,
  IInvoice,
  ILabJob,
  IMedia,
  initAccountSummary,
  IPatient,
  IPatientContactDetails,
  IPatientDocument,
  IPatientMetadata,
  IPatientRelationship,
  IPractice,
  IPrescription,
  PrescriptionStatus,
  isEventable,
  isPatientContactDetails,
  isPatientWithPrimaryContact,
  IsPrimaryContact,
  IStaffer,
  ISubmittedForm,
  ISubmittedFormHistory,
  ITag,
  ITask,
  ITransaction,
  PatientCollection,
  PatientForm,
  PatientMetadataValue,
  PatientRelationshipType,
  PatientStatus,
  TaskStatus,
  TransactionProvider,
  TreatmentStepAutomation,
  WithPrimaryContact,
  ISuggestedPatientData,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  all$,
  ArchivedDocument,
  asColRef,
  CollectionReference,
  DAY_MONTH_YEAR_FORMAT,
  doc,
  DocumentArchive,
  DocumentReference,
  find$,
  Firestore,
  firstResult,
  firstResult$,
  getDoc,
  getDocs,
  IReffable,
  isSameRef,
  isWithRef,
  multiSwitchMap,
  orderBy,
  query,
  query$,
  reduce2DArray,
  reduceToSingleArray,
  RequireProps,
  safeCombineLatest,
  saveDoc,
  snapshot,
  sortByCreatedAt,
  sortTimestampAsc,
  subCollection,
  toMoment,
  toNamedDocument,
  toTimestamp,
  undeletedQuery,
  where,
  WithRef,
} from '@principle-theorem/shared';
import { compact, first, get, isEmpty, set } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Appointment } from '../appointment/appointment';
import { Brand } from '../brand';
import { TreatmentPlan } from '../clinical-charting/treatment/treatment-plan';
import { Invoice } from '../invoice/invoice';
import { LabJob } from '../lab-job/lab-job';
import { OrganisationCache } from '../organisation/organisation-cache';
import { PatientRelationship } from './patient-relationship';

export class Patient {
  static init(overrides?: Partial<IPatient>): IPatient {
    return {
      name: '',
      address: '',
      relationships: [],
      status: PatientStatus.Active,
      statusHistory: [],
      email: '',
      gender: Gender.NotSpecified,
      contactNumbers: [],
      notes: [],
      tags: [],
      accountSummary: initAccountSummary(),
      deleted: false,
      ...overrides,
    };
  }

  static brandRef(patient: IReffable<IPatient>): DocumentReference<IBrand> {
    return Firestore.getParentDocRef<IBrand>(patient.ref);
  }

  static primaryContact$(
    patient: IPatient & WithPrimaryContact
  ): Observable<WithRef<IBasePatient & IsPrimaryContact>> {
    return Firestore.doc$(patient.primaryContact.patient.ref);
  }

  static preferredDentist$(
    patient: IPatient
  ): Observable<WithRef<IStaffer> | undefined> {
    if (!patient.preferredDentist) {
      return of(undefined);
    }
    return OrganisationCache.staff.get.doc$(patient.preferredDentist.ref);
  }

  static preferredHygienist$(
    patient: IPatient
  ): Observable<WithRef<IStaffer> | undefined> {
    if (!patient.preferredHygienist) {
      return of(undefined);
    }
    return OrganisationCache.staff.get.doc$(patient.preferredHygienist.ref);
  }

  static preferredPractice$(
    patient: IPatient
  ): Observable<WithRef<IPractice> | undefined> {
    if (!patient.preferredPractice) {
      return of(undefined);
    }
    return OrganisationCache.practices.doc$(patient.preferredPractice.ref);
  }

  static preferredFeeSchedule$(
    patient: IPatient
  ): Observable<WithRef<IFeeSchedule> | undefined> {
    if (!patient.preferredFeeSchedule) {
      return of(undefined);
    }
    return Firestore.doc$(patient.preferredFeeSchedule.ref);
  }

  static appointmentCol(
    patient: IReffable<IPatient>
  ): CollectionReference<IAppointment> {
    return subCollection<IAppointment>(
      patient.ref,
      PatientCollection.Appointments
    );
  }

  static appointments$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IAppointment>[]> {
    return all$(undeletedQuery(Patient.appointmentCol(patient)));
  }

  static archiveCol(
    patient: WithRef<IPatient>
  ): CollectionReference<ArchivedDocument<IPatient>> {
    return subCollection<ArchivedDocument<IPatient>>(
      patient.ref,
      PatientCollection.PatientDetailsHistory
    );
  }

  static clinicalChartsCol(
    patient: IReffable<IPatient>
  ): CollectionReference<IClinicalChart> {
    return subCollection<IClinicalChart>(
      patient.ref,
      PatientCollection.ClinicalCharts
    );
  }

  static patientDocumentCol(
    patient: IReffable<IPatient>
  ): CollectionReference<IPatientDocument> {
    return subCollection<IPatientDocument>(
      patient.ref,
      PatientCollection.PatientDocuments
    );
  }

  static patientDocuments$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IPatientDocument>[]> {
    return query$(undeletedQuery(Patient.patientDocumentCol(patient))).pipe(
      map((documents) => documents.sort(sortByCreatedAt))
    );
  }

  static prescriptionCol(
    patient: IReffable<IPatient>
  ): CollectionReference<IPrescription> {
    return subCollection<IPrescription>(
      patient.ref,
      PatientCollection.Prescriptions
    );
  }

  static prescriptions$(
    patient: IReffable<IPatient>,
    status?: PrescriptionStatus
  ): Observable<WithRef<IPrescription>[]> {
    if (status) {
      return query$(
        undeletedQuery(Patient.prescriptionCol(patient)),
        where('status', '==', status)
      ).pipe(map((prescriptions) => prescriptions.sort(sortByCreatedAt)));
    }

    return query$(undeletedQuery(Patient.prescriptionCol(patient))).pipe(
      map((prescription) => prescription.sort(sortByCreatedAt))
    );
  }

  static formCol(
    patient: IReffable<IPatient>
  ): CollectionReference<ISubmittedFormHistory> {
    return subCollection<ISubmittedFormHistory>(
      patient.ref,
      PatientCollection.SubmittedForms
    );
  }

  static forms$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<ISubmittedFormHistory>[]> {
    return all$(Patient.formCol(patient));
  }

  static form$(
    patient: IReffable<IPatient>,
    formType: PatientForm
  ): Observable<WithRef<ISubmittedFormHistory> | undefined> {
    const formCol = Patient.formCol(patient);
    const docRef = doc(formCol, formType);
    return find$(formCol, where('ref', '==', docRef));
  }

  static formData$(
    patient: IReffable<IPatient>,
    formType: PatientForm
  ): Observable<ISubmittedForm | undefined> {
    return Patient.form$(patient, formType).pipe(
      map((medicalHistory) =>
        medicalHistory ? medicalHistory.form : undefined
      )
    );
  }

  static creditTransactions$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<ITransaction<IAccountCreditExtendedData>>[]> {
    return Patient.invoices$(patient).pipe(
      multiSwitchMap((invoice) =>
        query$(
          asColRef<ITransaction<IAccountCreditExtendedData>>(
            Invoice.transactionCol(invoice)
          ),
          where('provider', 'in', [
            TransactionProvider.AccountCredit,
            TransactionProvider.AccountCreditTransfer,
          ])
        )
      ),
      reduce2DArray()
    );
  }

  static invoiceCol(
    patient: IReffable<IPatient>
  ): CollectionReference<IInvoice> {
    return subCollection<IInvoice>(patient, PatientCollection.Invoices);
  }

  static invoices$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IInvoice>[]> {
    return query$(undeletedQuery(Patient.invoiceCol(patient)));
  }

  static historyCol(
    patient: IReffable<IPatient>
  ): CollectionReference<IInteractionV2> {
    return subCollection<IInteractionV2>(
      patient.ref,
      PatientCollection.History
    );
  }

  static history$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IInteractionV2>[]> {
    return all$(undeletedQuery(Patient.historyCol(patient))).pipe(
      map((interactions) =>
        interactions.sort((interactionA, interactionB) =>
          sortTimestampAsc(interactionA.createdAt, interactionB.createdAt)
        )
      )
    );
  }

  static async addInteraction(
    patient: IReffable<IPatient>,
    interaction: IInteractionV2
  ): Promise<void> {
    await addDoc(Patient.historyCol(patient), interaction);
  }

  static relationships$(
    patient: WithRef<IPatient>
  ): Observable<WithRef<IPatient>[]> {
    return safeCombineLatest(
      patient.relationships.map((data) =>
        Firestore.doc$<IPatient>(data.patient.ref)
      )
    );
  }

  static upsertRelationship(
    patient: IPatient,
    relation: IPatientRelationship
  ): void {
    const foundIndex = patient.relationships.findIndex((relationship) =>
      isSameRef(relationship.patient, relation.patient)
    );
    if (foundIndex === -1) {
      patient.relationships.push(relation);
      return;
    }
    patient.relationships[foundIndex] = relation;
  }

  static async updatePatientDetails(
    patient: WithRef<IPatient>,
    stafferRef?: DocumentReference<IStaffer>
  ): Promise<void> {
    await DocumentArchive.snapshotToArchive(
      await OrganisationCache.patients.getDoc(patient.ref),
      Patient.archiveCol(patient),
      undefined,
      stafferRef
    );

    await Firestore.saveDoc(patient);
  }

  static async markAsDuplicate(
    duplicatePatient: WithRef<IPatient>,
    primaryPatient: WithRef<IPatient>
  ): Promise<void> {
    const relationship = {
      patient: toNamedDocument(primaryPatient),
      type: PatientRelationshipType.PrimaryPatient,
    };
    Patient.upsertRelationship(duplicatePatient, relationship);
    await Firestore.patchDoc(duplicatePatient.ref, {
      status: PatientStatus.Inactive,
      relationships: duplicatePatient.relationships,
    });
    await PatientRelationship.addRelationshipBackReference(
      duplicatePatient,
      relationship
    );
  }

  static isDuplicatePatient(patient: IPatient): boolean {
    return patient.relationships.some(
      (relationship) =>
        relationship.type === PatientRelationshipType.PrimaryPatient
    );
  }

  static async getMergedToPatient(
    patient: IPatient
  ): Promise<WithRef<IPatient> | undefined> {
    const mergedPatient = patient.relationships.find(
      (relationship) =>
        relationship.type === PatientRelationshipType.PrimaryPatient
    );

    if (!mergedPatient) {
      return;
    }

    return OrganisationCache.patients.getDoc(mergedPatient.patient.ref);
  }

  static async unmergePatient(patient: WithRef<IPatient>): Promise<void> {
    const primaryPatient = await Patient.getMergedToPatient(patient);
    if (!primaryPatient) {
      return;
    }

    await Firestore.patchDoc(patient.ref, {
      status: PatientStatus.Active,
      relationships: patient.relationships.filter(
        (relationship) =>
          relationship.type !== PatientRelationshipType.PrimaryPatient
      ),
    });

    await Firestore.patchDoc(primaryPatient.ref, {
      relationships: primaryPatient.relationships.filter(
        (relationship) =>
          relationship.type !== PatientRelationshipType.DuplicatePatient
      ),
    });
  }

  static withPatientRelationships$<T>(
    patient: WithRef<IPatient>,
    includedRelationships: PatientRelationshipType[],
    queryFn: (patient: IReffable<IPatient>) => Observable<T[]>
  ): Observable<T[]> {
    return safeCombineLatest(
      Patient.relationshipRefs(patient, includedRelationships).map(
        (patientRef) => queryFn({ ref: patientRef })
      )
    ).pipe(reduce2DArray());
  }

  static relationshipRefs(
    patient: WithRef<IPatient>,
    includedRelationships: PatientRelationshipType[]
  ): DocumentReference<IPatient>[] {
    return [
      patient.ref,
      ...patient.relationships
        .filter((relationship) =>
          includedRelationships.includes(relationship.type)
        )
        .map((relationship) => relationship.patient.ref),
    ];
  }

  static mediaCol(patient: IReffable<IPatient>): CollectionReference<IMedia> {
    return subCollection<IMedia>(patient.ref, PatientCollection.Media);
  }

  static media$(patient: IReffable<IPatient>): Observable<WithRef<IMedia>[]> {
    return all$(Patient.mediaCol(patient));
  }

  static age(patient: Pick<IPatient, 'dateOfBirth'>): number | undefined {
    if (!patient.dateOfBirth) {
      return;
    }
    return moment().diff(toMoment(patient.dateOfBirth), 'years');
  }

  static tags$(patient: IReffable<IPatient>): Observable<WithRef<ITag>[]> {
    return OrganisationCache.patients
      .doc$(patient.ref)
      .pipe(
        switchMap((latestPatient) =>
          safeCombineLatest(
            latestPatient.tags.map((tag) => Firestore.doc$(tag.ref))
          )
        )
      );
  }

  static storagePath(patient: IReffable<IPatient>): string {
    return patient.ref.path;
  }

  static getPriorAppointment$(
    patient: WithRef<IPatient>,
    appointment: RequireProps<IAppointment, 'event'>
  ): Observable<WithRef<IAppointment> | undefined> {
    return firstResult$(
      Appointment.col(patient),
      where('event.to', '<', appointment.event.from),
      orderBy('event.to', 'desc')
    );
  }

  static getSubsequentAppointment$(
    patient: WithRef<IPatient>,
    appointment: RequireProps<IAppointment, 'event'>
  ): Observable<WithRef<IAppointment> | undefined> {
    return firstResult$(
      Appointment.col(patient),
      where('event.from', '>', appointment.event.to),
      orderBy('event.from', 'asc')
    );
  }

  static lastAppointment$(
    patient: IReffable<IPatient>,
    status?: AppointmentStatus
  ): Observable<WithRef<IAppointment> | undefined> {
    return firstResult$(
      Appointment.col(patient),
      ...compact([
        status ? where('status', '==', status) : undefined,
        orderBy('event.from', 'desc'),
      ])
    );
  }

  static lastCompletedAppointment$(
    patient: WithRef<IPatient>
  ): Observable<WithRef<IAppointment> | undefined> {
    return this.lastAppointment$(patient, AppointmentStatus.Complete);
  }

  static async lastCompletedAppointment(
    patient: IReffable<IPatient>
  ): Promise<WithRef<IAppointment> | undefined> {
    return firstResult(
      Appointment.col(patient),
      where('status', '==', AppointmentStatus.Complete),
      orderBy('event.from', 'desc')
    );
  }

  static firstScheduledAppointment$(
    patient: WithRef<IPatient>
  ): Observable<WithRef<IAppointment> | undefined> {
    return firstResult$(
      Appointment.col(patient),
      where('status', 'in', [
        AppointmentStatus.Scheduled,
        AppointmentStatus.Confirmed,
        AppointmentStatus.Arrived,
        AppointmentStatus.CheckedIn,
        AppointmentStatus.InProgress,
        AppointmentStatus.CheckingOut,
        AppointmentStatus.Complete,
      ]),
      orderBy('event.from', 'asc')
    );
  }

  static async firstScheduledAppointment(
    patient: IReffable<IPatient>
  ): Promise<WithRef<IAppointment> | undefined> {
    return firstResult(
      Appointment.col(patient),
      where('status', 'in', [
        AppointmentStatus.Scheduled,
        AppointmentStatus.Confirmed,
        AppointmentStatus.Arrived,
        AppointmentStatus.CheckedIn,
        AppointmentStatus.InProgress,
        AppointmentStatus.CheckingOut,
        AppointmentStatus.Complete,
      ]),
      orderBy('event.from', 'asc')
    );
  }

  static async getLastCompletedAppointment(
    patient: WithRef<IPatient>
  ): Promise<WithRef<IAppointment> | undefined> {
    return snapshot(Patient.lastCompletedAppointment$(patient));
  }

  static async getNextScheduledAppointment(
    patient: WithRef<IPatient>
  ): Promise<WithRef<IAppointment> | undefined> {
    const appointments = await Patient.getAppointmentsOrderByEvent(patient);
    return appointments.find(
      (appointment) =>
        Appointment.isFutureAppointment(appointment) && isEventable(appointment)
    );
  }

  static nextScheduledAppointment$(
    patient: WithRef<IPatient>
  ): Observable<WithRef<IAppointment> | undefined> {
    return firstResult$(
      Patient.appointmentCol(patient),
      where('event.from', '>=', toTimestamp()),
      orderBy('event.from', 'asc')
    );
  }

  static getFutureAppointments$(
    patient: IReffable<IPatient>,
    dateFrom: Moment = moment()
  ): Observable<WithRef<IAppointment>[]> {
    return query$(
      Appointment.col(patient),
      where('event.from', '>=', toTimestamp(dateFrom)),
      orderBy('event.from', 'asc')
    );
  }

  static async getAppointmentsOrderByEvent(
    patient: WithRef<IPatient>
  ): Promise<WithRef<IAppointment>[]> {
    return query(Appointment.col(patient), orderBy('event.from', 'desc'));
  }

  static resolveContactDetails$(
    patient: WithRef<IPatient>
  ): Observable<WithRef<IBasePatient & IPatientContactDetails> | undefined> {
    if (isPatientContactDetails(patient) && hasValidContactDetails(patient)) {
      return of(patient as WithRef<IBasePatient & IPatientContactDetails>);
    }

    return Patient.resolvePrimaryContact$(patient).pipe(
      map((resolvedPatient) => {
        if (
          !isPatientContactDetails(resolvedPatient) ||
          !hasValidContactDetails(resolvedPatient)
        ) {
          return;
        }
        return {
          ...resolvedPatient,
          name: patient.name,
        };
      })
    );
  }

  static isNewPatient$(
    patient: IReffable<IPatient>,
    appointment?: DocumentReference<IAppointment>
  ): Observable<boolean> {
    if (!appointment) {
      return from(Patient.lastCompletedAppointment(patient)).pipe(
        map((lastAppointment) => !lastAppointment)
      );
    }
    return from(Patient.firstScheduledAppointment(patient)).pipe(
      map((firstAppointment) => isSameRef(appointment, firstAppointment))
    );
  }

  static async resolveContactDetails(
    ref: DocumentReference<IPatient>
  ): Promise<WithRef<IBasePatient & IPatientContactDetails> | undefined> {
    return snapshot(
      OrganisationCache.patients
        .doc$(ref)
        .pipe(switchMap((patient) => Patient.resolveContactDetails$(patient)))
    );
  }

  static resolvePrimaryContact$(
    patient: WithRef<IPatient>
  ): Observable<WithRef<IBasePatient & IPatientContactDetails> | undefined> {
    return isPatientWithPrimaryContact(patient)
      ? Patient.primaryContact$(patient)
      : of(undefined);
  }

  static async resolvePrimaryContact(
    ref: DocumentReference<IPatient>
  ): Promise<WithRef<IBasePatient & IPatientContactDetails> | undefined> {
    return snapshot(
      OrganisationCache.patients
        .doc$(ref)
        .pipe(switchMap((patient) => Patient.resolvePrimaryContact$(patient)))
    );
  }

  static async resolveContactNumbers(
    patient: Pick<WithRef<IPatient>, 'contactNumbers' | 'ref'>
  ): Promise<IContactNumber[]> {
    if (
      patient.contactNumbers &&
      hasContactableNumber(patient.contactNumbers)
    ) {
      return patient.contactNumbers;
    }

    const primaryPatient = await Patient.resolvePrimaryContact(patient.ref);
    return primaryPatient?.contactNumbers ?? [];
  }

  static async resolveFirstContactNumber(
    patient: Pick<WithRef<IPatient>, 'contactNumbers' | 'ref'>
  ): Promise<string | undefined> {
    const primaryContactNumber = first(
      await Patient.resolveContactNumbers(patient)
    );
    return primaryContactNumber ? primaryContactNumber.number : undefined;
  }

  static async resolveMobileNumber(
    patient: WithRef<IPatient>
  ): Promise<string | undefined> {
    const mobile = Patient.getMobileNumber(patient);
    if (mobile) {
      return mobile;
    }
    const primaryContact = await Patient.resolvePrimaryContact(patient.ref);
    return primaryContact ? Patient.getMobileNumber(primaryContact) : undefined;
  }

  static getMobileNumber(patient: IPatient): string | undefined {
    return findMobileNumber(patient.contactNumbers);
  }

  static async saveAppointment(
    patient: WithRef<IPatient>,
    appointment: WithRef<IAppointment> | IAppointment
  ): Promise<WithRef<IAppointment>> {
    if (isWithRef(appointment)) {
      await saveDoc(appointment);
      return appointment;
    }
    const appointmentRef = await addDoc(Appointment.col(patient), appointment);
    return getDoc(appointmentRef);
  }

  static tasks$(
    patient: IReffable<IPatient>,
    brand: WithRef<IBrand>,
    statusFilter: TaskStatus[] = []
  ): Observable<WithRef<ITask>[]> {
    return Brand.queryTasks$(
      brand,
      ...compact([
        where('mentionRefs', 'array-contains', patient.ref),
        statusFilter.length ? where('status', 'in', statusFilter) : undefined,
      ])
    );
  }

  static labJobs$(
    patient: IReffable<IPatient>,
    brand: WithRef<IBrand>
  ): Observable<WithRef<ILabJob>[]> {
    return from(getDocs(Brand.practiceCol(brand))).pipe(
      switchMap((practices) =>
        safeCombineLatest(
          practices.map((practice) => LabJob.byPatient$(practice, patient.ref))
        )
      ),
      map(reduceToSingleArray)
    );
  }

  static automations$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IAutomation<TreatmentStepAutomation>>[]> {
    return TreatmentPlan.all$(patient).pipe(
      multiSwitchMap((plan) => TreatmentPlan.automations$(plan)),
      map(reduceToSingleArray)
    );
  }

  static isDisabledPatient(status: PatientStatus): boolean {
    return [PatientStatus.Blocked, PatientStatus.Deceased].includes(status);
  }

  static updateMetadata(
    patient: WithRef<IPatient>,
    metadataKey: string,
    metadataValue: PatientMetadataValue
  ): IPatientMetadata {
    const metadata: IPatientMetadata = patient.metadata ?? {};
    set(metadata, metadataKey, metadataValue);
    return metadata;
  }

  static getMetadata(
    patient: IPatient,
    metadataKey: string
  ): PatientMetadataValue | undefined {
    const metadata = patient.metadata ?? {};
    return get(metadata, metadataKey) as PatientMetadataValue | undefined;
  }
}

export function findMobileNumber(
  contactNumbers: IContactNumber[] = []
): string | undefined {
  const mobile = contactNumbers.find((contact) => isMobileNumber(contact));
  return mobile ? formatPhoneNumber(mobile.number) : undefined;
}

export function formatPhoneNumber(mobileNumber: string): string {
  const sanitised = mobileNumber.replace(/\s/g, '');
  const formattedNumber = sanitised
    .replace(/[^+0-9]+/g, '') // Remove any non-numeric characters except '+'
    .replace(/^\+610/, '+61') // Replace +610 with +61
    .replace(/^0/, '+61') // Replace leading 0 with +61
    .replace(/^61/, '+61'); // Ensure the number starts with +61
  return formattedNumber;
}

export function isMobileNumber(contactNumber: IContactNumber): boolean {
  const sanitised = contactNumber.number.replace(/ /g, '');
  const search = /^((\+?610?[45])|(0[45]))[0-9]{8}/;
  return search.exec(sanitised) ? true : false;
}

export function hasContactableNumber(
  contactNumbers: IContactNumber[]
): boolean {
  return contactNumbers.some((contactNumber) => !isEmpty(contactNumber.number));
}

export function isEmail(data: string): boolean {
  const EMAIL_REGEXP =
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return EMAIL_REGEXP.test(data.trim());
}

export function hasValidContactDetails(
  data: Partial<IPatientContactDetails>
): boolean {
  return hasValidEmail(data) || hasValidMobile(data);
}

export function hasValidMobile(data: Partial<IPatientContactDetails>): boolean {
  return findMobileNumber(data.contactNumbers) !== undefined;
}

export function convertMobilePrefix(contactNumber: string): string {
  const formattedNumber = formatPhoneNumber(contactNumber);
  return [formattedNumber, formattedNumber.replace('+61', '0')].join(' ');
}

export function hasValidEmail(data: Partial<IPatientContactDetails>): boolean {
  return !!data.email && isEmail(data.email);
}

export function patientDataToSimilarQuery(
  patientData: ISuggestedPatientData
): string {
  const contactNumbers = compact(
    patientData.contactNumbers?.map((contactNumber) =>
      contactNumber.number
        ? convertMobilePrefix(contactNumber.number)
        : undefined
    )
  );

  const queryParams = compact([
    !patientData.name || patientData.name.length < 3
      ? undefined
      : patientData.name,
    !!patientData.dateOfBirth &&
      toMoment(patientData.dateOfBirth).format(DAY_MONTH_YEAR_FORMAT),
    !!patientData.mobileNumber && convertMobilePrefix(patientData.mobileNumber),
    !!contactNumbers.length && contactNumbers.join(' '),
  ]);

  return queryParams.length ? queryParams.join(' ') : '';
}
