import { type ICSVExport } from '@principle-theorem/ng-shared';
import { InvoiceStatus } from '@principle-theorem/principle-core/interfaces';
import { type IPractitionerReportRecord } from '@principle-theorem/reporting';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';

export interface IInvoicePaidInspectorCSV {
  paidAt: string;
  status: string;
  invoice: string;
  patient: string;
  treatmentsInvoicedAmount: string;
  depositsTakenAmount: string;
  discountsAppliedAmount: string;
  creditsAppliedAmount: string;
  receiptedCommissionAmount: string;
}

export class InvoicePaidInspectorToCSV
  implements ICSVExport<IPractitionerReportRecord, IInvoicePaidInspectorCSV>
{
  headers = [
    'Paid At',
    'Status',
    'Invoice',
    'Patient',
    'Treatment Invoiced',
    'Deposits',
    'Discounts',
    'Account Credits',
    'Receipted Amount',
  ];

  translate(records: IPractitionerReportRecord[]): IInvoicePaidInspectorCSV[] {
    return records.map((record) => ({
      paidAt:
        record.invoice.status === InvoiceStatus.Paid && record.invoice.paidAt
          ? toMoment(record.invoice.paidAt).format(DAY_MONTH_YEAR_FORMAT)
          : '',
      status: record.invoice.status,
      invoice: record.invoice.reference,
      patient: record.patient.name,
      treatmentsInvoicedAmount:
        record.summary.treatmentsInvoicedAmount.toString(),
      depositsTakenAmount: record.summary.depositsTakenAmount.toString(),
      discountsAppliedAmount: record.summary.discountsAppliedAmount.toString(),
      creditsAppliedAmount: record.summary.creditsAppliedAmount.toString(),
      receiptedCommissionAmount:
        record.summary.receiptedCommissionAmount.toString(),
    }));
  }
}
