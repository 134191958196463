import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type IAppointment } from '@principle-theorem/principle-core/interfaces';
import { doc$, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Component({
  selector: 'pr-appointment-popover',
  templateUrl: './appointment-popover.component.html',
  styleUrls: ['./appointment-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentPopoverComponent {
  appointment$: Observable<WithRef<IAppointment>>;

  constructor(
    @Inject(MAT_DIALOG_DATA) appointment: WithRef<IAppointment>,
    public dialogRef: MatDialogRef<AppointmentPopoverComponent>
  ) {
    this.appointment$ = doc$(appointment.ref);
  }

  close(): void {
    this.dialogRef.close();
  }
}
