import { roundTo2Decimals, TaxRate } from '@principle-theorem/accounting';
import { RawInlineNodes, toMentionContent } from '@principle-theorem/editor';
import {
  AccountCreditType,
  CancelledAndReplacedInvoice,
  CancelledInvoice,
  CollectionGroup,
  IAccountCredit,
  IAccountCreditRefunded,
  IAppointment,
  IBaseInvoice,
  IBrand,
  IChartedTreatment,
  ICreditRefundLineItem,
  ICreditTransferLineItem,
  ICustomLineItem,
  IDepositLineItem,
  IFeeLineItem,
  IHasLineItems,
  IHealthcareClaim,
  IInteractionV2,
  IInvoice,
  IInvoiceLineItem,
  IInvoiceReference,
  IInvoiceStatusLog,
  initInvoiceSummary,
  InvoiceCollection,
  InvoiceStatus,
  InvoiceType,
  IOrganisation,
  IPatient,
  IRefundLineItem,
  isAccountCreditExtendedData,
  isCreditRefundLineItem,
  isCreditTransferLineItem,
  isCustomLineItem,
  isDepositLineItem,
  isFeeLineItem,
  isProductLineItem,
  isRefundLineItem,
  IStaffer,
  isTreatmentLineItem,
  ITransaction,
  ITransactionAllocation,
  ITreatmentLineItem,
  ITreatmentPlan,
  ITreatmentStep,
  IUsedAccountCredit,
  MentionResourceType,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  all$,
  ArchivedDocument,
  asyncForEach,
  AtLeast,
  collectionGroupQuery,
  CollectionReference,
  customGroupBy,
  doc$,
  DocumentArchive,
  DocumentReference,
  find$,
  Transaction as FirebaseTransaction,
  Firestore,
  firstResult,
  firstResult$,
  INamedDocument,
  initFirestoreModel,
  IReffable,
  isDocRef,
  isObject,
  isSameRef,
  multiFilter,
  multiSort,
  query,
  query$,
  resolveParallel,
  snapshot,
  sortByCreatedAt,
  sortByUpdatedAt,
  sortTimestamp,
  subCollection,
  Timestamp,
  toMoment,
  toTimestamp,
  undeletedQuery,
  where,
  WithRef,
} from '@principle-theorem/shared';
import {
  compact,
  difference,
  first,
  flatten,
  isString,
  sum,
  sumBy,
  uniqWith,
} from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, Observable, of, OperatorFunction } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { AccountCredit } from '../account-credit/account-credit';
import { Appointment } from '../appointment/appointment';
import { TreatmentPlan } from '../clinical-charting/treatment/treatment-plan';
import { stafferToNamedDoc } from '../common';
import { toMention } from '../mention/mention';
import { OrganisationCache } from '../organisation/organisation-cache';
import { Patient } from '../patient/patient';
import { Transaction } from '../transaction/transaction';
import { TransactionOperators } from '../transaction/transaction-operators';
import {
  depositToLineItem,
  treatmentStepToLineItems,
} from './custom-line-items';
import {
  HealthcareClaim,
  HealthcareClaimGenerator,
} from './healthcare-claim-operators';
import { InvoiceInteractionBuilder } from './invoice-interaction-builder';
import { determineTaxFromStrategy } from './line-item';
import { LineItemActions } from './line-item-actions';

export class Invoice {
  static init(
    overrides: AtLeast<IBaseInvoice, 'due' | 'practice' | 'from' | 'to'>
  ): IInvoice {
    const firestoreModel = initFirestoreModel();
    return {
      status: InvoiceStatus.Draft,
      statusHistory: [
        {
          status: InvoiceStatus.Draft,
          updatedAt: overrides.createdAt ?? firestoreModel.createdAt,
        },
      ],
      items: [],
      claims: [],
      type: InvoiceType.Invoice,
      summary: initInvoiceSummary(),
      ...firestoreModel,
      ...overrides,
      reference: 'INV' + uuid(),
    };
  }

  static summary(invoice: WithRef<IInvoice>): RawInlineNodes {
    return [
      toMentionContent(
        toMention(
          { name: `${invoice.reference}`, ref: invoice.ref },
          MentionResourceType.Invoice
        )
      ),
    ];
  }

  static archiveCol(
    invoice: WithRef<IInvoice>
  ): CollectionReference<ArchivedDocument<IInvoice>> {
    return subCollection<ArchivedDocument<IInvoice>>(
      invoice.ref,
      InvoiceCollection.InvoiceHistory
    );
  }

  static isCancelledInvoice(invoice: IInvoice): invoice is CancelledInvoice {
    return invoice.status === InvoiceStatus.Cancelled;
  }

  static isReplacedInvoice(
    invoice: IInvoice
  ): invoice is CancelledAndReplacedInvoice {
    return (
      invoice.status === InvoiceStatus.Cancelled &&
      isObject(invoice) &&
      isObject(invoice.cancellation) &&
      isString(invoice.cancellation.reason) &&
      isDocRef(invoice.cancellation.replacement)
    );
  }

  static transactionCol(
    invoice: IReffable<IInvoice>
  ): CollectionReference<ITransaction> {
    return subCollection<ITransaction>(
      Invoice.resolveActiveInvoiceRef(invoice),
      InvoiceCollection.Transactions
    );
  }

  static interactionCol(
    invoice: IReffable<IInvoice>
  ): CollectionReference<IInteractionV2> {
    return subCollection<IInteractionV2>(
      invoice.ref,
      InvoiceCollection.InvoiceInteractions
    );
  }

  static interactions$(
    invoice: IReffable<IInvoice>
  ): Observable<WithRef<IInteractionV2>[]> {
    return all$(undeletedQuery(Invoice.interactionCol(invoice)));
  }

  static resolveActiveInvoiceRef(
    invoice: IReffable<IInvoice>
  ): DocumentReference<IInvoice> {
    return DocumentArchive.isArchivedDocument(invoice)
      ? Firestore.getParentDocRef<IInvoice>(invoice.ref)
      : invoice.ref;
  }

  static async getTransactions(
    invoice: IReffable<IInvoice>
  ): Promise<WithRef<ITransaction>[]> {
    return Firestore.getDocs(undeletedQuery(Invoice.transactionCol(invoice)));
  }

  static transactions$(
    invoice: IReffable<IInvoice>
  ): Observable<WithRef<ITransaction>[]> {
    return query$(undeletedQuery(Invoice.transactionCol(invoice)));
  }

  static amendmentHistoryCol(
    invoice: IReffable<IInvoice>
  ): CollectionReference<ArchivedDocument<IInvoice>> {
    return subCollection<ArchivedDocument<IInvoice>>(
      invoice.ref,
      InvoiceCollection.InvoiceHistory
    );
  }

  static amendmentHistory$(
    invoice: IReffable<IInvoice>
  ): Observable<WithRef<ArchivedDocument<IInvoice>>[]> {
    return all$(Invoice.amendmentHistoryCol(invoice)).pipe(
      multiSort((a, b) => sortTimestamp(a.archivedAt, b.archivedAt))
    );
  }

  static products(invoice: IInvoice): ICustomLineItem[] {
    return invoice.items.filter(isProductLineItem);
  }

  static treatments(invoice: IInvoice): ITreatmentLineItem[] {
    return invoice.items.filter(isTreatmentLineItem);
  }

  static deposits(
    invoice: IInvoice
  ): (IDepositLineItem | ICreditTransferLineItem | ICreditRefundLineItem)[] {
    return [
      ...invoice.items.filter(isDepositLineItem),
      ...invoice.items.filter(isCreditTransferLineItem),
      ...invoice.items.filter(isCreditRefundLineItem),
    ];
  }

  static fees(invoice: IInvoice): IFeeLineItem[] {
    return invoice.items.filter(isFeeLineItem);
  }

  static refunds(invoice: IInvoice): IRefundLineItem[] {
    return invoice.items.filter(isRefundLineItem);
  }

  static updateQuantity(
    invoice: IInvoice,
    lineItem: ICustomLineItem,
    quantity: number
  ): void {
    if (quantity === 0) {
      invoice.items = difference(invoice.items, [lineItem]);
      return;
    }
    lineItem.quantity = quantity;
  }

  static addLineItem(
    invoice: IInvoice,
    lineItem: ICustomLineItem,
    taxRate: TaxRate
  ): void {
    this.upsertLineItem(invoice, lineItem, taxRate);
  }

  static hasSameTreatmentItem(
    invoice: IHasLineItems,
    lineItem: ITreatmentLineItem
  ): boolean {
    const foundLineItem = this.findLineItem(invoice, lineItem);
    if (!foundLineItem) {
      return false;
    }

    if (
      foundLineItem.quantity !== lineItem.quantity ||
      foundLineItem.amount !== lineItem.amount
    ) {
      return false;
    }

    return true;
  }

  static findLineItem(
    invoice: IHasLineItems,
    lineItem: ITreatmentLineItem
  ): ITreatmentLineItem | undefined {
    return invoice.items
      .filter(
        (item): item is ITreatmentLineItem =>
          isCustomLineItem(item) && isTreatmentLineItem(item)
      )
      .find(
        (item) =>
          item.treatmentRef.treatmentUuid ===
          lineItem.treatmentRef.treatmentUuid
      );
  }

  static upsertLineItem(
    invoice: IHasLineItems,
    lineItem: ICustomLineItem,
    taxRate: TaxRate
  ): void {
    lineItem.tax = determineTaxFromStrategy(taxRate, lineItem);

    const index: number = invoice.items.findIndex(
      (item: IInvoiceLineItem) => item.uid === lineItem.uid
    );
    if (index < 0) {
      invoice.items.push(lineItem);
    } else {
      invoice.items[index] = lineItem;
    }
    if (lineItem.quantity <= 0) {
      invoice.items = invoice.items.filter(
        (item: IInvoiceLineItem) => item.uid !== lineItem.uid
      );
    }
  }

  static isLineItemLocked$(
    invoice: WithRef<IInvoice>,
    lineItem: ICustomLineItem
  ): Observable<boolean> {
    if (isDepositLineItem(lineItem)) {
      return Invoice.getExistingAccountCredits$(invoice).pipe(
        map((credits) =>
          credits.some((credit) => credit.depositUid === lineItem.uid)
        )
      );
    }
    return of(false);
  }

  static upsertSubLineItem(
    group: ICustomLineItem & IHasLineItems,
    lineItem: ICustomLineItem,
    taxRate: TaxRate
  ): void {
    this.upsertLineItem(group, lineItem, taxRate);
    group.amount = Invoice.total(group);
    group.tax = Invoice.tax(group);
  }

  static tax(parent: IHasLineItems): number {
    return LineItemActions.tax(parent.items);
  }

  static total(parent: IHasLineItems): number {
    return LineItemActions.total(parent.items);
  }

  static subtotal(invoice: IInvoice): number {
    return invoice.items
      .map((lineItem) => lineItem.amount * lineItem.quantity)
      .reduce(
        (lastValue: number, value: number): number => lastValue + value,
        0
      );
  }

  static async updateTreatmentLink(
    invoice: WithRef<IInvoice>,
    lineItem: ITreatmentLineItem
  ): Promise<void> {
    if (!lineItem.treatmentRef.planRef) {
      return;
    }

    const plan = await Firestore.getDoc(lineItem.treatmentRef.planRef);
    await upsertInvoiceToTreatments(plan, invoice);
    await Firestore.saveDoc(plan);
  }

  static isPaid(invoice: IInvoice): boolean {
    return invoice.status === InvoiceStatus.Paid;
  }

  static isUnpaid(invoice: IInvoice): boolean {
    return invoice.status === InvoiceStatus.Issued;
  }

  static findHealthcareClaim(
    invoice: IInvoice,
    claimUid: string
  ): IHealthcareClaim | undefined {
    if (!invoice.claims) {
      return undefined;
    }
    return invoice.claims.find((item) => item.uid === claimUid);
  }

  static findHealthcareClaimForTransaction<T>(
    invoice: IInvoice,
    transaction: WithRef<ITransaction<T>>
  ): IHealthcareClaim | undefined {
    if (!invoice.claims) {
      return undefined;
    }
    return invoice.claims.find((claim) =>
      HealthcareClaim.hasTransaction(claim, transaction.ref)
    );
  }

  static upsertHealthcareClaim(
    invoice: IInvoice,
    claim: IHealthcareClaim
  ): void {
    const claims = invoice.claims ?? [];
    const index = claims.findIndex((item) => item.uid === claim.uid);
    if (index < 0) {
      invoice.claims = [...claims, claim];
      return;
    }
    claims[index] = claim;
    invoice.claims = claims;
  }

  static async updateInvoiceTreatments(
    invoice: WithRef<IInvoice>,
    plan: IReffable<ITreatmentPlan>,
    step: WithRef<ITreatmentStep>,
    taxRate: TaxRate
  ): Promise<void> {
    const practitioner = await Invoice.findInvoicePractitioner(invoice);
    if (!practitioner) {
      throw new Error(`Couldn't resolve practitioner for invoice`);
    }
    invoice.items = invoice.items.filter(
      (lineItem) => !isTreatmentLineItem(lineItem)
    );
    treatmentStepToLineItems(
      step,
      plan,
      stafferToNamedDoc(practitioner),
      taxRate
    ).map((lineItem) => Invoice.upsertLineItem(invoice, lineItem, taxRate));
    const claims = await this.updateHealthcareClaims(invoice, practitioner);
    await Firestore.patchDoc(invoice.ref, {
      items: invoice.items,
      claims,
    });
  }

  static async updateHealthcareClaims(
    invoice: WithRef<IInvoice>,
    practitioner: WithRef<IStaffer>
  ): Promise<IHealthcareClaim[]> {
    const newClaims = await HealthcareClaimGenerator.createClaims(
      invoice.items,
      practitioner,
      invoice.practice.ref
    );
    const existingClaims = (invoice.claims ?? [])
      .filter((claim) => HealthcareClaim.shouldPersistClaim(claim))
      .map((claim) => HealthcareClaim.toOutdatedClaim(claim));
    return [...existingClaims, ...newClaims];
  }

  static async findInvoicePractitioner(
    invoice: WithRef<IInvoice>
  ): Promise<WithRef<IStaffer> | undefined> {
    const appointment = await findInvoiceAppointment(invoice);
    if (!appointment) {
      return;
    }
    return OrganisationCache.staff.get.getDoc(appointment.practitioner.ref);
  }

  static canEditInvoice(invoice: IInvoice): boolean {
    return Invoice.statusIsOneOf(invoice, [InvoiceStatus.Draft]);
  }

  static canCancelInvoice(invoice: IInvoice): boolean {
    return (
      Invoice.statusIsOneOf(invoice, [
        InvoiceStatus.Draft,
        InvoiceStatus.Issued,
        InvoiceStatus.Paid,
      ]) &&
      invoice.type !== InvoiceType.CreditNote &&
      !invoice.items.some(isCreditRefundLineItem) &&
      !invoice.items.some(isCreditTransferLineItem)
    );
  }

  static canWriteOffInvoice(invoice: IInvoice): boolean {
    return Invoice.statusIsOneOf(invoice, [InvoiceStatus.Issued]);
  }

  static canRevertToIssued(invoice: IInvoice): boolean {
    return Invoice.statusIsOneOf(invoice, [InvoiceStatus.WrittenOff]);
  }

  static canIssueInvoice(invoice: IInvoice): boolean {
    return Invoice.statusIsOneOf(invoice, [InvoiceStatus.Draft]);
  }

  static canReplaceInvoice(invoice: IInvoice): boolean {
    return (
      Invoice.isCancelledInvoice(invoice) &&
      !Invoice.isReplacedInvoice(invoice) &&
      Invoice.previousStatus(invoice)?.status !== InvoiceStatus.Draft
    );
  }

  static canAmendInvoice(invoice: IInvoice): boolean {
    return (
      !Invoice.statusIsOneOf(invoice, [InvoiceStatus.Draft]) &&
      invoice.type !== InvoiceType.CreditNote &&
      !invoice.items.some(isCreditRefundLineItem) &&
      !invoice.items.some(isCreditTransferLineItem)
    );
  }

  static canAddTransactions(invoice: IInvoice): boolean {
    return Invoice.statusIsOneOf(invoice, [
      InvoiceStatus.Draft,
      InvoiceStatus.Issued,
      InvoiceStatus.Paid,
    ]);
  }

  static canViewTransactions(_invoice: IInvoice): boolean {
    return true;
  }

  static statusIsOneOf(invoice: IInvoice, statuses: InvoiceStatus[]): boolean {
    return statuses.includes(invoice.status);
  }

  static patientDocRef(
    invoice: IReffable<IInvoice>
  ): DocumentReference<IPatient> {
    return Firestore.getParentDocRef<IPatient>(
      Invoice.resolveActiveInvoiceRef(invoice)
    );
  }

  static patient$(invoice: WithRef<IInvoice>): Observable<WithRef<IPatient>> {
    return doc$(Invoice.patientDocRef(invoice));
  }

  static getAssociatedAppointments$(
    invoice: WithRef<IInvoice>
  ): Observable<WithRef<IAppointment>[]> {
    return Invoice.patient$(invoice).pipe(
      switchMap((patient) =>
        query$(Appointment.col(patient), where('invoiceRef', '==', invoice.ref))
      )
    );
  }

  static getAssociatedAppointment$(
    invoice: WithRef<IInvoice>
  ): Observable<WithRef<IAppointment> | undefined> {
    return Invoice.patient$(invoice).pipe(
      switchMap((patient) =>
        firstResult$(
          Appointment.col(patient),
          where('invoiceRef', '==', invoice.ref)
        )
      )
    );
  }

  static getAssociatedAppointment(
    invoice: WithRef<IInvoice>
  ): Promise<WithRef<IAppointment> | undefined> {
    const patientRef = this.patientDocRef(invoice);
    return firstResult(
      Patient.appointmentCol({ ref: patientRef }),
      where('invoiceRef', '==', invoice.ref)
    );
  }

  static getExistingAccountCredits$(
    invoice: WithRef<IInvoice>
  ): Observable<WithRef<IAccountCredit>[]> {
    return Invoice.patient$(invoice).pipe(
      switchMap((patient) =>
        query$(
          undeletedQuery(AccountCredit.col(patient)),
          where('invoice', '==', invoice.ref)
        )
      )
    );
  }

  static getPractitionersOnInvoice(
    invoice: IInvoice
  ): INamedDocument<IStaffer>[] {
    const treatmentLineItems = invoice.items.filter(isTreatmentLineItem);
    const depositLineItems = invoice.items.filter(isDepositLineItem);
    const allAttributedTo = compact([
      ...treatmentLineItems.map(
        (treatment) => treatment.treatmentRef.attributedTo
      ),
      ...depositLineItems.map((deposit) => deposit.attributedTo),
    ]);
    return uniqWith(allAttributedTo, isSameRef);
  }

  static getPractitionerProportionsOnInvoice(
    invoice: IInvoice,
    includeDeposits: boolean = true
  ): IPractitionerProportionInvoiceAmount[] {
    const allAttributedTo = invoice.items.map((lineItem) => ({
      practitioner: this.getLineItemAllocatedTo(lineItem, includeDeposits),
      amount: lineItem.amount,
    }));
    return compact(
      customGroupBy(
        allAttributedTo,
        (item) => item.practitioner,
        isSameRef
      ).map((result) => {
        if (!result.group) {
          return;
        }
        return {
          practitioner: result.group,
          amount: sumBy(result.items, (item) => item.amount),
        };
      })
    );
  }

  static getLineItemAllocatedTo(
    lineItem: ICustomLineItem,
    includeDeposits: boolean = true
  ): INamedDocument<IStaffer> | undefined {
    if (isTreatmentLineItem(lineItem)) {
      return lineItem.treatmentRef?.attributedTo;
    }
    if (includeDeposits && isDepositLineItem(lineItem)) {
      return lineItem.attributedTo;
    }
  }

  static getAssociatedTreatmentPlans(
    invoice: IInvoice
  ): DocumentReference<ITreatmentPlan>[] {
    return uniqWith(
      compact(
        invoice.items
          .filter((lineItem): lineItem is ITreatmentLineItem =>
            isTreatmentLineItem(lineItem)
          )
          .map((treatment) => treatment.treatmentRef.planRef ?? undefined)
      ),
      isSameRef
    );
  }

  static async issueInvoice(
    invoice: WithRef<IInvoice>,
    transactions: ITransaction[],
    staffer: WithRef<IStaffer>,
    due: moment.Moment = moment().add(2, 'weeks')
  ): Promise<WithRef<IInvoice>> {
    const balance = Invoice.balance(invoice, transactions);
    const status = balance > 0 ? InvoiceStatus.Issued : InvoiceStatus.Paid;

    this.updateStatus(invoice, status);
    invoice.issuedAt = invoice.issuedAt ?? toTimestamp();
    invoice.due = invoice.due ?? toTimestamp(due);
    if (invoice.status === InvoiceStatus.Paid) {
      invoice.paidAt = Invoice.determinePaidAt(transactions);
      if (toMoment(invoice.paidAt).isBefore(toMoment(invoice.issuedAt))) {
        invoice.issuedAt = invoice.paidAt;
      }
      await Invoice.addDueAccountCredits(
        await snapshot(Invoice.patient$(invoice)),
        invoice,
        await snapshot(Invoice.getExistingAccountCredits$(invoice))
      );
    }

    await Firestore.saveDoc(invoice);
    const interaction = InvoiceInteractionBuilder.statusChangeInteraction(
      status,
      staffer
    );
    await Invoice.addInteraction(invoice, interaction);
    return invoice;
  }

  static determinePaidAt(transactions: ITransaction[]): Timestamp {
    const transactionGroups = new TransactionOperators(transactions)
      .groupByReference()
      .filter((group) => group.paidToDate() > 0);

    const latestGroupTransactions = compact(
      transactionGroups.map((group) =>
        group.incoming().completed().sort(sortByCreatedAt).first()
      )
    );

    return (
      new TransactionOperators(latestGroupTransactions)
        .sort(sortByCreatedAt)
        .first()?.createdAt ?? toTimestamp()
    );
  }

  static async writeOffInvoice<T extends IInvoice>(
    invoice: WithRef<T>,
    staffer: WithRef<IStaffer>
  ): Promise<T> {
    await Firestore.saveDoc(
      Invoice.updateStatus(invoice, InvoiceStatus.WrittenOff)
    );
    const interaction = InvoiceInteractionBuilder.statusChangeInteraction(
      InvoiceStatus.WrittenOff,
      staffer
    );
    await Invoice.addInteraction(invoice, interaction);
    return invoice;
  }

  static async addInteraction<T extends IInvoice>(
    invoice: IReffable<T>,
    interaction: IInteractionV2,
    atomicTransaction?: FirebaseTransaction
  ): Promise<void> {
    await addDoc(
      Invoice.interactionCol(invoice as IReffable<IInvoice>),
      interaction,
      undefined,
      atomicTransaction
    );
  }

  static cancelInvoice<T extends IInvoice>(invoice: T): T {
    this.updateStatus(invoice, InvoiceStatus.Cancelled);
    return invoice;
  }

  static updateStatus<T extends IInvoice>(
    invoice: T,
    status: InvoiceStatus,
    updatedAt: Timestamp = toTimestamp()
  ): T {
    if (invoice.status === status) {
      return invoice;
    }
    invoice.status = status;
    invoice.statusHistory.push({
      status,
      updatedAt,
    });
    return invoice;
  }

  static subTotal(parent: IHasLineItems): number {
    return LineItemActions.subTotal(parent.items);
  }

  static balance(parent: IHasLineItems, transactions: ITransaction[]): number {
    const paidtoDate = new TransactionOperators(
      transactions.filter((transaction) => !transaction.deleted)
    ).paidToDate();
    return roundTo2Decimals(this.total(parent) - paidtoDate);
  }

  static isOverpaid(
    invoice: WithRef<IInvoice>,
    transactions: ITransaction[]
  ): boolean {
    return (
      invoice.status === InvoiceStatus.Paid &&
      Invoice.getOverpaymentAmount(invoice, transactions) > 0
    );
  }

  static async isRefundableByCredit(
    invoice: WithRef<IInvoice>,
    credit: WithRef<IAccountCredit>
  ): Promise<boolean> {
    const transactions = await snapshot(
      Invoice.transactions$(invoice).pipe(
        multiFilter((transaction) =>
          isAccountCreditExtendedData(transaction.extendedData)
        )
      )
    );

    if (invoice.type !== InvoiceType.CreditNote) {
      const refundable = sum(
        transactionsToUsedCredits(
          transactions,
          credit,
          TransactionType.Incoming
        ).map((usedCredit) => usedCredit.amount)
      );

      const refunded = sum(
        transactionsToUsedCredits(
          transactions,
          credit,
          TransactionType.Outgoing
        ).map((usedCredit) => usedCredit.refunded)
      );

      return refundable - refunded > 0;
    }

    const refunded = sum(
      transactionsToUsedCredits(
        transactions,
        credit,
        TransactionType.Outgoing
      ).map((usedCredit) => usedCredit.amount)
    );

    return refunded > 0;
  }

  static firstEnteredStatus(
    invoice: IInvoice,
    status: InvoiceStatus
  ): Timestamp | undefined {
    const event: IInvoiceStatusLog | undefined = invoice.statusHistory
      .sort(sortByUpdatedAt)
      .reverse()
      .find((log: IInvoiceStatusLog) => log.status === status);
    return event ? event.updatedAt : undefined;
  }

  static lastEnteredStatus(
    invoice: IInvoice,
    status: InvoiceStatus
  ): Timestamp | undefined {
    const event = Invoice.lastLogForStatus(invoice, status);
    return event ? event.updatedAt : undefined;
  }

  static previousStatus(invoice: IInvoice): IInvoiceStatusLog | undefined {
    const ordered = invoice.statusHistory.sort(sortByUpdatedAt);
    return ordered[1];
  }

  static lastLogForStatus(
    invoice: IInvoice,
    status: InvoiceStatus
  ): IInvoiceStatusLog | undefined {
    return invoice.statusHistory
      .sort(sortByUpdatedAt)
      .find((log) => log.status === status);
  }

  static statusAsOfDate(
    invoice: IInvoice,
    asOfDate: Timestamp
  ): IInvoiceStatusLog | undefined {
    const asOfMoment = toMoment(asOfDate);
    if (toMoment(invoice.createdAt).isAfter(asOfMoment)) {
      return;
    }
    return invoice.statusHistory
      .sort(sortByUpdatedAt)
      .find((statusLog) => toMoment(statusLog.updatedAt).isBefore(asOfMoment));
  }

  static async findInvoiceByReference(
    reference: string,
    orgRef: DocumentReference<IOrganisation>
  ): Promise<WithRef<IInvoice> | undefined> {
    const results = await query(
      collectionGroupQuery<IInvoice>(CollectionGroup.Invoices),
      where('reference', '==', reference)
    );

    const invoices = results.filter((invoice) => {
      const patientRef = Firestore.getParentDocRef<IPatient>(invoice.ref);
      const brandRef = Firestore.getParentDocRef<IBrand>(patientRef);
      const invoiceOrgRef = Firestore.getParentDocRef<IOrganisation>(brandRef);
      return isSameRef(invoiceOrgRef, orgRef);
    });

    return first(invoices);
  }

  static async addDueAccountCredits(
    patient: WithRef<IPatient>,
    invoice: WithRef<IInvoice>,
    existingCredits: WithRef<IAccountCredit>[],
    atomicTransaction?: FirebaseTransaction
  ): Promise<DocumentReference<IAccountCredit>[]> {
    const credits = Invoice.getDueCredits(invoice, existingCredits);
    return resolveParallel(
      credits.map((credit) =>
        Invoice.addAccountCredit(patient, credit, atomicTransaction)
      )
    );
  }

  static async useRefundedAccountCredits(
    invoice: WithRef<IInvoice>,
    creditsToRefund: {
      credit: WithRef<IAccountCredit>;
      refund: IAccountCreditRefunded;
    }[],
    atomicTransaction?: FirebaseTransaction
  ): Promise<void> {
    await Firestore.patchDoc(
      invoice.ref,
      {
        items: invoice.items.map((item) => {
          if (!isCreditRefundLineItem(item)) {
            return item;
          }

          return {
            ...item,
            creditsUsed: item.creditsUsed.map((creditRefund) => ({
              ...creditRefund,
              refundedAt: invoice.paidAt ?? toTimestamp(),
            })),
          };
        }),
      },
      atomicTransaction
    );

    await asyncForEach(creditsToRefund, (creditToRefund) =>
      Firestore.patchDoc(
        creditToRefund.credit.ref,
        {
          used: creditToRefund.credit.used + creditToRefund.refund.amount,
        },
        atomicTransaction
      )
    );
  }

  static getDueCredits(
    invoice: WithRef<IInvoice>,
    existingCredits: WithRef<IAccountCredit>[]
  ): IAccountCredit[] {
    if (invoice.type === InvoiceType.CreditNote) {
      return [];
    }

    const issuedDepositUids = compact(
      existingCredits.map((credit) => credit.depositUid)
    );

    return invoice.items
      .filter(
        (item): item is IDepositLineItem | ICreditTransferLineItem =>
          isDepositLineItem(item) || isCreditTransferLineItem(item)
      )
      .filter((deposit) => !issuedDepositUids.includes(deposit.uid))
      .map((deposit) => ({
        ...AccountCredit.init({
          description: deposit.description,
          amount: deposit.amount,
          type: AccountCreditType.Deposit,
          invoice: invoice.ref,
          depositUid: deposit.uid,
          reservedFor: {
            treatment: 'treatments' in deposit ? deposit.treatments : undefined,
            practitioner: deposit.attributedTo,
            treatmentCategory: isDepositLineItem(deposit)
              ? deposit.forTreatmentCategoryRef
              : undefined,
          },
          practiceRef: invoice.practice.ref,
        }),
        createdAt: invoice.paidAt ?? toTimestamp(),
      }));
  }

  static async getRefundableCredits(
    invoice: WithRef<IInvoice>,
    atomicTransaction?: FirebaseTransaction
  ): Promise<
    { credit: WithRef<IAccountCredit>; refund: IAccountCreditRefunded }[]
  > {
    return (
      await asyncForEach(
        invoice.items.filter(isCreditRefundLineItem),
        async (creditsToRefund) =>
          asyncForEach(
            creditsToRefund.creditsUsed.filter(
              (creditUsed) => !creditUsed.refundedAt
            ),
            async (creditRefund) => {
              const credit = await Firestore.getDoc(
                creditRefund.accountCreditRef,
                atomicTransaction
              );

              return {
                credit,
                refund: creditRefund,
              };
            }
          )
      )
    ).flat();
  }

  static async upsertAssociatedAccountCredits(
    invoice: WithRef<IInvoice>,
    existingAccountCredits: WithRef<IAccountCredit>[],
    atomicTransaction?: FirebaseTransaction
  ): Promise<void> {
    if (!invoice.paidAt) {
      return;
    }
    const depositLineItemUids = invoice.items
      .filter(isDepositLineItem)
      .map((deposit) => deposit.uid);

    const invoiceAccountCredits = existingAccountCredits.filter(
      (credit) =>
        credit.depositUid && depositLineItemUids.includes(credit.depositUid)
    );

    await asyncForEach(invoiceAccountCredits, (accountCredit) =>
      Firestore.patchDoc(
        accountCredit.ref,
        {
          createdAt: invoice.paidAt ?? toTimestamp(),
        } as Partial<IAccountCredit>,
        atomicTransaction
      )
    );
  }

  static upsertDepositLineItem(
    invoice: WithRef<IInvoice>,
    credit: IAccountCredit,
    taxRate: TaxRate
  ): void {
    if (!isSameRef(credit.invoice, invoice) || !credit.depositUid) {
      return;
    }

    const existingCreditTransferLineItem = invoice.items
      .filter(isCreditTransferLineItem)
      .find((creditTransfer) => creditTransfer.uid === credit.depositUid);

    if (existingCreditTransferLineItem) {
      this.upsertCreditTransferLineItem(
        invoice,
        existingCreditTransferLineItem,
        credit,
        taxRate
      );
      return;
    }

    const existingDepositLineItem = invoice.items
      .filter(isDepositLineItem)
      .find((deposit) => deposit.uid === credit.depositUid);

    const depositLineItem =
      existingDepositLineItem ??
      depositToLineItem(
        credit.description,
        credit.amount,
        credit.reservedFor.practitioner,
        credit.reservedFor.treatmentCategory,
        credit.depositUid
      );

    depositLineItem.amount = credit.amount;
    depositLineItem.attributedTo = credit.reservedFor.practitioner;
    depositLineItem.forTreatmentCategoryRef =
      credit.reservedFor.treatmentCategory;

    Invoice.upsertLineItem(invoice, depositLineItem, taxRate);
  }

  static upsertCreditTransferLineItem(
    invoice: WithRef<IInvoice>,
    lineItem: ICreditTransferLineItem,
    credit: IAccountCredit,
    taxRate: TaxRate
  ): void {
    lineItem.amount = credit.amount;
    lineItem.attributedTo = credit.reservedFor.practitioner;
    Invoice.upsertLineItem(invoice, lineItem, taxRate);
  }

  static getOverpaymentAmount(
    invoice: WithRef<IInvoice>,
    transactions: ITransaction[]
  ): number {
    const overpayment = 0 - Invoice.balance(invoice, transactions);
    return overpayment > 0 ? overpayment : 0;
  }

  static async addAccountCredit(
    patient: WithRef<IPatient>,
    accountCredit: IAccountCredit,
    atomicTransaction?: FirebaseTransaction
  ): Promise<DocumentReference<IAccountCredit>> {
    return addDoc(
      AccountCredit.col(patient),
      AccountCredit.init(accountCredit),
      undefined,
      atomicTransaction
    );
  }

  static async revertToDraft(
    invoice: WithRef<IInvoice>,
    staffer: WithRef<IStaffer>,
    atomicTransaction?: FirebaseTransaction
  ): Promise<void> {
    await Invoice.amendInvoice(invoice, staffer.ref, atomicTransaction);
    const invoiceCredits = await snapshot(
      Invoice.getExistingAccountCredits$(invoice)
    );
    await AccountCredit.deleteUnusedAccountCredits(
      invoiceCredits,
      atomicTransaction
    );
    await Firestore.patchDoc(
      invoice.ref,
      {
        ...Invoice.updateStatus(invoice, InvoiceStatus.Draft),
        paidAt: undefined,
        due: undefined,
      },
      atomicTransaction
    );
    const interaction = InvoiceInteractionBuilder.statusChangeInteraction(
      InvoiceStatus.Draft,
      staffer
    );
    await Invoice.addInteraction(invoice, interaction, atomicTransaction);
  }

  static async amendInvoice(
    invoice: WithRef<IInvoice>,
    stafferRef: DocumentReference<IStaffer>,
    atomicTransaction?: FirebaseTransaction
  ): Promise<void> {
    const historyRef = await DocumentArchive.snapshotToArchive(
      await Firestore.getDoc(invoice.ref, atomicTransaction),
      Invoice.archiveCol(invoice),
      undefined,
      stafferRef,
      atomicTransaction
    );
    await Firestore.saveDoc(
      {
        ...invoice,
        amendmentOf: historyRef,
      },
      undefined,
      atomicTransaction
    );
  }

  static findTransactionAllocations(
    invoice: IInvoice,
    transactionRef: DocumentReference<ITransaction>
  ): ITransactionAllocation[] {
    const record = invoice.summary.transactionAllocations.find((allocation) =>
      isSameRef(allocation.transactionRef, transactionRef)
    );
    return record?.allocations ?? [];
  }
}

export async function upsertInvoiceToTreatments(
  plan: WithRef<ITreatmentPlan>,
  invoice: WithRef<IInvoice>
): Promise<void> {
  const allTreatments: IChartedTreatment[] = await snapshot(
    TreatmentPlan.allTreatments$(plan)
  );
  invoice.items.filter(isTreatmentLineItem).map((treatmentLine) => {
    const treatment = allTreatments.find(
      (item) => item.uuid === treatmentLine.treatmentRef.treatmentUuid
    );
    if (!treatment) {
      return;
    }
    upsertInvoiceRef(treatment, {
      invoiceRef: invoice.ref,
      lineItemUid: treatmentLine.uid,
      amount: treatmentLine.amount,
    });
  });
}

function upsertInvoiceRef(
  treatment: IChartedTreatment,
  ref: IInvoiceReference
): IChartedTreatment {
  treatment.invoices = [
    ...treatment.invoices.filter((item) => item.invoiceRef !== ref.invoiceRef),
    ref,
  ];
  return treatment;
}

function transactionsToUsedCredits(
  transactions: WithRef<ITransaction>[],
  credit: WithRef<IAccountCredit>,
  transactionType: TransactionType
): IUsedAccountCredit[] {
  return flatten(
    transactions
      .filter((transaction) => transaction.type === transactionType)
      .map((transaction) =>
        Transaction.getUsedCredits(transaction).filter((usedCredit) =>
          isSameRef(usedCredit, credit)
        )
      )
  );
}

export interface IPractitionerProportionInvoiceAmount {
  practitioner: INamedDocument<IStaffer>;
  amount: number;
}

export function withTransactions$(
  invoice: WithRef<IInvoice>
): Observable<[WithRef<IInvoice>, WithRef<ITransaction>[]]> {
  return combineLatest([of(invoice), Invoice.transactions$(invoice)]);
}

export async function withTransactions(
  invoice: WithRef<IInvoice>
): Promise<[WithRef<IInvoice>, WithRef<ITransaction>[]]> {
  return [
    invoice,
    await Firestore.getDocs(undeletedQuery(Invoice.transactionCol(invoice))),
  ];
}

export function withPatientAndTransactions$(
  invoice: WithRef<IInvoice>
): Observable<[WithRef<IPatient>, WithRef<IInvoice>, WithRef<ITransaction>[]]> {
  return combineLatest([
    Invoice.patient$(invoice),
    of(invoice),
    Invoice.transactions$(invoice),
  ]).pipe(take(1));
}

export const invoiceBalance: OperatorFunction<
  [IInvoice, ITransaction[]],
  number
> = map(([invoice, transactions]: [IInvoice, ITransaction[]]) =>
  Invoice.balance(invoice, transactions)
);

export const paidToDate: OperatorFunction<ITransaction[], number> = map(
  (transactions: ITransaction[]) =>
    new TransactionOperators(transactions).paidToDate()
);

export async function issueInvoice(
  invoice: WithRef<IInvoice>,
  staffer: WithRef<IStaffer>
): Promise<void> {
  const transactions = await snapshot(Invoice.transactions$(invoice));
  await Invoice.issueInvoice(invoice, transactions, staffer);
}

export async function getInvoiceTransactions(
  invoice: WithRef<IInvoice>
): Promise<WithRef<ITransaction>[]> {
  return snapshot(Invoice.transactions$(invoice));
}

export async function findInvoiceAppointment(
  invoice: WithRef<IInvoice>
): Promise<WithRef<IAppointment> | undefined> {
  const patientRef = Firestore.getParentDocRef<IPatient>(invoice.ref.path);
  const appointmentsCol = Appointment.col({ ref: patientRef });
  return snapshot(
    find$(appointmentsCol, where('invoiceRef', '==', invoice.ref))
  );
}
