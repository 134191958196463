import { getSchemaText } from '@principle-theorem/editor';
import {
  CalendarUnit,
  CalendarView,
  CollectionGroup,
  EventType,
  IAppointment,
  IBrand,
  ICalendarEvent,
  ICalendarEventSchedule,
  ICalendarEventSchedulesMap,
  IEventable,
  IPatient,
  IPractice,
  IScheduleSummary,
  IScheduleSummaryEvent,
  IScheduleSummaryEventable,
  IStaffer,
  getRangeForDisplay,
  isCalendarEvent,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  IReffable,
  ISODateType,
  ITimePeriod,
  ITimestampRange,
  RequireProps,
  Timezone,
  WithRef,
  all$,
  asyncForEach,
  overlappingChunkRange,
  collectionGroupQuery,
  isSameIdentifier,
  isSameRef,
  multiFilter,
  multiMap,
  multiSwitchMap,
  query$,
  reduce2DArray,
  reduceToSingleArray,
  safeCombineLatest,
  serialiseTimePeriod,
  toEntityModel,
  toEntityModels$,
  toISODate,
  toMomentTz,
  toTimePeriod,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { chunk, compact, differenceWith, groupBy, uniqWith } from 'lodash';
import { Observable, combineLatest, from, iif, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { Appointment } from './appointment/appointment';
import { Brand } from './brand';
import { stafferToParticipant } from './common';
import { CalendarEventSchedule } from './event/calendar-event-schedule';
import { Event, filterEventTypes, isSameEvent } from './event/event';
import { OrganisationCache } from './organisation/organisation-cache';
import { Practice } from './practice/practice';
import { ScheduleSummary } from './schedule-summary/schedule-summary';
import { Staffer } from './staffer/staffer';

export class EventableQueries {
  static allAppointments$(
    practice: IReffable<IPractice>,
    range: ITimePeriod,
    participants?: DocumentReference<IStaffer | IPatient>[]
  ): Observable<WithRef<RequireProps<IAppointment, 'event'>>[]> {
    return all$(
      collectionGroupQuery<RequireProps<IAppointment, 'event'>>(
        CollectionGroup.Appointments,
        ...compact([
          where('event.practice.ref', '==', practice.ref),
          where('event.from', '>=', range.from.toDate()),
          where('event.from', '<=', range.to.toDate()),
          participants && participants.length
            ? where('event.participantRefs', 'array-contains-any', participants)
            : undefined,
        ])
      )
    ).pipe(multiFilter((appointment) => !appointment.deleted));
  }

  static appointments$(
    brand: IReffable<IBrand>,
    range: ITimePeriod,
    participants?: DocumentReference<IStaffer | IPatient>[],
    practices?: IReffable<IPractice>[]
  ): Observable<WithRef<RequireProps<IAppointment, 'event'>>[]> {
    return iif(
      () => !!practices?.length,
      of(practices ?? []),
      Brand.practices$(brand).pipe(
        multiMap((practice) => ({ ref: practice.ref }))
      )
    ).pipe(
      multiSwitchMap((practice) => {
        if (!participants || participants.length < 10) {
          return EventableQueries.allAppointments$(
            practice,
            range,
            participants
          );
        }

        const participantGroups = chunk(participants, 10);
        return safeCombineLatest(
          participantGroups.map((group) =>
            EventableQueries.allAppointments$(practice, range, group)
          )
        ).pipe(map(reduceToSingleArray));
      }),
      map(reduceToSingleArray)
    );
  }

  static getEventables$(
    brand: IReffable<IBrand>,
    range: ITimePeriod,
    excludeTypes: EventType[] = [],
    participants?: DocumentReference<IStaffer | IPatient>[],
    practiceRef?: DocumentReference<IPractice>
  ): Observable<WithRef<IEventable>[]> {
    const appointments$ = excludeTypes.includes(EventType.Appointment)
      ? of([])
      : EventableQueries.appointments$(
          brand,
          range,
          participants,
          practiceRef ? [{ ref: practiceRef }] : undefined
        );
    const events$ = Brand.getCalendarEvents$(
      brand,
      range,
      participants,
      undefined,
      practiceRef
    );

    return combineLatest([appointments$, events$]).pipe(
      map(([appointments, events]) => [...appointments, ...events]),
      filterEventTypes(excludeTypes)
    );
  }

  static getEventablesLegacy$(
    brand: WithRef<IBrand>,
    practice: WithRef<IPractice>,
    view: CalendarView,
    range: ITimePeriod,
    unit: CalendarUnit,
    timezone: Timezone,
    brandPractitioners: WithRef<IStaffer>[],
    excludeTypes: EventType[] = []
  ): Observable<IScheduleSummaryEventable[]> {
    const displayRange = getRangeForDisplay(view, unit, range, timezone);

    const appointments$ = EventableQueries.allAppointments$(
      practice,
      displayRange,
      brandPractitioners.map((practitioner) => practitioner.ref)
    ).pipe(
      multiSwitchMap((appointment) =>
        query$(
          undeletedQuery(Appointment.interactionCol(appointment)),
          where('pinned', '==', true)
        ).pipe(map(() => appointment))
      )
    );

    const events$ = Brand.getCalendarEvents$(
      brand,
      displayRange,
      brandPractitioners.map((practitioner) => practitioner.ref),
      undefined,
      practice.ref
    ).pipe(filterEventTypes([EventType.GapCandidate, EventType.RosteredOn]));

    return combineLatest([appointments$, events$]).pipe(
      switchMap(([appointments, events]) =>
        legacyScheduleSummaryEventables$(
          practice,
          displayRange,
          appointments,
          events,
          brandPractitioners
        )
      ),
      multiFilter(({ event }) => !excludeTypes.includes(event.type)),
      toEntityModels$()
    );
  }

  static getScheduleSummaryEventablesWithFallback$(
    range: ITimePeriod,
    practice: WithRef<IPractice>,
    practitioners: WithRef<IStaffer>[],
    allStaffSchedules: ICalendarEventSchedulesMap,
    excludeTypes: EventType[] = [],
    unit: CalendarUnit = CalendarUnit.Day,
    view: CalendarView = CalendarView.Timeline
  ): Observable<IScheduleSummaryEventable[]> {
    const chunkedRange = overlappingChunkRange(range, 1, 'day');
    const practitionerGroups = chunk(practitioners, 10);

    const aggregateData$ = safeCombineLatest(
      practitionerGroups.flatMap((practitionerGroup) =>
        chunkedRange.flatMap((day) =>
          query$(
            Practice.scheduleSummaryCol({
              ref: practice.ref,
            }),
            where('day', '==', toISODate(day.from)),
            where('practice', '==', practice.ref),
            where(
              'staffer',
              'in',
              practitionerGroup.map((staffer) => staffer.ref)
            )
          )
        )
      )
    ).pipe(
      reduce2DArray(),
      map((summaries) => {
        return EventableQueries.buildAllEvents(
          summaries,
          allStaffSchedules,
          practice,
          practitioners,
          chunkedRange,
          excludeTypes
        );
      })
    );

    if (practice.enabledForSchedulingAggregateTrial) {
      return aggregateData$;
    }

    const legacyData$ = from(
      OrganisationCache.brands.getDoc(Practice.brandDoc(practice))
    ).pipe(
      switchMap((brand) =>
        EventableQueries.getEventablesLegacy$(
          brand,
          practice,
          view,
          range,
          unit,
          practice.settings.timezone,
          practitioners,
          excludeTypes
        )
      )
    );

    return legacyData$.pipe(
      withLatestFrom(aggregateData$),
      map(([legacyData, aggregateData]) => {
        const extraInAggregate = differenceWith(
          aggregateData,
          legacyData,
          (recordA, recordB) => {
            return isSameEvent(recordA.event, recordB.event);
          }
        );

        const missingFromAggregate = differenceWith(
          legacyData,
          aggregateData,
          (recordA, recordB) => {
            return isSameEvent(recordA.event, recordB.event);
          }
        );

        if (extraInAggregate.length > 1 || missingFromAggregate.length > 1) {
          // eslint-disable-next-line no-console
          console.error(
            `Difference between old query and new query eventables for date: ${toISODate(
              range.from
            )} - ${toISODate(range.to)}, practice: ${
              practice.ref.path
            }, extraInAggregate: ${
              extraInAggregate.length
            }, missingFromAggregate: ${missingFromAggregate.length}`,
            {
              extraInAggregate: extraInAggregate.map((record) => ({
                date: serialiseTimePeriod(record.event),
                eventType: record.event.type,
                practitioner: record.event.participants[0]?.name,
                record,
              })),
              missingFromAggregate: missingFromAggregate.map((record) => ({
                date: serialiseTimePeriod(record.event),
                eventType: record.event.type,
                practitioner: record.event.participants[0]?.name,
                record,
              })),
            }
          );
          return legacyData;
        }
        return legacyData;
      })
    );
  }

  static buildAllEvents(
    summaries: WithRef<IScheduleSummary>[],
    allStaffSchedules: ICalendarEventSchedulesMap,
    practice: WithRef<IPractice>,
    practitioners: WithRef<IStaffer>[],
    chunkedRange: ITimePeriod[],
    excludeTypes: EventType[]
  ): IScheduleSummaryEventable[] {
    const eventsFromSummaries = summaries.map((summary) => [
      ...summary.events,
      ...buildForecastedEvents(
        summary,
        getCalendarEventSchedules(allStaffSchedules, summary),
        practice.settings.timezone
      ),
      ...buildGapEvents(summary, practice, practitioners),
    ]);

    const forecastedEventsWithoutSummaries = buildFallbackForcastedEvents(
      summaries,
      allStaffSchedules,
      practice,
      practitioners,
      chunkedRange
    );

    const allEvents = [
      ...eventsFromSummaries.flat(),
      ...forecastedEventsWithoutSummaries,
    ];

    return uniqWith(
      allEvents
        .filter(({ event }) => event && !excludeTypes.includes(event.type))
        .map((event) =>
          toEntityModel<IScheduleSummaryEventable | IScheduleSummaryEvent>(
            event
          )
        ),
      (eventA, eventB) => isSameIdentifier(eventA, eventB)
    );
  }
}

export function buildGapEvents(
  summary: IScheduleSummary,
  practice: WithRef<IPractice>,
  practitioners: WithRef<IStaffer>[]
): IScheduleSummaryEventable[] {
  const staffer = practitioners.find((practitioner) =>
    isSameRef(practitioner, summary.staffer)
  );

  if (!staffer) {
    return [];
  }

  return summary.gaps.map((gap) => buildGapEvent(gap, practice, staffer));
}

export function buildGapEvent(
  gap: ITimestampRange,
  practice: WithRef<IPractice>,
  staffer: WithRef<IStaffer>
): IScheduleSummaryEventable {
  const event = Event.init({
    ...gap,
    practice,
    type: EventType.Gap,
    participantRefs: [staffer.ref],
    participants: [stafferToParticipant(staffer)],
  });

  return {
    uid: uuid(),
    event,
    isBlocking: false,
    metadata: {
      label: 'Gap',
      tags: [],
      pinnedNotes: [],
    },
  };
}

export function buildForecastedEvents(
  summary: IScheduleSummary,
  rosterSchedules: WithRef<ICalendarEventSchedule>[],
  timezone: Timezone
): IScheduleSummaryEventable[] {
  const blockingEvents = summary.events
    .filter(
      (event) =>
        event.event &&
        event.isBlocking &&
        event.event.type !== EventType.RosteredOn
    )
    .map((event) => toTimePeriod(event.event.from, event.event.to));

  return buildEvents(
    rosterSchedules,
    summary.day,
    timezone,
    summary.practice,
    blockingEvents
  );
}

export function buildFallbackForcastedEvents(
  summaries: WithRef<IScheduleSummary>[],
  allStaffSchedules: ICalendarEventSchedulesMap,
  practice: WithRef<IPractice>,
  practitioners: WithRef<IStaffer>[],
  range: ITimePeriod[]
): IScheduleSummaryEventable[] {
  const practitionerWithDayGroup = practitioners.flatMap((practitioner) =>
    range.map((day) => ({
      day: toISODate(day.from),
      practitioner,
    }))
  );

  const daysNotLoadedForPractitioners = practitionerWithDayGroup.filter(
    ({ day, practitioner }) => {
      return !summaries.some(
        (summary) =>
          summary.day === day && isSameRef(summary.staffer, practitioner)
      );
    }
  );

  const practitionersGroupedByDay = groupBy(
    daysNotLoadedForPractitioners,
    'practitioner.ref.path'
  );

  return Object.values(practitionersGroupedByDay).flatMap((group) => {
    const timezone = practice.settings.timezone;
    const stafferRef = group[0].practitioner.ref;
    const staffer = practitioners.find((practitioner) =>
      isSameRef(practitioner, stafferRef)
    );
    if (!staffer) {
      return [];
    }
    const rosterSchedules = allStaffSchedules[stafferRef.id] ?? [];
    return group.flatMap(({ day }) =>
      buildEvents(rosterSchedules, day, timezone, practice.ref)
    );
  });
}

function buildEvents(
  rosterSchedules: WithRef<ICalendarEventSchedule>[],
  day: ISODateType,
  timezone: Timezone,
  practiceRef: DocumentReference<IPractice>,
  blockingEvents: ITimePeriod[] = []
): IScheduleSummaryEventable[] {
  return rosterSchedules
    .filter(
      (rosterSchedule) =>
        rosterSchedule.item.event.type !== EventType.RosteredOn &&
        isSameRef(rosterSchedule.item.event.practice, practiceRef)
    )
    .flatMap((rosterSchedule) => {
      const range = {
        from: toMomentTz(day, timezone).startOf('day'),
        to: toMomentTz(day, timezone).endOf('day'),
      };

      const events = CalendarEventSchedule.buildEventsWithTimezone(
        rosterSchedule,
        range,
        blockingEvents,
        timezone
      );

      return events.map((event) => ({
        uid: uuid(),
        event: event.event,
        isBlocking: event.isBlocking,
        metadata: {
          label: getSchemaText(event.title),
          tags: event.eventTags,
          pinnedNotes: [getSchemaText(event.notes)],
          scheduleRef: event.scheduleRef,
        },
      }));
    });
}

function getCalendarEventSchedules(
  allStaffSchedules: ICalendarEventSchedulesMap,
  summary: IScheduleSummary
): WithRef<ICalendarEventSchedule>[] {
  const calendarEventSchedules = allStaffSchedules[summary.staffer.id];
  if (!calendarEventSchedules) {
    // eslint-disable-next-line no-console
    console.warn(
      `No calendar event schedules found for ${summary.staffer.path} on ${summary.day}`
    );
  }
  return calendarEventSchedules ?? [];
}

function legacyScheduleSummaryEventables$(
  practice: WithRef<IPractice>,
  displayRange: ITimePeriod,
  appointments: WithRef<RequireProps<IAppointment, 'event'>>[],
  events: WithRef<IEventable<object>>[],
  staff: WithRef<IStaffer>[]
): Observable<IScheduleSummaryEventable[]> {
  const eventables: WithRef<IEventable<object>>[] = [
    ...appointments,
    ...events,
  ];

  const breaks$ = Staffer.buildEventsFromForecast$(
    staff,
    displayRange,
    [...appointments, ...events],
    practice
  ).pipe(
    multiMap((eventable) => {
      const label = getSchemaText(eventable.title);
      const pinnedNotes = [getSchemaText(eventable.notes)];

      return {
        uid: uuid(),
        event: eventable.event,
        isBlocking: eventable.isBlocking,
        metadata: {
          label,
          tags: eventable.eventTags,
          pinnedNotes,
          scheduleRef: eventable.scheduleRef,
        },
      } as IScheduleSummaryEventable<ICalendarEvent>;
    })
  );

  const summaries = asyncForEach(eventables, (eventable) => {
    const isBlocking = isCalendarEvent(eventable) ? eventable.isBlocking : true;
    return ScheduleSummary.getSummaryFromEventable(
      {
        ...eventable,
        event: eventable.event,
        ref: eventable.ref,
      },
      isBlocking
    );
  });

  return combineLatest([summaries, breaks$]).pipe(
    reduce2DArray<IScheduleSummaryEventable | undefined>(),
    map(compact)
  );
}
