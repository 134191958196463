import { type OptionFinderQuery } from '@principle-theorem/principle-core';
import * as moment from 'moment-timezone';

export const DEFAULT_FROM_DATE: moment.Moment = moment().startOf('day');
export const DEFAULT_TO_DATE: moment.Moment = moment()
  .add(14, 'days')
  .startOf('day');

export interface IAppointmentFilterOptions {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  fromTime?: string;
  toTime?: string;
}

export function getDefaultFilterOptions(): IAppointmentFilterOptions {
  return {
    fromDate: DEFAULT_FROM_DATE,
    toDate: DEFAULT_TO_DATE,
  };
}

export function getFilterOptionsRangeSubject(
  options: IAppointmentFilterOptions
): OptionFinderQuery {
  return [
    moment(options.fromDate).startOf('day'),
    moment(options.toDate).endOf('day'),
  ];
}
