import { AtLeast, Region } from '@principle-theorem/shared';
import {
  IServiceCode,
  IServiceProvider,
  ItemCodeAliases,
  ServiceCodeType,
} from '../service-item';
import { ServiceCodeScope } from './ada-code';
import { ChartableSurface } from '../../core/chartable-surface';
import { TaxStrategy } from '@principle-theorem/accounting';

// https://www.acc.co.nz/assets/provider/ACC1522-Dentists-costs.pdf

export const BASE_ACC_CODES: Record<
  string,
  AtLeast<IServiceCode, 'code' | 'title' | 'fee'>
> = {
  DE1: {
    code: 'DE1',
    title: 'Dental consultation, including examination',
    notes: '6 monthly or for a new treatment plan',
    fee: 52.05,
  },
  DE2: {
    code: 'DE2',
    title: 'Periodic oral examination/review',
    notes: 'not to be claimed the same day as treatment',
    fee: 33.46,
  },
  DE3: {
    code: 'DE3',
    title: 'Extended initial examination',
    notes:
      'Complex cases relating to dental implants, orthodontics, and advanced restorative work - including study models and photographs, and tomography',
    fee: 143.7,
  },
  DX1: {
    code: 'DX1',
    title: 'Periapical or bitewing film (each)',
    fee: 26.01,
  },
  DX2: {
    code: 'DX2',
    title: 'Occlusal (each)',
    fee: 26.01,
  },
  DX3: {
    code: 'DX3',
    title: 'Panorex',
    fee: 68.01,
  },
  DX4: {
    code: 'DX4',
    title: 'Other additional images',
    notes: 'per treatment episode',
    fee: 10.26,
  },
  DX7: {
    code: 'DX7',
    title: 'Acute sedation',
    notes: 'IV only - initial consultation only',
    fee: 179.61,
  },
  DT1: {
    code: 'DT1',
    title: 'Emergency temporary cover',
    notes: 'not to be used with a definitive treatment code',
    fee: 50.38,
  },
  DG1: {
    code: 'DG1',
    title: 'Extraction of permanent or deciduous tooth',
    notes:
      'Per first tooth (deciduous teeth must have sufficient root to be classified as an extraction - evidence of this is required)',
    fee: 134.41,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DG2: {
    code: 'DG2',
    title: 'Surgical removal of tooth',
    notes: 'includes insertion and removal of sutures',
    fee: 215.54,
  },
  DG4: {
    code: 'DG4',
    title: 'Extraction of subsequent permanent or deciduous tooth',
    notes: 'In same quadrant as for DG1',
    fee: 73.44,
  },
  DG5: {
    code: 'DG5',
    title: 'Management of minor lacerations by suturing',
    notes:
      'Per operative site - initial examination or emergency use only, includes removal of sutures',
    fee: 140.11,
  },
  DG7: {
    code: 'DG7',
    title: 'Incision and drainage abscess cellulitis',
    fee: 158.05,
  },
  DG8: {
    code: 'DG8',
    title: 'Excision of traumatic mucous cyst',
    fee: 186.81,
  },
  DG10: {
    code: 'DG10',
    title: 'Splint application or removal',
    notes: 'For 3 splint units',
    fee: 107.76,
  },
  DG11: {
    code: 'DG11',
    title: 'Cleaning of wound and removal of debris',
    notes: 'Initial examination or emergency use only',
    fee: 49.26,
  },
  DG14: {
    code: 'DG14',
    title: 'Reduction of fractured alveolar process',
    fee: 104.92,
  },
  DG15: {
    code: 'DG15',
    title: 'Repositioning of displaced tooth (per tooth)',
    notes: 'Or replacing avulsed tooth',
    fee: 52.44,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DG17: {
    code: 'DG17',
    title: 'Occlusal adjustment',
    notes:
      'Simple - for use on accident related teeth only and not to be used with restorative codes',
    fee: 34.94,
  },
  DG22: {
    code: 'DG22',
    title: 'Minor surgical operations',
    notes:
      'Not otherwise covered by this schedule - provide clinical reasoning at time of invoice',
    fee: 150.45,
  },
  DG23: {
    code: 'DG23',
    title: 'Provision of bite splints',
    fee: 215.54,
  },
  DR1: {
    code: 'DR1',
    title: 'Amalgam 1 surface filling',
    notes: 'Including 2 fillings on the one surface',
    fee: 95.45,
  },
  DR2: {
    code: 'DR2',
    title: 'Amalgam 2 surface filling',
    notes: 'Approximo-occlusal',
    fee: 124.85,
  },
  DR3: {
    code: 'DR3',
    title: 'Complex amalgam restoration',
    fee: 180.63,
  },
  DR6: {
    code: 'DR6',
    title: 'Non-metallic simple fillings',
    notes: 'Including 2 fillings on the one surface',
    fee: 110.12,
  },
  DR7: {
    code: 'DR7',
    title: 'Non-metallic filling',
    notes: '2 or more surfaces per tooth',
    fee: 146.87,
  },
  DR8: {
    code: 'DR8',
    title: 'Rebonding tooth fragment or coronal portion',
    notes: 'If used with a restorative code DR6 - must show justification',
    fee: 98.72,
  },
  DR9: {
    code: 'DR9',
    title: 'Complex reconstruction in composite resin',
    notes: 'Direct',
    fee: 196.09,
  },
  DP1: {
    code: 'DP1',
    title: 'Plastic denture',
    notes: '1 tooth - material of choice',
    fee: 549.77,
  },
  DP2: {
    code: 'DP2',
    title: 'Each additional tooth',
    notes: 'All dentures',
    fee: 24.63,
  },
  DP5: {
    code: 'DP5',
    title: 'Metal-framed partial denture',
    notes: '1 tooth',
    fee: 1258.99,
  },
  DP7: {
    code: 'DP7',
    title: 'Transitional denture replacing missing tooth or teeth',
    fee: 500.87,
  },
  DP8: {
    code: 'DP8',
    title: 'Full upper or lower denture',
    fee: 821.1,
  },
  DP11: {
    code: 'DP11',
    title: 'Reline or rebase denture',
    fee: 257.06,
  },
  DP13: {
    code: 'DP13',
    title: 'Repair',
    notes: 'All types',
    fee: 83.96,
  },
  DP14: {
    code: 'DP14',
    title: 'Addition of tooth to existing denture',
    notes: 'Includes additional tooth',
    fee: 154.21,
  },
  DC3: {
    code: 'DC3',
    title: 'Indirect inlay/onlay',
    fee: 313.49,
  },
  DC6: {
    code: 'DC6',
    title: 'Porcelain veneer',
    notes: 'Includes any temporary treatment',
    fee: 922.92,
  },
  DC7: {
    code: 'DC7',
    title: 'Composite resin veneer',
    fee: 201.15,
  },
  DC8: {
    code: 'DC8',
    title: 'Post',
    notes: 'Wrought or pre-formed',
    fee: 107.76,
  },
  DC9: {
    code: 'DC9',
    title: 'Composite or amalgam core',
    fee: 129.33,
  },
  DC11: {
    code: 'DC11',
    title: 'Cast post and core',
    notes: 'Metal or ceramic',
    fee: 247.16,
  },
  DC15: {
    code: 'DC15',
    title: 'All ceramic crown',
    fee: 1091.24,
  },
  DC16: {
    code: 'DC16',
    title: 'Porcelain fused to metal crown',
    fee: 1049.35,
  },
  DC17: {
    code: 'DC17',
    title: 'Cast gold crown',
    notes: 'Full and three-quarters',
    fee: 986.44,
  },
  DC19: {
    code: 'DC19',
    title: 'Maryland bridge',
    notes: 'Per unit',
    fee: 760.34,
  },
  DC20: {
    code: 'DC20',
    title: 'Composite bridge',
    notes: 'Per unit',
    fee: 251.44,
  },
  DC25: {
    code: 'DC25',
    title: 'Recementing crown/bridge/veneer/inlay',
    fee: 37.77,
  },
  DC26: {
    code: 'DC26',
    title: 'Non-composite bridge',
    notes: 'On injured teeth that meet the requirement for a crown',
    fee: 2570.03,
  },
  DC27: {
    code: 'DC27',
    title: 'Replacement of non-composite bridge',
    fee: 3075,
  },
  DN1: {
    code: 'DN1',
    title: 'Pulpotomy or pulpectomy',
    notes: 'Includes dressing',
    fee: 143.7,
  },
  DN2: {
    code: 'DN2',
    title: 'Irrigation and dressing of root canal system',
    notes: 'Includes all temporary dressings',
    fee: 145.86,
  },
  DN3: {
    code: 'DN3',
    title: 'Complete preparation and obturation of root canal',
    notes: 'Per canal - open or closed apex',
    fee: 323.31,
  },
  DN5: {
    code: 'DN5',
    title: 'Apicoectomy and retrograde filling',
    notes: 'Per canal',
    fee: 314.77,
  },
  DN6: {
    code: 'DN6',
    title: 'Removal of root filling',
    notes: 'Per canal',
    fee: 252.53,
  },
  DN7: {
    code: 'DN7',
    title: 'Removal of post or post crown, or crown',
    fee: 252.53,
  },
  DN8: {
    code: 'DN8',
    title: 'Bleaching, 1 non-vital tooth',
    notes: 'Per treatment - includes vital teeth',
    fee: 179.61,
  },
  DN9: {
    code: 'DN9',
    title: 'Pulp capping',
    fee: 41.97,
  },
  DN10: {
    code: 'DN10',
    title: 'Removal of a fractured post or instrument',
    fee: 252.53,
  },
  DN11: {
    code: 'DN11',
    title: 'Repair of perforation',
    fee: 252.53,
  },
  DN13: {
    code: 'DN13',
    title: 'Negotiation of a calcified canal',
    notes: 'Can be used with item DN3',
    fee: 252.53,
  },
  DD1: {
    code: 'DD1',
    title: 'Gingivectomy',
    notes: 'Per tooth',
    fee: 120.34,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DD2: {
    code: 'DD2',
    title: 'Crown lengthening',
    notes: 'Per tooth',
    fee: 251.44,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DD4: {
    code: 'DD4',
    title: 'Sub gingival curettage',
    notes: 'Per tooth',
    fee: 100.4,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DD7: {
    code: 'DD7',
    title: 'Site preparation for dental implant',
    notes:
      'Bone grafting cannot be claimed with this code or with implant surgery',
    fee: 367.21,
  },
  DD8: {
    code: 'DD8',
    title: 'Placement of membrane',
    fee: 393.43,
  },
  DD9: {
    code: 'DD9',
    title: 'Substitute bone material',
    fee: 157.36,
  },
  DM1: {
    code: 'DM1',
    title: 'Resilient linings',
    notes:
      'Tooth or teeth - one per arch if required in cases where upper and lower implants are placed at the same time',
    fee: 75.55,
  },
  DM2: {
    code: 'DM2',
    title: 'Fixture head impressions and copings',
    notes: 'Per fixture',
    fee: 403.92,
  },
  DM3: {
    code: 'DM3',
    title: 'Dental implant crown',
    notes: 'Per single unit',
    fee: 1259.01,
  },
  DM4: {
    code: 'DM4',
    title: 'Dental stent and guide',
    notes: 'Per fixture',
    fee: 136.4,
  },
  DM5: {
    code: 'DM5',
    title: 'Definitive abutment',
    notes: 'Per fixture',
    fee: 403.92,
  },
  DM6: {
    code: 'DM6',
    title: 'Temporary abutment',
    notes: 'Per fixture',
    fee: 50.35,
  },
  DM7: {
    code: 'DM7',
    title: 'Repairs to abutments',
    notes: 'Per fixture',
    fee: 87.38,
  },
  DY1: {
    code: 'DY1',
    title: 'Dental consultation, including examination',
    notes: '6 monthly or for a new treatment plan',
    fee: 67.75,
  },
  DY14: {
    code: 'DY14',
    title: 'Temporary crown',
    fee: 125.91,
  },
  DY15: {
    code: 'DY15',
    title: 'Temporary bridge',
    notes: 'Per unit',
    fee: 125.91,
  },
  DY21: {
    code: 'DY21',
    title: 'Surgical decoronation',
    fee: 440.67,
  },
  DY22: {
    code: 'DY22',
    title: 'Removal of deciduous teeth',
    fee: 30.78,
  },
  DG18: {
    code: 'DG18',
    title: 'Removal of plates, wires and screws',
    fee: 437.19,
  },
  DX6: {
    code: 'DX6',
    title: 'Lateral or antero-posterior head films',
    fee: 69.07,
  },
};

export const ACC_CODE_MAP: ItemCodeAliases = Object.entries(
  BASE_ACC_CODES
).reduce(
  (codes, [key, code]) => ({
    ...codes,
    [key]: {
      scope: [ChartableSurface.Unscoped],
      rules: {},
      deleted: false,
      taxStatus: TaxStrategy.GSTPossible,
      ...code,
      type: ServiceCodeType.ACC,
    },
  }),
  {}
);

export const ACC_SERVICE_CODES: IServiceCode[] = Object.values(ACC_CODE_MAP);

export const ACC_SERVICE: IServiceProvider = {
  label: 'ACC',
  description: 'New Zealand Accident Compensation Corporation Codes',
  type: ServiceCodeType.ACC,
  items: ACC_CODE_MAP,
  region: Region.NewZealand,
};
