import { rand, randBoolean } from '@ngneat/falso';
import {
  IChartedItemDisplay,
  IChartedItemIcon,
} from '@principle-theorem/principle-core/interfaces';
import { CHARTING_COLOURS } from '@principle-theorem/shared';

export class ChartedItemDisplay {
  static init(overrides?: Partial<IChartedItemDisplay>): IChartedItemDisplay {
    return {
      hideWhenResolved: true,
      colour: CHARTING_COLOURS[0],
      icon: CHARTING_ICONS[0],
      hasFill: false,
      disablesSurface: false,
      ...overrides,
    };
  }
}

export const CHARTING_ICONS: IChartedItemIcon[] = [
  {
    name: 'Abrasion',
    slug: 'pr-abrasion',
  },
  {
    name: 'Arch - Consultation',
    slug: 'pr-arch-consultation',
  },
  {
    name: 'Arch - Done',
    slug: 'pr-arch-done',
  },
  {
    name: 'Arch - Exam',
    slug: 'pr-arch-exam',
  },
  {
    name: 'Arch - Guard',
    slug: 'pr-arch-guard',
  },
  {
    name: 'Arch - Periodic',
    slug: 'pr-arch-periodic',
  },
  {
    name: 'Arch',
    slug: 'pr-arch',
  },
  {
    name: 'Bridge',
    slug: 'pr-bridge',
  },
  {
    name: 'Candidiasis',
    slug: 'pr-candidiasis',
  },
  {
    name: 'Caries',
    slug: 'pr-caries',
  },
  {
    name: 'Cerec',
    slug: 'pr-cerec',
  },
  {
    name: 'Chipped Broken',
    slug: 'pr-chipped-broken',
  },
  {
    name: 'Consultation',
    slug: 'pr-consultation',
  },
  {
    name: 'Core, Post & Crown',
    slug: 'pr-core-post-and-crown',
  },
  {
    name: 'Crack',
    slug: 'pr-crack',
  },
  {
    name: 'Crowding',
    slug: 'pr-crowding',
  },
  {
    name: 'Crown',
    slug: 'pr-crown',
  },
  {
    name: 'Crown Small',
    slug: 'pr-crown-small',
  },
  {
    name: 'Deep Fissures',
    slug: 'pr-deep-fissures',
  },
  {
    name: 'Discolouration',
    slug: 'pr-discolouration',
  },
  {
    name: 'Exam - Emergency',
    slug: 'pr-exam-emergency',
  },
  {
    name: 'Exam - New Patient',
    slug: 'pr-exam-new-patient',
  },
  {
    name: 'Exam - Periodic',
    slug: 'pr-exam-periodic',
  },
  {
    name: 'Exam',
    slug: 'pr-exam',
  },
  {
    name: 'Extirpation',
    slug: 'pr-extirpation',
  },
  {
    name: 'Extraction',
    slug: 'pr-extraction',
  },
  {
    name: 'Gingivitis',
    slug: 'pr-gingivitis',
  },
  {
    name: 'Impacted',
    slug: 'pr-impacted',
  },
  {
    name: 'Implant',
    slug: 'pr-implant',
  },
  {
    name: 'Lichen Planus',
    slug: 'pr-lichen-planus',
  },
  {
    name: 'Linea Alba',
    slug: 'pr-linea-alba',
  },
  {
    name: 'Mobile',
    slug: 'pr-mobile',
  },
  {
    name: 'Partially Erupted',
    slug: 'pr-partially-erupted',
  },
  {
    name: 'Pericoronitis',
    slug: 'pr-pericoronitis',
  },
  {
    name: 'Peridontal',
    slug: 'pr-periodontal',
  },
  {
    name: 'Poor Hygiene',
    slug: 'pr-poor-hygiene',
  },
  {
    name: 'Pulpitus',
    slug: 'pr-pulpitis',
  },
  {
    name: 'Restoration Missing',
    slug: 'pr-restoration-missing',
  },
  {
    name: 'Restoration',
    slug: 'pr-restoration',
  },
  {
    name: 'Retained Root',
    slug: 'pr-retained-root',
  },
  {
    name: 'Root Canal',
    slug: 'pr-root-canal',
  },
  {
    name: 'Root Resorption',
    slug: 'pr-root-resorption',
  },
  {
    name: 'Scale & Clean',
    slug: 'pr-scale-and-clean',
  },
  {
    name: 'Sensitivity - Cold',
    slug: 'pr-sensitivity-cold',
  },
  {
    name: 'Sensitivity - General',
    slug: 'pr-sensitivity-general',
  },
  {
    name: 'Sensitivity - Hot',
    slug: 'pr-sensitivity-hot',
  },
  {
    name: 'Sensitivity - Sweet',
    slug: 'pr-sensitivity-sweet',
  },
  {
    name: 'Sensitivity - Touch',
    slug: 'pr-sensitivity-touch',
  },
  {
    name: 'Tooth',
    slug: 'pr-tooth',
  },
  {
    name: 'Veneer',
    slug: 'pr-veneer',
  },
  {
    name: 'Whitening',
    slug: 'pr-whitening',
  },
  {
    name: 'X-rays - 3D',
    slug: 'pr-x-rays-3d',
  },
  {
    name: 'X-rays',
    slug: 'pr-x-rays',
  },
  {
    name: 'Unerupted',
    slug: 'pr-unerupted',
  },
  {
    name: 'Periapical Lesion - Tooth',
    slug: 'pr-periapical-lesion-tooth',
  },
  {
    name: 'Periapical Lesion - Root',
    slug: 'pr-periapical-lesion-root',
  },
  {
    name: 'Diamond Filled Exclamation',
    slug: 'pr-diamond-filled-exclamation',
  },
  {
    name: 'Diamond Filled',
    slug: 'pr-diamond-filled',
  },
  {
    name: 'Diamond',
    slug: 'pr-diamond',
  },
  {
    name: 'Exclamation Diamond',
    slug: 'pr-diamond-exclamation',
  },
  {
    name: 'Exclamation Message',
    slug: 'pr-message-exclamation',
  },
  {
    name: 'Exclamation Octagon',
    slug: 'pr-octagon-exclamation',
  },
];

export function MockChartedItemDisplay(): IChartedItemDisplay {
  return ChartedItemDisplay.init({
    hideWhenResolved: randBoolean(),
    colour: rand(CHARTING_COLOURS),
    icon: rand(CHARTING_ICONS),
    disablesSurface: false,
    hasFill: randBoolean(),
  });
}
