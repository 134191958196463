import {
  Gender,
  IPatient,
  PatientStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  getParentDocRef,
  toMoment,
  type WithRef,
  DATE_FORMATS_DMY,
  TypeGuardFn,
  DocumentReference,
  isDocRef,
} from '@principle-theorem/shared';
import { compact, isBoolean, isNumber, isString, isUndefined } from 'lodash';
import { type CollectionCreateSchema } from 'typesense/lib/Typesense/Collections';
import { PrincipleTypesenseCollection } from './typesense';
import { Patient, formatPhoneNumber, isMobileNumber } from '../patient/patient';
import { NameHelpers } from '../patient/name-helpers';

export const PATIENT_COLLECTION_SCHEMA: CollectionCreateSchema = {
  name: PrincipleTypesenseCollection.Patients,
  fields: [
    {
      name: 'id',
      type: 'string',
    },
    {
      name: 'name',
      type: 'string',
      sort: true,
    },
    {
      name: 'searchNames',
      type: 'string[]',
    },
    {
      name: 'email',
      type: 'string',
      sort: true,
      optional: true,
    },
    {
      name: 'dateOfBirth',
      type: 'string',
      sort: true,
      optional: true,
    },
    {
      name: 'searchDateOfBirths',
      type: 'string[]',
      optional: true,
    },
    {
      name: 'address',
      type: 'string',
      optional: true,
    },
    {
      name: 'profileImageURL',
      type: 'string',
      optional: true,
    },
    {
      name: 'gender',
      type: 'string',
    },
    {
      name: 'status',
      type: 'string',
    },
    {
      name: 'createdAt',
      type: 'int64',
    },
    {
      name: 'tagNames',
      type: 'string[]',
    },
    {
      name: 'tagRefs',
      type: 'string[]',
    },
    {
      name: 'contactNumbers',
      type: 'string[]',
    },
    {
      name: 'searchContactNumbers',
      type: 'string[]',
    },
    {
      name: 'ref',
      type: 'string',
    },
    {
      name: 'brandRef',
      type: 'string',
    },
    {
      name: 'deleted',
      type: 'bool',
      facet: true,
    },
    {
      name: 'isDuplicate',
      type: 'bool',
      facet: true,
      optional: true,
    },
    {
      name: 'embedding',
      type: 'float[]',
      embed: {
        from: ['name', 'email', 'dateOfBirth', 'contactNumbers'],
        model_config: {
          model_name: 'ts/all-MiniLM-L12-v2',
        },
      },
    },
  ],
  default_sorting_field: 'name',
};

export interface IPatientIndexedProperties {
  id: string;
  name: string;
  searchNames: string[];
  email?: string;
  dateOfBirth?: string;
  searchDateOfBirths?: string[];
  address?: string;
  profileImageURL?: string;
  gender: Gender;
  status: PatientStatus;
  createdAt: number;
  tagNames: string[];
  tagRefs: string[];
  contactNumbers: string[];
  searchContactNumbers: string[];
  ref: string;
  brandRef: string;
  deleted: boolean;
  isDuplicate?: boolean;
}

export interface ITypesensePatient extends IPatientIndexedProperties {}

export class TypesensePatient {
  static fromPatient(patient: WithRef<IPatient>): ITypesensePatient {
    const brandRef = getParentDocRef(patient.ref);
    const dateOfBirth = patient.dateOfBirth
      ? toMoment(patient.dateOfBirth)
      : undefined;
    const contactNumbers = compact(
      (patient.contactNumbers ?? []).map(
        (contactNumber) => contactNumber.number
      )
    );

    return {
      id: patient.ref.id,
      name: patient.name,
      searchNames: [
        NameHelpers.fullName(patient.name),
        `${NameHelpers.nickname(patient.name)} ${NameHelpers.lastName(
          patient.name
        )}`,
      ],
      email: patient.email,
      address: patient.address,
      profileImageURL: patient.profileImageURL,
      dateOfBirth: patient.dateOfBirth,
      searchDateOfBirths: !dateOfBirth
        ? undefined
        : DATE_FORMATS_DMY.map((format) => dateOfBirth.format(format)),
      gender: patient.gender,
      status: patient.status,
      createdAt: toMoment(patient.createdAt).unix(),
      contactNumbers,
      searchContactNumbers: contactNumbers.flatMap((contactNumber) => {
        const number = formatPhoneNumber(contactNumber);
        return compact([
          number,
          number.replace('+61', '0'),
          !isMobileNumber({ label: '', number })
            ? number.substring(3)
            : undefined,
          !isMobileNumber({ label: '', number })
            ? number.substring(5)
            : undefined,
        ]);
      }),
      tagNames: (patient.tags ?? []).map((tag) => tag.name),
      tagRefs: (patient.tags ?? []).map((tag) => tag.ref.path),
      ref: patient.ref.path,
      brandRef: brandRef.path,
      deleted: patient.deleted,
      isDuplicate: Patient.isDuplicatePatient(patient),
    };
  }
}

export function isTypesensePatient(
  item: unknown,
  refCheck: TypeGuardFn<unknown> = isString
): item is ITypesensePatient {
  return TypeGuard.interface<ITypesensePatient>({
    id: isString,
    name: isString,
    searchNames: TypeGuard.arrayOf(isString),
    email: TypeGuard.undefinedOr(isString),
    address: TypeGuard.undefinedOr(isString),
    profileImageURL: TypeGuard.undefinedOr(isString),
    dateOfBirth: TypeGuard.undefinedOr(isString),
    searchDateOfBirths: [isUndefined, TypeGuard.arrayOf(isString)],
    gender: TypeGuard.enumValue(Gender),
    status: TypeGuard.enumValue(PatientStatus),
    createdAt: isNumber,
    contactNumbers: TypeGuard.arrayOf(isString),
    searchContactNumbers: TypeGuard.arrayOf(isString),
    tagNames: TypeGuard.arrayOf(isString),
    tagRefs: TypeGuard.arrayOf(isString),
    ref: refCheck,
    brandRef: isString,
    deleted: isBoolean,
    isDuplicate: TypeGuard.undefinedOr(isBoolean),
  })(item);
}

export interface ITypesensePatientWithRef
  extends Omit<ITypesensePatient, 'ref'> {
  ref: DocumentReference<IPatient>;
}

export function isTypesensePatientWithRef(
  item: unknown
): item is ITypesensePatientWithRef {
  return isTypesensePatient(item, isDocRef);
}
