import { type ICSVExport } from '@principle-theorem/ng-shared';
import { type IPractitionerGrouping } from '@principle-theorem/reporting';

export interface IInvoiceIssuedSelectorCSV {
  practitioner: string;
  treatmentsInvoicedAmount: string;
  depositsTakenAmount: string;
  discountsAppliedAmount: string;
  invoicedCommissionAmount: string;
}

export class InvoiceIssuedSelectorToCSV
  implements ICSVExport<IPractitionerGrouping, IInvoiceIssuedSelectorCSV>
{
  headers = [
    'Practitioner',
    'Treatment Invoiced',
    'Deposits',
    'Discounts',
    'Recievable Amount',
  ];

  translate(records: IPractitionerGrouping[]): IInvoiceIssuedSelectorCSV[] {
    return records.map((record) => ({
      practitioner: record.practitioner.name,
      treatmentsInvoicedAmount:
        record.total.treatmentsInvoicedAmount.toString(),
      depositsTakenAmount: record.total.depositsTakenAmount.toString(),
      discountsAppliedAmount: record.total.discountsAppliedAmount.toString(),
      invoicedCommissionAmount:
        record.total.invoicedCommissionAmount.toString(),
    }));
  }
}
