import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  IBrand,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { isArray } from 'lodash';

interface ISettingsGroup {
  label: string;
  children: IBreadcrumb[];
}

@Component({
  selector: 'pr-settings-splash',
  templateUrl: './settings-splash.component.html',
  styleUrls: ['./settings-splash.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsSplashComponent {
  breadcrumbs: IBreadcrumb[] = [{ label: 'Settings' }];
  settingsGroups: Signal<ISettingsGroup[]>;

  constructor(currentScope: CurrentScopeFacade) {
    const brand = toSignal(currentScope.currentBrand$);
    const practice = toSignal(currentScope.currentPractice$);
    this.settingsGroups = computed(() =>
      this._getNavGroups(brand(), practice())
    );
  }

  private _getNavGroups(
    brand?: WithRef<IBrand>,
    practice?: WithRef<IPractice>
  ): ISettingsGroup[] {
    const brandUid = brand?.ref.id;
    const practiceUid = practice?.ref.id;
    return this._removeEmptyGroupsAndNonLinks([
      {
        label: 'Brand Settings',
        children: [
          { label: 'General', path: this._brandPath(brandUid, 'general') },
          {
            label: 'Practices',
            path: this._brandPath(brandUid, 'practices'),
          },
        ],
      },
      {
        label: 'Practice Settings',
        children: [
          {
            label: 'General',
            path: this._practicePath(practiceUid, 'general'),
          },
          {
            label: 'Opening Hours',
            path: this._practicePath(practiceUid, 'opening-hours'),
          },
        ],
      },
      {
        label: 'My Settings',
        children: [
          { label: 'General', path: this._mySettingsPath(brandUid, 'general') },
        ],
      },
      {
        label: 'Accounting',
        children: [
          {
            label: 'Workspace Fee Schedules',
            path: this._workspacePath('fee-schedules'),
          },
          {
            label: 'Practice Fee Schedules',
            path: this._practicePath(practiceUid, 'fee-schedules'),
          },
          {
            label: 'My Fee Schedules',
            path: this._mySettingsPath(brandUid, 'fee-schedules'),
          },
          {
            label: 'Transaction Types',
            path: this._brandPath(brandUid, 'accounting'),
          },
          { label: 'Products', path: this._brandPath(brandUid, 'products') },
        ],
      },
      {
        label: 'Users',
        children: [
          { label: 'Users', path: this._workspacePath('users') },
          { label: 'Staff', path: this._brandPath(brandUid, 'staff') },
          { label: 'Roles', path: this._workspacePath('roles') },
          { label: 'Security', path: this._workspacePath('security') },
          { label: 'Teams', path: this._brandPath(brandUid, 'teams') },
        ],
      },
      {
        label: 'Integrations',
        children: [
          {
            label: 'Workspace Integrations',
            path: this._workspacePath('integrations'),
          },
          {
            label: 'Practice Integrations',
            path: this._practicePath(practiceUid, 'integrations'),
          },
        ],
      },
      {
        label: 'Treatments & Conditions',
        children: [
          {
            label: 'Condition Configurations',
            path: this._brandPath(brandUid, ['charting', 'conditions']),
          },
          {
            label: 'Treatment Configurations',
            path: this._brandPath(brandUid, ['charting', 'treatments']),
          },
          {
            label: 'Multi-Treatment Configurations',
            path: this._brandPath(brandUid, ['charting', 'multi-treatments']),
          },
          {
            label: 'Treatment Templates',
            path: this._brandPath(brandUid, 'treatment-templates'),
          },
          {
            label: 'Treatment Categories',
            path: this._brandPath(brandUid, 'treatment-categories'),
          },
        ],
      },
      {
        label: 'Miscellaneous',
        children: [
          {
            label: 'Automation Configurations',
            path: this._brandPath(brandUid, 'automations'),
          },
          {
            label: 'Rescheduling & Cancellation Reasons',
            path: this._brandPath(brandUid, 'cancellation-reasons'),
          },
          {
            label: 'Document Templates',
            path: this._brandPath(brandUid, 'document-templates'),
          },
          {
            label: 'Media Tags',
            path: this._practicePath(practiceUid, 'tags'),
          },
          {
            label: 'Medical History',
            path: this._brandPath(brandUid, 'medical-history'),
          },
          {
            label: 'My Snippets',
            path: this._mySettingsPath(brandUid, 'snippets'),
          },
          {
            label: 'Prescriptions',
            path: this._brandPath(brandUid, 'prescriptions'),
          },
          {
            label: 'Referral Sources',
            path: this._brandPath(brandUid, 'referral-sources'),
          },
          {
            label: 'Shared Snippets',
            path: this._brandPath(brandUid, 'snippets'),
          },
          {
            label: 'Tags',
            path: this._brandPath(brandUid, 'tags'),
          },
        ],
      },
    ]);
  }

  private _workspacePath(path: string[] | string): string[] {
    const segments = isArray(path) ? path : [path];
    return ['workspace', ...segments];
  }

  private _brandPath(
    brandUid: string | undefined,
    path: string[] | string
  ): string[] | undefined {
    return this._path('brand', brandUid, path);
  }

  private _practicePath(
    practiceUid: string | undefined,
    path: string[] | string
  ): string[] | undefined {
    return this._path('practice', practiceUid, path);
  }

  private _mySettingsPath(
    brandUid: string | undefined,
    path: string[] | string
  ): string[] | undefined {
    const segments = isArray(path) ? path : [path];
    return this._brandPath(brandUid, ['account', ...segments]);
  }

  private _path(
    root: string,
    uid: string | undefined,
    path: string[] | string
  ): string[] | undefined {
    if (!uid) {
      return;
    }
    const segments = isArray(path) ? path : [path];
    return [root, uid, ...segments];
  }

  private _removeEmptyGroupsAndNonLinks(
    groups: ISettingsGroup[]
  ): ISettingsGroup[] {
    return groups
      .map((group) => ({
        ...group,
        children: group.children.filter((breadcrumb) => !!breadcrumb.path),
      }))
      .filter((group) => group.children.length > 0);
  }
}
