import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  CSVExporterService,
  extendSortingDataAccessor,
  ObservableDataSource,
  timestampSortingAccessor,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { Invoice } from '@principle-theorem/principle-core';
import { type IInvoiceReportRecord } from '@principle-theorem/reporting';
import { type Timestamp } from '@principle-theorem/shared';
import {
  DAY_MONTH_YEAR_FORMAT,
  snapshot,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { OutstandingInvoicesReportFacade } from '../store/outstanding-invoices.facade';
import {
  type IPendingProviderRow,
  OutstandingInvoicesToCSV,
  toPendingProviders,
} from './outstanding-invoices-to-csv';

@Component({
  selector: 'pr-invoice-report-table',
  templateUrl: './invoice-report-table.component.html',
  styleUrls: ['./invoice-report-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceReportTableComponent implements OnDestroy {
  readonly dateFormat = DAY_MONTH_YEAR_FORMAT;
  trackByProvider = TrackByFunctions.field('provider');
  dataSource: ObservableDataSource<IInvoiceReportRecord>;
  displayedColumns: string[] = [
    'createdAt',
    'status',
    'invoice',
    'patient.name',
    'issuedAt',
    'due',
    'amount',
    'outstanding',
    'pending',
  ];

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private _reportFacade: OutstandingInvoicesReportFacade,
    private _csvExporter: CSVExporterService
  ) {
    this.dataSource = new ObservableDataSource(this._reportFacade.results$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  async downloadCSV(): Promise<void> {
    const query = await snapshot(this._reportFacade.query$);
    if (!query) {
      return;
    }
    const startDate = toMoment(query.startDate).format(DAY_MONTH_YEAR_FORMAT);
    const endDate = toMoment(query.endDate).format(DAY_MONTH_YEAR_FORMAT);
    const fileName = `outstanding-invoices-${startDate}-${endDate}`;
    const records = await snapshot(this._reportFacade.results$);
    await this._csvExporter.download(
      fileName,
      records,
      new OutstandingInvoicesToCSV()
    );
  }

  amount(record: IInvoiceReportRecord): number {
    return Invoice.total(record.invoice);
  }

  outstanding(record: IInvoiceReportRecord): number {
    return Invoice.balance(record.invoice, record.transactions);
  }

  pendingProviders(record: IInvoiceReportRecord): IPendingProviderRow[] {
    return toPendingProviders(record);
  }

  isOverdue(dueDate: Timestamp | undefined): boolean {
    return dueDate ? toMoment(dueDate).isBefore(moment()) : false;
  }

  private _sortingDataAccessor(
    record: IInvoiceReportRecord,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'createdAt':
        return timestampSortingAccessor(record.invoice.createdAt);
      case 'issuedAt':
        return timestampSortingAccessor(record.invoice.issuedAt);
      case 'due':
        return timestampSortingAccessor(record.invoice.due);
      case 'amount':
        return this.amount(record);
      case 'outstanding':
        return this.outstanding(record);
      default:
        return;
    }
  }
}
