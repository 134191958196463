import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  AllocationTarget,
  Invoice,
  TransactionAllocation,
  UNALLOCATED,
} from '@principle-theorem/principle-core';
import {
  INamedAllocationTarget,
  TransactionStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  PractitionerAccountCreditSummary,
  PractitionerTransactionSummary,
  createRecordSummary,
  type IAccountCreditPaymentTransactionRecord,
  type IAccountCreditReportRecord,
  type IAccountCreditTransactionsRecord,
  type IPractitionerIncomeReportGrouping,
  type IPractitionerTransactionReportRecord,
  type IPractitionerTransactionSummary,
  type ITransactionReportRecord,
  type ITransactionReportRequest,
} from '@principle-theorem/reporting';
import {
  asyncForEach,
  customGroupBy,
  multiFilter,
  multiSwitchMap,
  reduce2DArray,
  reduceToSingleArrayFn,
  toTimePeriod,
  type ITimePeriod,
} from '@principle-theorem/shared';
import { flatten, sortBy, uniqWith } from 'lodash';
import { combineLatest, from, type Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ReportingFunctions } from '../../../core/reporting-functions';

export interface IPractitionerTransactionsReportState {
  dateRange: ITimePeriod;
  summaries: IPractitionerIncomeReportGrouping[];
  selectedSummary?: IPractitionerIncomeReportGrouping;
  isLoading: boolean;
}

@Injectable()
export class PractitionerTransactionsReportStore extends ComponentStore<IPractitionerTransactionsReportState> {
  readonly isLoading$ = this.select((data) => data.isLoading);
  readonly summaries$ = this.select((data) => data.summaries);
  readonly selectedSummary$ = this.select((data) => data.selectedSummary);
  readonly dateRange$ = this.select((data) => data.dateRange);

  readonly loadTransactions = this.effect(
    (query$: Observable<ITransactionReportRequest>) =>
      query$.pipe(
        tap((request) =>
          this.setState({
            summaries: [],
            selectedSummary: undefined,
            isLoading: true,
            dateRange: toTimePeriod(request.startDate, request.endDate),
          })
        ),
        switchMap((query) =>
          combineLatest([
            from(ReportingFunctions.getTransactions(query)).pipe(
              multiFilter(
                (record) =>
                  record.transaction.status === TransactionStatus.Complete
              ),
              multiSwitchMap((record) => this._toTransactionRecords(record)),
              reduce2DArray(),
              multiFilter((record) =>
                [
                  record.summary.accountCreditAmount,
                  record.summary.discountAmount,
                  record.summary.paymentAmount,
                  record.summary.practitionerProportionAmount,
                ].some((amount) => amount !== 0)
              )
            ),
            ReportingFunctions.getAccountCredits$({
              startDate: query.startDate,
              endDate: query.endDate,
              practiceRef: query.practiceRef,
            }),
          ])
        ),
        map(([records, accountCredits]) =>
          this._toSummaries(records, accountCredits ?? [])
        ),
        map((summaries) => this._sortSummaries(summaries)),
        tap((summaries) =>
          this.patchState({
            summaries,
            isLoading: false,
            selectedSummary: undefined,
          })
        )
      )
  );

  readonly selectSummary = this.effect(
    (summary$: Observable<IPractitionerIncomeReportGrouping>) =>
      summary$.pipe(
        tap((summary) => this.patchState({ selectedSummary: summary }))
      )
  );

  readonly clearSelectedSummary = this.effect((_clear$: Observable<void>) =>
    _clear$.pipe(tap(() => this.patchState({ selectedSummary: undefined })))
  );

  private async _toTransactionRecords(
    record: ITransactionReportRecord
  ): Promise<IPractitionerTransactionReportRecord[]> {
    const allocationTargets = await this._getNamedAllocationTargets(record);
    return allocationTargets.map((practitioner) => {
      const summary = createRecordSummary(
        record.invoice,
        record.transaction,
        practitioner
      );
      const accountCreditPaymentTransactions =
        this._getPractitionerAccountCreditPayments(
          record.accountCreditTransactions,
          summary
        );

      return {
        transaction: record.transaction,
        invoice: record.invoice,
        patient: record.patient,
        practitioner,
        accountCreditPaymentTransactions,
        summary,
      };
    });
  }

  private _toSummaries(
    records: IPractitionerTransactionReportRecord[],
    accountCreditRecords: IAccountCreditReportRecord[]
  ): IPractitionerIncomeReportGrouping[] {
    const transactions = customGroupBy(
      records,
      (record) => record.practitioner.ref,
      AllocationTarget.isSame
    );

    const accountCredits = customGroupBy(
      accountCreditRecords,
      (record) => this._accountCreditAllocatedTo(record).ref,
      AllocationTarget.isSame
    );

    const allocationTargets = uniqWith(
      [
        ...records.map((record) => record.practitioner),
        ...accountCreditRecords.map((record) =>
          this._accountCreditAllocatedTo(record)
        ),
      ],
      (aAllocaton, bAllocation) =>
        AllocationTarget.isSame(aAllocaton.ref, bAllocation.ref)
    );
    return allocationTargets.map((practitioner) => {
      const practitionerTransactions = transactions
        .filter((group) =>
          AllocationTarget.isSame(group.group, practitioner.ref)
        )
        .map((group) => group.items)
        .reduce(reduceToSingleArrayFn, []);

      const practitionerAccountCredits = accountCredits
        .filter((group) =>
          AllocationTarget.isSame(group.group, practitioner.ref)
        )
        .map((group) => group.items)
        .reduce(reduceToSingleArrayFn, []);

      return {
        practitioner,
        transactions: {
          practitioner,
          records: practitionerTransactions,
          total: PractitionerTransactionSummary.reduce(
            practitionerTransactions.map((item) => item.summary)
          ),
        },
        accountCredits: {
          practitioner,
          records: practitionerAccountCredits,
          total: PractitionerAccountCreditSummary.reduce(
            practitionerAccountCredits
          ),
        },
      };
    });
  }

  private _getPractitionerAccountCreditPayments(
    accountCredits: IAccountCreditTransactionsRecord[],
    summary: IPractitionerTransactionSummary
  ): IAccountCreditPaymentTransactionRecord[] {
    const byCredit = accountCredits.map((accountCredit) => {
      return accountCredit.depositPayments.map((payment) => {
        const practitionerAmount =
          payment.transactionAmount * summary.practitionerProportionRatio;
        return {
          payment,
          practitionerAmount,
        };
      });
    });

    return flatten(byCredit);
  }

  private _accountCreditAllocatedTo(
    record: IAccountCreditReportRecord
  ): INamedAllocationTarget {
    return record.accountCredit.reservedFor.practitioner ?? UNALLOCATED;
  }

  private _sortSummaries(
    summaries: IPractitionerIncomeReportGrouping[]
  ): IPractitionerIncomeReportGrouping[] {
    const alphabetical = sortBy(
      summaries,
      (summary) => summary.practitioner.name
    );
    const unallocatedLast = alphabetical.sort((a, b) => {
      if (AllocationTarget.isUnallocated(a.practitioner.ref)) {
        return 1;
      }
      return AllocationTarget.isUnallocated(b.practitioner.ref) ? -1 : 0;
    });
    return unallocatedLast;
  }

  private async _getNamedAllocationTargets(
    record: ITransactionReportRecord
  ): Promise<INamedAllocationTarget[]> {
    const allocations = Invoice.findTransactionAllocations(
      record.invoice,
      record.transaction.ref
    );
    const allocationTargets = uniqWith(
      allocations.map((allocation) => allocation.allocatedTo),
      AllocationTarget.isSame
    );
    return asyncForEach(allocationTargets, (allocationTarget) =>
      TransactionAllocation.toNamedAllocationTarget(allocationTarget)
    );
  }
}
