import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  CSVExporterService,
  ObservableDataSource,
} from '@principle-theorem/ng-shared';
import {
  type IPractitionerGrouping,
  PRACTITIONER_INCOME_TOOLTIPS,
} from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  type FieldsOfType,
  multiMap,
  snapshot,
  toMoment,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PractitionerIncomeReportFacade } from '../store/practitioner-income.facade';
import { PractitionerIncomeReportType } from '../store/practitioner-income.reducers';
import { getColumnsForReportType } from '../practitioner-invoice-display-columns';
import { InvoiceIssuedSelectorToCSV } from '../../../practitioner-reporting/practitioner-income/practitioner-income-selector/invoice-issued-selector-to-csv';
import { InvoicePaidSelectorToCSV } from '../../../practitioner-reporting/practitioner-income/practitioner-income-selector/invoice-paid-selector-to-csv';
import { sum } from 'lodash';

@Component({
  selector: 'pr-practitioner-income-selector',
  templateUrl: './practitioner-income-selector.component.html',
  styleUrls: ['./practitioner-income-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PractitionerIncomeSelectorComponent implements OnDestroy {
  tooltips = PRACTITIONER_INCOME_TOOLTIPS;
  dataSource: ObservableDataSource<IPractitionerGrouping>;
  displayedColumns$: Observable<string[]>;

  constructor(
    public reportFacade: PractitionerIncomeReportFacade,
    private _csvExporter: CSVExporterService
  ) {
    this.dataSource = new ObservableDataSource(reportFacade.results$);
    this.displayedColumns$ = reportFacade.reportType$.pipe(
      map((reportType) => [
        'practitioner',
        ...getColumnsForReportType(reportType),
      ])
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  async downloadCSV(): Promise<void> {
    const query = await snapshot(this.reportFacade.query$);
    const reportType = await snapshot(this.reportFacade.reportType$);
    if (
      !query ||
      reportType === PractitionerIncomeReportType.ByTransactionDate
    ) {
      return;
    }
    const startDate = toMoment(query.startDate).format(DAY_MONTH_YEAR_FORMAT);
    const endDate = toMoment(query.endDate).format(DAY_MONTH_YEAR_FORMAT);
    const fileName = `practitioner-income-summary-${startDate}-${endDate}`;
    const records = await snapshot(this.reportFacade.results$);
    if (reportType === PractitionerIncomeReportType.ByInvoiceIssuedDate) {
      return this._csvExporter.download(
        fileName,
        records,
        new InvoiceIssuedSelectorToCSV()
      );
    }
    return this._csvExporter.download(
      fileName,
      records,
      new InvoicePaidSelectorToCSV()
    );
  }

  sumAmount$(
    key: FieldsOfType<IPractitionerGrouping['total'], number>
  ): Observable<number> {
    return this.dataSource.filteredData$.pipe(
      multiMap(
        (item) => item.total[key as keyof IPractitionerGrouping['total']]
      ),
      map(sum)
    );
  }
}
