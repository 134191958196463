<div class="layout-margin">
  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button (click)="downloadCSV()">Download CSV</button>
  </div>
</div>
<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="invoice">
    <th mat-header-cell *matHeaderCellDef>Invoice</th>
    <td mat-cell *matCellDef="let record">
      <a [routerLink]="record.invoice | invoiceUrl$ | async" target="_blank">
        {{ record.invoice.reference }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let record">
      <pr-invoice-status [dense]="true" [status]="record.invoice.status" />
    </td>
  </ng-container>

  <ng-container matColumnDef="patient.name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient</th>
    <td mat-cell *matCellDef="let record">
      <a [routerLink]="record.patient | patientUrl$ | async" target="_blank">
        {{ record.patient.name }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
    <td mat-cell *matCellDef="let record">
      {{ record.invoice.createdAt | moment | amDateFormat: dateFormat }}
    </td>
  </ng-container>

  <ng-container matColumnDef="issuedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Issued</th>
    <td mat-cell *matCellDef="let record">
      {{ record.invoice.issuedAt | moment | amDateFormat: dateFormat }}
    </td>
  </ng-container>

  <ng-container matColumnDef="due">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Due</th>
    <td
      mat-cell
      *matCellDef="let record"
      [ngClass]="{ overdue: record.invoice.due | map: isOverdue }"
    >
      {{ record.invoice.due | moment | amDateFormat: dateFormat }}
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Amount</th>
    <td mat-cell *matCellDef="let record">
      {{ record | map: amount | currency }}
    </td>
  </ng-container>

  <ng-container matColumnDef="pending">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Pending Amount(s)</th>
    <td mat-cell *matCellDef="let record">
      <div
        *ngFor="
          let row of record | map: pendingProviders;
          trackBy: trackByProvider
        "
      >
        {{ row.provider }}: {{ row.total | currency }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="outstanding">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Outstanding Amount
    </th>
    <td mat-cell *matCellDef="let record">
      {{ record | map: outstanding | currency }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
