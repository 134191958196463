@if (dvaForm$ | async; as form) {
  <div class="flex" [formGroup]="form">
    <mat-form-field class="flex-1">
      <mat-label>Membership Number</mat-label>
      <input matInput formControlName="number" />
      <mat-error *ngIf="form.controls.number.hasError('pattern')">
        Must contain only numbers
      </mat-error>
    </mat-form-field>
  </div>
}
