import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { TreatmentTemplate } from '@principle-theorem/principle-core';
import {
  type IPractice,
  type IStaffer,
  type ITreatmentTemplate,
  type TreatmentTemplateTreatments,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  type INamedDocument,
  snapshot,
  STEP_SIZE,
  type WithRef,
} from '@principle-theorem/shared';
import { omit } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UpsertTreatmentTemplateStore } from './upsert-treatment-template.store';

@Component({
  selector: 'pr-upsert-treatment-template',
  templateUrl: './upsert-treatment-template.component.html',
  styleUrls: ['./upsert-treatment-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertTreatmentTemplateComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByPractice = TrackByFunctions.ref<INamedDocument<IPractice>>();
  trackByStaffer = TrackByFunctions.ref<INamedDocument<IStaffer>>();
  form: TypedFormGroup<ITreatmentTemplateFormData> =
    new TypedFormGroup<ITreatmentTemplateFormData>({
      name: new TypedFormControl('', Validators.required),
      price: new TypedFormControl(0, Validators.required),
      isPublic: new TypedFormControl(false, Validators.required),
      duration: new TypedFormControl(0, Validators.required),
      enabledPractices: new TypedFormGroup<EnabledPracticesFormData>({}),
      implementedBy: new TypedFormGroup<ImplementedByFormData>({}),
    });
  update = false;
  stepSize = STEP_SIZE;

  constructor(
    private _dialogRef: MatDialogRef<
      UpsertTreatmentTemplateComponent,
      ITreatmentTemplate
    >,
    public store: UpsertTreatmentTemplateStore,
    private _brandScope: CurrentBrandScope,
    @Inject(MAT_DIALOG_DATA) public data?: ITreatmentTemplateData
  ) {
    if (this.data?.treatmentTemplate) {
      this.update = true;
      this.form.patchValue({
        ...omit(this.data.treatmentTemplate, [
          'enabledPractices',
          'implementedBy',
        ]),
        duration: TreatmentTemplate.getDurationRange(
          this.data.treatmentTemplate
        ).minDuration,
      });
    }

    this.store.loadBrand(this._brandScope.doc$.pipe(filterUndefined()));

    this.store.practices$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practices) => {
        practices.map((practice) => {
          let practiceIsEnabled = false;
          if (
            this.data?.treatmentTemplate &&
            TreatmentTemplate.practiceIsEnabled(
              this.data.treatmentTemplate,
              practice
            )
          ) {
            practiceIsEnabled = true;
          }
          this.enabledPractices.addControl(
            practice.ref.id,
            new TypedFormControl<boolean>(practiceIsEnabled)
          );
        });
      });

    this.store.staff$.pipe(takeUntil(this._onDestroy$)).subscribe((staff) => {
      staff.map((staffer) => {
        if (!this.data?.treatmentTemplate) {
          this.implementedBy.addControl(
            staffer.ref.id,
            new TypedFormGroup<IImplementorTreatmentPair>({
              enabled: new TypedFormControl<boolean>(false),
              treatment: new TypedFormControl<TreatmentTemplateTreatments>(),
            })
          );
          return;
        }

        const implementor = TreatmentTemplate.getImplementor(
          this.data.treatmentTemplate,
          staffer
        );
        this.implementedBy.addControl(
          staffer.ref.id,
          new TypedFormGroup<IImplementorTreatmentPair>({
            enabled: new TypedFormControl<boolean>(!!implementor),
            treatment: new TypedFormControl<TreatmentTemplateTreatments>(
              implementor?.treatment
            ),
          })
        );
      });
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get enabledPractices(): TypedFormGroup<EnabledPracticesFormData> {
    return this.form.controls
      .enabledPractices as TypedFormGroup<EnabledPracticesFormData>;
  }

  get implementedBy(): TypedFormGroup<ImplementedByFormData> {
    return this.form.controls
      .implementedBy as TypedFormGroup<ImplementedByFormData>;
  }

  getImplementationPair(
    staffer: INamedDocument<IStaffer>
  ): TypedFormGroup<IImplementorTreatmentPair> {
    return this.implementedBy.controls[
      staffer.ref.id
    ] as TypedFormGroup<IImplementorTreatmentPair>;
  }

  async save(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    const data: ITreatmentTemplateFormData = this.form.getRawValue();
    const treatmentTemplate: ITreatmentTemplate = TreatmentTemplate.init({
      ...this.data?.treatmentTemplate,
      name: data.name,
      price: data.price,
      isPublic: data.isPublic,
    });

    TreatmentTemplate.resetPractices(treatmentTemplate);

    const practices = await snapshot(this.store.practices$);
    practices.map((practice) => {
      if (data.enabledPractices[practice.ref.id]) {
        TreatmentTemplate.addPractice(treatmentTemplate, practice);
      }
    });

    TreatmentTemplate.resetImplementors(treatmentTemplate);
    const staff = await snapshot(this.store.staff$);
    staff.map((staffer) => {
      const implementor: IImplementorTreatmentPair | undefined =
        data.implementedBy[staffer.ref.id];
      if (implementor && implementor.enabled && implementor.treatment) {
        TreatmentTemplate.updateImplementor(
          treatmentTemplate,
          staffer,
          implementor.treatment,
          data.duration
        );
      }
    });

    this._dialogRef.close(treatmentTemplate);
  }
}

export interface ITreatmentTemplateData {
  treatmentTemplate?: WithRef<ITreatmentTemplate>;
}

interface ITreatmentTemplateFormData {
  name: string;
  price: number;
  duration: number;
  isPublic: boolean;
  enabledPractices: EnabledPracticesFormData;
  implementedBy: ImplementedByFormData;
}

interface IImplementorTreatmentPair {
  enabled: boolean;
  treatment?: TreatmentTemplateTreatments;
}

type EnabledPracticesFormData = Record<string, boolean>;

type ImplementedByFormData = Record<string, IImplementorTreatmentPair>;
