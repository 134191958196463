import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppointmentManager } from '@principle-theorem/ng-appointment/store';
import { ClinicalNoteEditDialogComponent } from '@principle-theorem/ng-clinical-charting';
import {
  CancelFollowUpComponent,
  FollowUpComponent,
  IAppointmentFollowUpData,
  ICancelFollowUpRequest,
} from '@principle-theorem/ng-follow-ups';
import {
  AppointmentContextualActionButtons,
  MentionActionResolverService,
  type IContextualActionButton,
} from '@principle-theorem/ng-interactions';
import { UpdatePatientComponent } from '@principle-theorem/ng-patient';
import {
  CurrentBrandScope,
  OrganisationService,
  ScheduleSummaryEventActionsService,
  type IContextualAction,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  DynamicSidebarService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { Appointment, toMention } from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  AppointmentSummary,
  MentionResourceType,
  isAppointment,
  type IAppointment,
  type IBrand,
  type IEvent,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  asDocRef,
  filterUndefined,
  snapshot,
  type WithRef,
  Firestore,
} from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppointmentInteractionsDialogComponent } from '../../appointment-interactions-dialog/appointment-interactions-dialog.component';
import { AppointmentAutomationsDialogComponent } from '../../scheduling/appointment-automations-dialog/appointment-automations-dialog.component';
import { ChecklistFormDialogComponent } from '../../scheduling/checklist-form-dialog/checklist-form-dialog.component';
import {
  UpdateAppointmentDurationComponent,
  type IUpdateAppointmentDurationData,
} from '../../scheduling/update-appointment-duration/update-appointment-duration.component';
import {
  WaitlistConfigurationDialogComponent,
  type IWaitListConfigurationData,
} from '../../scheduling/waitlist-configuration-dialog/waitlist-configuration-dialog.component';

@Component({
  selector: 'pr-appointment-menu',
  templateUrl: './appointment-menu.component.html',
  styleUrls: ['./appointment-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentMenuComponent {
  appointment$ = new ReplaySubject<WithRef<IAppointment> | AppointmentSummary>(
    1
  );
  patient$: Observable<WithRef<IPatient>>;
  canConfirm$: Observable<boolean>;
  canBeCancelled$: Observable<boolean>;
  canChangeDuration$: Observable<boolean>;
  canRevertConfirmation$: Observable<boolean>;
  canRevertCheckIn$: Observable<boolean>;
  canRevertInProgress$: Observable<boolean>;
  canBeRescheduled$: Observable<boolean>;
  hasFollowUp$: Observable<boolean>;
  isUnscheduled$: Observable<boolean>;
  trackByAction =
    TrackByFunctions.nestedField<IContextualActionButton>('action.name');
  actions: IContextualActionButton[] = [];
  @Output() appointmentReschedule = new EventEmitter<void>();
  @Output() appointmentCancel = new EventEmitter<void>();
  @Output() updateEventableStatus = new EventEmitter<AppointmentStatus>();

  @Input()
  set appointment(appointment: WithRef<IAppointment> | AppointmentSummary) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _brandScope: CurrentBrandScope,
    private _actions: MentionActionResolverService,
    private _router: Router,
    private _sidebar: DynamicSidebarService,
    private _scheduleSummaryEventActions: ScheduleSummaryEventActionsService
  ) {
    this.patient$ = this.appointment$.pipe(
      switchMap((appointment) =>
        isAppointment(appointment)
          ? Appointment.patient(appointment)
          : Firestore.getDoc(appointment.metadata.patientRef)
      )
    );

    this.actions = new AppointmentContextualActionButtons(
      this.appointment$,
      this.patient$
    ).createMany(this._actions.resolveActions(MentionResourceType.Appointment));

    this.canConfirm$ = this.appointment$.pipe(
      map((appointment) => Appointment.canConfirm(appointment))
    );
    this.canBeCancelled$ = this.appointment$.pipe(
      map((appointment) => Appointment.canBeCancelled(appointment))
    );
    this.canChangeDuration$ = this.appointment$.pipe(
      map((appointment) => Appointment.canChangeDuration(appointment))
    );
    this.canRevertConfirmation$ = this.appointment$.pipe(
      map((appointment) => Appointment.canRevertToScheduled(appointment))
    );
    this.canRevertCheckIn$ = this.appointment$.pipe(
      map((appointment) => Appointment.canRevertToConfirmed(appointment))
    );
    this.canRevertInProgress$ = this.appointment$.pipe(
      map((appointment) => Appointment.canRevertToCheckedIn(appointment))
    );
    this.canBeRescheduled$ = this.appointment$.pipe(
      map((appointment) => Appointment.canBeRescheduled(appointment))
    );

    this.isUnscheduled$ = this.appointment$.pipe(
      map((appointment) => Appointment.isUnscheduled(appointment))
    );
    this.hasFollowUp$ = this.appointment$.pipe(
      switchMap((_appointment) => this._getAppointment()),
      map((appointment) => Appointment.hasFollowUp(appointment))
    );
  }

  async runAction(action: IContextualAction): Promise<void> {
    const appointment = await this._getAppointment();
    const patient = await snapshot(this.patient$);
    action.do(
      toMention(patient, MentionResourceType.Patient),
      {
        interactions$: Appointment.interactions$(appointment),
        add: async (interaction) => {
          await Appointment.addInteraction(appointment, interaction);
        },
      },
      await Appointment.toMention(appointment)
    );
  }

  async editClinicalNote(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const appointment = await this._getAppointment();
    const staffer = await Appointment.practitioner(appointment);

    const config = DialogPresets.large({
      height: '80%',
      data: {
        patient,
        staffer,
        date: appointment?.event?.from,
        displayStafferSelector: true,
        displayNoteHistory: true,
      },
    });
    this._dialog.open(ClinicalNoteEditDialogComponent, config);
  }

  async showSchedulingNotes(): Promise<void> {
    const appointment = await this._getAppointment();
    const patient = await snapshot(this.patient$);
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data: {
        appointment,
        patient,
      },
    });
    this._dialog.open(AppointmentInteractionsDialogComponent, config);
  }

  async openChecklistForm(): Promise<void> {
    const appointment = await this._getAppointment();
    const config: MatDialogConfig = DialogPresets.medium({
      data: { appointment },
    });
    this._dialog.open(ChecklistFormDialogComponent, config);
  }

  async manageAutomations(): Promise<void> {
    const appointment = await this._getAppointment();
    const config: MatDialogConfig = DialogPresets.medium({
      data: {
        appointment,
      },
    });
    this._dialog.open(AppointmentAutomationsDialogComponent, config);
  }

  async rescheduleAppointment(): Promise<void> {
    const path = await this._getAppointmentPath();
    this.appointmentReschedule.emit();
    this._sidebar.close(true);
    await this._router.navigate([...path, 'manage', 'reschedule']);
  }

  async cancelAppointment(): Promise<void> {
    const path = await this._getAppointmentPath();
    this.appointmentCancel.emit();
    await this._router.navigate([...path, 'manage', 'cancel']);
  }

  async updateDuration(): Promise<void> {
    const appointment = await this._getAppointment();
    const patient = await snapshot(this.patient$);
    const config: MatDialogConfig = DialogPresets.medium({
      data: {
        appointment,
        patient,
      },
    });
    const event = await this._dialog
      .open<
        UpdateAppointmentDurationComponent,
        IUpdateAppointmentDurationData,
        IEvent
      >(UpdateAppointmentDurationComponent, config)
      .afterClosed()
      .toPromise();

    if (!event) {
      return;
    }

    await Firestore.patchDoc(appointment.ref, { event });
    await this._updateAppointmentScheduleSummary(appointment, event);
    this._snackBar.open('Appointment duration updated');
  }

  async confirmAppointment(): Promise<void> {
    const appointment = await this._getAppointment();

    if (Appointment.isUnscheduled(appointment)) {
      return;
    }
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await AppointmentManager.markConfirmed(staffer, appointment);
    this.updateEventableStatus.emit(AppointmentStatus.Confirmed);
    this._snackBar.open('Appointment marked as confirmed');
  }

  async revertConfirmedAppointment(): Promise<void> {
    const appointment = await this._getAppointment();
    if (!Appointment.canRevertToScheduled(appointment)) {
      return;
    }
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await AppointmentManager.markScheduled(staffer, appointment);
    this.updateEventableStatus.emit(AppointmentStatus.Scheduled);
    this._snackBar.open('Appointment confirmation reverted');
  }

  async revertCheckedInAppointment(): Promise<void> {
    const appointment = await this._getAppointment();
    if (!Appointment.canRevertToConfirmed(appointment)) {
      return;
    }
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await AppointmentManager.markConfirmed(staffer, appointment);
    this.updateEventableStatus.emit(AppointmentStatus.Confirmed);
    this._snackBar.open('Appointment check in reverted');
  }

  async revertInProgressAppointment(): Promise<void> {
    const appointment = await this._getAppointment();
    if (!Appointment.canRevertToCheckedIn(appointment)) {
      return;
    }
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await AppointmentManager.markCheckedIn(staffer, appointment);
    this.updateEventableStatus.emit(AppointmentStatus.CheckedIn);
    this._snackBar.open('Appointment in progress reverted');
  }

  async openPatientEdit(): Promise<void> {
    const patient = await snapshot(this.patient$);
    this._dialog.open(
      UpdatePatientComponent,
      DialogPresets.large({
        data: { patient },
      })
    );
  }

  async openWaitlistConfiguration(): Promise<void> {
    const data: IWaitListConfigurationData = {
      appointment: await this._getAppointment(),
    };
    const config: MatDialogConfig = DialogPresets.medium({ data });
    this._dialog.open<
      WaitlistConfigurationDialogComponent,
      IWaitListConfigurationData
    >(WaitlistConfigurationDialogComponent, config);
  }

  async addToFollowUp(): Promise<void> {
    const appointment = await this._getAppointment();
    const data: IAppointmentFollowUpData = {
      appointment,
      patient: await snapshot(this.patient$),
    };
    this._dialog.open(FollowUpComponent, DialogPresets.medium({ data }));
  }

  async cancelFollowUp(): Promise<void> {
    const appointment = await this._getAppointment();
    const data: ICancelFollowUpRequest = {
      appointment,
      patient: await snapshot(this.patient$),
    };
    this._dialog.open(CancelFollowUpComponent, DialogPresets.medium({ data }));
  }

  private async _getAppointmentPath(): Promise<string[]> {
    const appointment = await this._getAppointment();
    const patient = await snapshot(this.patient$);
    const brand: WithRef<IBrand> = await this._brandScope.toPromise();
    return [
      '/',
      brand.slug,
      'patients',
      patient.ref.id,
      'appointments',
      appointment.ref.id,
    ];
  }

  private async _getAppointment(): Promise<WithRef<IAppointment>> {
    const appointment = await snapshot(this.appointment$);
    if (isAppointment(appointment)) {
      return appointment;
    }
    const ref = appointment.ref as DocumentReference<IAppointment>;
    const resolvedAppointment = await Firestore.getDoc(
      asDocRef<IAppointment>(ref)
    );
    this.appointment$.next(resolvedAppointment);
    return resolvedAppointment;
  }

  private async _updateAppointmentScheduleSummary(
    appointment: WithRef<IAppointment>,
    event: IEvent
  ): Promise<void> {
    await this._scheduleSummaryEventActions.updateAppointmentSummary(
      appointment,
      {
        ...appointment,
        event,
      }
    );
  }
}
