<mat-toolbar color="accent">
  <ng-container *ngIf="update; else add">Update</ng-container>
  <ng-template #add>Add</ng-template>
  Treatment Template
</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Price</mat-label>
      <input type="number" matInput min="0" formControlName="price" />
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Duration (mins)</mat-label>
      <input
        type="number"
        matInput
        min="0"
        [step]="stepSize"
        formControlName="duration"
      />
    </mat-form-field>

    <div fxFlex class="checkbox">
      <mat-checkbox formControlName="isPublic">
        Available for booking online
      </mat-checkbox>
    </div>

    <div fxFlex class="checkbox" formGroupName="enabledPractices">
      <small>Practices offering this treatment</small>
      <div
        fxLayout="column"
        fxLayoutGap="8px"
        *ngFor="
          let practice of store.practices$ | async;
          trackBy: trackByPractice
        "
      >
        <mat-checkbox
          #checkbox
          [formControlName]="practice.ref.id"
          [value]="practice"
        >
          {{ practice.name }}
        </mat-checkbox>
        <ng-container *ngIf="checkbox.checked" />
      </div>
    </div>

    <div fxFlex class="checkbox" [formGroup]="implementedBy">
      <small>Staff offering this treatment</small>
      <div
        fxLayout="column"
        fxLayoutGap="8px"
        *ngFor="let staffer of store.staff$ | async; trackBy: trackByStaffer"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          *ngIf="
            staffer | map : getImplementationPair : this as implementationPair
          "
        >
          <mat-checkbox
            #stafferCheckbox
            [formControl]="implementationPair.controls.enabled"
            fxFlex="50"
          >
            {{ staffer.name }}
          </mat-checkbox>
          <pr-staffer-treatment-template-select
            *ngIf="stafferCheckbox.checked"
            fxFlex="50"
            class="my-1"
            [formControl]="implementationPair.controls.treatment"
            [staffer]="staffer"
            [brand]="store.brand$ | async"
          />
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button type="button" mat-dialog-close>Cancel</button>
      <button mat-flat-button type="submit" color="primary">Save</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
