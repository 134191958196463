import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import {
  type IPatient,
  type IPatientData,
  type ISuggestedPatientData,
} from '@principle-theorem/principle-core/interfaces';
import { AppointmentRequest } from '@principle-theorem/principle-core';
import { HISTORY_DATE_FORMAT, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isString } from 'lodash';

@Component({
  selector: 'pr-confirm-patient-dialog',
  templateUrl: './confirm-patient-dialog.component.html',
  styleUrls: ['./confirm-patient-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPatientDialogComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  disableContinueButton$ = new BehaviorSubject<boolean>(true);
  disableCreateNewPatientButton$ = new BehaviorSubject<boolean>(false);
  disableTooltipOnContinue$ = new BehaviorSubject<boolean>(false);
  patientSelector = new TypedFormControl<WithRef<IPatient> | undefined>(
    undefined
  );
  suggestedPatientData: ISuggestedPatientData;
  readonly dateFormat = HISTORY_DATE_FORMAT;

  constructor(
    private _brandScope: CurrentBrandScope,
    public dialogRef: MatDialogRef<ConfirmPatientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public patient: IPatientData
  ) {
    this.patientSelector.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        if (!value || isString(value)) {
          this.disableContinueButton$.next(true);
          this.disableTooltipOnContinue$.next(false);
          return;
        }
        this.disableContinueButton$.next(false);
        this.disableTooltipOnContinue$.next(true);
      });

    this.suggestedPatientData = {
      name: this.patient.primaryContact.name,
      dateOfBirth: this.patient.primaryContact.dateOfBirth,
      mobileNumber: this.patient.primaryContact.mobileNumber,
    };
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  patientConfirmed(): void {
    this.dialogRef.close(this.patientSelector.value);
  }

  async createNewPatient(): Promise<void> {
    this.disableCreateNewPatientButton$.next(true);
    const brand = await this._brandScope.toPromise();
    const patient =
      await AppointmentRequest.generatePatientFromAppointmentRequest(
        brand,
        this.patient
      );
    this.disableCreateNewPatientButton$.next(false);
    this.dialogRef.close(patient);
  }
}
