import {
  IBrand,
  IOrganisation,
  IPractice,
  IRole,
  IUser,
  Permission,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  IReffable,
  WithRef,
  asyncForEach,
  isSameRef,
  multiSortBy$,
  nameSorter,
  safeCombineLatest,
  snapshot,
  some,
  where,
} from '@principle-theorem/shared';
import { UserInfo } from 'firebase/auth';
import { compact, intersection, intersectionWith, uniq } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Brand } from '../brand';
import { Organisation } from '../organisation/organisation';
import { OrganisationCache } from '../organisation/organisation-cache';
import { Practice } from '../practice/practice';
import { setStafferDefaults } from '../staffer/set-staffer-defaults';

export class User {
  static init(overrides: AtLeast<IUser, 'name' | 'email'>): IUser {
    return {
      phone: '',
      isEnabled: true,
      roles: [],
      practices: [],
      brands: [],
      ...overrides,
    };
  }

  static storagePath(user: WithRef<IUser>): string {
    return user.ref.path;
  }

  static roles$(user: IUser): Observable<WithRef<IRole>[]> {
    if (!user.roles.length) {
      return of([]);
    }
    const roles: Observable<WithRef<IRole>>[] = user.roles.map((ref) =>
      OrganisationCache.roles.doc$(ref)
    );
    return combineLatest(roles);
  }

  static permissions$(user: IUser): Observable<Permission[]> {
    return User.roles$(user).pipe(
      map((roles) =>
        uniq(
          roles.reduce(
            (all: Permission[], role: IRole) => [...all, ...role.permissions],
            []
          )
        )
      )
    );
  }

  static hasPermission$(
    user: IUser,
    permission: Permission
  ): Observable<boolean> {
    return User.permissions$(user).pipe(
      map((permissions: Permission[]) => permissions.includes(permission))
    );
  }

  static hasPermissions(
    requestedPermissions: Permission[],
    userPermissions: Permission[]
  ): boolean {
    if (!requestedPermissions.length) {
      return true;
    }
    return intersection(requestedPermissions, userPermissions).length > 0;
  }

  static hasPermissions$(
    user: IUser,
    permissions: Permission[]
  ): Observable<boolean> {
    if (!permissions.length) {
      return of(true);
    }
    return safeCombineLatest(
      permissions.map((permission) => User.hasPermission$(user, permission))
    ).pipe(some());
  }

  static toFirebaseUser(user: WithRef<IUser>): UserInfo {
    return {
      providerId: 'mock-auth',
      uid: user.ref.id,
      displayName: user.name,
      email: user.email,
      // eslint-disable-next-line no-null/no-null
      photoURL: user.profileImageURL ?? null,
      phoneNumber: user.phone,
    };
  }

  static practices$(user: IUser): Observable<WithRef<IPractice>[]> {
    return safeCombineLatest(
      user.practices.map((ref) => OrganisationCache.practices.doc$(ref))
    );
  }

  static brands$(user: IUser): Observable<WithRef<IBrand>[]> {
    return safeCombineLatest(
      user.brands.map((ref) => OrganisationCache.brands.doc$(ref))
    );
  }

  static brandsInOrganisation$(
    organisation: IReffable<IOrganisation>,
    user: WithRef<IUser>
  ): Observable<WithRef<IBrand>[]> {
    return combineLatest([
      Organisation.brands$(organisation),
      User.brands$(user),
    ]).pipe(
      map(([brands, userBrands]) =>
        intersectionWith(brands, userBrands, isSameRef)
      )
    );
  }

  static practicesInBrand$(
    brand: IReffable<IBrand>,
    user: WithRef<IUser>
  ): Observable<WithRef<IPractice>[]> {
    return combineLatest([Brand.practices$(brand), User.practices$(user)]).pipe(
      map(([practices, userPractices]) =>
        intersectionWith(practices, userPractices, isSameRef)
      )
    );
  }

  static byPractice$(
    practice: IReffable<IPractice>
  ): Observable<WithRef<IUser>[]> {
    const brandRef = Practice.brandDoc(practice);
    return Brand.organisation$({ ref: brandRef }).pipe(
      switchMap((organisation) =>
        OrganisationCache.users.byPractice.query$(
          {
            practiceRef: practice.ref,
            organisationRef: organisation.ref,
          },
          Organisation.userCol(organisation),
          where('practices', 'array-contains', practice.ref)
        )
      ),
      multiSortBy$(nameSorter())
    );
  }

  static canInvite(user: WithRef<IUser>): boolean {
    return user.firstSignedInAt ? false : true;
  }

  static async setupUserDefaults(
    user: WithRef<IUser>,
    addTreatmentConfigs: boolean = false
  ): Promise<void> {
    const userStaffers = compact(
      await asyncForEach(user.brands, (brandDoc) =>
        snapshot(Brand.getBrandStafferFromUser({ ref: brandDoc }, user))
      )
    );
    await asyncForEach(userStaffers, async (staffer) =>
      setStafferDefaults(staffer, addTreatmentConfigs)
    );
  }

  static byBrand$(
    brand: IReffable<IBrand>,
    filterEnabled: boolean = true
  ): Observable<WithRef<IUser>[]> {
    return Brand.organisation$(brand).pipe(
      switchMap((organisation) =>
        OrganisationCache.users.byBrand.query$(
          {
            brandRef: brand.ref,
            organisationRef: organisation.ref,
            filterEnabled,
          },
          Organisation.userCol(organisation),
          ...compact([
            where('brands', 'array-contains', brand.ref),
            filterEnabled ? where('isEnabled', '==', true) : undefined,
          ])
        )
      ),
      multiSortBy$(nameSorter())
    );
  }
}
