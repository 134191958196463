import { Injectable } from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  ITransaction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  multiFilter,
  multiMap,
  safeCombineLatest,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { RefundAccountCreditTransaction } from './account-credit/account-credit-transaction-actions';
import { AccountCreditTransactionProvider } from './account-credit/account-credit-transaction-provider.service';
import { AmendCashTransaction } from './cash/actions/amend-cash-transaction';
import { DeleteCashTransaction } from './cash/actions/delete-cash-transaction';
import { RefundCashTransaction } from './cash/actions/refund-cash-transaction';
import { CashTransactionProvider } from './cash/cash-transaction-provider.service';
import { AmendDiscountTransaction } from './discount/actions/amend-discount-transaction';
import { RefundDiscountTransaction } from './discount/actions/refund-discount-transaction';
import { DiscountTransactionProvider } from './discount/discount-transaction-provider.service';
import { GeneralProviderActionsService } from './general-provider-actions.service';
import { CancelHicapsConnectHealthfundTransaction } from './hicaps-connect/health-fund/actions/cancel-hicaps-connect-healthfund-transaction';
import { PrintCustomerReceiptHicapsConnectHealthfundTransaction } from './hicaps-connect/health-fund/actions/print-customer-receipt-hicaps-connect-healthfund-transaction';
import { PrintProviderReceiptHicapsConnectHealthfundTransaction } from './hicaps-connect/health-fund/actions/print-provider-receipt-hicaps-connect-healthfund-transaction';
import { HicapsConnectHealthFundTransactionProvider } from './hicaps-connect/health-fund/hicaps-connect-health-fund-transaction-provider.service';
import { AmendHicapsConnectMedicareTransaction } from './hicaps-connect/medicare/actions/amend-hicaps-connect-medicare-transaction';
import { ApproveHicapsConnectMedicareTransaction } from './hicaps-connect/medicare/actions/approve-hicaps-connect-medicare-transaction';
import { VoidHicapsConnectMedicareTransaction } from './hicaps-connect/medicare/actions/void-hicaps-connect-medicare-transaction';
import { AmendManualTransaction } from './manual/actions/amend-manual-transaction';
import { ApproveManualTransaction } from './manual/actions/approve-manual-transaction';
import { CancelManualTransaction } from './manual/actions/cancel-manual-transaction';
import { DeleteManualTransaction } from './manual/actions/delete-manual-transaction';
import { RefundManualTransaction } from './manual/actions/refund-manual-transaction';
import { ManualTransactionProvider } from './manual/manual-transaction-provider.service';
import { RefundSmartpayCardPurchaseTransaction } from './smartpay/actions/refund-card-purchase-transaction';
import { RefundSmartpayQRPurchaseTransaction } from './smartpay/actions/refund-qr-purchase-transaction';
import { SmartpayCardPurchaseTransactionProvider } from './smartpay/smartpay-card-purchase-transaction-provider.service';
import { SmartpayQRPurchaseTransactionProvider } from './smartpay/smartpay-qr-purchase-transaction-provider.service';
import {
  ITransactionAction,
  ITransactionActionsData,
} from './transaction-action';
import { AmendEasyclaimTransaction } from './tyro/easyclaim/actions/amend-easyclaim-transaction';
import { ApproveEasyclaimTransaction } from './tyro/easyclaim/actions/approve-easyclaim-transaction';
import { VoidEasyclaimTransaction } from './tyro/easyclaim/actions/void-easyclaim-transaction';
import { AmendHealthPointTransaction } from './tyro/health-point/actions/amend-health-point-transaction';
import { CancelHealthPointTransaction } from './tyro/health-point/actions/cancel-health-point-transaction';
import { VoidHealthPointTransaction } from './tyro/health-point/actions/void-health-point-transaction';
import { TyroHealthPointTransactionProvider } from './tyro/health-point/tyro-health-point-transaction-provider.service';
import { RefundTyroPurchaseTransaction } from './tyro/purchase/actions/refund-purchase-transaction';
import { TyroPurchaseTransactionProvider } from './tyro/purchase/tyro-purchase-transaction-provider.service';
import { VoidHicapsConnectHealthfundTransaction } from './hicaps-connect/health-fund/actions/void-hicaps-connect-healthfund-transaction';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IProviderActionsMap<T = any> = {
  [key in TransactionProvider]: ITransactionAction<T>[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IProviderRefundMap<T = any> = Partial<
  Record<TransactionProvider, ITransactionAction<T> | undefined>
>;

@Injectable()
export class TransactionActionFactoryService {
  private _providerActionMap: IProviderActionsMap;
  private _providerRefundMap: IProviderRefundMap;

  constructor(
    private _actions: GeneralProviderActionsService,
    private _cash: CashTransactionProvider,
    private _manual: ManualTransactionProvider,
    private _discount: DiscountTransactionProvider,
    private _accountCredit: AccountCreditTransactionProvider,
    private _tyroPurchase: TyroPurchaseTransactionProvider,
    private _tyroHealthPoint: TyroHealthPointTransactionProvider,
    private _smartpayCardPurchase: SmartpayCardPurchaseTransactionProvider,
    private _smartpayQRPurchase: SmartpayQRPurchaseTransactionProvider,
    private _hicapsConnectHealthFund: HicapsConnectHealthFundTransactionProvider,
    private _organisation: OrganisationService
  ) {
    this._providerActionMap = {
      [TransactionProvider.Stripe]: [],
      [TransactionProvider.Cash]: [
        new AmendCashTransaction(this._actions),
        new DeleteCashTransaction(this._actions, this._organisation),
      ],
      [TransactionProvider.Manual]: [
        new AmendManualTransaction(this._actions),
        new CancelManualTransaction(this._actions),
        new ApproveManualTransaction(this._actions),
        new DeleteManualTransaction(this._actions, this._organisation),
      ],
      [TransactionProvider.Discount]: [
        new AmendDiscountTransaction(this._actions),
      ],
      [TransactionProvider.AccountCredit]: [],
      [TransactionProvider.AccountCreditTransfer]: [],
      [TransactionProvider.PaymentPlan]: [],
      [TransactionProvider.TyroEftpos]: [],
      [TransactionProvider.TyroHealthPoint]: [
        new AmendHealthPointTransaction(this._actions),
        new VoidHealthPointTransaction(this._actions),
        new CancelHealthPointTransaction(this._tyroHealthPoint),
      ],
      [TransactionProvider.TyroEasyClaimBulkBill]: [
        new AmendEasyclaimTransaction(this._actions),
        new VoidEasyclaimTransaction(this._actions),
        new ApproveEasyclaimTransaction(this._actions),
      ],
      [TransactionProvider.TyroEasyClaimPartPaid]: [
        new AmendEasyclaimTransaction(this._actions),
        new VoidEasyclaimTransaction(this._actions),
        new ApproveEasyclaimTransaction(this._actions),
      ],
      [TransactionProvider.TyroEasyClaimFullyPaid]: [
        new AmendEasyclaimTransaction(this._actions),
        new VoidEasyclaimTransaction(this._actions),
        new ApproveEasyclaimTransaction(this._actions),
      ],
      [TransactionProvider.MedipassHicaps]: [],
      [TransactionProvider.MedipassMedicare]: [],
      [TransactionProvider.MedipassDVA]: [],
      [TransactionProvider.MedipassPatientFunded]: [],
      [TransactionProvider.MedipassVirtualTerminal]: [],
      [TransactionProvider.MedipassGapPayment]: [],
      [TransactionProvider.HicapsConnectEftpos]: [],
      [TransactionProvider.HicapsConnectHealthFund]: [
        new CancelHicapsConnectHealthfundTransaction(
          this._hicapsConnectHealthFund
        ),
        new PrintProviderReceiptHicapsConnectHealthfundTransaction(
          this._hicapsConnectHealthFund
        ),
        new PrintCustomerReceiptHicapsConnectHealthfundTransaction(
          this._hicapsConnectHealthFund
        ),
        new VoidHicapsConnectHealthfundTransaction(this._actions),
      ],
      [TransactionProvider.HicapsConnectMedicare]: [
        new AmendHicapsConnectMedicareTransaction(this._actions),
        new VoidHicapsConnectMedicareTransaction(this._actions),
        new ApproveHicapsConnectMedicareTransaction(this._actions),
      ],
      [TransactionProvider.SmartpayCard]: [],
      [TransactionProvider.SmartpayQR]: [],
    };

    this._providerRefundMap = {
      [TransactionProvider.Cash]: new RefundCashTransaction(this._cash),
      [TransactionProvider.Manual]: new RefundManualTransaction(this._manual),
      [TransactionProvider.Discount]: new RefundDiscountTransaction(
        this._discount
      ),
      [TransactionProvider.AccountCredit]: new RefundAccountCreditTransaction(
        this._accountCredit
      ),
      [TransactionProvider.TyroEftpos]: new RefundTyroPurchaseTransaction(
        this._tyroPurchase
      ),
      [TransactionProvider.SmartpayCard]:
        new RefundSmartpayCardPurchaseTransaction(this._smartpayCardPurchase),
      [TransactionProvider.SmartpayQR]: new RefundSmartpayQRPurchaseTransaction(
        this._smartpayQRPurchase
      ),
    };
  }

  getActions(
    transaction: WithRef<ITransaction<unknown>>
  ): ITransactionAction<unknown>[] {
    const actions = this._providerActionMap[transaction.provider];
    return actions.filter((action) =>
      action.typeGuardFn(transaction.extendedData)
    );
  }

  getRefundAction(
    transaction: WithRef<ITransaction<unknown>>
  ): ITransactionAction<unknown> | undefined {
    const refundAction = this._providerRefundMap[transaction.provider];
    if (!refundAction) {
      return;
    }
    return refundAction.typeGuardFn(transaction.extendedData)
      ? refundAction
      : undefined;
  }

  getAvailableActions$(
    data: ITransactionActionsData<unknown>
  ): Observable<ITransactionAction<unknown>[]> {
    const actions = this.getActions(data.latestTransaction);
    const canDoPairs$ = actions.map((action) =>
      action.canDo$(data).pipe(map((canDo) => ({ canDo, action })))
    );
    return safeCombineLatest(canDoPairs$).pipe(
      multiFilter((pair) => pair.canDo),
      multiMap((pair) => pair.action)
    );
  }
}
