import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  BasicDialogService,
  CSVExporterService,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  CustomChartType,
  type ICustomReportChart,
  type ICustomReportChartSection,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot } from '@principle-theorem/shared';
import { type Crossfilter } from 'crossfilter2';
import { compact } from 'lodash';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  ReportBuilderDataSource,
  type IReportBuilderDataSource,
} from '../../../../models/report-builder-data-sources/report-builder-data-source';
import { ReportBuilderHelpers } from '../../../../models/report-builder-data-sources/report-builder-helpers';
import { ReportBuilderStore } from '../../report-builder.store';
import {
  getChartDisplay,
  type IChartDisplay,
} from './report-builder-chart/report-builder-chart-display';
import {
  ICSVColumn,
  ReportBuilderTableChartCSV,
} from './report-builder-table-chart/report-builder-table-chart-csv';

@Component({
  selector: 'pr-report-builder-chart-section',
  templateUrl: './report-builder-chart-section.component.html',
  styleUrls: ['./report-builder-chart-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderChartSectionComponent {
  section$ = new ReplaySubject<ICustomReportChartSection>(1);
  displays$: Observable<IChartDisplay[]>;
  isTable$: Observable<boolean>;
  @Output() addChartAtIndex = new EventEmitter<{ index: number }>();

  @Input()
  set section(section: ICustomReportChartSection) {
    if (section) {
      this.section$.next(section);
    }
  }

  constructor(
    public store: ReportBuilderStore,
    private _basicDialog: BasicDialogService,
    private _csvExporter: CSVExporterService
  ) {
    this.displays$ = combineLatest([this.section$, this.store.ndx$]).pipe(
      withLatestFrom(this.store.dataSource$),
      map(([[section, ndx], dataSource]) =>
        chartsToDisplays(section.charts, dataSource, ndx)
      )
    );
  }

  async deleteSection(): Promise<void> {
    const currentSection = await snapshot(this.section$);
    const current = await snapshot(this.store.display$);
    if (!current) {
      return;
    }
    this.store.setDisplay({
      ...current,
      chartSections: current.chartSections.filter(
        (section) => section.uid !== currentSection.uid
      ),
    });
  }

  getDefaultSectionName(
    data: [ICustomReportChartSection, IChartDisplay]
  ): string | undefined {
    const [section, display] = data;
    return ReportBuilderHelpers.getSectionFromPrefix(
      display.chart.groupBy,
      section.name
    );
  }

  isTable(display: IChartDisplay): boolean {
    return display.chart.type === CustomChartType.Table;
  }

  async downloadCSV(display: IChartDisplay): Promise<void> {
    const dataSource = await snapshot(this.store.dataSource$);
    if (!dataSource) {
      return;
    }
    const groupBy = dataSource.groupByOptions
      .flatMap((section) => section.options)
      .find((item) => item.measure.metadata.id === display.chart.groupBy);

    if (!groupBy) {
      return;
    }

    const dataPoints = (display.tableDisplay?.columns ?? []).map((column) => {
      const metadata = ReportBuilderDataSource.allColumns(dataSource).find(
        (item) => item.measure.metadata.id === column.id
      )?.measure.metadata;

      if (!metadata) {
        return;
      }

      return {
        label: column.label,
        uid: column.uid,
        id: column.uid,
        metadata,
      };
    });

    const columns: ICSVColumn[] = compact([
      {
        id: groupBy?.measure.metadata.id ?? '',
        label: groupBy?.measure.metadata.label ?? '',
        metadata: groupBy?.measure?.metadata,
      },
      ...dataPoints,
    ]);

    const fileName = await this._basicDialog.prompt(
      confirmationDialogData({
        title: 'Download Report',
        prompt: 'Enter a name for the report',
        submitLabel: 'Download',
      })
    );
    if (!fileName) {
      return;
    }

    const records = display.tableDisplay?.chart.data ?? [];
    const builder = new ReportBuilderTableChartCSV(columns, groupBy);

    await this._csvExporter.download(fileName, records, builder);
  }
}

export function chartsToDisplays(
  charts: ICustomReportChart[],
  dataSource?: IReportBuilderDataSource,
  ndx?: Crossfilter<unknown>
): IChartDisplay[] {
  if (!dataSource) {
    return [];
  }
  return compact(
    charts.map((chart) => getChartDisplay(dataSource, chart, ndx))
  );
}
