import {
  initVersionedSchema,
  RawInlineNodes,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  IAppointment,
  ILabJob,
  ILabJobType,
  IPatient,
  IPractice,
  LabJobStatus,
  MentionResourceType,
  PracticeCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  AtLeast,
  DATE_TIME_FORMAT,
  doc$,
  getDoc,
  getParentDocRef,
  initFirestoreModel,
  IReffable,
  MockTimestamp,
  query$,
  subCollection,
  toMoment,
  toMomentTz,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import {
  CollectionReference,
  DocumentReference,
  where,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { TimezoneResolver } from '../../timezone';
import { toMention } from '../mention/mention';
import { WorkflowItem } from '../workflow-item';

export class LabJobType {
  static init(overrides: AtLeast<ILabJobType, 'name'>): ILabJobType {
    return {
      cost: 0,
      createdAt: MockTimestamp(),
      updatedAt: MockTimestamp(),
      deleted: false,
      ...overrides,
    };
  }
}

export class LabJob extends WorkflowItem {
  static init(
    overrides: AtLeast<ILabJob, 'lab' | 'patient' | 'type'>
  ): ILabJob {
    return {
      status: LabJobStatus.Sending,
      statusHistory: [],
      cost: 0,
      title: [],
      description: initVersionedSchema(),
      interactions: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static col(practice: IReffable<IPractice>): CollectionReference<ILabJob> {
    return subCollection<ILabJob>(practice.ref, PracticeCollection.LabJobs);
  }

  static all$(practice: IReffable<IPractice>): Observable<WithRef<ILabJob>[]> {
    return all$(this.col(practice));
  }

  static appointment$(
    labJob: ILabJob
  ): Observable<WithRef<IAppointment> | undefined> {
    if (!labJob.appointment) {
      return of(undefined);
    }
    return doc$(labJob.appointment.ref);
  }

  static practice$(labJob: WithRef<ILabJob>): Observable<WithRef<IPractice>> {
    return doc$(getParentDocRef<IPractice>(labJob.ref));
  }

  static byPatient$(
    practice: IReffable<IPractice>,
    patientRef: DocumentReference<IPatient>
  ): Observable<WithRef<ILabJob>[]> {
    return query$(LabJob.col(practice), where('patient.ref', '==', patientRef));
  }

  static late(item: ILabJob): boolean {
    if (!item.dueDate || LabJob.complete(item)) {
      return false;
    }

    return moment().isAfter(toMoment(item.dueDate));
  }

  static complete(labJob: ILabJob): boolean {
    return labJob.status === LabJobStatus.Received;
  }

  static isOverdue(labJob: ILabJob): boolean {
    const now = moment();
    return (
      !LabJob.complete(labJob) &&
      now.isSameOrAfter(toMoment(labJob.dueDate ?? now))
    );
  }

  static async generateTitle(labJob: ILabJob): Promise<RawInlineNodes> {
    const titleContent: RawInlineNodes = [toTextContent(labJob.type.name)];

    if (labJob.patient) {
      titleContent.push(
        toTextContent(' for '),
        toMentionContent(toMention(labJob.patient, MentionResourceType.Patient))
      );
    }

    if (!labJob.appointment) {
      return titleContent;
    }

    const appointment = await getDoc(labJob.appointment.ref);

    if (appointment.event) {
      const timezone = await TimezoneResolver.fromEvent(appointment);
      const appointmentDescription = toMomentTz(
        appointment.event.from,
        timezone
      ).format(DATE_TIME_FORMAT);
      titleContent.push(toTextContent(` for ${appointmentDescription}`));
    }

    return titleContent;
  }

  static updateStatus(labJob: ILabJob, status: LabJobStatus): void {
    if (labJob.status === status) {
      return;
    }
    labJob.statusHistory.push({
      status: labJob.status,
      updatedAt: toTimestamp(),
    });
    labJob.status = status;
  }

  static async hasConflict(labJob: ILabJob): Promise<boolean> {
    if (!labJob.appointment) {
      return false;
    }

    const appointment = await getDoc(labJob.appointment.ref);

    if (!appointment.event) {
      return false;
    }
    const timezone = await TimezoneResolver.fromEvent(appointment);
    const within1Day: boolean =
      toMomentTz(appointment.event.from, timezone).diff(
        moment.tz(timezone),
        'days',
        true
      ) < 1;

    if (within1Day && labJob.status === LabJobStatus.Received) {
      return false;
    }

    return within1Day;
  }
}
