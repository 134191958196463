import {
  Appointment,
  PatientContextBuilder,
  ScopeDataBuilder,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPatient,
  type IPrincipleMention,
  type ITemplateContextOption,
  MentionResourceType,
  TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import { type IProvider, asDocRef, getDoc } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';

export class PatientMentionContextProvider
  implements IProvider<TemplateScope[], Promise<ITemplateContextOption[]>>
{
  constructor(
    private _mention: IPrincipleMention,
    private _appUrl: string
  ) {}

  canProvide(scopes: TemplateScope[]): boolean {
    const type = this._mention.resource.type;
    const isPatientScope = scopes.includes(TemplateScope.Patient);
    const isPatientResource = type === MentionResourceType.Patient;
    const isAppointmentResource = type === MentionResourceType.Appointment;
    return isPatientScope && (isPatientResource || isAppointmentResource);
  }

  async execute(): Promise<ITemplateContextOption[]> {
    const isAppointmentResource: boolean =
      this._mention.resource.type === MentionResourceType.Appointment;

    if (isAppointmentResource) {
      return this._resolvePatientContextsFromAppointment();
    }

    return [
      await this._resolvePatientContext(
        this._mention.resource.ref as DocumentReference<IPatient>
      ),
    ];
  }

  private async _resolvePatientContextsFromAppointment(): Promise<
    ITemplateContextOption[]
  > {
    const appointment = await getDoc(
      asDocRef<IAppointment>(this._mention.resource.ref)
    );
    return [
      await this._resolvePatientContext(Appointment.patientRef(appointment)),
    ];
  }

  private async _resolvePatientContext(
    patientRef: DocumentReference<IPatient>
  ): Promise<ITemplateContextOption> {
    const patient = await getDoc(patientRef);
    const scopeData = await ScopeDataBuilder.buildPatientScopeData(
      patient,
      this._appUrl
    );
    const context = new PatientContextBuilder(scopeData).build();

    return {
      label: this._mention.key,
      scope: TemplateScope.Patient,
      context,
      scopeData,
    };
  }
}
