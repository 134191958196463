import {
  DocumentReference,
  ISoftDelete,
  ITimestampRange,
} from '@principle-theorem/shared';
import { IBrand } from './brand';
import { IStaffer } from './staffer/staffer';

export enum ReportingJobType {
  AppointmentsWithoutClinicalNote = 'reporting.appointment-without-clinical-notes',
  PractitionerFTAVsUTARates = 'reporting.practitioner-fta-vs-uta-rates',
  OnlineBookings = 'reporting.online-bookings',
  PractitionerGaps = 'reporting.practitioner-gaps',
  PractitionerRebookingRates = 'reporting.practitioner-rebooking-rates',
  AccountCreditsUsedFromOtherPractice = 'reporting.account-credits-used-from-other-practice',
  DebtorsAsOfDate = 'reporting.debtors-as-of-date',
}

export interface IReportingJobDisplay {
  type: ReportingJobType;
  description: string;
  name: string;
}

export const REPORTING_JOBS_DISPLAY = [
  {
    type: ReportingJobType.AppointmentsWithoutClinicalNote,
    description:
      'List of appointments missing clinical notes. These are displayed by patient, practice, practitioner, and treatment',
    name: 'Appointments Without Clinical Notes',
  },
  {
    type: ReportingJobType.PractitionerFTAVsUTARates,
    description:
      'Displays the FTA and UTA rates for each practitioner by week. Please note: FTA is calculated as less than 24 hours from the appointment, and UTA is up to 48 hours before the appointment',
    name: 'Practitioner FTA vs UTA Rates',
  },
  {
    type: ReportingJobType.OnlineBookings,
    description:
      'Displays the number of online booking requests, grouped by week and practice',
    name: 'Online Bookings',
  },
  {
    type: ReportingJobType.PractitionerGaps,
    description:
      'The first sheet shows the gap vs rostered time for each practitioner by date. The second sheet is an overall time utilisation summary for the given time range',
    name: 'Practitioner Gaps',
  },
  {
    type: ReportingJobType.PractitionerRebookingRates,
    description:
      'Rebooking rate is calculated by the number of next appointments booked vs the total appointment count for each practitioner',
    name: 'Practitioner Rebooking Rates',
  },
  {
    type: ReportingJobType.AccountCreditsUsedFromOtherPractice,
    description:
      'Shows a list of account credit transactions where the account credit was originally paid for at another practice',
    name: 'Account Credits Used From Other Practice',
  },
  {
    type: ReportingJobType.DebtorsAsOfDate,
    description:
      'Shows a list of outstanding invoices as they were at the end of the selected date range. This includes backdated transactions that fall within the selected date range',
    name: 'Debtors as of date',
  },
];

export enum ReportingJobFileType {
  Csv = 'csv',
  Xlsx = 'xlsx',
}

export enum ReportingJobStatus {
  Pending = 'pending',
  InProgress = 'inProgress',
  Complete = 'complete',
  Failed = 'failed',
}

export interface IReportingJob {
  requestParams: IReportJobParams;
  type: ReportingJobType;
  status: ReportingJobStatus;
  file?: {
    name: string;
    path: string;
  };
}

export interface IReportJobParams {
  range: ITimestampRange;
  brandRef: DocumentReference<IBrand>;
  fileType: ReportingJobFileType;
}

export interface IPractitionerFTAReportParams {
  skipPractitioners: DocumentReference<IStaffer>[];
}

export interface IPractitionerGapsJobParams {
  skipPractitioners: DocumentReference<IStaffer>[];
  groupRecordsBy: 'day' | 'week';
}

export type ReportingJob = IReportingJob & ISoftDelete;

export type PractitionerFTAReportJob = ReportingJob & {
  type: ReportingJobType.PractitionerFTAVsUTARates;
  requestParams: IReportJobParams & IPractitionerFTAReportParams;
};

export type PractitionerGapsJob = ReportingJob & {
  type: ReportingJobType.PractitionerGaps;
  requestParams: IReportJobParams & IPractitionerGapsJobParams;
};
