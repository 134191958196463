import {
  AutomationStatus,
  type IAppointment,
  type IPractice,
  PatientCollection,
  IBrand,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  asyncForEach,
  collectionGroupQuery,
  getDoc,
  getDocs,
  patchDoc,
  type RequireProps,
  resolveSequentially,
  snapshot,
  toISODate,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import {
  where,
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { Appointment } from '../models/appointment/appointment';
import { TimezoneResolver } from '../timezone';
import { TreatmentStep } from '../models/clinical-charting/treatment/treatment-step';
import { Automation } from '../models/automation/automation';
import { Brand } from '../models/brand';
import { ILogger } from '@principle-theorem/developer-tools';

export class AddAutomationsToFutureAppointments {
  constructor(private _logger: ILogger) {}

  async run(
    brandRef: DocumentReference<IBrand>,
    startDate: Timestamp
  ): Promise<void> {
    const brand = await getDoc(brandRef);
    const practices = await snapshot(Brand.practices$(brand));
    await asyncForEach(practices, async (practice) => {
      await this._seedPracticeAutomations(brand, practice.ref, startDate);
    });
  }

  private async _seedPracticeAutomations(
    brand: WithRef<IBrand>,
    practiceRef: DocumentReference<IPractice>,
    startDate: Timestamp,
    endDate?: Timestamp
  ): Promise<void> {
    const appointments = await getDocs(
      collectionGroupQuery<RequireProps<IAppointment, 'event'>>(
        PatientCollection.Appointments,
        ...compact([
          where('practice.ref', '==', practiceRef),
          where('event.from', '>=', startDate),
          endDate ? where('event.from', '<=', endDate) : undefined,
        ])
      )
    );

    await resolveSequentially(appointments, async (appointment) => {
      this._logger.info(
        `Processing appointment with date ${toISODate(appointment.event.from)}`
      );
      const treatmentStep = await Appointment.treatmentStep(appointment);
      const timezone = await TimezoneResolver.fromEvent(appointment.event);
      const existingAutomations = await getDocs(
        TreatmentStep.automationCol(treatmentStep)
      );

      if (existingAutomations.length) {
        this._logger.info(
          `Appointment already has ${existingAutomations.length} automations`
        );

        await resolveSequentially(existingAutomations, async (automation) => {
          if (automation.status !== AutomationStatus.Pending) {
            return;
          }

          this._logger.info(`Fixing automation ${automation.ref.path}`);

          await patchDoc(automation.ref, {
            status: AutomationStatus.Cancelled,
          });

          await patchDoc(automation.ref, {
            status: AutomationStatus.Pending,
          });
        });

        return;
      }

      const brandAutomationConfigurations = await snapshot(
        Automation.getAutomations$(brand, appointment.event)
      );

      const treatmentAutomationConfigurations = await snapshot(
        TreatmentStep.getTreatmentConfigurationAutomations$(
          treatmentStep,
          appointment.event,
          brand.ref
        )
      );

      const automations = [
        ...brandAutomationConfigurations,
        ...treatmentAutomationConfigurations,
      ];

      this._logger.info(
        `Adding ${automations.length} automations to step: ${treatmentStep.ref.path}`
      );

      await asyncForEach(automations, async (automation) => {
        const now = moment.tz(timezone);

        const expectedTriggerDate = Automation.getExpectedTriggerDate(
          automation,
          appointment.event,
          timezone
        );

        const triggerDate = Automation.applyGracePeriod(
          expectedTriggerDate,
          timezone,
          now
        );

        if (expectedTriggerDate.isBefore(now, 'day')) {
          this._logger.info(`TriggerDate is before today. Skipping.`);
          return;
        }

        await addDoc(TreatmentStep.automationCol(treatmentStep), {
          ...automation,
          triggerDate: toTimestamp(triggerDate),
        });
      });
    });
  }
}
