<div fxLayout="column">
  <div
    class="layout-padding"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row">
      <h3 class="mat-headline-6">{{ title }}</h3>
      <mat-icon *ngIf="tooltip" [matTooltip]="tooltip">info</mat-icon>
    </div>
    <button mat-stroked-button (click)="downloadCSV()">Download CSV</button>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    fxFlex
    matSort
    matSortActive="issuedAt"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="patientName">
      <th mat-header-cell *matHeaderCellDef>Patient</th>
      <td mat-cell *matCellDef="let record">
        <a [routerLink]="record.patient | patientUrl$ | async" target="_blank">
          {{ record.patient.name }}
        </a>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="issuedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Issued</th>
      <td mat-cell *matCellDef="let record">
        {{ record.accountCredit.createdAt | moment | amDateFormat: dateFormat }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let record">
        <a
          [routerLink]="
            record.accountCredit | map: accountCreditUrl$ : this | async
          "
          target="_blank"
        >
          {{ record.accountCredit.description || 'No Description' }}
        </a>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="reservedFor">
      <th mat-header-cell *matHeaderCellDef>
        Reserved For
        <pt-multi-filter-group
          [compact]="true"
          [statusFilters]="stafferFilters$ | async"
          [activeStatuses]="selectedStaff$ | async"
          (statusFilterChange)="selectedStaff$.next($event)"
        />
      </th>
      <td mat-cell *matCellDef="let record">
        @if (record | map: resolveAccountCredit$ : this | async; as credit) {
          {{ credit.reservedFor?.practitioner?.name }}
        }
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="treatmentCategory">
      <th mat-header-cell *matHeaderCellDef>Treatment Category</th>
      <td mat-cell *matCellDef="let record">
        @if (
          record | map: resolveTreatmentCategory$ : this | async;
          as treatmentCategory
        ) {
          {{ treatmentCategory.name }}
          <div
            class="inline-block h-3 w-3 rounded-full"
            [matTooltip]="treatmentCategory.name"
            [style.backgroundColor]="treatmentCategory.colour.value"
          ></div>
        }
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="invoiceStatus">
      <th mat-header-cell *matHeaderCellDef>
        Invoice Status
        <pt-multi-filter-group
          [compact]="true"
          [statusFilters]="statusFilters$ | async"
          [activeStatuses]="selectedStatuses$ | async"
          (statusFilterChange)="selectedStatuses$.next($event)"
        />
      </th>
      <td mat-cell *matCellDef="let record">
        @if (record.invoice) {
          <pr-invoice-status [dense]="true" [status]="record.invoice.status" />
        }
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="invoice">
      <th mat-header-cell *matHeaderCellDef>Invoice Reference</th>
      <td mat-cell *matCellDef="let record">
        @if (record.invoice) {
          <div fxLayout="column">
            <a
              [routerLink]="record.invoice | invoiceUrl$ | async"
              target="_blank"
            >
              {{ record.invoice.reference }}
            </a>
          </div>
        }
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="transactionAmounts">
      <th mat-header-cell *matHeaderCellDef>
        <pr-value-with-icon>
          Transaction Amount
          <pt-multi-filter-group
            [compact]="true"
            [statusFilters]="providerFilters$ | async"
            [activeStatuses]="selectedProviders$ | async"
            (statusFilterChange)="selectedProviders$.next($event)"
          />
        </pr-value-with-icon>
      </th>
      <td mat-cell *matCellDef="let record">
        <div fxLayout="column">
          <div
            *ngFor="
              let transaction of record.transactions
                | map: filterActiveProviders$ : this
                | async;
              trackBy: trackByTransactionRef
            "
          >
            <pr-value-with-icon [ngClass]="{ refund: transaction.amount < 0 }">
              <div class="transaction-details">
                <small class="mat-caption">
                  ({{ transaction | map: getProviderName }})
                </small>
                {{ transaction.amount | currency }}
              </div>
            </pr-value-with-icon>
          </div>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="accountCreditTotal">
      <th mat-header-cell *matHeaderCellDef>
        <pr-value-with-icon>Account Credit Total</pr-value-with-icon>
      </th>
      <td mat-cell *matCellDef="let record">
        <pr-value-with-icon>
          {{ record.accountCredit.amount | currency }}
        </pr-value-with-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <pr-value-with-icon>
          <strong>{{
            'amount' | map: sumAmount$ : this | async | currency
          }}</strong>
        </pr-value-with-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="accountCreditUsed">
      <th mat-header-cell *matHeaderCellDef>
        <pr-value-with-icon>Account Credit Used</pr-value-with-icon>
      </th>
      <td mat-cell *matCellDef="let record">
        <pr-value-with-icon>
          {{ record.accountCredit.used | currency }}
        </pr-value-with-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <pr-value-with-icon>
          <strong>{{
            'used' | map: sumAmount$ : this | async | currency
          }}</strong>
        </pr-value-with-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="accountCreditRemaining">
      <th mat-header-cell *matHeaderCellDef>
        <pr-value-with-icon>Account Credit Remaining</pr-value-with-icon>
      </th>
      <td mat-cell *matCellDef="let record">
        <pr-value-with-icon>
          {{ record.accountCredit.remaining | currency }}
        </pr-value-with-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <pr-value-with-icon>
          <strong>{{
            'remaining' | map: sumAmount$ : this | async | currency
          }}</strong>
        </pr-value-with-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let record">
        <button mat-icon-button [matMenuTriggerFor]="creditActionsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #creditActionsMenu="matMenu">
          @if (record | map: resolveAccountCredit$ : this | async; as credit) {
            <button
              mat-menu-item
              [disabled]="!(credit | map: creditActions.canSplit : this)"
              (click)="creditActions.split(credit)"
            >
              <mat-icon>call_split</mat-icon>
              <span>Split</span>
            </button>

            <button mat-menu-item (click)="creditActions.edit(credit)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
          }
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons />
</div>
