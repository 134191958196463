<ng-container *ngIf="labJobs$ | async as labJobs">
  <mat-list *ngIf="labJobs.length">
    <pr-lab-job-item
      *ngFor="let labJob of labJobs; trackBy: trackByLabJob"
      [selectDisabled]="multiSelectDisabled$ | async"
      [item]="labJob"
      [selectedItem]="selectedItem"
      (selectedItemChange)="selectItem($event)"
      [selectionList]="selectionList"
     />
  </mat-list>
</ng-container>

<ng-container *ngIf="deletedLabJobs$ | async as deletedLabJobs">
  <mat-list *ngIf="deletedLabJobs.length">
    <div
      mat-subheader
      class="collapsible-subheader"
      (click)="deletedContent.toggle()"
    >
      Deleted
      <mat-icon>
        {{ deletedContent.expanded ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </div>
    <pt-collapsible #deletedContent>
      <ng-template ptCollapsibleContent>
        <pr-lab-job-item
          *ngFor="let labJob of deletedLabJobs; trackBy: trackByLabJob"
          [selectDisabled]="multiSelectDisabled$ | async"
          [item]="labJob"
          [selectedItem]="selectedItem"
          (selectedItemChange)="selectItem($event)"
          [selectionList]="selectionList"
         />
      </ng-template>
    </pt-collapsible>
  </mat-list>
</ng-container>
