import {
  ITransaction,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuardFn } from '@principle-theorem/shared';
import { first, last, sum, uniq } from 'lodash';

type FilterPredicate<T> =
  | ((value: T, index: number, array: T[]) => value is T)
  | ((value: T, index: number, array: T[]) => unknown);

export class TransactionOperators<T extends ITransaction> {
  constructor(private _transactions: T[]) {}

  byReference(reference: string): TransactionOperators<T> {
    return this.filter((transaction) => transaction.reference === reference);
  }

  groupByReference(): TransactionOperators<T>[] {
    const references = uniq(this._transactions.map((item) => item.reference));
    return references.map((reference) => this.byReference(reference));
  }

  transactionGuard<R extends ITransaction>(
    typeGuardFn: TypeGuardFn<R>
  ): TransactionOperators<R> {
    const filtered = this._transactions.filter((transaction) =>
      typeGuardFn(transaction)
    );
    return new TransactionOperators<R>(filtered as unknown as R[]);
  }

  extendedDataGuard<L extends ITransaction<G>, G>(
    typeGuardFn: TypeGuardFn<G>
  ): TransactionOperators<L> {
    const filtered = this._transactions.filter((transaction) =>
      typeGuardFn(transaction.extendedData)
    );
    return new TransactionOperators<L>(filtered as unknown as L[]);
  }

  incoming(): TransactionOperators<T> {
    return this.byType(TransactionType.Incoming);
  }

  claim(): TransactionOperators<T> {
    return this.byType(TransactionType.Claim);
  }

  outgoing(): TransactionOperators<T> {
    return this.byType(TransactionType.Outgoing);
  }

  byType(type: string | string[]): TransactionOperators<T> {
    const types = Array.isArray(type) ? type : [type];
    return this.filter((transaction) => types.includes(transaction.type));
  }

  byProvider(provider: string | string[]): TransactionOperators<T> {
    const providers = Array.isArray(provider) ? provider : [provider];
    return this.filter((transaction) =>
      providers.includes(transaction.provider)
    );
  }

  completed(): TransactionOperators<T> {
    return this.byStatus(TransactionStatus.Complete);
  }

  pending(): TransactionOperators<T> {
    return this.byStatus(TransactionStatus.Pending);
  }

  failed(): TransactionOperators<T> {
    return this.byStatus(TransactionStatus.Failed);
  }

  byStatus(status: TransactionStatus): TransactionOperators<T> {
    return this.filter((transaction) => transaction.status === status);
  }

  sort(
    compareFn?: ((a: T, b: T) => number) | undefined
  ): TransactionOperators<T> {
    return new TransactionOperators(this._transactions.sort(compareFn));
  }

  reverse(): TransactionOperators<T> {
    return new TransactionOperators(this._transactions.reverse());
  }

  filter(predicate: FilterPredicate<T>): TransactionOperators<T> {
    return new TransactionOperators(this._transactions.filter(predicate));
  }

  sum(): number {
    return sum(this._transactions.map((item) => item.amount));
  }

  count(): number {
    return this._transactions.length;
  }

  paidToDate(): number {
    const completed = this.completed();
    return (
      completed.claim().sum() +
      completed.incoming().sum() -
      completed.outgoing().sum()
    );
  }

  benefitReceived(): number {
    const completed = this.completed();
    const claimReceived = completed.claim().sum() + completed.incoming().sum();
    return claimReceived - completed.outgoing().sum();
  }

  result(): T[] {
    return this._transactions;
  }

  first(): T | undefined {
    return first(this._transactions);
  }

  last(): T | undefined {
    return last(this._transactions);
  }
}
