<mat-form-field
  class="w-full"
  subscriptSizing="dynamic"
  [appearance]="appearance"
>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="selected"
    [compareWith]="compareFn"
    [disabled]="disabled"
  >
    <mat-option
      *ngFor="let staffer of staff$ | async; trackBy: trackByStaffer"
      [value]="staffer"
    >
      <pr-staffer-display [staffer]="staffer" />
    </mat-option>
  </mat-select>
</mat-form-field>
