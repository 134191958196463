<div class="layout-padding practitioner-income-spacer">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2>Select a practitioner</h2>
    <button mat-stroked-button (click)="downloadCSV()">Download CSV</button>
  </div>
</div>

<div class="overflow-x-scroll">
  <pr-practitioner-transactions-report-summary-table
    [summaries]="practitionerSummaries$ | async"
    [footerData]="practitionerSubtotalFooterData$ | async"
    [showHeaders]="true"
  />

  <pr-practitioner-transactions-report-summary-table
    class="mt-4"
    [summaries]="unallocatedSummaries$ | async"
    [footerData]="totalFooterData$ | async"
    [showHeaders]="false"
  />
</div>
