import { rand, randWord } from '@ngneat/falso';
import {
  IBrand,
  IBrandSettings,
  ISchedulingEventReason,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  DocumentReference,
  Properties,
  TIMEZONES,
} from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';

export class BrandMock extends BaseFirestoreMock implements Properties<IBrand> {
  get name(): string {
    return randWord();
  }

  get slug(): string {
    return uuid();
  }

  get settings(): IBrandSettings {
    return {
      timezone: rand(TIMEZONES),
    };
  }

  get defaultSchedulingEventReasonOrder(): DocumentReference<ISchedulingEventReason>[] {
    return [];
  }
}
