@if (section$ | async; as section) {
  <div fxLayout="row wrap">
    @if (section.charts.length >= 1) {
      @for (
        display of displays$ | async;
        track display.chart.uid;
        let index = $index;
        let isFirst = $first;
        let isLast = $last
      ) {
        <pr-report-builder-chart
          [display]="display"
          [sectionName]="section.name"
          [readOnly]="(store.editMode$ | async) === false"
        >
          @if (store.editMode$ | async) {
            <ng-container ngProjectAs="report-builder-chart-action">
              <div class="edit-buttons">
                <button
                  mat-icon-button
                  matTooltip="Duplicate Chart"
                  (click)="store.duplicateChart(display.chart, section.name)"
                >
                  <mat-icon>content_copy</mat-icon>
                </button>
                <button
                  mat-icon-button
                  [disabled]="isFirst"
                  matTooltip="Move Chart Left"
                  (click)="store.moveChartLeft(display.chart, section.name)"
                >
                  <mat-icon>arrow_back</mat-icon>
                </button>
                <button
                  mat-icon-button
                  [disabled]="isLast"
                  matTooltip="Move Chart Right"
                  (click)="store.moveChartRight(display.chart, section.name)"
                >
                  <mat-icon>arrow_forward</mat-icon>
                </button>
                @if (display | map: isTable) {
                  <button
                    mat-icon-button
                    color="accent"
                    matTooltip="Edit Table Name"
                    (click)="store.editTable(display.chart, section.name)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                } @else {
                  <button
                    mat-icon-button
                    color="accent"
                    matTooltip="Edit Chart"
                    (click)="store.editChart(display.chart, section.name)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                }
                <button
                  mat-icon-button
                  color="warn"
                  matTooltip="Remove Chart"
                  (click)="store.deleteChart(display.chart)"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </ng-container>
          } @else {
            @if (display | map: isTable) {
              <button mat-stroked-button (click)="downloadCSV(display)">
                Download CSV
              </button>
            }
          }
        </pr-report-builder-chart>
        @if ((store.editMode$ | async) && !isLast) {
          <div
            class="border-primary-400 mb-2 mr-2 flex cursor-pointer flex-col self-stretch rounded-lg border-2 border-dashed p-1 opacity-50 hover:opacity-100"
            matTooltip="Add New Chart"
            matTooltipPosition="right"
            (click)="addChartAtIndex.emit({ index })"
          >
            <div class="flex-1"></div>
            <button mat-icon-button color="primary">
              <mat-icon>add_chart</mat-icon>
            </button>
            <div class="flex-1"></div>
          </div>
        }
      }
    }

    @if (store.editMode$ | async) {
      <div
        class="border-primary-400 mb-2 mr-2 flex min-h-[300px] w-[294px] cursor-pointer flex-col items-center justify-center self-stretch rounded-lg border-2 border-dashed p-4 opacity-50 hover:opacity-100"
        (click)="addChartAtIndex.emit({ index: section.charts.length })"
      >
        <button mat-button color="primary">
          <mat-icon>add_chart</mat-icon> Add New Chart
        </button>
      </div>
    }
  </div>
}
