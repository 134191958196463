import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CheckinAppointmentActionService,
  CheckoutAppointmentActionService,
  CompleteAppointmentActionService,
  ConfirmAppointmentActionService,
  IAppointmentCardAction,
  OpenAppointmentActionService,
  StartAppointmentActionService,
  type AppointmentCardAction,
} from '@principle-theorem/ng-appointment';
import { CalendarFacade } from '@principle-theorem/ng-calendar/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EventableTimelineStore } from '@principle-theorem/ng-eventable';
import {
  CurrentPracticeScope,
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { queryParam$ } from '@principle-theorem/ng-shared';
import {
  Appointment,
  AppointmentGrouper,
  EventableQueries,
} from '@principle-theorem/principle-core';
import {
  AppointmentSummary,
  DayEventMap,
  EventType,
  IScheduleSummaryEventable,
  isAppointmentSummary,
  type IAppointment,
  type IAppointmentDateGroup,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_FORMAT,
  CASUAL_DATE_WITH_YEAR,
  ISO_DATE_FORMAT,
  asDocRef,
  filterUndefined,
  getDoc,
  getEnumValues,
  isChanged$,
  isSameRange,
  isSameRef,
  shareReplayCold,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, omit } from 'lodash';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  type Observable,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';

type PanelSelectEvent =
  | {
      action: 'deselected';
    }
  | {
      action: 'selected';
      id: string;
    };

@Component({
  selector: 'pr-dashboard-appointments',
  templateUrl: './dashboard-appointments.component.html',
  styleUrls: ['./dashboard-appointments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EventableTimelineStore],
})
export class DashboardAppointmentsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  expanded$ = new BehaviorSubject<string | undefined>(undefined);
  selectedAppointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  selectionChange$ = new ReplaySubject<PanelSelectEvent>(1);
  date$ = new BehaviorSubject<Date>(new Date());
  headlineFormat = CASUAL_DATE_WITH_YEAR;
  dateGroups$: Observable<IAppointmentDateGroup[]>;
  actions: AppointmentCardAction[];
  showTableOfContents$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    confirmAppointment: ConfirmAppointmentActionService,
    checkoutAppointment: CheckoutAppointmentActionService,
    checkinAppointment: CheckinAppointmentActionService,
    startAppointment: StartAppointmentActionService,
    openAppointment: OpenAppointmentActionService,
    finishAppointment: CompleteAppointmentActionService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _location: Location,
    private _eventableTimelineStore: EventableTimelineStore,
    private _practiceScope: CurrentPracticeScope,
    private _organisation: OrganisationService,
    private _calendarFacade: CalendarFacade,
    private _globalStore: GlobalStoreService
  ) {
    this.actions = [
      confirmAppointment,
      checkoutAppointment,
      checkinAppointment,
      startAppointment,
      openAppointment,
      finishAppointment,
    ];

    this.dateGroups$ = this._eventableTimelineStore.events$.pipe(
      map((events) => this._eventsToDateGroups(events)),
      shareReplayCold()
    );

    queryParam$(this._route, 'id')
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((id) => this.expanded$.next(id));

    this.selectionChange$
      .pipe(
        throttleTime(500),
        withLatestFrom(this._route.queryParams),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([event, queryParams]) => {
        const urlTree = this._router.createUrlTree([], {
          relativeTo: this._route,
          queryParams: {
            ...omit(queryParams, 'id'),
            id: event.action === 'selected' ? event.id : undefined,
          },
        });

        this._location.go(urlTree.toString());
      });

    this.showTableOfContents$ = this._eventableTimelineStore.dateRange$.pipe(
      map((range) => !range.from.isSame(range.to, 'day'))
    );

    const events$ = combineLatest([
      this._calendarFacade.range$.pipe(distinctUntilChanged(isSameRange)),
      this._practiceScope.doc$.pipe(filterUndefined()),
      this._organisation.practicePractitioners$.pipe(isChanged$(isSameRef)),
      this._globalStore.rosterSchedules$,
      this._calendarFacade.unit$,
      this._calendarFacade.view$,
    ]).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(
        ([dateRange, practice, selectedStaff, allStaffSchedules, unit, view]) =>
          EventableQueries.getScheduleSummaryEventables$(
            dateRange,
            practice,
            selectedStaff,
            allStaffSchedules,
            unit,
            view,
            getEnumValues(EventType).filter(
              (type) => ![EventType.Appointment].includes(type)
            )
          )
      ),
      tap(() => this.loading$.next(false)),
      shareReplayCold()
    );

    combineLatest([events$, this._calendarFacade.range$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([events, dateRange]) =>
        this._eventableTimelineStore.loadEvents({ events, dateRange })
      );
  }

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this._practice$.next(practice);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async dateChange(date: Date): Promise<void> {
    await this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        date: moment(date).format(ISO_DATE_FORMAT),
      },
    });
  }

  resetExpanded(): void {
    this.selectionChange$.next({
      action: 'deselected',
    });
  }

  async setExpanded(event: AppointmentSummary): Promise<void> {
    if (!event.ref) {
      return;
    }
    const appointment = await getDoc(asDocRef<IAppointment>(event.ref));
    this.selectedAppointment$.next(appointment);

    this.selectionChange$.next({
      action: 'selected',
      id: appointment.ref.id,
    });
  }

  isExpanded(
    expanded: string | undefined,
    appointment: AppointmentSummary
  ): boolean {
    return expanded === appointment.ref?.id;
  }

  statusColour(event: AppointmentSummary): string {
    return Appointment.statusColour(event.metadata.status);
  }

  statusTooltip(event: AppointmentSummary): string {
    return Appointment.statusTooltip(event.metadata.status);
  }

  updateEventable(
    action: IAppointmentCardAction,
    eventable: AppointmentSummary
  ): void {
    const updateFn = (
      oldEvent: IScheduleSummaryEventable,
      newEvent: IScheduleSummaryEventable
    ): void => this._eventableTimelineStore.updateEvent(oldEvent, newEvent);

    action.updateEventableSummary(eventable, updateFn);
  }

  private _eventsToDateGroups(
    events: DayEventMap<IScheduleSummaryEventable>
  ): IAppointmentDateGroup[] {
    return Object.entries(events).map(([day, summaries]) => {
      const appointments = this._getAppointmentSummaries(compact(summaries));
      return {
        id: day,
        name: toMoment(day).format(CASUAL_DATE_FORMAT),
        groups: new AppointmentGrouper().getByGroup(appointments),
      };
    });
  }

  private _getAppointmentSummaries(
    events: IScheduleSummaryEventable<object>[]
  ): AppointmentSummary[] {
    return events.filter(
      (summary) => isAppointmentSummary(summary) && !!summary.ref
    ) as AppointmentSummary[];
  }
}
