<ng-container *ngIf="appointment$ | ngrxPush as appointment">
  <mat-toolbar color="accent">
    <span class="truncate" fxFlex>
      {{ appointment.treatmentPlan.name }}
      <ng-container *ngIf="appointment.treatmentPlan.treatmentStep.name">
        - {{ appointment.treatmentPlan.treatmentStep.name }}
      </ng-container>
    </span>
    <pr-appointment-menu
      [appointment]="appointment"
      (appointmentReschedule)="close()"
      (appointmentCancel)="close()"
    />
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <pr-appointment-card [appointment]="appointment" />
</ng-container>
