import { getSchemaText, initVersionedSchema } from '@principle-theorem/editor';
import {
  IClinicalNote,
  IPatient,
  IStaffer,
  PatientCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  IReffable,
  ISODateType,
  WithRef,
  addDoc,
  getDoc,
  isSameRef,
  query$,
  subCollection,
  toTimestamp,
  undeletedQuery,
} from '@principle-theorem/shared';
import { CollectionReference, where } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { stafferToNamedDoc } from '../../common';

export class ClinicalNote {
  static init(
    overrides: AtLeast<IClinicalNote, 'owner' | 'recordDate'>
  ): IClinicalNote {
    return {
      uid: uuid(),
      content: initVersionedSchema(),
      immutable: false,
      createdAt: toTimestamp(),
      updatedAt: toTimestamp(),
      deleted: false,
      ...overrides,
    };
  }

  static col(patient: IReffable<IPatient>): CollectionReference<IClinicalNote> {
    return subCollection<IClinicalNote>(
      patient.ref,
      PatientCollection.ClinicalNotes
    );
  }

  static all$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IClinicalNote>[]> {
    return query$(undeletedQuery(ClinicalNote.col(patient)));
  }

  static filterEmpty(
    clinicalNotes: WithRef<IClinicalNote>[]
  ): WithRef<IClinicalNote>[] {
    return clinicalNotes.filter((note) => {
      return getSchemaText(note.content).trim().length > 0;
    });
  }

  static getEditableNote$(
    patient: WithRef<IPatient>,
    staffer: WithRef<IStaffer>,
    recordDate: ISODateType
  ): Observable<WithRef<IClinicalNote> | undefined> {
    return query$(
      undeletedQuery(ClinicalNote.col(patient)),
      where('recordDate', '==', recordDate)
    ).pipe(
      map((notes) => notes.find((note) => isSameRef(note.owner, staffer))),
      tap((note) => {
        if (!note) {
          void ClinicalNote.create(patient, staffer, recordDate);
        }
      })
    );
  }

  static async create(
    patient: WithRef<IPatient>,
    staffer: WithRef<IStaffer>,
    recordDate: ISODateType
  ): Promise<WithRef<IClinicalNote>> {
    const noteRef = await addDoc(
      ClinicalNote.col(patient),
      ClinicalNote.init({
        owner: stafferToNamedDoc(staffer),
        recordDate,
      })
    );
    return getDoc(noteRef);
  }
}
