import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  PatientStatus,
  type IPatient,
  type ISuggestedPatientData,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  getDoc,
  guardFilter,
  isSameRef,
  snapshot,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, isNil, isString } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  Subject,
  of,
} from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { PatientSelectorStore } from './patient-selector.store';
import {
  Patient,
  convertMobilePrefix,
  ITypesensePatientWithRef,
} from '@principle-theorem/principle-core';

@Component({
  selector: 'pr-patient-selector',
  templateUrl: './patient-selector.component.html',
  styleUrls: ['./patient-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatientSelectorComponent),
      multi: true,
    },
  ],
})
export class PatientSelectorComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  private _onChange: (patient?: WithRef<IPatient> | string) => void;
  isRequired$ = new BehaviorSubject<boolean>(false);
  emitEvent$ = new BehaviorSubject<boolean>(true);
  searchCtrl = new TypedFormControl<string | ITypesensePatientWithRef>();
  searchFilter: InputSearchFilter<ITypesensePatientWithRef>;
  filteredPatients$: Observable<ITypesensePatientWithRef[]>;
  trackByPatient = TrackByFunctions.ref<ITypesensePatientWithRef>();
  filterPatient$ = new BehaviorSubject<WithRef<IPatient> | undefined>(
    undefined
  );
  suggestedPatientData$ = new BehaviorSubject<
    ISuggestedPatientData | undefined
  >(undefined);
  suggestedPatients$ = new BehaviorSubject<ITypesensePatientWithRef[]>([]);
  selectedPatient$ = new BehaviorSubject<WithRef<IPatient> | undefined>(
    undefined
  );
  similarQuery$ = new BehaviorSubject<string>('');
  showSuggestions$: Observable<boolean>;

  @Output() clear = new EventEmitter<void>();
  @Input() placeholder: string = 'Patient';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() disableAllPatients = false;
  @Input() matHint?: string;
  @Input() label?: string;

  @Input()
  set suggestedPatientData(patientData: ISuggestedPatientData) {
    if (patientData) {
      this.suggestedPatientData$.next(patientData);
      this.similarQuery$.next(this._patientDataToSimilarQuery(patientData));
    }
  }

  @Input()
  set filterPatient(filterPatient: WithRef<IPatient>) {
    if (filterPatient) {
      this.filterPatient$.next(filterPatient);
    }
  }

  @Input()
  set required(required: boolean) {
    if (!isNil(required)) {
      this.isRequired$.next(required);
    }
  }

  @Input()
  set emitEvent(emitEvent: boolean) {
    if (!isNil(emitEvent)) {
      this.emitEvent$.next(emitEvent);
    }
  }

  @Input()
  set selectedPatient(patient: WithRef<IPatient> | undefined) {
    this.selectedPatient$.next(patient);
  }

  constructor(private _store: PatientSelectorStore) {
    this._store.loadPatients(
      this.searchCtrl.valueChanges.pipe(guardFilter(isString))
    );
    this._store.loadSuggestedPatients(this.similarQuery$);

    this.showSuggestions$ = combineLatest([
      this.selectedPatient$,
      this.suggestedPatients$,
    ]).pipe(
      map(
        ([selectedPatient, suggestedPatients]) =>
          !selectedPatient && !!suggestedPatients.length
      )
    );

    combineLatest([this._store.suggestedPatients$, this.suggestedPatientData$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([suggestedPatients, patientData]) => {
        if (!patientData) {
          this.suggestedPatients$.next([]);
          return;
        }

        const dateOfBirth = patientData.dateOfBirth;
        if (!dateOfBirth) {
          this.suggestedPatients$.next(suggestedPatients);
          return;
        }

        this.suggestedPatients$.next(
          suggestedPatients.filter((patient) => {
            if (!patient.dateOfBirth) {
              return true;
            }
            return toMoment(patient.dateOfBirth).isSame(toMoment(dateOfBirth));
          })
        );
      });

    this.filteredPatients$ = combineLatest([
      this._store.patients$,
      this.filterPatient$,
    ]).pipe(
      map(([patients, filterPatient]) =>
        this.disableAllPatients
          ? []
          : filterPatient
            ? patients.filter((patient) => !isSameRef(patient, filterPatient))
            : patients
      )
    );

    this.searchCtrl.valueChanges
      .pipe(
        switchMap((value) => (isString(value) ? of(value) : getDoc(value.ref))),
        takeUntil(this._onDestroy$)
      )
      .subscribe((newValue) => {
        if (this._onChange) {
          this._onChange(newValue);
        }
      });
  }

  ngOnDestroy(): void {
    this._store.patchState({ suggestedPatients: [] });
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: string): void {
    this.searchCtrl.setValue(value ?? '', { emitEvent: this.emitEvent$.value });
  }

  registerOnChange(fn: () => void): void {
    this._onChange = fn;
  }

  registerOnTouched(_fn: () => void): void {
    //
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchCtrl.disable();
      return;
    }
    this.searchCtrl.enable();
  }

  displayFn(value: string | WithRef<IPatient>): string {
    if (!value) {
      return '';
    }
    if (!isString(value)) {
      return value.name;
    }
    return value;
  }

  clearUser(): void {
    this.searchCtrl.setValue('');
    this.clear.emit();
  }

  async selectFirstOption(): Promise<void> {
    const firstOption = await snapshot(
      this.searchFilter.results$.pipe(map((options) => first(options)))
    );
    if (firstOption) {
      this.searchCtrl.setValue(firstOption);
    }
  }

  isDisabledPatient(status: PatientStatus): boolean {
    return Patient.isDisabledPatient(status);
  }

  private _patientDataToSimilarQuery(
    patientData: ISuggestedPatientData
  ): string {
    const contactNumbers = compact(
      patientData.contactNumbers?.map((contactNumber) =>
        contactNumber.number
          ? convertMobilePrefix(contactNumber.number)
          : undefined
      )
    );

    const queryParams = compact([
      !patientData.name || patientData.name.length < 3
        ? undefined
        : patientData.name,
      !!patientData.dateOfBirth &&
        toMoment(patientData.dateOfBirth).format(DAY_MONTH_YEAR_FORMAT),
      !!patientData.mobileNumber &&
        convertMobilePrefix(patientData.mobileNumber),
      !!contactNumbers.length && contactNumbers.join(' '),
    ]);

    return queryParams.length ? queryParams.join(' ') : '';
  }
}
