import {
  IPatient,
  IPatientRelationship,
  PatientRelationshipType,
  RELATIONSHIP_TYPE_MAP,
  WithPrimaryContact,
  isPatientWithPrimaryContact,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  INamedDocument,
  WithRef,
  asyncForEach,
  isSameRef,
  snapshot,
  toNamedDocument,
} from '@principle-theorem/shared';
import { Patient, hasValidContactDetails } from './patient';
import { OrganisationCache } from '../organisation/organisation-cache';

export class PatientRelationship {
  static async addRelationshipBackReference(
    patient: WithRef<IPatient>,
    relationship: IPatientRelationship
  ): Promise<void> {
    const backReference = PatientRelationship.getInverseRelationship(
      patient,
      relationship
    );
    if (!backReference) {
      return;
    }
    const otherPatient = await OrganisationCache.patients.getDoc(
      relationship.patient.ref
    );
    Patient.upsertRelationship(otherPatient, backReference);
    await Firestore.patchDoc(otherPatient.ref, {
      relationships: otherPatient.relationships,
    });
  }

  static async addRelationshipBackReferences(
    patient: WithRef<IPatient>
  ): Promise<void> {
    await asyncForEach(patient.relationships, (relationship) =>
      PatientRelationship.addRelationshipBackReference(patient, relationship)
    );
  }

  static async addPrimaryRelationship(
    patient: WithRef<IPatient>
  ): Promise<void> {
    if (!isPatientWithPrimaryContact(patient)) {
      return;
    }
    const backReference = PatientRelationship.getInverseRelationship(
      patient,
      patient.primaryContact
    );

    if (!backReference) {
      return;
    }
    const primaryContact = await snapshot(Patient.primaryContact$(patient));
    Patient.upsertRelationship(primaryContact, backReference);

    await Firestore.patchDoc(primaryContact.ref, {
      relationships: primaryContact.relationships,
      isPrimaryContact: true,
    });
  }

  static async removePrimaryRelationship(
    patient: WithRef<IPatient>
  ): Promise<void> {
    if (!isPatientWithPrimaryContact(patient)) {
      return;
    }

    const primaryContact = await snapshot(Patient.primaryContact$(patient));
    const relationships = primaryContact.relationships.filter(
      (relationship) => !isSameRef(relationship.patient, patient)
    );
    await Firestore.patchDoc(primaryContact.ref, { relationships });
  }

  static async removePrimaryContact(
    patient: WithRef<IPatient> & WithPrimaryContact
  ): Promise<void> {
    if (!patient.primaryContact) {
      return;
    }
    if (!hasValidContactDetails(patient)) {
      throw new Error(`Patient ${patient.ref.id} has no valid contact details`);
    }
    await PatientRelationship.removePrimaryRelationship(patient);
    await Firestore.saveDoc({
      ...patient,
      primaryContact: undefined,
    } as WithRef<IPatient>);
  }

  static getInverseRelationshipType(
    relationship: PatientRelationshipType
  ): PatientRelationshipType | undefined {
    return RELATIONSHIP_TYPE_MAP.find(
      (relationshipMap) => relationshipMap.type === relationship
    )?.inverse;
  }

  static getInverseRelationship(
    patient: INamedDocument<IPatient>,
    relationship: IPatientRelationship
  ): IPatientRelationship | undefined {
    const type = PatientRelationship.getInverseRelationshipType(
      relationship.type
    );
    if (!type) {
      return;
    }
    return {
      patient: toNamedDocument(patient),
      type,
    };
  }
}
