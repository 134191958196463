import {
  type IFirestoreModel,
  type INamedDocument,
  type INamedTypeDocument,
} from '@principle-theorem/shared';
import { type IPractice } from '../../practice/practice';
import { type IStaffer } from '../../staffer/staffer';
import { type IMultiTreatmentConfiguration } from './multi-treatment-configuration';
import { type ITreatmentConfiguration } from './treatment-configuration';

export interface ITreatmentTemplate extends IFirestoreModel {
  name: string;
  price: number;
  isPublic: boolean;
  enabledPractices: IEnabledPractice[];
  implementedBy: IImplementsTreatmentTemplate[];
}

export interface IEnabledPractice {
  practice: INamedDocument<IPractice>;
  priceOverride?: number;
}

export enum TreatmentTemplateType {
  Treatment = 'treatment',
  MultiTreatment = 'multiTreatment',
}

export type TreatmentTemplateTreatments = INamedTypeDocument<
  IMultiTreatmentConfiguration | ITreatmentConfiguration,
  TreatmentTemplateType
>;

export interface IImplementsTreatmentTemplate {
  staffer: INamedDocument<IStaffer>;
  treatment: TreatmentTemplateTreatments;
  duration: number;
}

export interface ITreatmentTemplateDurationRange {
  minDuration: number;
  maxDuration: number;
}
