import {
  CANCELLATION_REASON_METADATA_CONFIGURATION,
  IBrand,
  ISchedulingEventConditions,
  ISchedulingEventReason,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  DocumentReference,
  Firestore,
  IReffable,
  WithRef,
  isSameRef,
  slugify,
} from '@principle-theorem/shared';
import { compact, sortBy } from 'lodash';

export class SchedulingEventReason {
  static init(
    overrides: AtLeast<
      ISchedulingEventReason,
      'metadataDisplayRef' | 'name' | 'metadataKey'
    >
  ): ISchedulingEventReason {
    return {
      deleted: false,
      description: '',
      eventTypes: [],
      hrsBeforeAppointment: undefined,
      isSystemReason: false,
      moveToSameDayDefault: false,
      fillGapDefault: false,
      ...overrides,
    };
  }

  static brandRef(
    schedulingEventReason: IReffable<ISchedulingEventReason>
  ): DocumentReference<IBrand> {
    return Firestore.getParentDocRef<IBrand>(schedulingEventReason.ref);
  }

  static sortByRefArray(
    reasons: WithRef<ISchedulingEventReason>[],
    refs: DocumentReference<ISchedulingEventReason>[] = []
  ): WithRef<ISchedulingEventReason>[] {
    return sortBy(reasons, (reason) => {
      const orderIndex = refs.findIndex((ref) => isSameRef(reason.ref, ref));
      return orderIndex === -1 ? reasons.length : orderIndex;
    });
  }

  static getMetadataKey(name: string): string {
    const reasonMetadataKey = slugify(name);
    const cancellationMetadataKey =
      CANCELLATION_REASON_METADATA_CONFIGURATION.key;
    return `${cancellationMetadataKey}.${reasonMetadataKey}`;
  }

  static conditionsDisplay(reason: ISchedulingEventReason): string {
    const hrsBefore = reason.hrsBeforeAppointment
      ? `less than ${reason.hrsBeforeAppointment} hrs before`
      : undefined;
    const moveToSameDayDefault = reason.moveToSameDayDefault
      ? 'always use when rescheduling to same day'
      : undefined;
    const fillGapDefault = reason.fillGapDefault
      ? 'always use when filling a gap'
      : undefined;
    return compact([hrsBefore, moveToSameDayDefault, fillGapDefault]).join(
      ', or '
    );
  }

  static filterByEventType(
    reasons: WithRef<ISchedulingEventReason>[],
    eventType: SchedulingEventType
  ): WithRef<ISchedulingEventReason>[] {
    if (eventType === SchedulingEventType.Schedule) {
      return [];
    }
    return reasons.filter((reason) => reason.eventTypes.includes(eventType));
  }

  static sortReasonsByDefaults(
    reasons: WithRef<ISchedulingEventReason>[],
    schedulingConditions: ISchedulingEventConditions
  ): WithRef<ISchedulingEventReason>[] {
    if (schedulingConditions.fillGapDefault) {
      return sortBy(reasons, 'fillGapDefault').reverse();
    }
    if (schedulingConditions.moveToSameDayDefault) {
      return sortBy(reasons, 'moveToSameDayDefault').reverse();
    }
    return sortBy(reasons, 'hrsBeforeAppointment');
  }

  static determineDefaultReason(
    reasons: WithRef<ISchedulingEventReason>[],
    schedulingConditions: ISchedulingEventConditions
  ): WithRef<ISchedulingEventReason> | undefined {
    const sortedReasons = this.sortReasonsByDefaults(
      reasons,
      schedulingConditions
    );

    return this.filterByEventType(
      sortedReasons,
      schedulingConditions.eventType
    ).find((reason) => {
      return this.reasonMatchesConditions(reason, schedulingConditions);
    });
  }

  static reasonMatchesConditions(
    reason: WithRef<ISchedulingEventReason>,
    schedulingConditions: ISchedulingEventConditions
  ): boolean {
    if (meetsFillingGapCondition(reason, schedulingConditions)) {
      return true;
    }
    if (meetsMoveToSameDayCondition(reason, schedulingConditions)) {
      return true;
    }
    if (
      reason.hrsBeforeAppointment === undefined &&
      !reason.moveToSameDayDefault &&
      !reason.fillGapDefault
    ) {
      return true;
    }
    if (
      reason.hrsBeforeAppointment !== undefined &&
      meetsHrsBeforeCondition(reason, schedulingConditions)
    ) {
      return true;
    }
    return false;
  }
}

function meetsHrsBeforeCondition(
  reason: WithRef<ISchedulingEventReason>,
  schedulingConditions: ISchedulingEventConditions
): boolean {
  if (!reason.hrsBeforeAppointment) {
    return true;
  }
  if (schedulingConditions.hrsBeforeAppointmentScheduledFor) {
    return (
      reason.hrsBeforeAppointment >
      schedulingConditions.hrsBeforeAppointmentScheduledFor
    );
  }
  return false;
}

function meetsMoveToSameDayCondition(
  reason: WithRef<ISchedulingEventReason>,
  schedulingConditions: ISchedulingEventConditions
): boolean {
  return (
    reason.moveToSameDayDefault && schedulingConditions.moveToSameDayDefault
  );
}

function meetsFillingGapCondition(
  reason: WithRef<ISchedulingEventReason>,
  schedulingConditions: ISchedulingEventConditions
): boolean {
  return reason.fillGapDefault && schedulingConditions.fillGapDefault;
}
