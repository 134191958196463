<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Account Credits"
  class="bg-white"
/>
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding">
  <h2 class="layout-margin">Account Credits</h2>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="layout-margin"
  >
    <pt-date-range-form
      [useRangePicker]="true"
      [from]="from"
      [to]="to"
      (changed)="updateDateRange($event)"
    />
    <pr-practice-selector
      subscriptSizing="dynamic"
      [brands]="brands$ | async"
      [practice]="practice$ | async"
      (practiceSelected)="practice$.next($event)"
    />
  </div>

  <div class="layout-padding">
    <pr-account-credits-report-table
      fxFlex
      *ngIf="(store.loading$ | async) === false; else loading"
      [records]="store.records$ | async"
      [dateRange]="dataBuilder.dateChange | async"
    />
  </div>
</div>

<ng-template #loading>
  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner mode="indeterminate" />
  </div>
</ng-template>
