import { Injectable, inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { type IPrincipleState } from '@principle-theorem/ng-principle-shared';
import {
  LabJobStatus,
  type ILabJob,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  serialise,
  unserialise$,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { Subject, combineLatest, type Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  ACTIVE_LAB_JOBS_FILTER,
  LAB_JOB_PRESET_FILTERS,
  LabJobPresetFilter,
  type ILabJobFilter,
} from '../lab-job-preset-filters';
import {
  LAB_JOB_DUE_DATE_SORT,
  LAB_JOB_SORT_OPTIONS,
  LabJobSortId,
  type ILabJobSortOption,
} from '../lab-job-sort-options';
import * as LabJobActions from './lab-jobs.actions';
import { type ILabJobsPartialState } from './lab-jobs.reducer';
import * as LabJobSelectors from './lab-jobs.selectors';

@Injectable()
export class LabJobsFacade {
  private _store = inject(Store<ILabJobsPartialState & IPrincipleState>);

  watchLabJobs$ = new Subject<boolean>();
  startWatching$: Observable<boolean>;
  stopWatching$: Observable<boolean>;

  statusFilterParam$: Observable<string | undefined>;
  activeSortParam$: Observable<string | undefined>;
  presetFilterParam$: Observable<string | undefined>;
  labJobIdParam$: Observable<string | undefined>;

  statusFilter$: Observable<LabJobStatus>;
  activeSort$: Observable<LabJobSortId>;
  activePresetFilter$: Observable<LabJobPresetFilter>;

  presetFilter$: Observable<ILabJobFilter>;
  activeSortOption$: Observable<ILabJobSortOption>;

  labJobsLoaded$: Observable<boolean>;
  selectedLabJob$: Observable<WithRef<ILabJob> | undefined>;
  labJobs$: Observable<WithRef<ILabJob>[]>;
  filteredLabJobs$: Observable<WithRef<ILabJob>[]>;

  constructor() {
    this.startWatching$ = this.watchLabJobs$.pipe(
      filter((watching) => !!watching)
    );
    this.stopWatching$ = this.watchLabJobs$.pipe(
      filter((watching) => !watching)
    );
    this.statusFilterParam$ = this._store.pipe(
      select(LabJobSelectors.getStatusFilterParam)
    );
    this.activeSortParam$ = this._store.pipe(
      select(LabJobSelectors.getSortParam)
    );
    this.presetFilterParam$ = this._store.pipe(
      select(LabJobSelectors.getPresetFilterParam)
    );
    this.labJobIdParam$ = this._store.pipe(
      select(LabJobSelectors.getLabJobIdParam)
    );
    this.statusFilter$ = this._store.pipe(
      select(LabJobSelectors.getStatusFilter)
    );
    this.activeSort$ = this._store.pipe(select(LabJobSelectors.getSortOption));
    this.activePresetFilter$ = this._store.pipe(
      select(LabJobSelectors.getPresetFilter)
    );
    this.presetFilter$ = this.activePresetFilter$.pipe(
      map((filterId) => {
        const found = LAB_JOB_PRESET_FILTERS.find(
          (presetFilter) => presetFilter.id === filterId
        );
        if (!found) {
          return ACTIVE_LAB_JOBS_FILTER;
        }
        return found;
      })
    );
    this.activeSortOption$ = this.activeSort$.pipe(
      map((sortId) => {
        const found = LAB_JOB_SORT_OPTIONS.find(
          (option) => option.id === sortId
        );
        if (!found) {
          return LAB_JOB_DUE_DATE_SORT;
        }
        return found;
      })
    );

    this.labJobsLoaded$ = this._store.pipe(
      select(LabJobSelectors.getLabsJobsLoaded)
    );
    this.selectedLabJob$ = this._store.pipe(
      select(LabJobSelectors.getSelectedLabJob),
      unserialise$()
    );
    this.labJobs$ = this._store.pipe(
      select(LabJobSelectors.getLabJobs),
      unserialise$()
    );
    this.filteredLabJobs$ = combineLatest([
      this.labJobs$,
      this.statusFilter$,
      this.presetFilter$,
    ]).pipe(
      map(([labJobs, statusFilter, presetFilter]) => {
        return labJobs
          .filter((labJob) => labJob.status === statusFilter)
          .filter((labJob) => presetFilter.filter(labJob));
      })
    );
  }

  unsubscribeLabJobs(): void {
    this.watchLabJobs$.next(false);
  }

  loadPracticeLabJobs(): void {
    this.watchLabJobs$.next(true);
    this._dispatch(LabJobActions.loadPracticeLabJobs());
  }

  loadPatientLabJobs(patientRef: DocumentReference<IPatient>): void {
    this.watchLabJobs$.next(true);
    this._dispatch(LabJobActions.loadPatientLabJobs(serialise({ patientRef })));
  }

  selectLabJob(id: string): void {
    this._dispatch(LabJobActions.selectLabJob({ id }));
  }

  viewLabJob(id?: string): void {
    if (id) {
      this.selectLabJob(id);
    }
    this._dispatch(LabJobActions.viewLabJob());
  }

  addLabJob(): void {
    this._dispatch(LabJobActions.addLabJob());
  }

  loadLabJobsForLab(id: string): void {
    this.watchLabJobs$.next(true);
    this._dispatch(LabJobActions.loadLabJobsForLab({ id }));
  }

  private _dispatch(action: Action): void {
    this._store.dispatch(action);
  }
}
