import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CSVExporterService } from '@principle-theorem/ng-shared';
import { AllocationTarget } from '@principle-theorem/principle-core';
import {
  type IPractitionerAccountCreditGrouping,
  type IPractitionerIncomeReportGrouping,
  type IPractitionerTransactionGrouping,
} from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  multiFilter,
  snapshot,
  toMoment,
  type FieldsOfType,
  type ITimePeriod,
} from '@principle-theorem/shared';
import { sum } from 'lodash';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PractitionerTransactionsReportStore } from '../practitioner-transactions-report.store';
import { IFooterData } from './practitioner-transactions-report-summary-table/practitioner-transactions-report-summary-table.component';
import { PractitionerTransactionSummaryToCSV } from './practitioner-transactions-summary-to-csv';

@Component({
  selector: 'pr-practitioner-transactions-report-summary',
  templateUrl: './practitioner-transactions-report-summary.component.html',
  styleUrls: ['./practitioner-transactions-report-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PractitionerTransactionsReportSummaryComponent {
  summaries$ = new ReplaySubject<IPractitionerIncomeReportGrouping[]>(1);
  dateRange$ = new ReplaySubject<ITimePeriod>(1);
  practitionerSummaries$: Observable<IPractitionerIncomeReportGrouping[]>;
  practitionerSubtotalFooterData$: Observable<IFooterData>;
  unallocatedSummaries$: Observable<IPractitionerIncomeReportGrouping[]>;
  totalFooterData$: Observable<IFooterData>;

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  @Input()
  set summaries(summaries: IPractitionerIncomeReportGrouping[]) {
    if (summaries) {
      this.summaries$.next(summaries);
    }
  }

  constructor(
    public store: PractitionerTransactionsReportStore,
    private _csvExporter: CSVExporterService
  ) {
    this.practitionerSummaries$ = this.summaries$.pipe(
      multiFilter(
        (summary) => !AllocationTarget.isUnallocated(summary.practitioner.ref)
      )
    );
    this.practitionerSubtotalFooterData$ = this.practitionerSummaries$.pipe(
      map((summaries) =>
        this._calculateFooterData(summaries, 'Practitioner Allocated')
      )
    );

    this.unallocatedSummaries$ = this.summaries$.pipe(
      multiFilter((summary) =>
        AllocationTarget.isUnallocated(summary.practitioner.ref)
      )
    );
    this.totalFooterData$ = combineLatest([
      this.unallocatedSummaries$,
      this.practitionerSubtotalFooterData$,
    ]).pipe(
      map(([summaries, practitionerFooterData]) =>
        this._calculateFooterData(summaries, 'Total', practitionerFooterData)
      )
    );
  }

  async downloadCSV(): Promise<void> {
    const range = await snapshot(this.dateRange$);
    const startDate = toMoment(range.from).format(DAY_MONTH_YEAR_FORMAT);
    const endDate = toMoment(range.to).format(DAY_MONTH_YEAR_FORMAT);
    const fileName = `practitioner-transactions-summary-${startDate}-${endDate}`;
    const summaries = await snapshot(this.summaries$);
    await this._csvExporter.download(
      fileName,
      summaries,
      new PractitionerTransactionSummaryToCSV()
    );
  }

  private _calculateFooterData(
    summaries: IPractitionerIncomeReportGrouping[],
    rowLabel?: string,
    additionalData?: IFooterData
  ): IFooterData {
    return {
      practitioner: rowLabel,
      reservedAccountCreditAmount: this._sumAmount(
        summaries,
        'reservedAccountCreditAmount',
        additionalData?.reservedAccountCreditAmount
      ),
      accountCreditAmount: this._sumAmount(
        summaries,
        'accountCreditAmount',
        additionalData?.accountCreditAmount
      ),
      discountAmount: this._sumAmount(
        summaries,
        'discountAmount',
        additionalData?.discountAmount
      ),
      paymentAmount: this._sumAmount(
        summaries,
        'paymentAmount',
        additionalData?.paymentAmount
      ),
      practitionerProportionAmount: this._sumAmount(
        summaries,
        'practitionerProportionAmount',
        additionalData?.practitionerProportionAmount
      ),
    };
  }

  private _sumAmount(
    summaries: IPractitionerIncomeReportGrouping[],
    key:
      | FieldsOfType<IPractitionerTransactionGrouping['total'], number>
      | FieldsOfType<IPractitionerAccountCreditGrouping['total'], number>,
    existingAmount: number = 0
  ): number {
    const values = summaries.map(
      (item) =>
        item.transactions.total[
          key as keyof IPractitionerTransactionGrouping['total']
        ] ??
        item.accountCredits.total[
          key as keyof IPractitionerAccountCreditGrouping['total']
        ]
    );
    return existingAmount + sum(values);
  }
}
