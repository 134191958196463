import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import { Practice } from '@principle-theorem/principle-core';
import { ITag } from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  INamedDocument,
  filterUndefined,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-media-tags-dialog',
  templateUrl: './media-tags-dialog.component.html',
  styleUrl: './media-tags-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaTagsDialogComponent {
  tagsCol$: Observable<CollectionReference<ITag>>;
  tagsCtrl = new TypedFormControl<INamedDocument<ITag>[]>([]);

  constructor(
    private _organisation: OrganisationService,
    public dialogRef: MatDialogRef<MediaTagsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: INamedDocument<ITag>[]
  ) {
    this.tagsCol$ = this._organisation.practice$.pipe(
      filterUndefined(),
      map((practice) => Practice.mediaTagCol(practice))
    );

    this.tagsCtrl.setValue(data);
  }
}
