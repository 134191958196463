import {
  Invoice,
  LineItemActions,
  TransactionOperators,
  withTransactions$,
} from '@principle-theorem/principle-core';
import {
  type IBalance,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import { safeCombineLatest, type WithRef } from '@principle-theorem/shared';
import { iif, of, type OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export function invoiceToBalance(): OperatorFunction<
  WithRef<IInvoice>,
  IBalance
> {
  return switchMap((invoice) =>
    withTransactions$(invoice).pipe(
      map(([invoiceData, transactions]) => ({
        subtotal: Invoice.subTotal(invoiceData),
        tax: Invoice.tax(invoiceData),
        treatments: LineItemActions.sum(Invoice.treatments(invoiceData)),
        deposits: LineItemActions.sum(Invoice.deposits(invoiceData)),
        other: LineItemActions.sum([
          ...Invoice.fees(invoiceData),
          ...Invoice.products(invoiceData),
        ]),
        total: Invoice.total(invoiceData),
        paidToDate: new TransactionOperators(transactions).paidToDate(),
        balance: Invoice.balance(invoice, transactions),
      }))
    )
  );
}

export function invoicesToBalance(): OperatorFunction<
  WithRef<IInvoice>[],
  IBalance
> {
  return switchMap((invoices) =>
    iif(
      () => !invoices.length,
      of([]),
      safeCombineLatest(
        invoices.map((invoice) => of(invoice).pipe(invoiceToBalance()))
      )
    ).pipe(map((balances) => reduceBalances(balances)))
  );
}

export function reduceBalances(balances: IBalance[]): IBalance {
  const startingBalance = {
    subtotal: 0,
    tax: 0,
    treatments: 0,
    deposits: 0,
    other: 0,
    total: 0,
    paidToDate: 0,
    balance: 0,
  };
  return balances.reduce((acc, balance) => {
    return {
      subtotal: acc.subtotal + balance.subtotal,
      tax: acc.tax + balance.tax,
      treatments: acc.treatments + balance.treatments,
      deposits: acc.deposits + balance.deposits,
      other: acc.other + balance.other,
      total: acc.total + balance.total,
      paidToDate: acc.paidToDate + balance.paidToDate,
      balance: acc.balance + balance.balance,
    };
  }, startingBalance);
}
