import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  CSVExporterService,
  ObservableDataSource,
} from '@principle-theorem/ng-shared';
import { PRACTITIONER_INCOME_TOOLTIPS } from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  type ITimePeriod,
  snapshot,
  toMoment,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import {
  type IReferralSourcesGrouping,
  ReferralSourcesReportStore,
} from '../referral-sources-report.store';
import { ReferralSourcesSummaryToCSV } from './referral-sources-summary-to-csv';

@Component({
  selector: 'pr-referral-sources-report-summary',
  templateUrl: './referral-sources-report-summary.component.html',
  styleUrls: ['./referral-sources-report-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralSourcesReportSummaryComponent implements OnDestroy {
  tooltips = PRACTITIONER_INCOME_TOOLTIPS;
  dataSource: ObservableDataSource<IReferralSourcesGrouping>;
  displayedColumns = ['referralSource', 'patientCount', 'treatmentTotal'];
  summaries$ = new ReplaySubject<IReferralSourcesGrouping[]>(1);
  dateRange$ = new ReplaySubject<ITimePeriod>(1);

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  @Input()
  set summaries(summaries: IReferralSourcesGrouping[]) {
    if (summaries) {
      this.summaries$.next(summaries);
    }
  }

  constructor(
    public store: ReferralSourcesReportStore,
    private _csvExporter: CSVExporterService
  ) {
    this.dataSource = new ObservableDataSource(this.summaries$);
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  async downloadCSV(): Promise<void> {
    const range = await snapshot(this.dateRange$);
    const startDate = toMoment(range.from).format(DAY_MONTH_YEAR_FORMAT);
    const endDate = toMoment(range.to).format(DAY_MONTH_YEAR_FORMAT);
    const fileName = `referral-sources-summary-${startDate}-${endDate}`;
    const summaries = await snapshot(this.summaries$);
    await this._csvExporter.download(
      fileName,
      summaries,
      new ReferralSourcesSummaryToCSV()
    );
  }
}
