import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  CurrentScopeFacade,
  GlobalStoreService,
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { Brand, Chat } from '@principle-theorem/principle-core';
import {
  doc,
  doc$,
  filterNil,
  filterUndefined,
} from '@principle-theorem/shared';
import { Subject, combineLatest, type Observable } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ChatActions } from '../../chat-actions';
import {
  getChatSummary$,
  type IChatSummary,
} from '../../pages/chats-dashboard/chats-dashboard.store';

@Component({
  selector: 'pr-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  summary$: Observable<IChatSummary>;
  actions: ChatActions;
  @ViewChild('scrollMe', { static: false })
  myScrollContainer?: ElementRef<HTMLElement>;

  constructor(
    private _currentScope: CurrentScopeFacade,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute,
    private _stateNav: StateBasedNavigationService,
    private _globalStore: GlobalStoreService
  ) {
    const staffer$ = this._organisation.staffer$.pipe(filterUndefined());
    this.summary$ = this._route.paramMap.pipe(
      map((param) => param.get('uid')),
      filterNil(),
      switchMap((uid) =>
        combineLatest([
          this._currentScope.currentBrand$.pipe(
            filterUndefined(),
            switchMap((brand) => doc$(doc(Brand.chatCol(brand), uid)))
          ),
          staffer$,
        ])
      ),
      switchMap(([chat, staffer]) =>
        getChatSummary$(chat, staffer, this._globalStore)
      ),
      filterUndefined()
    );

    this.actions = new ChatActions(
      this.summary$,
      staffer$,
      this._snackBar,
      this._onDestroy$
    );

    this.actions.contentAdded$
      .pipe(startWith(), takeUntil(this._onDestroy$))
      .subscribe(() => setTimeout(() => this._scrollToBottom(), 50));

    combineLatest([this.summary$, staffer$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([summary, staffer]) => {
        if (Chat.userHasAccess(summary.chat, staffer.ref)) {
          return;
        }

        void this._stateNav.practice(['chats']);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _scrollToBottom(): void {
    try {
      if (!this.myScrollContainer) {
        return;
      }
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
      // Do nada
    }
  }
}
