import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { LabJobsFacade } from '@principle-theorem/ng-labs';
import {
  ObservableDataSource,
  SelectionListStore,
} from '@principle-theorem/ng-shared';
import {
  type ILabJob,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { isSameRef, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-lab-jobs',
  templateUrl: './patient-lab-jobs.component.html',
  styleUrls: ['./patient-lab-jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SelectionListStore],
})
export class PatientLabJobsComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  dataSource: ObservableDataSource<WithRef<ILabJob>>;
  sort: MatSort = new MatSort();

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this._patient$.next(patient);
    }
  }

  constructor(
    private _labJobsFacade: LabJobsFacade,
    public selectionList: SelectionListStore<WithRef<ILabJob>>
  ) {
    this.dataSource = new ObservableDataSource<WithRef<ILabJob>>(
      this._labJobsFacade.labJobs$
    );
    this._patient$.pipe(takeUntil(this._onDestroy$)).subscribe((patient) => {
      this._labJobsFacade.loadPatientLabJobs(patient.ref);
    });
    this.selectionList.setCompareFn(isSameRef);
    this.selectionList.loadOptions(this.dataSource.filteredData$);
  }

  ngOnDestroy(): void {
    this._labJobsFacade.unsubscribeLabJobs();
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.dataSource.disconnect();
  }

  editLabJob(labJob: WithRef<ILabJob>): void {
    this._labJobsFacade.viewLabJob(labJob.ref.id);
  }
}
