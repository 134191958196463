import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  type OnDestroy,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  CurrentBrandScope,
  PatientSelectorStore,
} from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  AppointmentRequest,
  ITypesensePatientWithRef,
  patientDataToSimilarQuery,
} from '@principle-theorem/principle-core';
import {
  IPatient,
  IPatientFormData,
  IPrimaryContactFormData,
  type IPatientData,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  HISTORY_DATE_FORMAT,
  WithRef,
} from '@principle-theorem/shared';
import { Observable, Subject, of } from 'rxjs';

enum PatientType {
  Patient = 'Patient',
  PrimaryContact = 'PrimaryContact',
}

@Component({
  selector: 'pr-confirm-patient-dialog',
  templateUrl: './confirm-patient-dialog.component.html',
  styleUrls: ['./confirm-patient-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPatientDialogComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _dialog = inject(MatDialog);
  private _brand = inject(CurrentBrandScope);
  public dialogRef = inject(MatDialogRef<ConfirmPatientDialogComponent>);
  public patient = inject<IPatientData>(MAT_DIALOG_DATA);
  readonly dateFormat = HISTORY_DATE_FORMAT;
  readonly patientType = PatientType;

  primaryContactMatches = toSignal(this._matches$(this.patient.primaryContact));
  patientMatches = toSignal(this._patientMatches$(this.patient.patient));
  primarySelected = signal<ITypesensePatientWithRef | undefined>(undefined);
  patientSelected = signal<ITypesensePatientWithRef | undefined>(undefined);

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  handleSelection(type: PatientType, match: ITypesensePatientWithRef): void {
    if (type === PatientType.PrimaryContact) {
      this.primarySelected.update((prev) =>
        prev && prev.id === match.id ? undefined : match
      );
    }
    if (type === PatientType.Patient) {
      this.patientSelected.update((prev) =>
        prev && prev.id === match.id ? undefined : match
      );
    }
  }

  async scheduleAppointment(): Promise<void> {
    const typesensePatient = this.patientSelected();
    const typesensePrimary = this.primarySelected();

    const patient = typesensePatient
      ? await Firestore.getDoc(typesensePatient.ref)
      : undefined;
    const primaryPatient = typesensePrimary
      ? await Firestore.getDoc(typesensePrimary.ref)
      : undefined;

    const { prompt, count } = this._getSavePatientPrompt();

    if (count > 0) {
      const data = confirmationDialogData({
        title: `Create New ${count > 1 ? 'Patients' : 'Patient'}`,
        prompt,
        submitLabel: 'Confirm',
      });

      const confirmed = await this._dialog
        .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
          ConfirmDialogComponent,
          DialogPresets.small({ data })
        )
        .afterClosed()
        .toPromise();

      if (!confirmed) {
        return;
      }
    }

    this.dialogRef.close(
      await this._resolveRequestPatient(patient, primaryPatient)
    );

    return;
  }

  private async _resolveRequestPatient(
    selectedPatient?: WithRef<IPatient>,
    selectedPrimaryPatient?: WithRef<IPatient>
  ): Promise<WithRef<IPatient>> {
    const brand = await this._brand.toPromise();
    return AppointmentRequest.getPatientForAppointmentRequest(
      brand,
      this.patient,
      selectedPatient,
      selectedPrimaryPatient
    );
  }

  private _getSavePatientPrompt(): { prompt: string; count: number } {
    const patients = [
      {
        name: this.patient.patient?.name,
        matches: this.patientMatches(),
        selected: this.patientSelected(),
      },
      {
        name: this.patient.primaryContact?.name,
        matches: this.primaryContactMatches(),
        selected: this.primarySelected(),
      },
    ];

    const { names, count } = patients.reduce(
      (acc, patient) => {
        if (patient.name && patient.matches?.length && !patient.selected) {
          acc.count++;
          acc.names.push(patient.name);
        }
        return acc;
      },
      { names: [] as string[], count: 0 }
    );

    const prompt =
      names.length > 0
        ? `You have not selected a match for ${names.join(
            ' or '
          )}. Are you sure you want to create ${names.join(
            ' and '
          )} as new patient${names.length > 1 ? 's' : ''}?`
        : '';

    return { prompt, count };
  }

  private _matches$(
    patient: IPrimaryContactFormData
  ): Observable<ITypesensePatientWithRef[]> {
    const store = new PatientSelectorStore();
    const query = patientDataToSimilarQuery({
      name: patient.name,
      dateOfBirth: patient.dateOfBirth,
      mobileNumber: patient.mobileNumber,
    });

    store.loadSuggestedPatients(query);
    return store.suggestedPatients$;
  }

  private _patientMatches$(
    patient?: IPatientFormData
  ): Observable<ITypesensePatientWithRef[]> {
    if (!patient) {
      return of([]);
    }

    const store = new PatientSelectorStore();
    const query = patientDataToSimilarQuery({
      name: patient.name,
      dateOfBirth: patient.dateOfBirth,
    });

    store.loadSuggestedPatients(query);
    return store.suggestedPatients$;
  }
}
