import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { getSchemaText } from '@principle-theorem/editor';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ClinicalNote } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IClinicalNote,
  type IPatient,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  filterUndefined,
  getDocs,
  multiMap,
  snapshot,
  sortMoment,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { jsPDF } from 'jspdf';
import { kebabCase } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
} from 'rxjs';
import { map, concatMap } from 'rxjs/operators';

interface IDisplayClinicalNotes extends Pick<IClinicalNote, 'recordDate'> {
  owner: string;
  content: string[];
}

@Component({
  selector: 'pr-download-clinical-notes',
  templateUrl: './download-clinical-notes.component.html',
  styleUrls: ['./download-clinical-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadClinicalNotesComponent {
  @ViewChild('content') content: ElementRef<HTMLElement>;
  brand$: Observable<WithRef<IBrand>>;
  practice$: Observable<WithRef<IPractice>>;
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  clinicalNotes$ = new BehaviorSubject<WithRef<IClinicalNote>[]>([]);
  clinicalNotesDisplay$: Observable<IDisplayClinicalNotes[]>;
  trackByNote = TrackByFunctions.index<IDisplayClinicalNotes>();
  trackByContent =
    TrackByFunctions.index<Pick<IDisplayClinicalNotes, 'content'>>();
  readonly dateFormat = DAY_MONTH_YEAR_FORMAT;
  @Input() type: 'menu' | 'button' = 'button';

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input()
  set clinicalNotes(clinicalNotes: WithRef<IClinicalNote>[]) {
    if (clinicalNotes) {
      this.clinicalNotes$.next(clinicalNotes);
    }
  }

  constructor(currentScopeFacade: CurrentScopeFacade) {
    this.brand$ = currentScopeFacade.currentBrand$.pipe(filterUndefined());
    this.practice$ = currentScopeFacade.currentPractice$.pipe(
      filterUndefined()
    );

    this.clinicalNotesDisplay$ = combineLatest([
      this.patient$,
      this.clinicalNotes$,
    ]).pipe(
      concatMap(async ([patient, clinicalNotes]) =>
        clinicalNotes.length
          ? clinicalNotes
          : getDocs(ClinicalNote.col(patient))
      ),
      map((notes) =>
        ClinicalNote.filterEmpty(notes).sort((a, b) =>
          sortMoment(toMoment(a.recordDate), toMoment(b.recordDate))
        )
      ),
      multiMap((clinicalNote) => ({
        recordDate: clinicalNote.recordDate,
        owner: clinicalNote.owner.name,
        content: getSchemaText(clinicalNote.content).split('\n'),
      }))
    );
  }

  async downloadClinicalNotes(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const pdf = new jsPDF('p', 'pt', 'A4');
    const filename = `${kebabCase(patient.name)}-clinical-notes.pdf`;
    const content = this.content.nativeElement;
    content.hidden = false;

    await pdf.html(content, {
      callback: (doc) => {
        doc.save(filename);
        content.hidden = true;
      },
      margin: [30, 20, 30, 20],
      autoPaging: 'text',
      filename,
    });
  }
}
