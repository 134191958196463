import { Validators } from '@angular/forms';
import { ContactNumberFormGroup } from '@principle-theorem/ng-principle-shared';
import {
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IMedicareCard,
  type Gender,
  type IContactNumber,
  type IHealthFundCard,
  type IPatientContactDetails,
  type IReferralSource,
  IDVACard,
  IAddressMetadata,
} from '@principle-theorem/principle-core/interfaces';
import {
  isISODateType,
  type ISODateType,
  Timestamp,
  ONLY_NUMBERS,
} from '@principle-theorem/shared';

export type IPatientDetailsFormData = Omit<
  IPatientContactDetails,
  'address'
> & {
  address: IAddressMetadata;
  name: string;
  gender: Gender;
  dateOfBirth: ISODateType;
  referrer: IReferralSource;
  healthFundCard: IHealthFundCard;
  medicareCard: IMedicareCard;
  dvaCard: IDVACard;
  showHealthFundCard: boolean;
  showMedicareCard: boolean;
  showDVACard: boolean;
};

export class PatientDetailsFormDataGroup extends TypedFormGroup<IPatientDetailsFormData> {
  constructor() {
    super({
      name: new TypedFormControl<string>('', Validators.required),
      dateOfBirth: new TypedFormControl<ISODateType>(undefined).withGuard(
        isISODateType
      ),
      gender: new TypedFormControl<Gender>(undefined, Validators.required),
      email: new TypedFormControl<string>('', Validators.email),
      contactNumbers: new TypedFormArray<IContactNumber>([
        new ContactNumberFormGroup(false),
      ]),
      address: new TypedFormControl<IAddressMetadata>({ address: '' }),
      referrer: new TypedFormControl<IReferralSource>(undefined),
      showHealthFundCard: new TypedFormControl<boolean>(false),
      showMedicareCard: new TypedFormControl<boolean>(false),
      showDVACard: new TypedFormControl<boolean>(false),
      healthFundCard: new TypedFormGroup<IHealthFundCard>({
        fundCode: new TypedFormControl<string>(''),
        membershipNumber: new TypedFormControl<string>(
          '',
          Validators.pattern(ONLY_NUMBERS)
        ),
        memberNumber: new TypedFormControl<string>('', [
          Validators.pattern(ONLY_NUMBERS),
          Validators.maxLength(2),
        ]),
      }),
      medicareCard: new TypedFormGroup<IMedicareCard>({
        number: new TypedFormControl<string>(
          '',
          Validators.pattern(ONLY_NUMBERS)
        ),
        subNumerate: new TypedFormControl<string>('', [
          Validators.pattern(ONLY_NUMBERS),
          Validators.maxLength(2),
        ]),
        expiryDate: new TypedFormControl<Timestamp>(undefined),
      }),
      dvaCard: new TypedFormGroup<IDVACard>({
        number: new TypedFormControl<string>(
          '',
          Validators.pattern(ONLY_NUMBERS)
        ),
        expiryDate: new TypedFormControl<Timestamp>(undefined),
      }),
    });
  }
}
