import { roundTo2Decimals } from '@principle-theorem/accounting';
import { type ICSVExport } from '@principle-theorem/ng-shared';
import { getTransactionProviderName } from '@principle-theorem/principle-core';
import { type IAccountCreditReportRecord } from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  reduceToSingleArrayFn,
  toMoment,
} from '@principle-theorem/shared';

export interface IAccountCreditCSV {
  issuedAt: string;
  patient: string;
  reservedFor: string;
  invoiceStatus?: string;
  invoice?: string;
  transactionType: string;
  transactionAmount: string;
  accountCreditTotal: string;
  accountCreditRemaining: string;
}

export class AccountCreditToCSV
  implements ICSVExport<IAccountCreditReportRecord, IAccountCreditCSV>
{
  headers = [
    'Issued At',
    'Patient',
    'Reserved For',
    'Invoice Status',
    'Invoice Reference',
    'Transaction Type',
    'Transaction Amount',
    'Account Credit Total',
    'Account Credit Remaining',
  ];

  translate(records: IAccountCreditReportRecord[]): IAccountCreditCSV[] {
    return records
      .map((record) =>
        record.transactions.map((transaction) => ({
          issuedAt: toMoment(record.accountCredit.createdAt).format(
            DAY_MONTH_YEAR_FORMAT
          ),
          patient: record.patient.name,
          reservedFor:
            record.accountCredit.reservedFor.practitioner?.name ?? '',
          invoiceStatus: record.invoice?.status,
          invoice: record.invoice?.reference,
          transactionType: getTransactionProviderName(transaction),
          transactionAmount: roundTo2Decimals(transaction.amount).toString(),
          accountCreditTotal: roundTo2Decimals(
            record.accountCredit.amount
          ).toString(),
          accountCreditRemaining: roundTo2Decimals(
            record.accountCredit.remaining
          ).toString(),
        }))
      )
      .reduce(reduceToSingleArrayFn);
  }
}
