import { rand, randFloat, randNumber } from '@ngneat/falso';
import {
  InvoiceAction,
  InvoiceStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  IInvoiceDimension,
  IInvoiceEventFact,
} from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { AppointmentDimensionMock } from '../dimensions/appointment-dimension.mock';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import {
  SerialisedDocumentReferenceMock,
  BigQueryNamedDocumentMock,
} from '../dimensions/common.mock';
import {
  InvoiceDimensionMock,
  MockPaidInvoiceProps,
  MockIssuedInvoiceProps,
  MockCancelledInvoiceProps,
  BigQueryTreatmentLineItemMock,
  BigQueryDepositLineItemMock,
} from '../dimensions/invoice-dimension.mock';
import {
  PatientDimensionMock,
  MOCKED_REFERRERS,
} from '../dimensions/patient-dimension.mock';
import { MOCKED_PRACTICES } from '../dimensions/practice-dimension.mock';
import { TransactionDimensionMock } from '../dimensions/transaction-dimension.mock';

function MockInvoice(): IInvoiceDimension {
  const roll = randNumber({ min: 0, max: 100 });
  if (roll < 60) {
    return MockGenerator.generate(InvoiceDimensionMock, MockPaidInvoiceProps());
  }
  if (roll < 90) {
    return MockGenerator.generate(
      InvoiceDimensionMock,
      MockIssuedInvoiceProps()
    );
  }
  return MockGenerator.generate(
    InvoiceDimensionMock,
    MockCancelledInvoiceProps()
  );
}

export class InvoiceEventFactMock
  extends BaseMock
  implements IInvoiceEventFact
{
  appointmentRefs = [MockGenerator.generate(SerialisedDocumentReferenceMock)];
  invoiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  transactionRefs = [MockGenerator.generate(SerialisedDocumentReferenceMock)];
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  issuedAt = toSerialisedTimestamp(MockTimestamp());
  invoice = MockInvoice();
  transactions = [MockGenerator.generate(TransactionDimensionMock)];
  brand = MockGenerator.generate(BrandDimensionMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  practice = rand(MOCKED_PRACTICES);
  referrer = rand(MOCKED_REFERRERS);
  paidOnCheckout = rand([true, true, false]);
  practitionerFees = [
    {
      practitioner: MockGenerator.generate(BigQueryNamedDocumentMock),
      treatments: [MockGenerator.generate(BigQueryTreatmentLineItemMock)],
      treatmentsTotal: randNumber(),
      deposits: [MockGenerator.generate(BigQueryDepositLineItemMock)],
      depositsTotal: randNumber(),
      practitionerTotal: randNumber(),
      allocatedPercent: randFloat({ min: 0, max: 1 }),
    },
  ];
  practitionerPayment = [
    {
      practitioner: MockGenerator.generate(BigQueryNamedDocumentMock),
      allocatedPercent: randFloat({ min: 0, max: 1 }),
      allocatedAmount: randNumber(),
    },
  ];
  treatmentPlans = [MockGenerator.generate(SerialisedDocumentReferenceMock)];
  appointments = [MockGenerator.generate(AppointmentDimensionMock)];
  timestamp = toSerialisedTimestamp(MockTimestamp());
  event = {
    action: InvoiceAction.Paid,
    statusBefore: InvoiceStatus.Issued,
    statusAfter: InvoiceStatus.Paid,
  };
  amountRemaining = randFloat({
    min: 200,
    max: 2000,
  });
  paidToDate = randFloat({
    min: 0,
    max: 2000,
  });
  subTotal = randFloat({
    min: 0,
    max: 2000,
  });
  tax = randFloat({
    min: 0,
    max: 20,
  });
  isOverdue = rand([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  overdueBy = randNumber({
    min: 1,
    max: 60,
  });
  total = randFloat({
    min: 0,
    max: 2000,
  });
}
