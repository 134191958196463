<div fxFill fxLayout="column" *ngIf="appointment$ | async as appointment">
  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="16px">
    <pr-appointment-scheduling-header
      [appointment]="appointment"
      [required]="isRequired$ | async"
    />

    <pr-appointment-menu [appointment]="appointment" />
  </div>

  <div *ngIf="treatmentStep$ | async as treatmentStep" fxFlex>
    <h3>{{ treatmentStep.name }}</h3>
    <div class="practitioner mat-caption">
      {{ appointment.practitioner.name }}
    </div>
    <div *ngIf="organisation.hasMultiplePractices$ | async" class="mat-caption">
      {{ appointment.practice.name }}
    </div>
    <div class="mat-caption">{{ duration$ | async }} mins</div>
    <ng-container *ngIf="appointment.activeFollowUp">
      <div
        class="mat-caption"
        *ngIf="hasCancelledFollowUp$ | async; else followUpWithDate"
      >
        Appointment cancelled with no follow-up
      </div>
      <ng-template #followUpWithDate>
        <div class="mat-caption" *ngIf="followUpDate$ | async as followUpDate">
          Follow-Up set for
          {{ followUpDate | moment | amCalendar: dateService.format.calendar }}
        </div>
      </ng-template>
    </ng-container>
    <pr-relative-scheduling-summary
      *ngIf="treatmentStep.schedulingRules"
      [rules]="treatmentStep.schedulingRules"
    />
  </div>

  <pt-buttons-container *ngIf="showButtons" padding="none" alignment="start">
    <ng-container *ngIf="isUnscheduled$ | async; else scheduledAppointment">
      <button mat-stroked-button fxFlex (click)="schedule()">Schedule</button>
      <button mat-stroked-button fxFlex (click)="followUp()">
        <ng-template #setFollowUp>Set Follow-Up</ng-template>
        <ng-container *ngIf="hasFollowUp$ | async; else setFollowUp">
          Edit Follow-Up
        </ng-container>
      </button>
    </ng-container>

    <ng-template #scheduledAppointment>
      <button mat-stroked-button fxFlex (click)="schedule()">
        Re-Schedule
      </button>
      <button mat-stroked-button fxFlex (click)="cancel()">Cancel</button>
    </ng-template>
  </pt-buttons-container>
</div>
