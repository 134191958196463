import { splitCamel } from '@principle-theorem/ng-shared';
import {
  getRefundRemaining,
  Invoice,
  TimezoneResolver,
  Transaction,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import {
  isTreatmentLineItem,
  TransactionStatus,
  type ICustomLineItem,
  type IInvoice,
  type IPractice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  isSameRef,
  mergeDayAndTime,
  snapshot,
  sortByCreatedAt,
  titlecase,
  toMomentTz,
  type DocumentReference,
  type IReffable,
  type Timezone,
  type TypeGuardFn,
  type WithRef,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { combineLatest, from, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ITransactionActionsData } from './transaction-action';

interface INonClaimableItemTransformable {
  invoice: IInvoice;
  lineItem: ICustomLineItem;
}

export function getNonClaimableItemTransformables(
  invoice: IInvoice
): INonClaimableItemTransformable[] {
  return invoice.items
    .filter((item) => !isTreatmentLineItem(item))
    .map((lineItem) => ({ invoice, lineItem }));
}

export class TransactionHelpers {
  static lastTransaction$<T>(
    data: ITransactionActionsData<T>,
    typeGuardFn: TypeGuardFn<T>
  ): Observable<ITransaction<T> | undefined> {
    return Invoice.transactions$(data.invoice).pipe(
      map((transactions) =>
        new TransactionOperators(transactions)
          .byReference(data.latestTransaction.reference)
          .extendedDataGuard(typeGuardFn)
          .sort(sortByCreatedAt)
          .reverse()
          .last()
      )
    );
  }

  static lastCompletedTransaction$<T>(
    data: ITransactionActionsData<T>,
    typeGuardFn: TypeGuardFn<T>
  ): Observable<ITransaction<T> | undefined> {
    return Invoice.transactions$(data.invoice).pipe(
      map((transactions) =>
        new TransactionOperators(transactions)
          .byReference(data.latestTransaction.reference)
          .completed()
          .extendedDataGuard(typeGuardFn)
          .sort(sortByCreatedAt)
          .reverse()
          .last()
      )
    );
  }

  static canAmend$<T>(data: ITransactionActionsData<T>): Observable<boolean> {
    return this.lastTransaction$(data, (_item): _item is unknown => true).pipe(
      map((transaction) => {
        if (!transaction) {
          return false;
        }
        if (!isSameRef(data.transaction, data.latestTransaction)) {
          return false;
        }
        return [TransactionStatus.Pending, TransactionStatus.Complete].includes(
          transaction.status
        );
      })
    );
  }

  static hasReceivedPayment$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return Invoice.transactions$(invoice).pipe(
      map((transactions) =>
        new TransactionOperators(transactions).paidToDate()
      ),
      map((paidToDate) => paidToDate > 0)
    );
  }
}

export function getRefundRemaining$<T>(
  data: ITransactionActionsData<T>
): Observable<number> {
  return Invoice.transactions$(data.invoice).pipe(
    map((transactions) =>
      getRefundRemaining(data.latestTransaction, transactions)
    )
  );
}

export function getRefundLabel$<T>(
  data: ITransactionActionsData<T>
): Observable<string> {
  const provider = titlecase(splitCamel(data.transaction.provider));
  return of(`${provider} Refund`);
}

export function getRefundInfo$<T>(
  data: ITransactionActionsData<T>
): Observable<string[]> {
  return getRefundRemaining$(data).pipe(
    map((refundRemaining) => {
      if (refundRemaining <= 0) {
        return [];
      }
      return [data.transaction.reference, refundAvailableInfo(refundRemaining)];
    })
  );
}

export function refundAvailableInfo(refundRemaining: number): string {
  const amount = `$${refundRemaining.toFixed(2)}`;
  return `${amount} available`;
}

export function buildTransactionAmendmentData$(
  currentTransaction: WithRef<ITransaction>
): Observable<{
  currentIndex: number;
  transactions: WithRef<ITransaction>[];
  timezone: Timezone;
}> {
  return combineLatest([
    Transaction.invoice$(currentTransaction).pipe(
      switchMap((invoice) => Invoice.transactions$(invoice))
    ),
    from(TimezoneResolver.fromPracticeRef(currentTransaction.practiceRef)),
  ]).pipe(
    map(([transactions, timezone]) => {
      const sortedTransactions = new TransactionOperators(transactions)
        .byReference(currentTransaction.reference)
        .sort(sortByCreatedAt)
        .reverse()
        .result();
      const currentIndex = sortedTransactions.findIndex((sortedTransaction) =>
        isSameRef(sortedTransaction, currentTransaction)
      );
      return {
        currentIndex,
        transactions: sortedTransactions,
        timezone,
      };
    })
  );
}

export async function determineNewCreatedAtDate(
  transaction: Pick<WithRef<ITransaction>, 'createdAt' | 'practiceRef'>,
  dateReceived: Moment,
  minDate?: Moment,
  maxDate?: Moment
): Promise<Moment> {
  const timezone = await TimezoneResolver.fromPracticeRef(
    transaction.practiceRef
  );

  const newCreatedAt = mergeDayAndTime(
    dateReceived,
    toMomentTz(transaction.createdAt, timezone),
    timezone
  );

  const now = moment.tz(timezone);
  if (newCreatedAt.isAfter(now)) {
    return now;
  }

  if (minDate && newCreatedAt.isSameOrBefore(minDate, 'minute')) {
    return minDate.clone().add(1, 'minute');
  }

  if (maxDate && newCreatedAt.isSameOrAfter(maxDate, 'minute')) {
    return maxDate.clone().subtract(1, 'minute');
  }

  return newCreatedAt;
}

export function determineMinAmendmentDate(
  transactionIndex: number,
  transactions: WithRef<ITransaction>[],
  timezone: Timezone
): Moment | undefined {
  if (transactions.length <= 1) {
    return;
  }
  if (transactionIndex === 0) {
    return;
  }
  return toMomentTz(transactions[transactionIndex - 1].createdAt, timezone);
}

export function determineMaxAmendmentDate(
  transactionIndex: number,
  transactions: WithRef<ITransaction>[],
  timezone: Timezone
): Moment {
  if (transactions.length <= 1) {
    return moment.tz(timezone);
  }
  if (transactionIndex === transactions.length - 1) {
    return moment.tz(timezone);
  }
  return toMomentTz(transactions[transactionIndex + 1].createdAt, timezone);
}

export async function resolveTransactionPracticeRef(
  practice$: Observable<IReffable<IPractice> | undefined>,
  defaultValue: IReffable<IPractice>
): Promise<DocumentReference<IPractice>> {
  const resolved = (await snapshot(practice$)) ?? defaultValue;
  return resolved.ref;
}

export function hasValidServiceDate$(
  invoice: WithRef<IInvoice>
): Observable<boolean> {
  return Invoice.getAssociatedAppointment$(invoice).pipe(
    map((appointment) => appointment?.event?.from ?? invoice.createdAt),
    map((serviceDate) => toMoment(serviceDate).isSameOrBefore(moment(), 'day'))
  );
}
