<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <h1 class="mat-headline-4">Treatment Templates</h1>
    <pt-buttons-container>
      <button mat-flat-button color="primary" (click)="add()">
        Create Treatment Template
      </button>
    </pt-buttons-container>
  </div>

  <h3 class="mat-headline-6">Available for booking online</h3>
  <ng-container *ngIf="publicTreatments$ | async as publicTreatments">
    <mat-list *ngIf="publicTreatments.length; else noPublicTreatments">
      <pr-treatment-template
        *ngFor="
          let treatmentTemplate of publicTreatments;
          trackBy: trackByTemplate
        "
        [brand]="brand$ | async"
        [treatmentTemplate]="treatmentTemplate"
      />
    </mat-list>
  </ng-container>
  <ng-template #noPublicTreatments>
    <pr-empty-state image="list" title="public templates" />
  </ng-template>

  <h3 class="mat-headline-6">Only available for booking by staff</h3>
  <ng-container *ngIf="privateTreatments$ | async as privateTreatments">
    <mat-list *ngIf="privateTreatments.length; else noPrivateTreatments">
      <pr-treatment-template
        *ngFor="
          let treatmentTemplate of privateTreatments;
          trackBy: trackByTemplate
        "
        [brand]="brand$ | async"
        [treatmentTemplate]="treatmentTemplate"
      />
    </mat-list>
  </ng-container>
  <ng-template #noPrivateTreatments>
    <pr-empty-state image="list" title="private templates" />
  </ng-template>
</div>
