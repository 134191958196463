import { type ICSVExport } from '@principle-theorem/ng-shared';
import { isManualExtendedData } from '@principle-theorem/principle-core/interfaces';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';
import { startCase } from 'lodash';
import { type ILatestTransactionReportRecord } from '../../../core/reporting-functions';

export interface IPendingPaymentCSV {
  transactionDate: string;
  patient: string;
  invoice: string;
  transactionType: string;
  transactionProvider: string;
  transactionProviderType: string;
  amount: string;
}

export class PendingPaymentToCSV
  implements ICSVExport<ILatestTransactionReportRecord, IPendingPaymentCSV>
{
  headers = [
    'Transaction Date',
    'Patient',
    'Invoice/Transaction Reference',
    'Type',
    'Manual Type',
    'Amount',
  ];

  translate(records: ILatestTransactionReportRecord[]): IPendingPaymentCSV[] {
    return records.map((record) => {
      let transactionProviderType = '';
      if (isManualExtendedData(record.latest.extendedData)) {
        transactionProviderType =
          record.latest.extendedData.transactionType?.name ?? '';
      }

      return {
        transactionDate: toMoment(record.latest.createdAt).format(
          DAY_MONTH_YEAR_FORMAT
        ),
        patient: record.patient.name,
        invoice: record.invoice.reference,
        transactionType: record.latest.type,
        transactionProvider: startCase(record.latest.provider),
        transactionProviderType,
        amount: record.latest.amount.toString(),
      };
    });
  }
}
