import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  HicapsConnectReceiptType,
  PrincipleHicapsConnect,
} from '@principle-theorem/hicaps-connect';
import { FeatureFlagsService } from '@principle-theorem/ng-feature-flags';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import {
  CurrentScopeFacade,
  StateBasedNavigationService,
  UpdateHealthCardsService,
} from '@principle-theorem/ng-principle-shared';
import {
  Invoice,
  Practice,
  Transaction,
} from '@principle-theorem/principle-core';
import { HICAPS_CONNECT_FEATURE } from '@principle-theorem/principle-core/features';
import {
  IHealthcareClaim,
  IHicapsConnectHealthFundCancelTransactionExtendedData,
  IHicapsConnectHealthFundTransactionExtendedData,
  IInvoice,
  IPractice,
  ITransaction,
  TransactionAction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Region,
  WithRef,
  snapshot,
} from '@principle-theorem/shared';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HealthFundMemberNumberDialogService } from '../../transaction-components/health-fund-member-number-dialog/health-fund-member-number-dialog.service';
import {
  ITransactionProvider,
  TransactionProviderType,
} from '../../transaction-provider';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';
import { HicapsConnectService } from '../hicaps-connect.service';
import { HicapsConnectHealthFundRequestBuilder } from './hicaps-connect-health-fund-request-builder';
import { HicapsConnectHealthFundTransactionBuilder } from './hicaps-connect-health-fund-transaction-builder';

@Injectable()
export class HicapsConnectHealthFundTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.HicapsConnectHealthFund;
  providerType = TransactionProviderType.HealthFund;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor(
    private _hicapsConnect: HicapsConnectService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _accountFunctions: AccountingFunctionsService,
    private _snackbar: MatSnackBar,
    private _updateHealthCards: UpdateHealthCardsService,
    private _memberNumberDialog: HealthFundMemberNumberDialogService,
    private _featureFlags: FeatureFlagsService,
    private _stateNav: StateBasedNavigationService
  ) {
    this.isEnabled$ = this._currentScopeFacade.currentPractice$.pipe(
      map(
        (practice) =>
          this._featureFlags.isFeatureEnabled(HICAPS_CONNECT_FEATURE) &&
          Practice.isHicapsConnectEnabled(practice)
      )
    );
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canCapture =
      !Invoice.isPaid(invoice) && Invoice.canAddTransactions(invoice);
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>,
    claim?: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    if (!claim) {
      return;
    }
    const patient = await Firestore.getDoc(Invoice.patientDocRef(invoice));
    const formData = await this._memberNumberDialog.open(invoice, patient);
    if (!formData) {
      return;
    }
    const terminal = await this._hicapsConnect.selectTerminal(invoice.practice);
    if (!terminal) {
      return;
    }

    const appointment = await Invoice.getAssociatedAppointment(invoice);
    const serviceDate = await HicapsConnectHelpers.getServiceDate(
      invoice,
      appointment?.event?.from
    );
    const data = HicapsConnectHealthFundRequestBuilder.buildClaim(
      this._hicapsConnect.getConfig(),
      patient,
      invoice,
      claim,
      terminal,
      formData,
      serviceDate
    );
    if (!data) {
      return;
    }
    const { request, extendedData } = data;
    this._snackbar.open('Sending request to terminal');

    const api = this._hicapsConnect.getDeviceAPI(terminal.bridgeDevice);
    const response = await snapshot(
      api.sendClaimRequest(request, extendedData)
    );

    const practiceRef = Firestore.getParentDocRef<IPractice>(terminal.ref);
    const transaction =
      await HicapsConnectHealthFundTransactionBuilder.toHealthFundTransaction(
        invoice,
        practiceRef,
        request,
        response
      );

    const result = await this._accountFunctions.addTransactionToInvoice(
      invoice,
      transaction,
      TransactionAction.Add,
      claim
    );
    if (!result) {
      return;
    }

    const isSuccess = PrincipleHicapsConnect.isSuccessResponse();
    if (isSuccess(response)) {
      await this._updateHealthCards.open(
        this._updateHealthCards.factory(patient).fromTransaction(transaction)
      );
    }
    return result;
  }

  async cancel(
    invoice: WithRef<IInvoice>,
    initialTransaction: WithRef<
      ITransaction<IHicapsConnectHealthFundTransactionExtendedData>
    >,
    claim?: IHealthcareClaim
  ): Promise<
    | DocumentReference<
        ITransaction<IHicapsConnectHealthFundCancelTransactionExtendedData>
      >
    | undefined
  > {
    if (!claim) {
      return;
    }
    const terminal = await this._hicapsConnect.selectTerminal(invoice.practice);
    if (!terminal) {
      return;
    }
    const patient = await Firestore.getDoc(Invoice.patientDocRef(invoice));
    const formData = await this._memberNumberDialog.open(invoice, patient);
    if (!formData) {
      return;
    }

    const data = HicapsConnectHealthFundRequestBuilder.buildClaimCancel(
      this._hicapsConnect.getConfig(),
      patient,
      invoice,
      claim,
      terminal,
      formData,
      initialTransaction
    );
    if (!data) {
      return;
    }
    const { request, extendedData } = data;
    this._snackbar.open('Sending request to terminal');

    const api = this._hicapsConnect.getDeviceAPI(terminal.bridgeDevice);
    const response = await snapshot(
      api.sendClaimCancelRequest(request, extendedData)
    );

    const practiceRef = Firestore.getParentDocRef<IPractice>(terminal.ref);
    const cancelTransaction =
      await HicapsConnectHealthFundTransactionBuilder.toHealthFundCancelTransaction(
        invoice,
        practiceRef,
        request,
        response,
        initialTransaction
      );

    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      cancelTransaction,
      TransactionAction.Cancel,
      claim
    );
  }

  async printReceipt(
    transaction: WithRef<ITransaction>,
    receiptType: HicapsConnectReceiptType
  ): Promise<void> {
    const invoiceRef = Transaction.invoiceDocRef(transaction);
    const patientRef = Invoice.patientDocRef({ ref: invoiceRef });
    await this._stateNav.brand([
      'patients',
      patientRef.id,
      'account',
      'invoices',
      invoiceRef.id,
      'print-hicaps-receipt',
      transaction.ref.id,
      receiptType,
    ]);
  }
}
