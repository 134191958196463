import { type IntegrationType } from '@principle-theorem/integrations';
import { type ISoftDelete } from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { type IAutomationConfiguration } from '../automation/automation';
import { type IPatient } from '../patient/patient';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import { type SmsIntegrationOption } from './sms-integration';

export const SMS_SEGMENT_SIZE = 160;

export interface ITwilioSMSMetadata {
  provider: IntegrationType.Twilio;
  conversationSid?: string;
  participantSid?: string;
  messageSid: string;
}

export interface IPodiumSMSMetadata {
  provider: IntegrationType.Podium;
  conversationUid: string;
  messageUid: string;
}

export type SMSProviderMetadata = ITwilioSMSMetadata | IPodiumSMSMetadata;

export enum CommunicationDirection {
  Inbound = 'inbound',
  Outbound = 'outbound',
}

export enum SMSDeliveryStatus {
  Pending = 'pending',
  Sent = 'sent',
  Delivered = 'delivered',
  Failed = 'failed',
}

export interface IBaseSMSMessage extends ISoftDelete {
  readBy: DocumentReference<IStaffer>[];
  body: string;
  patientPhoneNumber: string;
  practicePhoneNumber: string;
  conversationRef?: DocumentReference<SMSConversation>;
  patientRef: DocumentReference<IPatient>;
  practiceRef: DocumentReference<IPractice>;
  authorRef:
    | DocumentReference<
        IPatient | IStaffer | IPractice | IAutomationConfiguration
      >
    | SmsIntegrationOption;
  direction: CommunicationDirection;
}

export type SMSMessage = IBaseSMSMessage &
  (
    | { status: SMSDeliveryStatus.Pending }
    | {
        status: SMSDeliveryStatus;
        metadata: SMSProviderMetadata;
      }
  );

/**
 * When we're sending a message we can map the patient that we're expecting a reply from.
 * If we only add a conversation map when we're the first sender, we know that we can
 * best guess who the patient is for in cases where the same mobile number might be on
 * different patients. At this point it's also working out which other patients it could
 * be for.
 **/

export enum ConversationStatus {
  Pending = 'pending',
  Open = 'open',
  Closed = 'closed',
}

type TwilioConversationStatus = 'active' | 'inactive' | 'closed';

export const TWILIO_CONVERSATION_STATUS_MAP: Record<
  TwilioConversationStatus,
  ConversationStatus
> = {
  active: ConversationStatus.Open,
  inactive: ConversationStatus.Open,
  closed: ConversationStatus.Closed,
};

export interface ITwilioConversationMetadata {
  provider: IntegrationType.Twilio;
  conversationSid: string;
  participantSid: string;
  status: 'active' | 'inactive' | 'closed';
}

export interface IPodiumConversationMetadata {
  provider: IntegrationType.Podium;
  conversationUid: string;
}

export type ConversationProviderMetadata =
  | ITwilioConversationMetadata
  | IPodiumConversationMetadata;

export interface IBaseConversationMessage extends ISoftDelete {
  label?: string;
  patientPhoneNumber: string;
  practicePhoneNumber: string;
  patientRef: DocumentReference<IPatient>;
  practiceRef: DocumentReference<IPractice>;
  assignee?: DocumentReference<IStaffer>;
  lastMessage?: DocumentReference<SMSMessage>;
  lastMessageAt?: Timestamp;
}

export type SMSConversation = IBaseConversationMessage &
  (
    | { status: ConversationStatus.Pending }
    | {
        status: ConversationStatus;
        metadata: ConversationProviderMetadata;
      }
  );

export type TwilioConversation = SMSConversation & {
  metadata: ITwilioConversationMetadata;
};

export type PodiumConversation = SMSConversation & {
  metadata: IPodiumConversationMetadata;
};
