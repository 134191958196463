import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { CustomReport } from '@principle-theorem/principle-core';
import { snapshot, snapshotResolverResult } from '@principle-theorem/shared';
import { CustomReportResolverService } from './custom-report-resolver.service';

@Injectable()
export class HasAccessToCustomReportGuard {
  constructor(
    private _organisation: OrganisationService,
    private _customReport: CustomReportResolverService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const staffer = await snapshot(this._organisation.staffer$);
    const report = await snapshotResolverResult(
      this._customReport.resolve(next)
    );
    return report ? CustomReport.userHasAccess(report, staffer) : false;
  }
}
