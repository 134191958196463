import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BasicDialogService,
  confirmationDialogData,
  CSVExporterService,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { firstValueFrom, snapshot } from '@principle-theorem/shared';
import * as dc from 'dc';
import { ReportBuilderStore } from '../report-builder.store';
import { ResolvedColumns } from './report-builder-column-selector-dialog/resolved-columns';
import {
  IReportBuilderColumnsDialogRequest,
  IReportBuilderColumnsDialogResponse,
  ReportBuilderColumnsDialogComponent,
} from './report-builder-columns-dialog/report-builder-columns-dialog.component';
import { ReportBuilderCSV } from './report-builder-csv';

@Component({
  selector: 'pr-report-builder-results-table',
  templateUrl: './report-builder-results-table.component.html',
  styleUrls: ['./report-builder-results-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderResultsTableComponent {
  constructor(
    public store: ReportBuilderStore,
    private _dialog: MatDialog,
    private _basicDialog: BasicDialogService,
    private _csvExporter: CSVExporterService
  ) {}

  resetFilters(): void {
    dc.filterAll();
    dc.renderAll();
  }

  async editColumns(): Promise<void> {
    const dataSource = await firstValueFrom(this.store.dataSource$);
    const display = await firstValueFrom(this.store.display$);
    if (!dataSource || !display) {
      return;
    }
    const data = {
      dataSource,
      columns: display?.columns ?? [],
    };

    const response = await this._dialog
      .open<
        ReportBuilderColumnsDialogComponent,
        IReportBuilderColumnsDialogRequest,
        IReportBuilderColumnsDialogResponse
      >(
        ReportBuilderColumnsDialogComponent,
        DialogPresets.large({ maxHeight: '80vh', data })
      )
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }
    this.store.setDisplay({ ...display, columns: response.columns });
  }

  async downloadCSV(): Promise<void> {
    const dataSource = await snapshot(this.store.dataSource$);
    if (!dataSource) {
      return;
    }
    const fileName = await this._basicDialog.prompt(
      confirmationDialogData({
        title: 'Download Report',
        prompt: 'Enter a name for the report',
        submitLabel: 'Download',
      })
    );
    if (!fileName) {
      return;
    }

    const results = await snapshot(this.store.results$);
    const records = results?.filtered ?? [];
    const display = await snapshot(this.store.display$);
    const builder = new ReportBuilderCSV(
      ResolvedColumns.toColumns(dataSource, display?.columns ?? [])
    );

    await this._csvExporter.download(fileName, records, builder);
  }
}
