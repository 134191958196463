import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgCoreModule } from '@principle-theorem/ng-core';
import {
  NgInteractionsModule,
  PrincipleEditorModule,
} from '@principle-theorem/ng-interactions';
import { NgLabsModule } from '@principle-theorem/ng-labs';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPerioChartingModule } from '@principle-theorem/ng-perio-charting';
import { NgPrincipleAccountingModule } from '@principle-theorem/ng-principle-accounting';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgTasksModule } from '@principle-theorem/ng-tasks';
import { AddChartedConditionComponent } from './add-charted-item-dialog/add-charted-condition/add-charted-condition.component';
import { AddChartedTreatmentComponent } from './add-charted-item-dialog/add-charted-treatment/add-charted-treatment.component';
import { AvailableSurfacesComponent } from './add-charted-item-dialog/available-surfaces/available-surfaces.component';
import { AddChartedTreatmentButtonComponent } from './add-charted-treatment-button/add-charted-treatment-button.component';
import { AddChartedTreatmentDialogComponent } from './add-charted-treatment-dialog/add-charted-treatment-dialog.component';
import { AddServiceCodeComponent } from './add-service-code/add-service-code.component';
import { ChangeBasePriceDialogComponent } from './change-base-price-dialog/change-base-price-dialog.component';
import { ChartClearSelectionButtonComponent } from './chart-clear-selection-button/chart-clear-selection-button.component';
import { ChartFiltersSelectorComponent } from './chart-filters-selector/chart-filters-selector.component';
import { ChartSectionSelectorComponent } from './chart-section-selector/chart-section-selector.component';
import { ChartSurfaceSelectorDialogComponent } from './chart-surface-selector-dialog/chart-surface-selector-dialog.component';
import { ChartTreatmentSuggestionsComponent } from './chart-treatment-suggestions/chart-treatment-suggestions.component';
import { ChartTypeSelectorComponent } from './chart-type-selector/chart-type-selector.component';
import { ChartUiComponent } from './chart-ui/chart-ui.component';
import { ChartViewSelectorComponent } from './chart-view-selector/chart-view-selector.component';
import { ChartViewToolbarComponent } from './chart-view-toolbar/chart-view-toolbar.component';
import { ChartedItemCardV2Component } from './charted-item-card-v2/charted-item-card-v2.component';
import { ChartedItemHeaderComponent } from './charted-item-header/charted-item-header.component';
import { ChartedItemMultiActionToolbarComponent } from './charted-item-multi-action-toolbar/charted-item-multi-action-toolbar.component';
import { ChartedItemNotesListComponent } from './charted-item-notes-list/charted-item-notes-list.component';
import { ChartedItemToolbarComponent } from './charted-item-toolbar/charted-item-toolbar.component';
import { ChartedConditionCardComponent } from './charted-items-display/charted-condition-card/charted-condition-card.component';
import { ChartedConditionSummaryComponent } from './charted-items-display/charted-condition-summary/charted-condition-summary.component';
import { ChartedConditionsComponent } from './charted-items-display/charted-conditions/charted-conditions.component';
import { ChartedItemNotesDialogComponent } from './charted-items-display/charted-item-notes-dialog/charted-item-notes-dialog.component';
import { ChartedTreatmentPlanComponent } from './charted-items-display/charted-treatment-plan/charted-treatment-plan.component';
import { ChartedTreatmentsComponent } from './charted-items-display/charted-treatments/charted-treatments.component';
import { FilteredFlaggedTreatmentComponent } from './charted-items-display/charted-treatments/filtered-flagged-treatment/filtered-flagged-treatment.component';
import { FlaggedTreatmentComponent } from './charted-items-display/charted-treatments/flagged-treatment/flagged-treatment.component';
import { ConditionsSearchComponent } from './charted-items-display/conditions-search/conditions-search.component';
import { SearchResultChartedItemComponent } from './charted-items-display/search-result-charted-item/search-result-charted-item.component';
import { TreatConditionDialogComponent } from './charted-items-display/treat-condition-dialog/treat-condition-dialog.component';
import { TreatmentsSearchComponent } from './charted-items-display/treatments-search/treatments-search.component';
import { ChartedItemsSurfaceListComponent } from './charted-items-surface-list/charted-items-surface-list.component';
import { ChartingHistoryListComponent } from './charting-history-list/charting-history-list.component';
import { ChartingHistorySelectorComponent } from './charting-history-selector/charting-history-selector.component';
import { ClinicalChartComponent } from './clinical-chart/clinical-chart.component';
import { ClinicalNoteEditDialogComponent } from './clinical-note-edit-dialog/clinical-note-edit-dialog.component';
import { ClinicalNotesHistoryDialogComponent } from './clinical-notes-history-dialog/clinical-notes-history-dialog.component';
import { ClinicalNotesListComponent } from './clinical-notes-list/clinical-notes-list.component';
import { ClinicalNotesComponent } from './clinical-notes/clinical-notes.component';
import { ConditionConfigurationListComponent } from './condition-configuration-list/condition-configuration-list.component';
import { CreateConditionConfigurationComponent } from './create-condition-configuration/create-condition-configuration.component';
import { CreateMultiTreatmentConfigurationComponent } from './create-multi-treatment-configuration/create-multi-treatment-configuration.component';
import { CreateTreatmentConfigurationComponent } from './create-treatment-configuration/create-treatment-configuration.component';
import { DentalChartSvgModule } from './dental-chart-svg/dental-chart-svg.module';
import { DisplayBasePriceComponent } from './display-base-price/display-base-price.component';
import { DownloadClinicalNotesComponent } from './download-clinical-notes/download-clinical-notes.component';
import { EditBasePriceComponent } from './edit-base-price/edit-base-price.component';
import { ConditionChartingModule } from './edit-condition-configuration/condition-charting/condition-charting.module';
import { EditConditionConfigurationComponent } from './edit-condition-configuration/edit-condition-configuration.component';
import { GeneralConditionConfigurationComponent } from './edit-condition-configuration/general-condition-configuration/general-condition-configuration.component';
import { EditMultiTreatmentConfigurationComponent } from './edit-multi-treatment-configuration/edit-multi-treatment-configuration.component';
import { EditTreatmentStepConfigurationDialogComponent } from './edit-multi-treatment-configuration/edit-treatment-step-configuration-dialog/edit-treatment-step-configuration-dialog.component';
import { GeneralMultiTreatmentConfigurationComponent } from './edit-multi-treatment-configuration/general-multi-treatment-configuration/general-multi-treatment-configuration.component';
import { TreatmentStepsConfigurationComponent } from './edit-multi-treatment-configuration/treatment-steps-configuration/treatment-steps-configuration.component';
import { EditTodaysAppointmentDialogComponent } from './edit-todays-appointment-dialog/edit-todays-appointment-dialog.component';
import { AddChecklistItemsComponent } from './edit-treatment-configuration/checklist-configuration/add-checklist-items/add-checklist-items.component';
import { ChecklistConfigurationComponent } from './edit-treatment-configuration/checklist-configuration/checklist-configuration.component';
import { ChecklistItemsComponent } from './edit-treatment-configuration/checklist-configuration/checklist-items/checklist-items.component';
import { ConditionSelectorComponent } from './edit-treatment-configuration/condition-selector/condition-selector.component';
import { EditConditionComponent } from './edit-treatment-configuration/condition-selector/edit-condition/edit-condition.component';
import { EditTreatmentConfigurationComponent } from './edit-treatment-configuration/edit-treatment-configuration.component';
import { EducationalAidsComponent } from './edit-treatment-configuration/educational-aids/educational-aids.component';
import { TreatmentAutomatedNotificationConfigurationComponent } from './edit-treatment-configuration/treatment-automated-notification-configuration/treatment-automated-notification-configuration.component';
import { TreatmentChartingOptionsComponent } from './edit-treatment-configuration/treatment-charting-options/treatment-charting-options.component';
import { TreatmentChartingModule } from './edit-treatment-configuration/treatment-charting/treatment-charting.module';
import { TreatmentGeneratedTaskConfigurationComponent } from './edit-treatment-configuration/treatment-generated-task-configuration/treatment-generated-task-configuration.component';
import { ExpandableTreatmentStepComponent } from './expandable-treatment-step/expandable-treatment-step.component';
import { MultiStepTreatmentComponent } from './multi-step-treatment/multi-step-treatment.component';
import { MultiTreatmentConfigurationListComponent } from './multi-treatment-configuration-list/multi-treatment-configuration-list.component';
import { MultiTreatmentSurfaceSelectorDialogComponent } from './multi-treatment-surface-selector-dialog/multi-treatment-surface-selector-dialog.component';
import { NextTreatmentStepSelectorComponent } from './next-treatment-step-selector/next-treatment-step-selector.component';
import { PerioChartUiComponent } from './perio-chart-ui/perio-chart-ui.component';
import { PractitionerSelectorComponent } from './practitioner-selector/practitioner-selector.component';
import { ConditionsQuickChartComponent } from './quick-charting/conditions-quick-chart/conditions-quick-chart.component';
import { QuickChartBarComponent } from './quick-charting/quick-chart-bar/quick-chart-bar.component';
import { TreatmentsQuickChartComponent } from './quick-charting/treatments-quick-chart/treatments-quick-chart.component';
import { ServiceGroupsModule } from './service-groups/service-groups.module';
import { ServiceItemListComponent } from './service-item-list/service-item-list.component';
import { ServiceItemSmartGroupItemComponent } from './service-item-smart-group-item/service-item-smart-group-item.component';
import { ServiceItemSmartGroupListComponent } from './service-item-smart-group-list/service-item-smart-group-list.component';
import { ServiceItemComponent } from './service-item/service-item.component';
import { ServiceLineItemComponent } from './service-line-item/service-line-item.component';
import { ServicePricingRuleItemSelectComponent } from './service-pricing-rule-item-select/service-pricing-rule-item-select.component';
import { StaffFeeScheduleSelectorComponent } from './staff-fee-schedule-selector/staff-fee-schedule-selector.component';
import { TodaysAppointmentComponent } from './todays-appointment/todays-appointment.component';
import { TreatmentConfigurationListComponent } from './treatment-configuration-list/treatment-configuration-list.component';
import { TreatmentPlanHeaderComponent } from './treatment-plan-header/treatment-plan-header.component';
import { TreatmentPlanListDialogComponent } from './treatment-plan-list-dialog/treatment-plan-list-dialog.component';
import { TreatmentPlanListComponent } from './treatment-plan-list/treatment-plan-list.component';
import { TreatmentPlanPrintComponent } from './treatment-plan-print/treatment-plan-print.component';
import { TreatmentPlanStepsComponent } from './treatment-plan-steps/treatment-plan-steps.component';
import { TreatmentPlanSummaryComponent } from './treatment-plan-summary/treatment-plan-summary.component';
import { TreatmentPlanTableComponent } from './treatment-plan-table/treatment-plan-table.component';
import { TreatmentPlanningComponent } from './treatment-planning/treatment-planning.component';
import { TreatmentPlansConsolidatedComponent } from './treatment-plans-consolidated/treatment-plans-consolidated.component';
import { TreatmentPlansConsolidatedStore } from './treatment-plans-consolidated/treatment-plans-consolidated.store';
import { TreatmentPlansComponent } from './treatment-plans/treatment-plans.component';
import { TreatmentScopeListComponent } from './treatment-scope-list/treatment-scope-list.component';
import { TreatmentScopeComponent } from './treatment-scope/treatment-scope.component';
import { TreatmentStepAppointmentStatusComponent } from './treatment-step-appointment-status/treatment-step-appointment-status.component';
import { TreatmentStepHeaderComponent } from './treatment-step-header/treatment-step-header.component';
import { TreatmentStepRenameDialogComponent } from './treatment-step-rename-dialog/treatment-step-rename-dialog.component';
import { TreatmentStepStatusComponent } from './treatment-step-status/treatment-step-status.component';
import { TreatmentPlanStatusComponent } from './treatment-plan-status/treatment-plan-status.component';
import { TreatmentStepComponent } from './treatment-step/treatment-step.component';
import { EditTreatmentStepDialogComponent } from './treatment-steps-editor/edit-treatment-step-dialog/edit-treatment-step-dialog.component';
import { TreatmentPlanEditorHeaderComponent } from './treatment-steps-editor/treatment-plan-editor-header/treatment-plan-editor-header.component';
import { TreatmentPlanEditorComponent } from './treatment-steps-editor/treatment-plan-editor/treatment-plan-editor.component';
import { TreatmentPlanViewTypeSelectorComponent } from './treatment-steps-editor/treatment-plan-view-type-selector/treatment-plan-view-type-selector.component';
import { TreatmentStepsEditorComponent } from './treatment-steps-editor/treatment-steps-editor.component';
import { TreatmentStepsEditorService } from './treatment-steps-editor/treatment-steps-editor.service';
import { TreatmentPackagesComponent } from './edit-treatment-configuration/treatment-packages/treatment-packages.component';
import { NgClinicalFeesModule } from '@principle-theorem/ng-clinical-fees';
import { EditTreatmentPackageComponent } from './edit-treatment-configuration/treatment-packages/edit-treatment-package/edit-treatment-package.component';
import { StaffFeeScheduleMenuComponent } from './staff-fee-schedule-menu/staff-fee-schedule-menu.component';
import { MultiTreatmentPackagesComponent } from './edit-multi-treatment-configuration/multi-treatment-packages/multi-treatment-packages.component';
import { EditMultiTreatmentPackageComponent } from './edit-multi-treatment-configuration/multi-treatment-packages/edit-multi-treatment-package/edit-multi-treatment-package.component';
import { EditTreatmentPackageServiceItemsComponent } from './edit-treatment-configuration/treatment-packages/edit-treatment-package-service-items/edit-treatment-package-service-items.component';
import { TreatmentPackageMenuComponent } from './treatment-package-menu/treatment-package-menu.component';
import { ServiceItemExclusiveGroupItemComponent } from './service-item-exclusive-group-item/service-item-exclusive-group-item.component';
import { ServiceItemExclusiveGroupListComponent } from './service-item-exclusive-group-list/service-item-exclusive-group-list.component';
import { PerioChartSettingsComponent } from './perio-chart-settings/perio-chart-settings.component';

@NgModule({
  imports: [
    ConditionChartingModule,
    TreatmentChartingModule,
    ServiceGroupsModule,
    NgMaterialModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    ReactiveFormsModule,
    NgPerioChartingModule,
    PrincipleEditorModule,
    NgPrincipleAccountingModule,
    NgCoreModule,
    DentalChartSvgModule,
    NgTasksModule,
    NgLabsModule,
    NgClinicalFeesModule,
    NgInteractionsModule,
    PractitionerSelectorComponent,
  ],
  declarations: [
    ServiceItemListComponent,
    ServiceLineItemComponent,
    AddServiceCodeComponent,
    ChartedItemCardV2Component,
    ChartedItemHeaderComponent,
    ChartedItemsSurfaceListComponent,
    MultiStepTreatmentComponent,
    TreatmentScopeComponent,
    TreatmentScopeListComponent,
    TreatmentStepComponent,
    TreatmentStepHeaderComponent,
    TreatmentStepRenameDialogComponent,
    ServiceItemSmartGroupListComponent,
    ServiceItemSmartGroupItemComponent,
    ChartedItemNotesListComponent,
    AddChartedTreatmentDialogComponent,
    AddChartedTreatmentButtonComponent,
    ChartedConditionCardComponent,
    ChartedConditionSummaryComponent,
    ChartedTreatmentsComponent,
    ChartedConditionsComponent,
    ConditionsSearchComponent,
    TreatmentsSearchComponent,
    SearchResultChartedItemComponent,
    ChartedTreatmentPlanComponent,
    ChartedItemNotesDialogComponent,
    TreatConditionDialogComponent,
    FlaggedTreatmentComponent,
    FilteredFlaggedTreatmentComponent,
    TodaysAppointmentComponent,
    ChartUiComponent,
    ChartViewSelectorComponent,
    ChartSectionSelectorComponent,
    ChartFiltersSelectorComponent,
    ClinicalNotesComponent,
    ClinicalNotesHistoryDialogComponent,
    AvailableSurfacesComponent,
    AddChartedConditionComponent,
    AddChartedTreatmentComponent,
    ChartTreatmentSuggestionsComponent,
    ChartSurfaceSelectorDialogComponent,
    QuickChartBarComponent,
    ConditionsQuickChartComponent,
    TreatmentsQuickChartComponent,
    StaffFeeScheduleSelectorComponent,
    ChartClearSelectionButtonComponent,
    ClinicalChartComponent,
    NextTreatmentStepSelectorComponent,
    ConditionConfigurationListComponent,
    CreateConditionConfigurationComponent,
    EditConditionConfigurationComponent,
    GeneralConditionConfigurationComponent,
    CreateTreatmentConfigurationComponent,
    EditTreatmentConfigurationComponent,
    TreatmentConfigurationListComponent,
    TreatmentGeneratedTaskConfigurationComponent,
    ChecklistConfigurationComponent,
    TreatmentAutomatedNotificationConfigurationComponent,
    EducationalAidsComponent,
    AddChecklistItemsComponent,
    ChecklistItemsComponent,
    TreatmentChartingOptionsComponent,
    ConditionSelectorComponent,
    EditConditionComponent,
    MultiTreatmentConfigurationListComponent,
    CreateMultiTreatmentConfigurationComponent,
    EditMultiTreatmentConfigurationComponent,
    GeneralMultiTreatmentConfigurationComponent,
    TreatmentStepsConfigurationComponent,
    EditTreatmentStepConfigurationDialogComponent,
    ChartingHistorySelectorComponent,
    ChartTypeSelectorComponent,
    PerioChartUiComponent,
    ChartViewToolbarComponent,
    TreatmentPlanningComponent,
    TreatmentPlanEditorComponent,
    EditTreatmentStepDialogComponent,
    TreatmentStepsEditorComponent,
    TreatmentPlanSummaryComponent,
    EditTodaysAppointmentDialogComponent,
    TreatmentPlanPrintComponent,
    EditBasePriceComponent,
    DisplayBasePriceComponent,
    ChartingHistoryListComponent,
    ServicePricingRuleItemSelectComponent,
    ServiceItemComponent,
    ClinicalNotesListComponent,
    TreatmentPlanTableComponent,
    TreatmentStepStatusComponent,
    TreatmentPlanStatusComponent,
    TreatmentPlansComponent,
    TreatmentPlanHeaderComponent,
    TreatmentPlanStepsComponent,
    ExpandableTreatmentStepComponent,
    TreatmentStepAppointmentStatusComponent,
    ClinicalNoteEditDialogComponent,
    TreatmentPlanEditorHeaderComponent,
    TreatmentPlanListComponent,
    TreatmentPlanListDialogComponent,
    TreatmentPlansConsolidatedComponent,
    TreatmentPlanViewTypeSelectorComponent,
    ChangeBasePriceDialogComponent,
    ChartedItemMultiActionToolbarComponent,
    MultiTreatmentSurfaceSelectorDialogComponent,
    ChartedItemToolbarComponent,
    DownloadClinicalNotesComponent,
    TreatmentPackagesComponent,
    EditTreatmentPackageComponent,
    StaffFeeScheduleMenuComponent,
    MultiTreatmentPackagesComponent,
    EditMultiTreatmentPackageComponent,
    EditTreatmentPackageServiceItemsComponent,
    TreatmentPackageMenuComponent,
    ServiceItemExclusiveGroupItemComponent,
    ServiceItemExclusiveGroupListComponent,
    PerioChartSettingsComponent,
  ],
  exports: [
    ServiceItemListComponent,
    ServiceLineItemComponent,
    AddServiceCodeComponent,
    ChartedItemCardV2Component,
    ChartedItemHeaderComponent,
    ChartedItemsSurfaceListComponent,
    MultiStepTreatmentComponent,
    TreatmentScopeComponent,
    TreatmentScopeListComponent,
    TreatmentStepComponent,
    TreatmentStepHeaderComponent,
    TreatmentStepRenameDialogComponent,
    ServiceItemSmartGroupListComponent,
    ServiceItemSmartGroupItemComponent,
    ChartedItemNotesListComponent,
    AddChartedTreatmentDialogComponent,
    AddChartedTreatmentButtonComponent,
    ChartedConditionCardComponent,
    ChartedConditionSummaryComponent,
    ChartedTreatmentsComponent,
    ChartedConditionsComponent,
    ConditionsSearchComponent,
    TreatmentsSearchComponent,
    SearchResultChartedItemComponent,
    ChartedTreatmentPlanComponent,
    ChartedItemNotesDialogComponent,
    TreatConditionDialogComponent,
    FlaggedTreatmentComponent,
    FilteredFlaggedTreatmentComponent,
    TodaysAppointmentComponent,
    ChartUiComponent,
    ClinicalNotesComponent,
    AvailableSurfacesComponent,
    ClinicalChartComponent,
    NextTreatmentStepSelectorComponent,
    ConditionConfigurationListComponent,
    CreateConditionConfigurationComponent,
    EditConditionConfigurationComponent,
    GeneralConditionConfigurationComponent,
    TreatmentChartingModule,
    EditTreatmentConfigurationComponent,
    TreatmentConfigurationListComponent,
    ConditionSelectorComponent,
    TreatmentChartingOptionsComponent,
    MultiTreatmentConfigurationListComponent,
    CreateMultiTreatmentConfigurationComponent,
    EditMultiTreatmentConfigurationComponent,
    ChartingHistorySelectorComponent,
    PerioChartUiComponent,
    TreatmentPlanningComponent,
    TreatmentPlanEditorComponent,
    EditTreatmentStepDialogComponent,
    TreatmentStepsEditorComponent,
    PractitionerSelectorComponent,
    StaffFeeScheduleSelectorComponent,
    TreatmentPlanSummaryComponent,
    TreatmentGeneratedTaskConfigurationComponent,
    TreatmentAutomatedNotificationConfigurationComponent,
    EditTodaysAppointmentDialogComponent,
    TreatmentPlanPrintComponent,
    ChartingHistoryListComponent,
    ClinicalNotesListComponent,
    TreatmentPlanTableComponent,
    TreatmentStepStatusComponent,
    TreatmentPlanStatusComponent,
    TreatmentPlansComponent,
    TreatmentPlanHeaderComponent,
    TreatmentPlanStepsComponent,
    ExpandableTreatmentStepComponent,
    ChartTypeSelectorComponent,
    ClinicalNoteEditDialogComponent,
    TreatmentPlansConsolidatedComponent,
    ChartedItemMultiActionToolbarComponent,
    DownloadClinicalNotesComponent,
    ServiceItemExclusiveGroupItemComponent,
    ServiceItemExclusiveGroupListComponent,
    PerioChartSettingsComponent,
  ],
  providers: [TreatmentStepsEditorService, TreatmentPlansConsolidatedStore],
})
export class ComponentsModule {}
