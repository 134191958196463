<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">Provider Settings</h1>

  <form
    [formGroup]="form"
    (ngSubmit)="submit()"
    fxLayout="column"
    fxLayoutGap="32px"
  >
    <div formArrayName="providerDetails">
      <h3 class="mat-subtitle-1">Healthcare Claim Details</h3>
      <pr-provider-details-input
        *ngFor="
          let control of providerDetails.controls;
          trackBy: trackByIndex;
          index as index;
          first as isFirst;
          last as isLast
        "
        [form]="control"
      >
        <div fxLayout="row" fxLayoutGap="8px">
          <button
            mat-icon-button
            [disabled]="isFirst"
            (click)="providerDetailControls.removeControl(index)"
            matTooltip="Remove provider option"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="practices$ | async as practices"
            [disabled]="!isLast || practices.length <= index + 1"
            (click)="providerDetailControls.addControl(index)"
            matTooltip="Add new provider option"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </pr-provider-details-input>
    </div>
    <div fxLayout="column" fxLayoutGap="16px">
      <h3 class="mat-subtitle-1">Prescription Prescriber Number</h3>
      <mat-form-field>
        <mat-label>Prescriber Number</mat-label>
        <input matInput formControlName="prescriberNumber" />
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayoutGap="16px">
      <h3 class="mat-subtitle-1">Qualifications</h3>
      <mat-form-field>
        <mat-label>Qualifications</mat-label>
        <input matInput formControlName="qualifications" />
        <mat-hint>
          Separate multiple qualifications with a comma e.g. MBBS, FRACGP
        </mat-hint>
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayoutGap="16px">
      <h3 class="mat-subtitle-1">Other Details</h3>
      <mat-form-field>
        <mat-label>Provider Commission</mat-label>
        <input
          matInput
          type="number"
          min="0"
          max="100"
          formControlName="providerCommission"
        />
        <span matTextSuffix>%</span>
      </mat-form-field>
    </div>
    <pt-buttons-container>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Save
      </button>
    </pt-buttons-container>
  </form>
</div>
