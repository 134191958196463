import { ReportBuilderDataSourceId } from '@principle-theorem/principle-core/interfaces';
import { FactTables, toMeasureBuilderData } from '@principle-theorem/reporting';
import { ChartFilters } from './chart-filters';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ReportBuilderColumns } from './report-builder-columns';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { ReportBuilderGroups } from './report-builder-groups';

const factTable = FactTables.invoiceEvent.latestEvent;

export const INVOICES_REPORT_BUILDER_DATA_SOURCE: IReportBuilderDataSource = {
  id: ReportBuilderDataSourceId.Invoices,
  name: 'Invoices',
  description:
    'One result per Invoice within Principle. This includes information about the practice and patient associated with each invoice.',
  factTable,
  queryableTimestamps: [
    { property: factTable.invoice.createdAt },
    { property: factTable.invoice.issuedAt },
    { property: factTable.invoice.paidAt },
    { property: factTable.timestamp },
  ],
  groupByOptions: [
    ReportBuilderGroups.toGroup('Common', [
      ReportBuilderGroups.toOption(factTable.invoice.status),
      ReportBuilderGroups.toOption(factTable.invoice.issuedAt),
      ReportBuilderGroups.toOption(factTable.invoice.paidAt),
      ReportBuilderGroups.toOption(factTable.practice.name),
      ReportBuilderGroups.toOption(factTable.brand.name),
      ReportBuilderGroups.toOption(factTable.count),
    ]),
    ReportBuilderGroups.invoice(factTable.invoice),
    ReportBuilderGroups.patient(factTable.patient),
  ],
  availableColumns: [
    ReportBuilderColumns.toColumnGroup('Invoice', [
      factTable.isOverdue,
      factTable.paidOnCheckout,
      factTable.paidToDate,
      factTable.amountRemaining,
      factTable.subTotal,
      factTable.tax,
      factTable.practice.name,
      ...ReportBuilderColumns.invoice(factTable.invoice),
      factTable.link,
    ]),
    ReportBuilderColumns.toColumnGroup('Patient', [
      ...ReportBuilderColumns.patient(factTable.patient),
      factTable.patientLink,
    ]),
  ],
  defaultColumns: ReportBuilderColumns.toReferences([
    factTable.patient.name,
    factTable.invoice.createdAt,
    factTable.invoice.total,
    factTable.invoice.status,
    factTable.link,
  ]),
  availableCharts: [
    ReportBuilderChartFilters.predefined(
      'overview.brand.price',
      factTable.brand.name,
      ChartFilters.numberChart('Total Price', factTable.brand.name, [
        toMeasureBuilderData(factTable.total),
      ])
    ),
    ReportBuilderChartFilters.predefined(
      'overview.practice',
      factTable.practice.name,
      ChartFilters.pieChart('Practice', factTable.practice.name, [
        toMeasureBuilderData(factTable.total),
      ])
    ),
    ReportBuilderChartFilters.predefined(
      'overview.totalInvoiced',
      factTable.invoice.createdAt,
      ChartFilters.columnChartOverTime(
        'Total Invoiced',
        factTable.invoice.createdAt,
        [toMeasureBuilderData(factTable.total)]
      )
    ),
    ...ReportBuilderChartFilters.patient(factTable.patient, factTable.total),
    ...ReportBuilderChartFilters.invoice(factTable.invoice, factTable.total),
  ],
};
