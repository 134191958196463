<mat-form-field fxFlex *ngIf="tagSearch$ | ngrxPush as tagSearch">
  <mat-label>Tags</mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let tag of tagsInput.tags$ | ngrxPush; trackBy: trackByTag"
      [style.--mdc-chip-label-text-color]="tag | tagTextColour"
      [style.background-color]="tag.hexColour"
      [removable]="false"
      (removed)="tagsInput.remove(tag)"
    >
      <span fxFlex>{{ tag.name }}</span>
      <mat-icon matChipRemove (click)="tagsInput.remove(tag)">cancel</mat-icon>
    </mat-chip-row>

    @if (canManageTags$ | async) {
      <input
        #tagInput
        #trigger="matAutocompleteTrigger"
        [formControl]="tagCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (keydown.enter)="trigger.closePanel(); create(tagInput.value)"
      />
    } @else {
      <input
        #tagInput
        #trigger="matAutocompleteTrigger"
        [formControl]="tagCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (keydown.enter)="trigger.closePanel()"
      />
    }

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="add($event.option.value)"
    >
      <mat-option
        *ngFor="let tag of tagSearch.results$ | ngrxPush; trackBy: trackByTag"
        [value]="tag"
      >
        <mat-chip-row
          [disabled]="true"
          [style.--mdc-chip-disabled-label-text-color]="tag | tagTextColour"
          [style.background-color]="tag.hexColour"
        >
          {{ tag.name }}
        </mat-chip-row>
      </mat-option>
    </mat-autocomplete>
  </mat-chip-grid>
</mat-form-field>
