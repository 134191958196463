import {
  type INamedDocument,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import { type IEvent, type IEventable, isEvent } from '../event/event';
import { type IStaffer } from '../staffer/staffer';
import { type ITag } from '../tag/tag';

export interface ISchedulingConflict {
  reason: string;
  blocking: boolean;
}

export interface IAppointmentSuggestion {
  event: IEvent;
  schedulingConflicts: ISchedulingConflict[];
  practitioner: INamedDocument<IStaffer>;
  score: number;
  intersectingTags: INamedDocument<ITag>[];
  overlappingEvents: IEventable[];
  adjacentEvents: IEventable[];
}

export function isAppointmentSuggestion(
  data: unknown
): data is IAppointmentSuggestion {
  return (
    isObject(data) &&
    'event' in data &&
    isEvent(data.event) &&
    'practitioner' in data &&
    isINamedDocument(data.practitioner) &&
    isNumber(data.score) &&
    'intersectingTags' in data &&
    'schedulingConflicts' in data &&
    'overlappingEvents' in data &&
    'adjacentEvents' in data
  );
}

export interface IEnabledSuggestionMatchRules {
  distance: boolean;
  duration: boolean;
  overlap: boolean;
}
