import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  Brand,
  LabJob,
  OrganisationCache,
  Patient,
} from '@principle-theorem/principle-core';
import {
  PatientRelationshipType,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  filterUndefined,
  multiFilter,
  serialise$,
  unserialise$,
} from '@principle-theorem/shared';
import { of, type Observable } from 'rxjs';
import {
  concatMap,
  map,
  repeatWhen,
  skipWhile,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { LabJobInteractionsDialogComponent } from '../components/lab-job-interactions-dialog/lab-job-interactions-dialog.component';
import { CreateLabJobActionService } from '../create-lab-job-action.service';
import * as LabJobActions from './lab-jobs.actions';
import { LabJobsFacade } from './lab-jobs.facade';

@Injectable({ providedIn: 'root' })
export class LabJobsEffects {
  private _actions$ = inject(Actions);
  private _currentScopeFacade = inject(CurrentScopeFacade);
  private _labJobsFacade = inject(LabJobsFacade);
  loadPracticeLabJobs$: Observable<Action> = createEffect(() =>
    this._loadPracticeLabJobs$()
  );
  viewLabJob$: Observable<Action> = createEffect(() => this._viewLabJob$());
  loadPatientLabJobs$: Observable<Action> = createEffect(() =>
    this._loadPatientLabJobs$()
  );
  addLabJob$: Observable<void> = createEffect(() => this._addLabJob$(), {
    dispatch: false,
  });
  viewLabJobFromRoute$: Observable<Action> = createEffect(() =>
    this._viewLabJobFromRoute$()
  );
  loadLabJobsForLab$: Observable<Action> = createEffect(() =>
    this._loadLabJobsForLab$()
  );
  loadLabJobFromRouteSuccess$: Observable<Action> = createEffect(() =>
    this._loadLabJobFromRouteSuccess$()
  );

  constructor(
    private _dialog: MatDialog,
    private _createLabJobActionService: CreateLabJobActionService
  ) {}

  private _loadPatientLabJobs$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(LabJobActions.loadPatientLabJobs),
      unserialise$(),
      switchMap(({ patientRef }) =>
        OrganisationCache.patients.getDoc(asDocRef<IPatient>(patientRef))
      ),
      withLatestFrom(
        this._currentScopeFacade.currentBrand$.pipe(filterUndefined())
      ),
      switchMap(([patient, brand]) => {
        return Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          (patientReffable) => Patient.labJobs$(patientReffable, brand)
        ).pipe(
          multiFilter((labJob) => !LabJob.complete(labJob)),
          // eslint-disable-next-line rxjs/no-unsafe-takeuntil
          takeUntil(this._labJobsFacade.stopWatching$),
          repeatWhen(() => this._labJobsFacade.startWatching$)
        );
      }),
      serialise$(),
      map((labJobs) => LabJobActions.loadLabJobsSuccess({ labJobs }))
    );
  }

  private _loadPracticeLabJobs$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(LabJobActions.loadPracticeLabJobs),
      withLatestFrom(
        this._currentScopeFacade.currentPractice$.pipe(filterUndefined())
      ),
      switchMap(([_, practice]) => LabJob.all$(practice)),
      serialise$(),
      map((labJobs) => LabJobActions.loadLabJobsSuccess({ labJobs })),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(this._labJobsFacade.stopWatching$),
      repeatWhen(() => this._labJobsFacade.startWatching$)
    );
  }

  private _loadLabJobsForLab$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(LabJobActions.loadLabJobsForLab),
      withLatestFrom(
        this._currentScopeFacade.currentBrand$.pipe(filterUndefined())
      ),
      switchMap(([action, brand]) => {
        return Brand.labJobs$(brand).pipe(
          multiFilter((labJob) => labJob.lab.ref.id === action.id),
          // eslint-disable-next-line rxjs/no-unsafe-takeuntil
          takeUntil(this._labJobsFacade.stopWatching$),
          repeatWhen(() => this._labJobsFacade.startWatching$)
        );
      }),
      serialise$(),
      map((labJobs) => LabJobActions.loadLabJobsForLabSuccess({ labJobs }))
    );
  }

  private _viewLabJobFromRoute$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(LabJobActions.selectJobFromRoute),
      withLatestFrom(this._labJobsFacade.selectedLabJob$),
      switchMap(([action, selectedLabJob]) => {
        if (selectedLabJob) {
          return of(LabJobActions.viewLabJob());
        }
        return this._currentScopeFacade.currentBrand$.pipe(
          filterUndefined(),
          switchMap((brand) => {
            return Brand.labJobs$(brand).pipe(
              multiFilter((labJob) => labJob.ref.id === action.id),
              map((labJobs) => labJobs[0]),
              serialise$(),
              map((labJob) => {
                if (!labJob) {
                  return LabJobActions.loadLabJobFromRouteFailure();
                }
                return LabJobActions.loadLabJobFromRouteSuccess({ labJob });
              })
            );
          })
        );
      })
    );
  }

  private _loadLabJobFromRouteSuccess$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(LabJobActions.loadLabJobFromRouteSuccess),
      map(() => LabJobActions.viewLabJob())
    );
  }

  // TODO: https://app.clickup.com/t/20peb8
  private _viewLabJob$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(LabJobActions.viewLabJob),
      withLatestFrom(this._labJobsFacade.selectedLabJob$),
      skipWhile((labJob) => labJob === undefined),
      switchMap(([_, labJob]) => {
        if (this._dialog.openDialogs.length) {
          return of(LabJobActions.cancelFilterAction());
        }
        return this._dialog
          .open(
            LabJobInteractionsDialogComponent,
            DialogPresets.large({
              height: '80%',
              autoFocus: false,
              data: { labJob },
            })
          )
          .afterClosed()
          .pipe(map(() => LabJobActions.clearSelectedLabJob()));
      })
    );
  }

  // TODO: https://app.clickup.com/t/1yw3vf
  private _addLabJob$(): Observable<void> {
    return this._actions$.pipe(
      ofType(LabJobActions.addLabJob),
      concatMap(() => this._createLabJobActionService.do())
    );
  }
}
