import { Inject, Injectable } from '@angular/core';
import { TypesenseSearchService as SharedTypesenseSearchService } from '@principle-theorem/ng-shared';
import {
  ITypesensePatient,
  ITypesensePatientWithRef,
  ITypesenseTask,
  ITypesenseTaskWithRef,
  Organisation,
  PrincipleTypesenseCollection,
} from '@principle-theorem/principle-core';
import {
  IPatient,
  ITask,
  TaskStatus,
  TypesenseCluster,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  asDocRef,
  filterUndefined,
  snapshot,
} from '@principle-theorem/shared';
import { Typesense } from '@principle-theorem/typesense';
import { of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';
import {
  INgPrincipleSharedConfig,
  NG_PRINCIPLE_SHARED_CONFIG,
} from '../ng-principle-shared-config';
import { OrganisationService } from '../organisation.service';
import {
  IPatientBlockFilters,
  TypesensePatientListBloc,
} from './typesense-patient-list-bloc';
import {
  ITaskBlockFilters,
  TypesenseTaskListBloc,
} from './typesense-task-list-bloc';

export interface ISearchResults<T> {
  results: T[];
  numberFound: number;
  totalAvailable: number;
}

@Injectable({
  providedIn: 'root',
})
export class TypesenseSearchService {
  results$: Observable<ITypesensePatient[]>;

  constructor(
    private _typesense: SharedTypesenseSearchService,
    private _organisation: OrganisationService,
    @Inject(NG_PRINCIPLE_SHARED_CONFIG)
    private _config: INgPrincipleSharedConfig
  ) {}

  patientQuery$(
    search$: Observable<string>,
    filters$: Observable<IPatientBlockFilters> = of({
      showDuplicate: false,
      showDeleted: false,
    }),
    page$?: Observable<number>,
    numberOfResults$?: Observable<number>,
    sortBy: string | string[] = '_text_match:desc,name:asc'
  ): Observable<ISearchResults<ITypesensePatientWithRef>> {
    const organisation$ =
      this._organisation.organisation$.pipe(filterUndefined());
    const brand$ = this._organisation.brand$.pipe(filterUndefined());
    const patientBloc = new TypesensePatientListBloc(
      this._config.typesense[TypesenseCluster.Patients],
      this._typesense,
      [
        'name',
        'searchNames',
        'dateOfBirth',
        'searchDateOfBirths',
        'email',
        'contactNumbers',
        'searchContactNumbers',
      ],
      organisation$,
      brand$,
      search$,
      filters$,
      page$,
      numberOfResults$,
      sortBy
    );

    return patientBloc.results$.pipe(
      map((response) =>
        this.toSearchResults<
          IPatient,
          ITypesensePatient,
          ITypesensePatientWithRef
        >(response)
      )
    );
  }

  similarPatientsQuery$(
    search$: Observable<string>,
    numberOfResults$?: Observable<number>
  ): Observable<ISearchResults<ITypesensePatientWithRef>> {
    return this.patientQuery$(
      search$,
      of({
        showDuplicate: false,
        showDeleted: false,
      }),
      of(0),
      numberOfResults$,
      'name:asc'
    );
  }

  patientTaskQuery$(
    patientRef: DocumentReference<IPatient>
  ): Observable<ISearchResults<ITypesenseTaskWithRef>> {
    return this.taskQuery$(
      of('*'),
      of({
        statuses: [TaskStatus.Open],
        showDeleted: false,
        mentionRefs: [patientRef],
      }),
      of('title:asc')
    );
  }

  taskQuery$(
    search$: Observable<string>,
    filters$: Observable<ITaskBlockFilters>,
    sortBy$: Observable<string | string[]>,
    page$?: Observable<number>,
    numberOfResults$?: Observable<number>
  ): Observable<ISearchResults<ITypesenseTaskWithRef>> {
    const organisation$ =
      this._organisation.organisation$.pipe(filterUndefined());
    const brand$ = this._organisation.brand$.pipe(filterUndefined());
    const taskBloc = new TypesenseTaskListBloc(
      this._config.typesense[TypesenseCluster.Tasks],
      this._typesense,
      organisation$,
      brand$,
      search$,
      filters$,
      page$,
      numberOfResults$,
      sortBy$
    );

    return taskBloc.results$.pipe(
      map((response) =>
        this.toSearchResults<ITask, ITypesenseTask, ITypesenseTaskWithRef>(
          response
        )
      )
    );
  }

  async clearTaskCache(): Promise<void> {
    const organisation = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );
    const brand = await snapshot(
      this._organisation.brand$.pipe(filterUndefined())
    );

    await this._typesense.clearCache(
      Organisation.integrationCol(organisation),
      Typesense.getScopedCollectionName(
        [organisation.ref, brand.ref],
        PrincipleTypesenseCollection.Tasks
      ),
      this._config.typesense[TypesenseCluster.Tasks]
    );
  }

  toSearchResults<
    DocRefType extends object,
    TypesenseType extends { ref: string },
    ReturnType extends Omit<TypesenseType, 'ref'> & {
      ref: DocumentReference<DocRefType>;
    },
  >(response: SearchResponse<TypesenseType>): ISearchResults<ReturnType> {
    const results = [...(response.hits ?? [])].map(
      (item) =>
        ({
          ...item.document,
          ref: asDocRef<DocRefType>(item.document.ref),
        }) as unknown as ReturnType
    );

    return {
      results,
      numberFound: response.found,
      totalAvailable: response.out_of,
    };
  }
}
