import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AppointmentInteractionsDialogComponent,
  IAppointmentInteractionsData,
} from '@principle-theorem/ng-appointment';
import {
  EditInteractionDialogComponent,
  IEditInteractionDialogData,
  IEditInteractionResponse,
} from '@principle-theorem/ng-interactions';
import {
  StateBasedNavigationService,
  TimelineNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  Appointment,
  SchedulingEvent,
} from '@principle-theorem/principle-core';
import {
  ISchedulingEvent,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, WithRef } from '@principle-theorem/shared';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SchedulingEventActionsService {
  constructor(
    private _timelineNav: TimelineNavigationService,
    private _stateNav: StateBasedNavigationService,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar
  ) {}

  disableShowOnTimeline(schedulingEvent: WithRef<ISchedulingEvent>): boolean {
    return !schedulingEvent.eventAfter;
  }

  disableChangeReason$(
    schedulingEvent: WithRef<ISchedulingEvent>
  ): Observable<boolean> {
    return from(SchedulingEvent.findInteraction(schedulingEvent)).pipe(
      map(
        (interaction) =>
          schedulingEvent.eventType === SchedulingEventType.Schedule ||
          !interaction
      )
    );
  }

  async viewSchedulingNotes(
    schedulingEvent: WithRef<ISchedulingEvent>
  ): Promise<void> {
    const appointment = await Firestore.getDoc(
      SchedulingEvent.appointmentRef(schedulingEvent)
    );
    const patient = await Firestore.getDoc(Appointment.patientRef(appointment));
    const data: IAppointmentInteractionsData = { appointment, patient };

    this._dialog.open<
      AppointmentInteractionsDialogComponent,
      IAppointmentInteractionsData,
      void
    >(
      AppointmentInteractionsDialogComponent,
      DialogPresets.large({ height: '80%', data })
    );
  }

  async openPatientAppointments(
    schedulingEvent: WithRef<ISchedulingEvent>
  ): Promise<void> {
    const appointmentRef = SchedulingEvent.appointmentRef(schedulingEvent);
    const patientUid = Appointment.patientRef({ ref: appointmentRef }).id;
    await this._stateNav.brand(
      ['patients', patientUid, 'appointment-history'],
      { fragment: appointmentRef.id }
    );
  }

  async showOnTimeline(
    schedulingEvent: WithRef<ISchedulingEvent>
  ): Promise<void> {
    const appointment = await Firestore.getDoc(
      SchedulingEvent.appointmentRef(schedulingEvent)
    );
    await this._timelineNav.showOnTimeline(appointment);
  }

  async changeReason(
    schedulingEvent: WithRef<ISchedulingEvent>
  ): Promise<void> {
    const interaction = await SchedulingEvent.findInteraction(schedulingEvent);
    if (!interaction) {
      return;
    }
    const config = DialogPresets.large<IEditInteractionDialogData>({
      data: {
        interaction,
      },
    });

    const response = await this._dialog
      .open<
        EditInteractionDialogComponent,
        IEditInteractionDialogData,
        IEditInteractionResponse
      >(EditInteractionDialogComponent, config)
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }

    if (schedulingEvent) {
      this._snackbar.open('Updating scheduling event...');
      await Firestore.patchDoc(schedulingEvent.ref, {
        reason: response.selectedReason.reason,
        reasonSetManually: response.selectedReason.reasonSetManually,
      });
    }
    await Firestore.patchDoc(interaction.ref, response.interaction);
  }
}
