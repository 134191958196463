import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CSVExporterService } from '@principle-theorem/ng-shared';
import { type IPrincipleReconciliationReportData } from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  snapshot,
  toMoment,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { ReconciliationReportFacade } from '../store/reconciliation-report.facade';
import { ReconciliationTransactionSummaryToCSV } from './reconciliation-transactions-summary/reconciliation-transactions-summary-to-csv';

@Component({
  selector: 'pr-principle-reconciliation-report',
  templateUrl: './principle-reconciliation-report.component.html',
  styleUrls: ['./principle-reconciliation-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrincipleReconciliationReportComponent {
  report$ = new ReplaySubject<IPrincipleReconciliationReportData | undefined>(
    1
  );

  constructor(
    public reportFacade: ReconciliationReportFacade,
    private _csvExporter: CSVExporterService
  ) {}

  @Input()
  set report(report: IPrincipleReconciliationReportData | undefined) {
    this.report$.next(report);
  }

  async downloadCSV(): Promise<void> {
    const range = await snapshot(this.reportFacade.range$);
    const startDate = toMoment(range.from).format(DAY_MONTH_YEAR_FORMAT);
    const endDate = toMoment(range.to).format(DAY_MONTH_YEAR_FORMAT);
    const fileName = `reconciliation-transactions-summary-${startDate}-${endDate}`;
    const report = await snapshot(this.report$);
    if (report) {
      const csvData = [...report.revenue.sources, ...report.discount.sources];
      await this._csvExporter.download(
        fileName,
        csvData ?? [],
        new ReconciliationTransactionSummaryToCSV()
      );
    }
  }
}
