import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Appointment, toMention } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IInteractiveResource,
  type IPatient,
  type IPrincipleMention,
  type IStaffer,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { doc$, getDoc, type WithRef } from '@principle-theorem/shared';
import { from, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface IAppointmentInteractionsData {
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
}

@Component({
  selector: 'pr-appointment-interactions-dialog',
  templateUrl: './appointment-interactions-dialog.component.html',
  styleUrls: ['./appointment-interactions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentInteractionsDialogComponent {
  appointment$: Observable<WithRef<IAppointment>>;
  patient$: Observable<WithRef<IPatient>>;
  patientMention$: Observable<IPrincipleMention>;
  appointmentMention$: Observable<IPrincipleMention>;
  interactiveResource$: Observable<IInteractiveResource>;
  staffer: WithRef<IStaffer>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAppointmentInteractionsData,
    public snackBar: MatSnackBar
  ) {
    this.appointment$ = doc$(data.appointment.ref);
    this.patient$ = from(getDoc(data.patient.ref));

    this.patientMention$ = this.patient$.pipe(
      map((patient) => toMention(patient, MentionResourceType.Patient))
    );

    this.appointmentMention$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.toMention(appointment))
    );

    this.interactiveResource$ = this.appointment$.pipe(
      map((appointment) => ({
        interactions$: Appointment.interactions$(appointment),
        add: async (interaction) => {
          await Appointment.addInteraction(appointment, interaction);
        },
      }))
    );
  }
}
