import { TaxRate, roundTo2Decimals } from '@principle-theorem/accounting';
import {
  IAccountCredit,
  IAccountCreditExtendedData,
  ICreditUsedData,
  ISplitAccountCreditFormData,
  IStaffer,
  ITransaction,
  IUsedAccountCredit,
  TransactionProvider,
  isAccountCreditExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  FirestoreTransactionRunner,
  PartialRequired,
  WithRef,
  addDoc,
  asyncForEach,
  getParentColRef,
  isSameRef,
  toTimestamp,
} from '@principle-theorem/shared';
import { compact, min } from 'lodash';
import { v4 as uuid } from 'uuid';
import { stafferToNamedDoc } from '../common';
import { Invoice } from '../invoice/invoice';
import { InvoiceInteractionBuilder } from '../invoice/invoice-interaction-builder';
import { AccountCredit } from './account-credit';

interface IReassignAccountCreditUsageResult {
  alteredTransaction: WithRef<ITransaction<IAccountCreditExtendedData>>;
  fromCreditDelta: number;
  toCreditDelta: number;
}

interface IReducedReassignAccountCreditUsageResult {
  alteredTransactions: WithRef<ITransaction<IAccountCreditExtendedData>>[];
  fromCredit: WithRef<IAccountCredit>;
  toCredit: WithRef<IAccountCredit>;
}

export class AccountCreditSplit {
  static async split(
    originalCredit: WithRef<IAccountCredit>,
    splitCredit: ISplitAccountCreditFormData,
    useForTransactions: ICreditUsedData[],
    splitBy: WithRef<IStaffer>,
    taxRate: TaxRate
  ): Promise<DocumentReference<IAccountCredit>> {
    const newCredit = AccountCredit.init({
      type: originalCredit.type,
      invoice: originalCredit.invoice,
      reference: originalCredit.reference,
      depositUid: uuid(),
      createdAt: toTimestamp(splitCredit.createdAt),
      issued: toTimestamp(splitCredit.createdAt),
      amount: roundTo2Decimals(splitCredit.amount),
      description: splitCredit.description,
      reservedFor: {
        practitioner: splitCredit.practitioner
          ? stafferToNamedDoc(splitCredit.practitioner)
          : undefined,
        treatmentCategory: splitCredit.treatmentCategory,
      },
    });

    const amendedOriginalCredit = {
      ...originalCredit,
      amount: roundTo2Decimals(originalCredit.amount - newCredit.amount),
    };

    const newCreditRef = await FirestoreTransactionRunner.run(
      async ({ transaction }) => {
        await AccountCredit.amend(
          amendedOriginalCredit,
          splitBy.ref,
          transaction
        );
        const creditColRef = getParentColRef<IAccountCredit>(
          originalCredit.ref
        );
        return addDoc<IAccountCredit>(
          creditColRef,
          newCredit,
          undefined,
          transaction
        );
      }
    );

    await FirestoreTransactionRunner.run(async ({ transaction }) => {
      if (!originalCredit.invoice) {
        return;
      }
      const invoice = await Firestore.getDoc(
        originalCredit.invoice,
        transaction
      );
      Invoice.upsertDepositLineItem(invoice, amendedOriginalCredit, taxRate);
      Invoice.upsertDepositLineItem(invoice, newCredit, taxRate);
      await Invoice.amendInvoice(invoice, splitBy.ref, transaction);
      await Invoice.addInteraction(
        invoice,
        InvoiceInteractionBuilder.splitDeposit(
          splitBy,
          amendedOriginalCredit,
          newCredit
        )
      );
    });

    await FirestoreTransactionRunner.run(async ({ transaction }) => {
      const reassignResult = this.reassignAllCreditUsage(
        await Firestore.getDoc(originalCredit.ref, transaction),
        await Firestore.getDoc(newCreditRef, transaction),
        useForTransactions.map((data) => data.transaction)
      );
      await Firestore.patchDoc(
        reassignResult.fromCredit.ref,
        { used: reassignResult.fromCredit.used },
        transaction
      );
      await Firestore.patchDoc(
        reassignResult.toCredit.ref,
        { used: reassignResult.toCredit.used },
        transaction
      );
      await asyncForEach(
        reassignResult.alteredTransactions,
        (alteredTransaction) => {
          const extendedData: IAccountCreditExtendedData = {
            ...alteredTransaction.extendedData,
            accountCreditsUsed:
              alteredTransaction.extendedData?.accountCreditsUsed ?? [],
          };
          return Firestore.patchDoc(
            alteredTransaction.ref,
            { extendedData },
            transaction
          );
        }
      );
    });
    return newCreditRef;
  }

  static reassignAllCreditUsage(
    initialFromCredit: WithRef<IAccountCredit>,
    initialToCredit: WithRef<IAccountCredit>,
    transactions: WithRef<ITransaction>[]
  ): IReducedReassignAccountCreditUsageResult {
    return transactions.reduce(
      (acc: IReducedReassignAccountCreditUsageResult, transaction) => {
        const result = this.reassignCreditUsage(
          acc.fromCredit,
          acc.toCredit,
          transaction
        );
        return {
          fromCredit: this.adjustCreditUsed(
            acc.fromCredit,
            result?.fromCreditDelta
          ),
          toCredit: this.adjustCreditUsed(acc.toCredit, result?.toCreditDelta),
          alteredTransactions: compact([
            ...acc.alteredTransactions,
            result?.alteredTransaction,
          ]),
        };
      },
      {
        fromCredit: initialFromCredit,
        toCredit: initialToCredit,
        alteredTransactions: [],
      }
    );
  }

  static reassignCreditUsage(
    fromCredit: WithRef<IAccountCredit>,
    toCredit: WithRef<IAccountCredit>,
    transaction: WithRef<ITransaction>
  ): IReassignAccountCreditUsageResult | undefined {
    if (!this.isAccountCreditTransaction(transaction)) {
      return;
    }
    const fromUsedCredit = transaction.extendedData.accountCreditsUsed.find(
      (credit) => isSameRef(credit, fromCredit)
    );
    if (!fromUsedCredit) {
      return;
    }
    const reassignAmount =
      min([AccountCredit.remaining(toCredit), fromUsedCredit.amount]) ?? 0;
    if (!reassignAmount) {
      return;
    }

    const fromCreditDelta = -reassignAmount;
    transaction.extendedData.accountCreditsUsed =
      this.upsertUsedAccountCreditAmount(
        transaction.extendedData.accountCreditsUsed,
        fromCredit,
        fromCreditDelta
      );

    const toCreditDelta = reassignAmount;
    transaction.extendedData.accountCreditsUsed =
      this.upsertUsedAccountCreditAmount(
        transaction.extendedData.accountCreditsUsed,
        toCredit,
        toCreditDelta
      );

    return {
      alteredTransaction: transaction,
      fromCreditDelta,
      toCreditDelta,
    };
  }

  static upsertUsedAccountCreditAmount(
    creditsUsed: IUsedAccountCredit[],
    credit: WithRef<IAccountCredit>,
    amount: number,
    refunded: number = 0,
    totalRefunded: number = 0
  ): IUsedAccountCredit[] {
    const existing = creditsUsed.find((creditUsed) =>
      isSameRef(creditUsed, credit)
    );
    if (existing) {
      existing.amount += amount;
      existing.refunded += refunded;
      existing.totalRefunded += totalRefunded;
      return this.removeEmptyUsedCredits(creditsUsed);
    }
    const creditUsed: IUsedAccountCredit = {
      ref: credit.ref,
      name: credit.description,
      amount,
      refunded,
      totalRefunded,
    };
    return this.removeEmptyUsedCredits([...creditsUsed, creditUsed]);
  }

  static removeEmptyUsedCredits(
    creditsUsed: IUsedAccountCredit[]
  ): IUsedAccountCredit[] {
    return creditsUsed.filter(
      (creditUsed) =>
        creditUsed.amount !== 0 ||
        creditUsed.refunded !== 0 ||
        creditUsed.totalRefunded !== 0
    );
  }

  static adjustCreditUsed(
    accountCredit: WithRef<IAccountCredit>,
    amount: number = 0
  ): WithRef<IAccountCredit> {
    return {
      ...accountCredit,
      used: accountCredit.used + amount,
    };
  }

  static isAccountCreditTransaction(
    transaction: WithRef<ITransaction>
  ): transaction is PartialRequired<
    WithRef<ITransaction<IAccountCreditExtendedData>>,
    'extendedData'
  > {
    return (
      transaction.provider === TransactionProvider.AccountCredit &&
      transaction.extendedData !== undefined &&
      isAccountCreditExtendedData(transaction.extendedData)
    );
  }
}
