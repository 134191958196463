import { type ICSVExport } from '@principle-theorem/ng-shared';
import { AllocationTarget } from '@principle-theorem/principle-core';
import { type IPractitionerIncomeReportGrouping } from '@principle-theorem/reporting';

export interface IPractitionerTransactionSummaryCSV {
  practitioner: string;
  reservedAccountCreditAmount: string;
  accountCreditAmount: string;
  discountAmount: string;
  paymentAmount: string;
  practitionerProportionAmount: string;
}

export class PractitionerTransactionSummaryToCSV
  implements
    ICSVExport<
      IPractitionerIncomeReportGrouping,
      IPractitionerTransactionSummaryCSV
    >
{
  headers = [
    'Practitioner',
    'Reserved Account Credits',
    'Account Credits',
    'Discounts',
    'Payments',
    'Practitioner Proportion',
  ];

  translate(
    records: IPractitionerIncomeReportGrouping[]
  ): IPractitionerTransactionSummaryCSV[] {
    return records.map((record) => {
      const isUnallocated = AllocationTarget.isUnallocated(
        record.practitioner.ref
      );

      return {
        practitioner: record.practitioner.name,
        reservedAccountCreditAmount:
          record.accountCredits.total.reservedAccountCreditAmount.toString(),
        accountCreditAmount:
          record.transactions.total.accountCreditAmount.toString(),
        discountAmount: record.transactions.total.discountAmount.toString(),
        paymentAmount: record.transactions.total.paymentAmount.toString(),
        practitionerProportionAmount: isUnallocated
          ? ''
          : record.transactions.total.practitionerProportionAmount.toString(),
      };
    });
  }
}
