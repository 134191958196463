<ng-container *ngIf="balance$ | async as balance">
  <div fxLayout="row" fxLayoutGap="16px" class="line">
    <div fxFlex>Subtotal</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.subtotal | currency }}</span>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="16px" class="line">
    <div fxFlex>Tax</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.tax | currency }}</span>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="16px" class="line">
    <div fxFlex>Total</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.total | currency }}</span>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="16px" class="line">
    <div fxFlex>Paid to Date</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.paidToDate | currency }}</span>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="line balance"
  >
    <div fxFlex>Balance</div>
    <div class="amount">
      <span class="amount-inset">{{ balance.balance | currency }}</span>
    </div>
  </div>
</ng-container>
