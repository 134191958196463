import { ISnippet } from '@principle-theorem/editor';
import { INotification } from '@principle-theorem/notifications';
import { AppointmentPermissions } from '@principle-theorem/principle-core/features';
import {
  BrandCollection,
  CollectionGroup,
  EventType,
  IBrand,
  ICalendarEvent,
  IEventable,
  IPractice,
  IProviderData,
  IRole,
  IStaffer,
  ITeam,
  ITreatmentPlan,
  IUser,
  IUserHasPermissionPair,
  OrganisationCollection,
  Permission,
  RootCollection,
  StafferCollection,
  TreatmentPlanStatus,
  TreatmentTypeConfiguration,
  isAppointment,
  isCalendarEvent,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  CollectionReference,
  DocumentReference,
  Firestore,
  INamedDocument,
  IReffable,
  ITimePeriod,
  Query,
  WithRef,
  all$,
  asDocRef,
  collectionGroupQuery,
  initFirestoreModel,
  isSameRef,
  multiSwitchMap,
  reduceToSingleArray,
  safeCombineLatest,
  subCollection,
  toTimePeriod,
  where,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { MonoTypeOperatorFunction, Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MultiTreatmentConfiguration } from '../clinical-charting/treatment/multi-treatment-configuration';
import { TreatmentConfiguration } from '../clinical-charting/treatment/treatment-configuration';
import { CalendarEventSchedule } from '../event/calendar-event-schedule';
import { isParticipant } from '../event/event';
import { OrganisationCache } from '../organisation/organisation-cache';
import { User } from '../user/user';
import { resolveToStaff } from '../user/user-operators';
import { Roster } from './roster';

export class Staffer {
  static init(overrides: AtLeast<IStaffer, 'user'>): IStaffer {
    return {
      teams: [],
      tasks: {
        watching: 0,
        assigned: 0,
      },
      quickChartingConfiguration: {
        conditions: [],
        treatments: [],
        multiTreatments: [],
      },
      providerDetails: [],
      settings: {},
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static user$(staffer: IStaffer): Observable<WithRef<IUser>> {
    return OrganisationCache.users.get.doc$(staffer.user.ref);
  }

  static roles$(staffer: IStaffer): Observable<WithRef<IRole>[]> {
    return Staffer.user$(staffer).pipe(switchMap((user) => User.roles$(user)));
  }

  static permissions$(staffer: IStaffer): Observable<Permission[]> {
    return Staffer.user$(staffer).pipe(
      switchMap((user: WithRef<IUser>) => User.permissions$(user))
    );
  }

  static practitionersByBrand$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IStaffer>[]> {
    return User.byBrand$(brand).pipe(
      filterByPermission(AppointmentPermissions.AppointmentConduct),
      resolveToStaff(brand),
      map(compact)
    );
  }

  static hasPermissionsByBrand$(
    brand: IReffable<IBrand>,
    permissions: Permission[]
  ): Observable<WithRef<IStaffer>[]> {
    return User.byBrand$(brand).pipe(
      filterByPermissions(permissions),
      resolveToStaff(brand),
      map(compact)
    );
  }

  static byPractice$(
    practice: IReffable<IPractice>
  ): Observable<WithRef<IStaffer>[]> {
    const users$: Observable<WithRef<IUser>[]> = User.byPractice$(practice);

    return combineLatest([
      OrganisationCache.brands.doc$(
        asDocRef<IBrand>(Firestore.getParentDocRef(practice.ref))
      ),
      users$,
    ]).pipe(
      switchMap(([brand, users]) => resolveToStaff(brand)(of(users))),
      map(compact)
    );
  }

  static practitionersByPractice$(
    practice: IReffable<IPractice>
  ): Observable<WithRef<IStaffer>[]> {
    const users$: Observable<WithRef<IUser>[]> = User.byPractice$(
      practice
    ).pipe(filterByPermission(AppointmentPermissions.AppointmentConduct));

    return combineLatest([
      OrganisationCache.brands.doc$(
        asDocRef<IBrand>(Firestore.getParentDocRef(practice.ref))
      ),
      users$,
    ]).pipe(
      switchMap(([brand, users]) => resolveToStaff(brand)(of(users))),
      map(compact)
    );
  }

  static hasPermission$(
    staffer: IStaffer,
    permission: Permission
  ): Observable<boolean> {
    return Staffer.user$(staffer).pipe(
      switchMap((user: WithRef<IUser>) => User.hasPermission$(user, permission))
    );
  }

  static hasPermissionsByPractice$(
    practice: WithRef<IPractice>,
    permissions: Permission[]
  ): Observable<WithRef<IStaffer>[]> {
    const users$: Observable<WithRef<IUser>[]> = User.byPractice$(
      practice
    ).pipe(filterByPermissions(permissions));

    return combineLatest([
      OrganisationCache.brands.doc$(
        asDocRef<IBrand>(Firestore.getParentDocRef(practice.ref))
      ),
      users$,
    ]).pipe(
      switchMap(([brand, users]) => resolveToStaff(brand)(of(users))),
      map(compact)
    );
  }

  static teams(staffer: IStaffer): Observable<WithRef<ITeam>[]> {
    return safeCombineLatest(
      staffer.teams.map((team) => Firestore.getDoc(team.ref))
    );
  }

  static notificationCol(
    staffer: WithRef<IStaffer>
  ): CollectionReference<INotification> {
    return subCollection<INotification>(
      staffer.ref,
      StafferCollection.Notifications
    );
  }

  static notifications$(
    staffer: WithRef<IStaffer>
  ): Observable<WithRef<INotification>[]> {
    return all$(Staffer.notificationCol(staffer));
  }

  static snippetCol(staffer: WithRef<IStaffer>): CollectionReference<ISnippet> {
    return subCollection<ISnippet>(staffer.ref, StafferCollection.Snippets);
  }

  static snippets$(
    staffer: WithRef<IStaffer>
  ): Observable<WithRef<ISnippet>[]> {
    return all$(Staffer.snippetCol(staffer));
  }

  static practices$(staffer: IStaffer): Observable<WithRef<IPractice>[]> {
    return Staffer.user$(staffer).pipe(
      switchMap((user) =>
        combineLatest(
          user.practices.map((ref) => OrganisationCache.practices.doc$(ref))
        )
      )
    );
  }

  static chartableItems$(
    staffer: WithRef<IStaffer>
  ): Observable<TreatmentTypeConfiguration[]> {
    return combineLatest([
      MultiTreatmentConfiguration.all$(staffer),
      TreatmentConfiguration.all$(staffer),
    ]).pipe(
      map(([multiTreatmentConfigurations, treatmentConfigurations]) => [
        ...multiTreatmentConfigurations,
        ...treatmentConfigurations,
      ])
    );
  }

  static getTreatmentPlansCollectionGroup(
    staffer: WithRef<IStaffer>,
    statuses: TreatmentPlanStatus[]
  ): Query<ITreatmentPlan> {
    return collectionGroupQuery<ITreatmentPlan>(
      CollectionGroup.TreatmentPlans,
      where('practitioner.ref', '==', staffer.ref),
      where('status', 'in', statuses)
    );
  }

  static getProviderData(
    staffer: IStaffer,
    practiceRef: DocumentReference<IPractice>
  ): IProviderData | undefined {
    const providerData = staffer.providerDetails.find((provider) =>
      isSameRef(provider.practiceRef, practiceRef)
    );
    if (
      !providerData ||
      !providerData.providerModality ||
      !providerData.providerNumber
    ) {
      return;
    }
    return providerData;
  }

  static getRosterBreakEvents$(
    staffer: IReffable<IStaffer>,
    range: ITimePeriod,
    events: WithRef<IEventable>[] = [],
    practice?: INamedDocument<IPractice>
  ): Observable<ICalendarEvent[]> {
    const blockingEvents = events
      .filter(
        (event) =>
          isAppointment(event) ||
          (isCalendarEvent(event) &&
            event.isBlocking &&
            event.event.type !== EventType.RosteredOn)
      )
      .filter((event) => isParticipant(event, staffer.ref))
      .map((event) => toTimePeriod(event.event.from, event.event.to));

    return Roster.all$(staffer, EventType.Break, practice).pipe(
      multiSwitchMap((schedule) =>
        CalendarEventSchedule.buildEvents(schedule, range, blockingEvents)
      ),
      map(reduceToSingleArray)
    );
  }

  static getRosterPreBlockEvents$(
    staffer: IReffable<IStaffer>,
    range: ITimePeriod,
    events: WithRef<IEventable>[] = [],
    practice?: INamedDocument<IPractice>
  ): Observable<ICalendarEvent[]> {
    const blockingEvents = events
      .filter(
        (event) =>
          isAppointment(event) ||
          (isCalendarEvent(event) &&
            event.isBlocking &&
            event.event.type !== EventType.RosteredOn)
      )
      .filter((event) => isParticipant(event, staffer.ref))
      .map((event) => toTimePeriod(event.event.from, event.event.to));

    return Roster.all$(staffer, EventType.PreBlock, practice).pipe(
      multiSwitchMap((schedule) =>
        CalendarEventSchedule.buildEvents(schedule, range, blockingEvents)
      ),
      map(reduceToSingleArray)
    );
  }

  static buildEventsFromForecast$(
    staff: IReffable<IStaffer>[],
    range: ITimePeriod,
    events: WithRef<IEventable>[] = [],
    practice?: INamedDocument<IPractice>
  ): Observable<ICalendarEvent[]> {
    return safeCombineLatest([
      ...staff.map((staffMember) =>
        Staffer.getRosterBreakEvents$(staffMember, range, events, practice)
      ),
      ...staff.map((staffMember) =>
        Staffer.getRosterPreBlockEvents$(staffMember, range, events, practice)
      ),
    ]).pipe(map(reduceToSingleArray));
  }
}

export function filterByPermission(
  permission: Permission
): MonoTypeOperatorFunction<WithRef<IUser>[]> {
  return (source: Observable<WithRef<IUser>[]>) =>
    source.pipe(
      switchMap((users) =>
        safeCombineLatest(
          users.map((user) =>
            User.hasPermission$(user, permission).pipe(
              map((hasPermission: boolean) => ({ user, hasPermission }))
            )
          )
        )
      ),
      map((pairs: IUserHasPermissionPair[]) =>
        pairs
          .filter((pair: IUserHasPermissionPair) => pair.hasPermission)
          .map((pair: IUserHasPermissionPair) => pair.user)
      )
    );
}

export function filterByPermissions(
  permissions: Permission[]
): MonoTypeOperatorFunction<WithRef<IUser>[]> {
  return (source: Observable<WithRef<IUser>[]>) =>
    source.pipe(
      switchMap((users) =>
        safeCombineLatest(
          users.map((user) =>
            User.hasPermissions$(user, permissions).pipe(
              map((hasPermission: boolean) => ({ user, hasPermission }))
            )
          )
        )
      ),
      map((pairs: IUserHasPermissionPair[]) =>
        pairs
          .filter((pair: IUserHasPermissionPair) => pair.hasPermission)
          .map((pair: IUserHasPermissionPair) => pair.user)
      )
    );
}

export function isStafferRef(
  ref: DocumentReference<unknown>
): ref is DocumentReference<IStaffer> {
  const segments = ref.path.split('/');
  return (
    segments.length === 6 &&
    String(segments[0]) === String(RootCollection.Organisations) &&
    String(segments[2]) === String(OrganisationCollection.Brands) &&
    String(segments[4]) === String(BrandCollection.Staff)
  );
}
