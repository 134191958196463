import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  ContactNumberControls,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormControl,
  validFormGroupChanges$,
  type TypedFormArray,
} from '@principle-theorem/ng-shared';
import {
  GENDERS,
  isPatientRelationship,
  type Gender,
  type IContactNumber,
  type IPatient,
  type IPatientDetails,
  type ISuggestedPatientData,
  type PatientOptionalField,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  isChanged$,
  isPathChanged$,
  type WithRef,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, ReplaySubject, Subject, type Observable } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { PatientDetailsFormGroup } from './patient-details.formgroup';

@Component({
  selector: 'pr-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class PatientDetailsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByGender = TrackByFunctions.variable<Gender>();
  trackByIndex = TrackByFunctions.index();
  searchCtrl: TypedFormControl<string | IPatient> = new TypedFormControl('');
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  genders: Gender[] = GENDERS;
  maxDate: moment.Moment = moment();
  patientFormGroup = new PatientDetailsFormGroup(this._onDestroy$);
  errorMessage$: Observable<string> = this.patientFormGroup.errorMessage$();
  contactNumberControls: ContactNumberControls = new ContactNumberControls(
    this.contactNumbers
  );
  suggestedPatientData$ = new BehaviorSubject<
    ISuggestedPatientData | undefined
  >(undefined);
  selectedPatient$: Observable<WithRef<IPatient> | undefined>;

  @Output() patientSelected: EventEmitter<WithRef<IPatient>> = new EventEmitter<
    WithRef<IPatient>
  >();

  @Output()
  patientDetailsChange = new EventEmitter<IPatientDetails>();

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _schedulingFacade: AppointmentSchedulingFacade
  ) {
    this.searchCtrl.valueChanges
      .pipe(
        filter((value): value is WithRef<IPatient> => !isString(value)),
        switchMap((patient) => doc$(patient.ref)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((patient) => {
        this.patient$.next(patient);
        this.patientSelected.emit(patient);
      });

    this.searchCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((search) => isString(search)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((value) =>
        this.patientFormGroup.controls.name.setValue(value)
      );

    this.patient$
      .pipe(isPathChanged$('ref.id'), takeUntil(this._onDestroy$))
      .subscribe((patient) => {
        this.contactNumberControls.initContactNumberControls(patient);
        this.patientFormGroup.patchPatient(patient, { emitEvent: false });
        this.searchCtrl.setValue(patient.name, { emitEvent: false });
        this.patientFormGroup.markAsPristine();
      });

    this.selectedPatient$ = this._schedulingFacade.selectedPatient$;

    validFormGroupChanges$(this.patientFormGroup)
      .pipe(debounceTime(500), isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((details) => {
        // TODO: https://app.clickup.com/t/32wzec
        // Objects with null values save in db
        if (!details.referrer) {
          delete details.referrer;
        }
        if (!isPatientRelationship(details.primaryContact)) {
          delete details.primaryContact;
        }
        details.relationships = details.relationships.filter(
          isPatientRelationship
        );
        this.patientDetailsChange.emit(details);
      });

    this._organisation.brand$
      .pipe(
        map((brand) => brand?.settings.patient?.requiredFields ?? []),
        takeUntil(this._onDestroy$)
      )
      .subscribe((requiredFields) => {
        requiredFields.map((field) => {
          this.patientFormGroup.controls[field].addValidators(
            Validators.required
          );
        });
        this.patientFormGroup.updateValueAndValidity();
      });

    this.patientFormGroup.valueChanges
      .pipe(debounceTime(300), takeUntil(this._onDestroy$))
      .subscribe((patientDetails) =>
        this.suggestedPatientData$.next({
          name: patientDetails.name,
          dateOfBirth: patientDetails.dateOfBirth,
          contactNumbers: patientDetails.contactNumbers,
        })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  displayFn(value: string | WithRef<IPatient>): string {
    if (!value) {
      return '';
    }
    if (!isString(value)) {
      return value.name;
    }
    return value;
  }

  clearUser(): void {
    this.patientFormGroup.reset({}, { emitEvent: false });
    this.patientSelected.emit();
  }

  get contactNumbers(): TypedFormArray<IContactNumber> {
    return this.patientFormGroup.controls
      .contactNumbers as TypedFormArray<IContactNumber>;
  }

  isRequired$(field: PatientOptionalField): Observable<boolean> {
    return this._organisation.brand$.pipe(
      map((brand) => {
        const requiredFields = brand?.settings.patient?.requiredFields ?? [];
        return requiredFields.includes(field);
      })
    );
  }
}
