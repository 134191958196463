import {
  type INamedDocument,
  isObject,
  type ISODateType,
  type WithRef,
} from '@principle-theorem/shared';
import { type IAppointmentSuggestion } from '../appointment/appointment-suggestion';
import { type ITreatmentTemplateWithStep } from '../clinical-charting/treatment/treatment-plan-from-template';
import { type ITreatmentTemplate } from '../clinical-charting/treatment/treatment-template';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import { type PatientRelationshipType } from './patient-relationship';
import { type IContactNumber } from './patient';
import { IBrand } from '../brand';
import { IAddressMetadata } from '../address/address';

export interface IOnlineBookingData {
  practice: WithRef<IPractice>;
  templates: WithRef<ITreatmentTemplate>[];
  brand: WithRef<IBrand>;
}

export interface IGetBookingSuggestionsRequest {
  practice: INamedDocument<IPractice>;
  template: WithRef<ITreatmentTemplate>;
  date: ISODateType;
  practitioner?: INamedDocument<IStaffer>;
}

export interface IHasPracticeId {
  practiceId: string;
}

export function isHasPracticeId(data: unknown): data is IHasPracticeId {
  return isObject(data) && 'practiceId' in data;
}

export function isGetBookingSuggestionRequest(
  data: unknown
): data is IGetBookingSuggestionsRequest {
  return (
    isObject(data) && 'practice' in data && 'template' in data && 'date' in data
  );
}

export interface IPatientFormData {
  relationshipType: PatientRelationshipType;
  name: string;
  dateOfBirth: ISODateType;
}

export interface IPrimaryContactFormData
  extends Omit<IPatientFormData, 'relationshipType'> {
  mobileNumber: string;
  email: string;
  address?: IAddressMetadata;
}

export interface ISuggestedPatientData {
  name?: string;
  dateOfBirth?: ISODateType;
  contactNumbers?: IContactNumber[];
  mobileNumber?: string;
}

export interface IPatientData {
  primaryContact: IPrimaryContactFormData;
  patient?: IPatientFormData;
}

export interface IBookingRequest {
  patientData: IPatientData;
  suggestion: IAppointmentSuggestion;
  template: ITreatmentTemplateWithStep;
}

export function isBookingRequest(data: unknown): data is IBookingRequest {
  return isObject(data) && 'patientData' in data && 'suggestion' in data;
}
