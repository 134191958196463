import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets, TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IClinicalNote,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  getDoc,
  HISTORY_DATE_FORMAT,
  isSameRef,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClinicalNoteEditDialogComponent } from '../clinical-note-edit-dialog/clinical-note-edit-dialog.component';

@Component({
  selector: 'pr-clinical-notes-list',
  templateUrl: './clinical-notes-list.component.html',
  styleUrls: ['./clinical-notes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalNotesListComponent {
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  clinicalNotes$ = new ReplaySubject<WithRef<IClinicalNote>[]>(1);
  trackByNote = TrackByFunctions.ref<WithRef<IClinicalNote>>();
  readonly dateFormat = HISTORY_DATE_FORMAT;

  @Input()
  set clinicalNotes(clinicalNotes: WithRef<IClinicalNote>[]) {
    if (clinicalNotes) {
      this.clinicalNotes$.next(clinicalNotes);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    private _dialog: MatDialog,
    private _organisation: OrganisationService
  ) {}

  isNoteOwner$(note: WithRef<IClinicalNote>): Observable<boolean> {
    return this._organisation.staffer$.pipe(
      filterUndefined(),
      map((staffer) => isSameRef(staffer, note.owner))
    );
  }

  noteLockedMessage(note: WithRef<IClinicalNote>): string {
    return note.immutable ? `${note.owner.name} has locked this note` : '';
  }

  async toggleLock(note: WithRef<IClinicalNote>): Promise<void> {
    await patchDoc(note.ref, { immutable: !note.immutable }, undefined, {
      omitUpdateTimestamp: true,
    });
  }

  async editClinicalNote(note: WithRef<IClinicalNote>): Promise<void> {
    const patient = await snapshot(this.patient$);
    const staffer = await getDoc(note.owner.ref);
    const config = DialogPresets.large({
      height: '80%',
      data: {
        date: note.recordDate,
        patient,
        staffer,
      },
    });
    this._dialog.open(ClinicalNoteEditDialogComponent, config);
  }
}
