import { Injectable, inject } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ProfileImageService } from '@principle-theorem/ng-shared';
import {
  Brand,
  FeeSchedule,
  OrganisationCache,
  Roster,
  Staffer,
} from '@principle-theorem/principle-core';
import {
  type HasFeeSchedules,
  type IBrand,
  type IFeeSchedule,
  type IStaffer,
  type ITag,
  type ITreatmentCategory,
  type ICalendarEventSchedule,
  isStaffer,
  ICalendarEventSchedulesMap,
  IPatientMetadataDisplay,
} from '@principle-theorem/principle-core/interfaces';
import {
  type IReffable,
  type WithRef,
  filterUndefined,
  getDoc,
  isSameRef,
  multiMap,
  multiSortBy$,
  multiSwitchMap,
  nameSorter,
  reduce2DArray,
  all$,
  undeletedQuery,
  isChanged$,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OrganisationService } from '../organisation.service';
import { fromPairs } from 'lodash';

export enum TagType {
  Appointment = 'appointment',
  Event = 'event',
  Patient = 'patient',
  PatientNote = 'patientNote',
}

interface ITypedTag extends WithRef<ITag> {
  type: TagType;
}

export interface IGlobalStoreState {
  stafferDetails: { id: string; name: string; url?: string }[];
  staff: WithRef<IStaffer>[];
  principleStaff: WithRef<IStaffer>[];
  tags: ITypedTag[];
  treatmentCategories: WithRef<ITreatmentCategory>[];
  feeSchedules: WithRef<IFeeSchedule>[];
  rosterSchedules: ICalendarEventSchedulesMap;
  patientMetadataDisplays: WithRef<IPatientMetadataDisplay>[];
}

@Injectable({
  providedIn: 'root',
})
export class GlobalStoreService extends ComponentStore<IGlobalStoreState> {
  private _organisation = inject(OrganisationService);
  private _profileImage = inject(ProfileImageService);
  readonly stafferDetails$ = this.select((state) => state.stafferDetails);
  readonly staff$ = this.select((state) => state.staff);
  readonly principleStaff$ = this.select((state) => state.principleStaff);
  readonly tags$ = this.select((state) => state.tags);
  readonly treatmentCategories$ = this.select(
    (state) => state.treatmentCategories
  );
  readonly feeSchedules$ = this.select((state) => state.feeSchedules);
  readonly rosterSchedules$ = this.select((state) => state.rosterSchedules);
  readonly patientMetadataDisplays$ = this.select(
    (state) => state.patientMetadataDisplays
  );

  readonly loadStafferDetails = this.effect(
    (staff$: Observable<WithRef<IStaffer>[]>) => {
      return staff$.pipe(
        tapResponse(
          (staff) => this.patchState({ staff }),
          // eslint-disable-next-line no-console
          console.error
        ),
        multiSwitchMap((staffer) =>
          Staffer.user$(staffer).pipe(
            switchMap((user) =>
              this._profileImage.getUserProfileImage$(user).pipe(
                map((url) => ({
                  id: staffer.ref.id,
                  url,
                  name: user.name,
                }))
              )
            )
          )
        ),
        tapResponse(
          (stafferDetails) => this.patchState({ stafferDetails }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadManagementStafferDetails = this.effect(
    (staff$: Observable<WithRef<IStaffer>[]>) => {
      return staff$.pipe(
        tapResponse(
          (staff) => this.patchState({ staff }),
          // eslint-disable-next-line no-console
          console.error
        ),
        multiSwitchMap((staffer) =>
          Staffer.user$(staffer).pipe(
            switchMap((user) =>
              this._profileImage.getUserProfileImage$(user).pipe(
                map((url) => ({
                  id: staffer.ref.id,
                  url,
                  name: user.name,
                }))
              )
            )
          )
        ),
        tapResponse(
          (stafferDetails) => this.patchState({ stafferDetails }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadStafferRosters = this.effect(
    (staff$: Observable<WithRef<IStaffer>[]>) => {
      return staff$.pipe(
        multiSwitchMap((staffer) =>
          all$(undeletedQuery(Roster.col({ ref: staffer.ref }))).pipe(
            map((schedules) => [staffer.ref.id, schedules])
          )
        ),
        map((rosterSchedules) => fromPairs(rosterSchedules)),
        tapResponse(
          (rosterSchedules) => this.patchState({ rosterSchedules }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadTags = this.effect((brand$: Observable<WithRef<IBrand>>) => {
    return brand$.pipe(
      switchMap((brand) =>
        combineLatest([
          Brand.appointmentTags$(brand).pipe(
            multiMap((tag) => ({
              ...tag,
              type: TagType.Appointment,
            }))
          ),
          Brand.eventTags$(brand).pipe(
            multiMap((tag) => ({
              ...tag,
              type: TagType.Event,
            }))
          ),
          Brand.patientTags$(brand).pipe(
            multiMap((tag) => ({
              ...tag,
              type: TagType.Patient,
            }))
          ),
          Brand.patientNoteTags$(brand).pipe(
            multiMap((tag) => ({
              ...tag,
              type: TagType.PatientNote,
            }))
          ),
        ])
      ),
      reduce2DArray(),
      multiSortBy$(nameSorter()),
      tapResponse(
        (tags) => this.patchState({ tags }),
        // eslint-disable-next-line no-console
        console.error
      )
    );
  });

  readonly loadTreatmentCategories = this.effect(
    (brand$: Observable<WithRef<IBrand>>) => {
      return brand$.pipe(
        switchMap((brand) => Brand.treatmentCategories$(brand)),
        tapResponse(
          (treatmentCategories) => this.patchState({ treatmentCategories }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadFeeSchedules = this.effect(
    (sources$: Observable<HasFeeSchedules[]>) =>
      sources$.pipe(
        FeeSchedule.resolveSchedules$(),
        tapResponse(
          (feeSchedules) => this.patchState({ feeSchedules }),
          // eslint-disable-next-line no-console
          console.error
        )
      )
  );

  readonly loadPatientMetadataDisplays = this.effect(
    (brand$: Observable<WithRef<IBrand>>) => {
      return brand$.pipe(
        switchMap((brand) => Brand.patientMetadataDisplays$(brand)),
        tapResponse(
          (patientMetadataDisplays) =>
            this.patchState({ patientMetadataDisplays }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  constructor() {
    super({
      stafferDetails: [],
      staff: [],
      principleStaff: [],
      tags: [],
      treatmentCategories: [],
      feeSchedules: [],
      rosterSchedules: {},
      patientMetadataDisplays: [],
    });

    this.loadManagementStafferDetails(this._organisation.managementStaff$);
    this.loadStafferDetails(this._organisation.staff$);
    const allStaff$ = this._organisation.brand$.pipe(
      switchMap((brand) => (brand ? Brand.staff$(brand, true) : of([])))
    );
    this.loadStafferDetails(allStaff$);
    this.loadStafferRosters(
      this._organisation.practicePractitioners$.pipe(isChanged$(isSameRef))
    );
    this.loadTags(this._organisation.brand$.pipe(filterUndefined()));
    this.loadTreatmentCategories(
      this._organisation.brand$.pipe(filterUndefined())
    );
    this.loadFeeSchedules(
      combineLatest([
        this._organisation.staffer$.pipe(filterUndefined()),
        this._organisation.practice$.pipe(filterUndefined()),
        this._organisation.brand$.pipe(filterUndefined()),
        this._organisation.organisation$.pipe(filterUndefined()),
      ])
    );
    this.loadPatientMetadataDisplays(
      this._organisation.brand$.pipe(filterUndefined())
    );
  }

  getStaffer$(
    stafferRef: DocumentReference<IStaffer>
  ): Observable<WithRef<IStaffer> | undefined> {
    return this.select(this.staff$, (staff) =>
      staff.find((staffer) => isSameRef(staffer, stafferRef))
    ).pipe(
      switchMap((staffer) => {
        if (staffer) {
          return of(staffer);
        }
        return OrganisationCache.staff.get.doc$(stafferRef);
      })
    );
  }

  getStafferImage$(
    staffer: IReffable<IStaffer>
  ): Observable<string | undefined> {
    return this.select(
      this.stafferDetails$,
      (stafferDetails) =>
        stafferDetails.find(
          (stafferDetail) => stafferDetail.id === staffer.ref.id
        )?.url
    );
  }

  getStafferName$(
    staffer: IReffable<IStaffer> | WithRef<IStaffer>
  ): Observable<string> {
    return this.select(this.stafferDetails$, (stafferDetails) => {
      const stafferName = stafferDetails.find(
        (stafferDetail) => stafferDetail.id === staffer.ref.id
      )?.name;

      if (stafferName) {
        return stafferName;
      }

      if (isStaffer(staffer)) {
        return staffer.user.name;
      }

      return '';
    });
  }

  getStafferRosterSchedules$(
    staffer: IReffable<IStaffer>
  ): Observable<WithRef<ICalendarEventSchedule>[]> {
    return this.select(
      this.rosterSchedules$,
      (rosterSchedules) => rosterSchedules[staffer.ref.id] ?? []
    );
  }

  getTag$(
    tagRef: DocumentReference<ITag>
  ): Observable<WithRef<ITag> | undefined> {
    return this.select(this.tags$, (tags) =>
      tags.find((tag) => isSameRef(tag, tagRef))
    ).pipe(
      switchMap((tag) => {
        if (tag) {
          return of(tag);
        }
        return getDoc(tagRef);
      })
    );
  }

  getTagsByType$(type: TagType): Observable<WithRef<ITag>[]> {
    return this.select(this.tags$, (tags) =>
      tags.filter((tag) => tag.type === type)
    );
  }

  getTreatmentCategory$(
    categoryRef: DocumentReference<ITreatmentCategory>
  ): Observable<WithRef<ITreatmentCategory> | undefined> {
    return this.select(this.treatmentCategories$, (treatmentCategories) =>
      treatmentCategories.find((category) => isSameRef(category, categoryRef))
    );
  }

  getFeeSchedule$(
    feeScheduleRef: DocumentReference<IFeeSchedule>
  ): Observable<WithRef<IFeeSchedule>> {
    return this.select(this.feeSchedules$, (feeSchedules) =>
      feeSchedules.find((schedule) => isSameRef(schedule, feeScheduleRef))
    ).pipe(
      switchMap((schedule) => {
        if (schedule) {
          return of(schedule);
        }
        return getDoc(feeScheduleRef);
      })
    );
  }
}
