import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
  isDisabled$,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  CustomChartType,
  ENABLED_CHART_TYPES,
  ICustomReportChart,
  MeasureFormatter,
  MeasureReducer,
} from '@principle-theorem/principle-core/interfaces';
import {
  ICanGroupMeasuresProperty,
  type ICanBeChartedProperty,
} from '@principle-theorem/reporting';
import { uid } from '@principle-theorem/shared';
import { type ComparableValue } from 'crossfilter2';
import { first } from 'lodash';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { IGroupBySection } from '../../../../models/report-builder-data-sources/report-builder-data-source';
import {
  IChartDisplay,
  getChartDisplay,
} from '../../report-builder-chart-filters/report-builder-chart-section/report-builder-chart/report-builder-chart-display';
import { ReportBuilderStore } from '../../report-builder.store';
import { ReportBuilderManageChartBloc } from './report-builder-manage-chart-bloc';

export interface IReportBuilderAddChartRequest {
  measures: ICanBeChartedProperty[];
  groupBys: IGroupBySection[];
  filteredData: object[];
  chart?: ICustomReportChart;
}

export interface IReportBuilderAddChartResponse {
  label: string;
  type: CustomChartType;
  measure: ICanBeChartedProperty;
  groupBy: ICanGroupMeasuresProperty;
  reducer: MeasureReducer;
  filters: ComparableValue[];
}

@Component({
  selector: 'pr-report-builder-add-chart-dialog',
  templateUrl: './report-builder-add-chart-dialog.component.html',
  styleUrls: ['./report-builder-add-chart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class ReportBuilderAddChartDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form = new TypedFormGroup<IReportBuilderAddChartResponse>({
    type: new TypedFormControl(CustomChartType.Row, Validators.required),
    label: new TypedFormControl('New Chart', Validators.required),
    measure: new TypedFormControl(undefined, Validators.required),
    filters: new TypedFormControl([]),
    reducer: new TypedFormControl(MeasureReducer.Sum),
    groupBy: new TypedFormControl(undefined, Validators.required),
  });
  chartTypes: CustomChartType[] = ENABLED_CHART_TYPES;
  isDisabled$ = isDisabled$(this.form);
  display$ = new ReplaySubject<IChartDisplay | undefined>(1);
  bloc: ReportBuilderManageChartBloc;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IReportBuilderAddChartRequest,
    public _dialogRef: MatDialogRef<
      ReportBuilderAddChartDialogComponent,
      IReportBuilderAddChartResponse
    >,
    private _store: ReportBuilderStore
  ) {
    this.bloc = new ReportBuilderManageChartBloc(
      this._store,
      this.data.filteredData,
      this._onDestroy$,
      this.form.controls,
      [MeasureFormatter.Link]
    );

    combineLatest([
      formControlChanges$(this.form.controls.measure),
      formControlChanges$(this.form.controls.reducer),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([measure, reducer]) => {
        const measureLabel = measure?.metadata.label;
        if (!measureLabel) {
          return;
        }

        let label = '';

        switch (reducer) {
          case MeasureReducer.Count:
            label = measureLabel;
            break;
          case MeasureReducer.Average:
            label = `Average ${measureLabel}`;
            break;
          case MeasureReducer.Sum:
            if (measureLabel.includes('Total')) {
              label = `${measureLabel}`;
              break;
            }
            label = `Total ${measureLabel}`;
            break;
          default:
            return;
        }

        this.form.controls.label.setValue(label, { emitEvent: false });
      });

    combineLatest([
      validFormGroupChanges$(this.form).pipe(debounceTime(500)),
      this._store.dataSource$,
      this._store.ndx$,
    ])
      .pipe(
        map(([form, dataSource, ndx]) => {
          if (!dataSource) {
            return;
          }

          const chart: ICustomReportChart = {
            uid: uid(),
            type: form.type,
            label: form.label,
            groupBy: form.groupBy.metadata.id,
            settings: [
              {
                uid: uid(),
                label: form.measure.metadata.label,
                dataPoint: form.measure.metadata.id,
                reducer: form.reducer,
                filters: form.filters,
              },
            ],
            builderConfig: {
              rotateXAxisLabels:
                form.type === CustomChartType.Column ? true : false,
            },
            builderData: {
              colourOverride: !!form.groupBy.groupMeasure.colourAccessor,
              plottedOverTime: [
                MeasureFormatter.Timestamp,
                MeasureFormatter.Day,
              ].includes(
                form.groupBy.metadata.formatter ?? MeasureFormatter.Text
              )
                ? true
                : false,
            },
          };
          return getChartDisplay(dataSource, chart, ndx);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((display) => this.display$.next(display));

    if (!data.chart) {
      this.form.controls.groupBy.setValue(
        first(data.groupBys.flatMap((section) => section.options))?.measure
      );
    }

    if (data.chart) {
      const chart = data.chart;
      const setting = first(chart.settings);
      const measure = setting
        ? data.measures.find(
            (availableMeasure) =>
              availableMeasure.metadata.id === setting.dataPoint
          )
        : undefined;

      if (!measure) {
        // eslint-disable-next-line no-console
        console.error(
          `No measure found for ${setting?.dataPoint}`,
          data.measures
        );
      }

      const groupBy = data.groupBys
        .flatMap((section) => section.options)
        .find(
          (availableGroupBy) =>
            availableGroupBy.measure.metadata.id === chart.groupBy
        )?.measure;

      this.form.patchValue({
        type: chart.type,
        label: chart.label,
        measure,
        groupBy,
        reducer: setting?.reducer,
        filters: setting?.filters,
      });

      this.bloc.dataPointSearchCtrl.setValue(measure?.metadata.label ?? '');
      this.bloc.groupBySearchCtrl.setValue(groupBy?.metadata.label ?? '');
      this.form.markAsDirty();
      this.form.markAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this._dialogRef.close(this.form.getRawValue());
  }
}
