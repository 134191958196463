<mat-toolbar color="accent">Confirm Patient Request Details</mat-toolbar>
<div class="layout-padding" fxLayout="column" fxLayoutGap="8px">
  <ng-container *ngIf="patient.patient; else primaryContact">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
      <h2>Patient</h2>
      <span>
        <strong>Name:</strong>
        {{ patient.patient.name }}
      </span>
      <span>
        <strong>Date Of Birth:</strong>
        {{ patient.patient.dateOfBirth | moment | amDateFormat: dateFormat }}
      </span>
      <h2>Primary Contact</h2>
      <span>
        <strong>Name:</strong>
        {{ patient.primaryContact.name }}
      </span>
      <span>
        <strong>Date Of Birth:</strong>
        {{
          patient.primaryContact.dateOfBirth | moment | amDateFormat: dateFormat
        }}
      </span>
      <span>
        <strong>Email:</strong>
        {{ patient.primaryContact.email }}
      </span>
      <span>
        <strong>Mobile Number:</strong>
        {{ patient.primaryContact.mobileNumber }}
      </span>
      <span>
        <strong>Relationship to Patient:</strong>
        {{ patient.patient.relationshipType | titlecase }}
      </span>
    </div>
  </ng-container>
  <ng-template #primaryContact>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
      <span>
        <strong>Name:</strong>
        {{ patient.primaryContact.name }}
      </span>
      <span>
        <strong>Date Of Birth:</strong>
        {{
          patient.primaryContact.dateOfBirth | moment | amDateFormat: dateFormat
        }}
      </span>
      <span><strong>Email:</strong> {{ patient.primaryContact.email }}</span>
      <span>
        <strong>Mobile Number:</strong>
        {{ patient.primaryContact.mobileNumber }}
      </span>
    </div>
  </ng-template>

  <mat-divider />

  <p class="mat-small">
    If this patient already exists select them from the patient list below or
    click <strong>Create New Patient</strong>.
  </p>

  <pr-patient-selector
    fxFlex
    [formControl]="patientSelector"
    [suggestedPatientData]="suggestedPatientData"
   />

  <span
    [matTooltipDisabled]="disableTooltipOnContinue$ | async"
    matTooltip="Please select a patient"
  >
    <button
      mat-flat-button
      color="primary"
      fxFlex
      [disabled]="disableContinueButton$ | async"
      (click)="patientConfirmed()"
    >
      Continue
    </button>
  </span>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
    <mat-divider fxFlex />
    <span>OR</span>
    <mat-divider fxFlex />
  </div>

  <button
    mat-flat-button
    color="primary"
    [disabled]="disableCreateNewPatientButton$ | async"
    (click)="createNewPatient()"
  >
    Create New Patient
  </button>
</div>
