import { isMixedSchema, MixedSchema } from '@principle-theorem/editor';
import {
  isINamedDocument,
  isISODateType,
  isObject,
  type INamedDocument,
  type ISODateType,
  type ISoftDelete,
  type Timestamp,
} from '@principle-theorem/shared';
import { isBoolean, isString } from 'lodash';
import { type IStaffer } from '../../staffer/staffer';

export interface IClinicalNote extends ISoftDelete {
  uid: string;
  content: MixedSchema;
  immutable: boolean;
  owner: INamedDocument<IStaffer>;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  recordDate: ISODateType;
}

export function isClinicalNote(data: unknown): data is IClinicalNote {
  return (
    isObject(data) &&
    'uid' in data &&
    isString(data.uid) &&
    'content' in data &&
    isMixedSchema(data.content) &&
    'owner' in data &&
    isINamedDocument(data.owner) &&
    'recordDate' in data &&
    isISODateType(data.recordDate) &&
    'deleted' in data &&
    isBoolean(data.deleted)
  );
}
