import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CurrentScopeFacade,
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { Brand, Chat } from '@principle-theorem/principle-core';
import { IChat } from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  doc,
  doc$,
  filterUndefined,
} from '@principle-theorem/shared';
import { Observable, Subject, combineLatest } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ChatActions } from '../../chat-actions';
import {
  IChatSummary,
  getChatSummary$,
} from '../../pages/chats-dashboard/chats-dashboard.store';

@Component({
  selector: 'pr-floating-chat',
  templateUrl: './floating-chat.component.html',
  styleUrl: './floating-chat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingChatComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _myScrollContainer?: ElementRef<HTMLElement>;
  summary$: Observable<IChatSummary>;
  actions: ChatActions;

  @ViewChild('scrollMe', { static: false })
  set scroll(myScrollContainer: ElementRef<HTMLElement>) {
    this._myScrollContainer = myScrollContainer;
    this._scrollToBottom();
  }

  constructor(
    private _currentScope: CurrentScopeFacade,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) chatRef: DocumentReference<IChat>,
    public dialogRef: MatDialogRef<FloatingChatComponent>,
    private _globalStore: GlobalStoreService
  ) {
    const staffer$ = this._organisation.staffer$.pipe(filterUndefined());

    this.summary$ = combineLatest([
      this._currentScope.currentBrand$.pipe(
        filterUndefined(),
        switchMap((brand) => doc$(doc(Brand.chatCol(brand), chatRef.id)))
      ),
      staffer$,
    ]).pipe(
      switchMap(([chat, staffer]) =>
        getChatSummary$(chat, staffer, this._globalStore)
      ),
      filterUndefined()
    );

    this.actions = new ChatActions(
      this.summary$,
      staffer$,
      this._snackBar,
      this._onDestroy$
    );

    this.actions.contentAdded$
      .pipe(startWith(), takeUntil(this._onDestroy$))
      .subscribe(() => setTimeout(() => this._scrollToBottom(), 50));

    combineLatest([this.summary$, staffer$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([summary, staffer]) => {
        if (Chat.userHasAccess(summary.chat, staffer.ref)) {
          return;
        }

        this.dialogRef.close();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _scrollToBottom(): void {
    try {
      if (!this._myScrollContainer) {
        return;
      }
      this._myScrollContainer.nativeElement.scrollTop =
        this._myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
      // Do nada
    }
  }
}
