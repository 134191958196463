import {
  isDocRef,
  isINamedDocument,
  isObject,
  type DocumentReference,
  type IFirestoreModel,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { type ChartView } from '../clinical-charting/core/chart-view';
import { IPerioSettings } from '../clinical-charting/core/perio';
import { type InteractionType } from '../interaction/interaction';
import { type MentionResourceType } from '../mention/mention';
import { type IPractice } from '../practice/practice';
import { type ITag } from '../tag/tag';
import { type ITeam } from '../team/team';
import { type ITimelineStafferDisplayOptions } from '../timeline/interactive-timeline-display-options';
import { type IUser } from '../user/user';
import { type IQuickChartingConfigurations } from './quick-charting-configuration';

export enum ServiceTypeModality {
  GeneralDentist = 'D',
  Endodontist = '3',
  OralSurgeon = '4',
  Orthodontist = '5',
  Paedodontist = '6',
  Periodontist = '7',
  Prosthetist = '8',
  Prosthodontist = '9',
  DentalTechnician = '0',
}

export interface IProviderData {
  practiceRef: DocumentReference<IPractice>;
  providerNumber: string;
  providerModality: ServiceTypeModality;
  payeeProviderNumber?: string;
}

export interface IRecentlyViewed<T extends object = object> {
  title: string;
  ref: DocumentReference<T>;
  viewedAt: Timestamp;
  type: MentionResourceType;
}

export type ProviderLineItemDetails = Partial<IProviderData> & {
  user: INamedDocument<IUser>;
};

export interface IStaffer extends IFirestoreModel {
  user: INamedDocument<IUser>;
  teams: INamedDocument<ITeam>[];
  tasks: IStafferTasks;
  providerDetails: IProviderData[];
  prescriberNumber?: string;
  qualifications?: string;
  quickChartingConfiguration: IQuickChartingConfigurations;
  providerCommission?: number;
  settings?: Partial<IStafferSettings>;
  recentlyViewed?: IRecentlyViewed[];
}

export function isStaffer(data: unknown): data is WithRef<IStaffer> {
  return (
    isObject(data) &&
    'ref' in data &&
    isDocRef(data.ref) &&
    'user' in data &&
    isINamedDocument(data.user)
  );
}

export interface IChartSettings {
  view: ChartView;
  perio: IPerioSettings;
}

export interface IStafferTimelineSettings
  extends ITimelineStafferDisplayOptions {
  filteredStaffByPractice: IStaffByPractice[];
  orderedStaffByPractice: IStaffByPractice[];
  showRosteredOffStaff: boolean;
  hideEmptyDays: boolean;
}

export interface IStaffByPractice {
  staff: DocumentReference<IStaffer>[];
  practice: DocumentReference<IPractice>;
}

export enum PatientMediaViewType {
  Grid = 'grid',
  List = 'list',
}

export enum PatientMediaSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum PatientMediaGroupBy {
  None = 'none',
  Date = 'date',
  Tags = 'tags',
}

export enum PlanStepPairStatus {
  Completed = 'completed',
  InProgress = 'inProgress',
  Scheduled = 'scheduled',
  Unscheduled = 'unscheduled',
  Cancelled = 'cancelled',
}

export interface IStafferSettings {
  charting: Partial<IChartSettings>;
  interactions: Partial<{
    filters: InteractionType[];
    tagFilters: DocumentReference<ITag>[];
  }>;
  timeline: Partial<IStafferTimelineSettings>;
  display: Partial<{
    sidebarClosed: boolean;
  }>;
  conversations: Partial<{
    showAllPractices: boolean;
  }>;
  chats: Partial<{
    showAllPractices: boolean;
    notificationSoundOverride?: string;
  }>;
  treatmentPlan: Partial<{
    filters: PlanStepPairStatus[];
  }>;
  patientMedia: Partial<{
    viewType: PatientMediaViewType;
    size: PatientMediaSize;
    groupBy: PatientMediaGroupBy;
    imageEditMode: boolean;
  }>;
}

export interface IStafferTasks {
  watching: number;
  assigned: number;
}

export enum StafferCollection {
  Snippets = 'snippets',
  FeeSchedules = 'feeSchedules',
  Notifications = 'notifications',
  ConditionConfigurations = 'conditionConfigurations',
  TreatmentConfigurations = 'treatmentConfigurations',
  MultiTreatmentConfigurations = 'multiTreatmentConfigurations',
  RosterSchedules = 'rosterSchedules',
}
