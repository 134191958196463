import { ILogger } from '@principle-theorem/developer-tools';
import { type IBrand } from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  addDoc,
  asyncForEach,
  bufferedQuery$,
  getCount,
  multiConcatMap,
  toQuery,
  where,
} from '@principle-theorem/shared';
import { isEmpty } from 'lodash';
import { Brand } from '../models/brand';
import { formatPhoneNumber } from '../models/patient/patient';

export class AddPatientPhoneNumberSummaries {
  constructor(private _logger: ILogger) {}

  async run(
    brandRef: DocumentReference<IBrand>,
    dryRun: boolean = false
  ): Promise<void> {
    await bufferedQuery$(Brand.patientCol({ ref: brandRef }), 100, 'ref')
      .pipe(
        multiConcatMap(async (patient) => {
          const contactNumbers = (patient.contactNumbers || []).filter(
            (contactNumber) => !isEmpty(contactNumber.number)
          );

          await asyncForEach(contactNumbers, async (contactNumber) => {
            const formattedNumber = formatPhoneNumber(contactNumber.number);
            const existingRecords = await getCount(
              toQuery(
                Brand.patientPhoneNumberCol({ ref: brandRef }),
                where('patientRef', '==', patient.ref),
                where('phoneNumber', '==', formattedNumber)
              )
            );

            if (existingRecords > 0) {
              // eslint-disable-next-line no-console
              this._logger.info(
                `Phone number ${formattedNumber} already exists for patient ${patient.ref.path}`
              );
              return;
            }

            // eslint-disable-next-line no-console
            this._logger.info(
              `Adding phone number ${formattedNumber} for patient ${patient.ref.path}`
            );

            if (dryRun) {
              return;
            }
            await addDoc(Brand.patientPhoneNumberCol({ ref: brandRef }), {
              patientRef: patient.ref,
              phoneNumber: formattedNumber,
            });
          });
        })
      )
      .toPromise();
  }
}
