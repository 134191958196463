import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  PatientRelationshipType,
  type IClinicalNote,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { ClinicalNote, Patient } from '@principle-theorem/principle-core';
import { type WithRef, sortMoment, toMoment } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IClinicalNotesDialogData {
  patient: WithRef<IPatient>;
}

@Component({
  selector: 'pr-clinical-notes-history-dialog',
  templateUrl: './clinical-notes-history-dialog.component.html',
  styleUrls: ['./clinical-notes-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalNotesHistoryDialogComponent {
  clinicalNotes$: Observable<WithRef<IClinicalNote>[]>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IClinicalNotesDialogData) {
    this.clinicalNotes$ = Patient.withPatientRelationships$(
      data.patient,
      [PatientRelationshipType.DuplicatePatient],
      ClinicalNote.all$
    ).pipe(
      map((notes) =>
        ClinicalNote.filterEmpty(notes).sort((a, b) =>
          sortMoment(toMoment(a.recordDate), toMoment(b.recordDate))
        )
      )
    );
  }
}
