<div class="layout-padding" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <button mat-stroked-button (click)="reportFacade.clearSelectedSummary()">
      Back
    </button>
    <h2 class="mat-headline-6" *ngIf="result$ | async as result">
      {{ result.name | splitCamel | titlecase }}
    </h2>
  </div>
  <button mat-stroked-button (click)="downloadCSV()">Download CSV</button>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  fxFlex
  matSort
  matSortActive="transactionDate"
  matSortDirection="asc"
>
  <ng-container matColumnDef="transactionDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date</th>
    <td mat-cell *matCellDef="let record">
      {{ record.transaction.createdAt | moment | amDateFormat: dateFormat }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="invoiceIssuedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Issued At</th>
    <td mat-cell *matCellDef="let record">
      {{ record.invoice.issuedAt | moment | amDateFormat: dateFormat }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="invoice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Invoice/Transaction Reference
    </th>
    <td mat-cell *matCellDef="let record">
      <div fxLayout="column">
        <a [routerLink]="record.invoice | invoiceUrl$ | async" target="_blank">
          {{ record.invoice.reference }}
        </a>
        <small class="mat-caption">({{ record.transaction.reference }})</small>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="patient">
    <th mat-header-cell *matHeaderCellDef>Patient</th>
    <td mat-cell *matCellDef="let record">
      <a [routerLink]="record.patient | patientUrl$ | async" target="_blank">
        {{ record.patient.name }}
      </a>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="transactionType">
    <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
    <td mat-cell *matCellDef="let record">
      {{ record.transaction.type | splitCamel | titlecase }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="transactionProvider">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let record">
      {{ record.transaction.provider | splitCamel | titlecase }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="transactionProviderType">
    <th mat-header-cell *matHeaderCellDef>Card / Manual Type</th>
    <td mat-cell *matCellDef="let record">
      <ng-container
        *ngIf="record.transaction.extendedData?.transactionType?.name"
      >
        {{ record.transaction.extendedData.transactionType.name }}
      </ng-container>
      <ng-container *ngIf="record.transaction.extendedData?.response?.cardType">
        {{ record.transaction.extendedData.response.cardType }}
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let record">
      <ng-container
        *ngIf="record.transaction.description"
      >
        {{ record.transaction.description }}
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="pendingAmount">
    <th mat-header-cell *matHeaderCellDef>Pending Amount</th>
    <td mat-cell *matCellDef="let record">
      <span [ngClass]="{ refund: record.pending < 0 }">
        {{ record.pending | currency }}
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <strong
        *ngIf="'pending' | map: sumAmount$:this | async as total"
        [ngClass]="{ refund: total < 0 }"
      >
        {{ total | currency }}
      </strong>
    </td>
  </ng-container>

  <ng-container matColumnDef="completeAmount">
    <th mat-header-cell *matHeaderCellDef>Complete Amount</th>
    <td mat-cell *matCellDef="let record">
      <span [ngClass]="{ refund: record.complete < 0 }">
        {{ record.complete | currency }}
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <strong
        *ngIf="'complete' | map: sumAmount$:this | async as total"
        [ngClass]="{ refund: total < 0 }"
      >{{ total | currency }}</strong
      >
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
