<div class="border-b border-solid border-slate-300 py-2">
  <div class="flex flex-row flex-wrap items-center gap-y-2">
    <div class="order-1 w-full md:w-5/12 lg:w-4/12">
      <ng-container *ngTemplateOutlet="dateTemplate" />
    </div>
    <div
      class="order-2 flex w-full items-center md:order-3 md:w-5/12 lg:order-2 lg:w-5/12"
    >
      <ng-container *ngTemplateOutlet="treatmentTemplate" />
      <ng-container *ngTemplateOutlet="tagsTemplate" />
    </div>
    <div
      class="order-3 w-full md:order-2 md:w-7/12 lg:order-3 lg:w-3/12 2xl:order-3"
    >
      <ng-container *ngTemplateOutlet="actionsTemplate" />
    </div>
  </div>
</div>

<ng-template #dateTemplate>
  <div class="flex flex-auto flex-row items-center gap-2">
    <ng-container
      *ngIf="participants$ | async as participants; else noParticipants"
    >
      <ng-container *ngIf="participants.length > 0; else noParticipants">
        <pt-user-icon
          *ngFor="let participant of participants; trackBy: trackByParticipant"
          [src]="participant | profileImage$ | async"
          [name]="participant.name"
          [diameter]="50"
        />
      </ng-container>
    </ng-container>

    <ng-template #noParticipants>
      <div
        class="h-12 w-12 rounded-full border border-solid border-slate-200 bg-slate-100"
      ></div>
    </ng-template>

    <div class="flex flex-col" *ngIf="appointment$ | async as appointment">
      <div class="text-lg font-bold">
        <ng-container *ngIf="appointment.event as event; else unscheduled">
          <ng-container
            *ngIf="
              event.from
                | momentTimezone$: appointment.practice.ref
                | async as eventDate;
              else unscheduled
            "
          >
            {{ eventDate | moment | amDateFormat: dateFormat }}
          </ng-container>
        </ng-container>
      </div>
      <div>
        <span *ngIf="organisation.hasMultiplePractices$ | async">
          {{ appointment.practice.name }} -
        </span>
        {{ appointment | duration }} mins
      </div>
    </div>
  </div>
</ng-template>

<ng-template #unscheduled>Unscheduled</ng-template>

<ng-template #treatmentTemplate>
  <div class="flex-auto">
    <div class="flex flex-row" *ngIf="treatmentStep$ | async as treatmentStep">
      <div>{{ treatmentStep.name }}</div>
      <pr-treatment-category-indicator
        *ngIf="treatmentCategory$ | async as category"
        [category]="category"
        displayOnly
      />
    </div>
    <div
      class="appointment-status font-bold"
      [ngClass]="appointment.status"
      *ngIf="appointment$ | async as appointment"
    >
      {{ appointment.status | splitCamel | titlecase }}
    </div>
  </div>
</ng-template>

<ng-template #tagsTemplate>
  <ng-container *ngIf="tags$ | async as tags">
    <div class="flex-auto" *ngIf="tags.length">
      <pr-tag-limit-list [tags]="tags" />
    </div>
  </ng-container>
</ng-template>

<ng-template #actionsTemplate>
  <div class="flex items-center justify-end">
    <pr-appointment-card-actions
      [actions]="actions"
      [appointment]="appointment$ | async"
      [patient]="patient$ | async"
    />

    <pr-appointment-menu [appointment]="appointment$ | async" />
  </div>
</ng-template>
