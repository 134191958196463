import { type ICSVExport } from '@principle-theorem/ng-shared';
import { type IReferralSourcesGrouping } from '../referral-sources-report.store';

export interface IReferralSourcesSummaryCSV {
  referralSource: string;
  patientCount: string;
  treatmentTotal: string;
}

export class ReferralSourcesSummaryToCSV
  implements ICSVExport<IReferralSourcesGrouping, IReferralSourcesSummaryCSV>
{
  headers = ['Referral Source', 'Patient Count', 'Treatment Total'];

  translate(records: IReferralSourcesGrouping[]): IReferralSourcesSummaryCSV[] {
    return records.map((record) => ({
      referralSource: record.referralSource,
      patientCount: record.records.length.toString(),
      treatmentTotal: record.treatmentTotal.toString(),
    }));
  }
}
