import { type ICSVExport } from '@principle-theorem/ng-shared';
import {
  isManualExtendedData,
  isPurchaseTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { type IReconciliationTransactionReportRecord } from '@principle-theorem/reporting';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';
import { isTransactionCompleteCallbackData } from '@principle-theorem/tyro';
import { startCase } from 'lodash';

export interface IReconciliationTransactionDrilldownCSV {
  transactionDate: string;
  invoiceIssuedAt: string;
  patient: string;
  invoice: string;
  transactionProvider: string;
  transactionProviderType: string;
  description: string;
  pending: string;
  complete: string;
}

export class ReconciliationTransactionDrilldownToCSV
  implements
    ICSVExport<
      IReconciliationTransactionReportRecord,
      IReconciliationTransactionDrilldownCSV
    >
{
  headers = [
    'Transaction Date',
    'Invoice Issued At',
    'Patient',
    'Invoice/Transaction Reference',
    'Type',
    'Card / Manual Type',
    'Description',
    'Pending Amount',
    'Complete Amount',
  ];

  translate(
    records: IReconciliationTransactionReportRecord[]
  ): IReconciliationTransactionDrilldownCSV[] {
    return records.map((record) => {
      let transactionProviderType = '';
      if (isManualExtendedData(record.transaction.extendedData)) {
        transactionProviderType =
          record.transaction.extendedData.transactionType?.name ?? '';
      }
      if (
        isPurchaseTransactionExtendedData(record.transaction.extendedData) &&
        isTransactionCompleteCallbackData(
          record.transaction.extendedData.response
        )
      ) {
        transactionProviderType =
          record.transaction.extendedData.response.cardType ?? '';
      }

      return {
        transactionDate: toMoment(record.transaction.createdAt).format(
          DAY_MONTH_YEAR_FORMAT
        ),
        invoiceIssuedAt: record.invoice.issuedAt
          ? toMoment(record.invoice.issuedAt).format(DAY_MONTH_YEAR_FORMAT)
          : '',
        patient: record.patient.name,
        invoice: record.invoice.reference,
        transactionProvider: startCase(record.transaction.provider),
        transactionProviderType,
        description: record.transaction.description ?? '',
        pending: record.pending.toString(),
        complete: record.complete.toString(),
      };
    });
  }
}
